import axios from 'axios';

import { dispatch } from 'store';
import { setHasErrorReducer, setErrorMessageReducer } from 'store/slices/error';

export default async function createStripeCharge(
    amount,
    dibsuserid,
    currency,
    custStripeId,
    pmid,
    dibsStudioId,
    items,
    packageItems,
    stripeidForThisStudio,
    creditApplied,
    cartPreparedForCheckout,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/create-charge-from-pm`;
    let datatosend = {};
    const reqbody = {
        amount,
        custStripeId,
        dibsuserid,
        currency,
        pmid,
        dibsStudioId,
        items,
        packageItems,
        stripeidForThisStudio,
        creditApplied,
        cartPreparedForCheckout,
    };
    const charge = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;

                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from creating charge on stripe: ${err}`);
                dispatch(
                    setErrorMessageReducer(
                        `There was an error processing your card. ${err}`,
                    ),
                );
                dispatch(setHasErrorReducer(true));
                setTimeout(() => {
                    dispatch(setHasErrorReducer(false));
                    dispatch(setErrorMessageReducer(''));
                }, 5000);
                return err;
            });
    };
    // const ensureClientExistsInStripe = async () => {
    //     const url = `${baseURL}/stripe/create-user`;
    //     const params = {
    //         userid: dibsuserid,
    //         dibsId: dibsStudioId,
    //     };
    //     await axios
    //         .post(url, params)
    //         .then((res) => {
    //             const { data } = res;
    //             if (data === 1) {
    //                 checkdone = true;
    //             }
    //         })
    //         .catch((err) => {
    //             console.log(`error from creating user in stripe: ${err}`);
    //             dispatch(
    //                 setErrorMessageReducer(
    //                     `There was an error processing your card. ${err}`,
    //                 ),
    //             );
    //             dispatch(setHasErrorReducer(true));
    //             setTimeout(() => {
    //                 dispatch(setHasErrorReducer(false));
    //                 dispatch(setErrorMessageReducer(''));
    //             }, 5000);
    //         });
    //     return;
    // };
    // await ensureClientExistsInStripe().then(async () => {
    //     console.log('checkdone is: ', checkdone);
    //     if (checkdone) {
    //         await charge();
    //     } else {
    //         console.log(`client not created in stripe`);
    //         dispatch(
    //             setErrorMessageReducer(
    //                 `There was an error processing your card.`,
    //             ),
    //         );
    //         dispatch(setHasErrorReducer(true));
    //         setTimeout(() => {
    //             dispatch(setHasErrorReducer(false));
    //             dispatch(setErrorMessageReducer(''));
    //         }, 5000);
    //     }
    //     console.log('would call charge now');
    // });
    await charge();
    return datatosend;
}
