
import axios from 'axios';
export default async function startDibsTransactionCheckout(cartItems, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/start-dibs-transactions`;
    let datatosend = {};
    const checkout = async () => {
        await axios.post(basicurl, { cartItems, userid }).then((res) => {
            datatosend = res.data;
        }).catch((err) => {
            console.log('there was an error starting the dibs transaction', err);
            datatosend = { errorreturned: err };
        });
    };
    await checkout();
    return datatosend;
}
