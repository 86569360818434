import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ApptLayout from 'layouts/appointmentLayout';

const ShopifyView = () => {
    // redirect to the appt confirmation page
    const navigate = useNavigate();
    let { token } = useParams();
    console.log('\n\n\n\n\n\n\n\n');
    console.log('page that was called by shopify was', window.location.href);
    console.log('token: ', token);
    useEffect(() => {
        sessionStorage.setItem('shopifyToken', token);
        localStorage.setItem('shopifyToken', token);
        console.log('shopify token was placed in local storage');
        const apptToShowConfirmationFor =
            localStorage.getItem('apptBeingConfirmed');
        console.log('apptToShowConfirmationFor', apptToShowConfirmationFor);
        // setTimeout(() => {
        //     navigate('/appointments/226');
        // }, 1000);
    }, [navigate, token]);
    return <ApptLayout />;
};

export default ShopifyView;
