import { Grid, Typography, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';

import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';

import SubCard from 'ui-component/cards/AccountSubCard';
import { resetPasswordFromProfile } from 'firebaseConfig';
import setFirebaseAuth from 'actions/setFirebaseAuthentication';

const validationSchema = yup.object().shape({
    newpassword: yup
        .string()
        .required('Password is required')
        .min(
            7,
            'Your password must be at least 8 characters. Please try again.',
        ),
    matchpassword: yup
        .string()
        .oneOf(
            [yup.ref('newpassword'), null],
            'Your passwords must match. Please try again.',
        ),
});

// ==============================|| RESET PASSWORD FROM PROFILE ||============================== //

const ResetPassword = () => {
    const { userid, email, hasSetPwdFirebase } = useSelector(
        (state) => state.thisuser,
    );
    const { dibsStudioId, buttonControlsWidget, widgetOpenStyle } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [showUpdatePassword, setShowUpdatePassword] = useState(false);
    const [smaller, setSmaller] = useState(false);
    useEffect(() => {
        if (hasSetPwdFirebase) {
            setShowUpdatePassword(true);
        }
        if (buttonControlsWidget && widgetOpenStyle === 'full-page') {
            setSmaller(true);
        } else {
            setSmaller(false);
        }
    }, [buttonControlsWidget, hasSetPwdFirebase, widgetOpenStyle]);
    const formik = useFormik({
        initialValues: {
            currentpassword: '',
            newpassword: '',
            matchpassword: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                await resetPasswordFromProfile(
                    email,
                    values.currentpassword,
                    values.newpassword,
                )
                    .then((res) => {
                        if (!res.verifiedoldpassword) {
                            setErrorMessage(
                                'Your current password could not be validated. Please try it again.',
                            );
                            setHasError(true);
                            setTimeout(() => {
                                setHasError(false);
                                setErrorMessage('');
                            }, 10000);
                        }
                        if (res.verifiedoldpassword && !res.passwordUpdated) {
                            setErrorMessage(
                                'Your password could not be updated. Please refresh your screen and try again.',
                            );
                            setHasError(true);
                            setTimeout(() => {
                                setHasError(false);
                                setErrorMessage('');
                            }, 10000);
                        }

                        if (res.passwordUpdated) {
                            setSuccessMessage('Your password has been updated');
                            setHasSuccess(true);
                            setFirebaseAuth(userid);
                            setTimeout(() => {
                                setHasSuccess(false);
                                setSuccessMessage('');
                            }, 10000);
                        }
                    })
                    .catch((error) => {
                        console.log(
                            `caught error is: ${JSON.stringify(
                                error,
                                null,
                                2,
                            )}`,
                        );
                    });
                // navigate('/dashboard', { replace: true });
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <>
            {showUpdatePassword && (
                <Grid
                    item
                    xs={window.isTiny ? 12 : 5}
                    md={smaller ? 10 : 4.5}
                    lg={window.isTiny ? 11 : 4.5}
                    sx={{
                        mb: 2,
                        mr: window.isTiny ? 0 : 3,
                        mt: window.isTiny ? 1 : 0,
                    }}>
                    <SubCard
                        title="Change Password"
                        id="Change-Password"
                        dibsstudioid={dibsStudioId}>
                        <Grid container spacing={gridSpacing}>
                            {hasError && (
                                <Grid item xs={12}>
                                    <Typography variant="errorText">
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            )}
                            {hasSuccess && (
                                <Grid item xs={12}>
                                    <Typography variant="successText">
                                        {successMessage}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={11}
                                            sx={{ mb: 2, mt: 2, mr: 2 }}>
                                            <TextField
                                                name="currentpassword"
                                                label="Current Password"
                                                value={
                                                    formik.values
                                                        .currentpassword || ''
                                                }
                                                autoComplete="current-password"
                                                fullWidth
                                                onChange={formik.handleChange}
                                                placeholder="Enter your current password"
                                                onBlur={formik.handleBlur}
                                                type="password"
                                                error={
                                                    formik.touched
                                                        .currentpassword &&
                                                    Boolean(
                                                        formik.errors
                                                            .currentpassword,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched
                                                        .currentpassword &&
                                                    formik.errors
                                                        .currentpassword
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sx={{ mt: 2 }}>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        name="newpassword"
                                                        label="New Password"
                                                        autoComplete="new-password"
                                                        value={
                                                            formik.values
                                                                .newpassword ||
                                                            ''
                                                        }
                                                        fullWidth
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        placeholder="Enter your new password"
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        type="password"
                                                        error={
                                                            formik.touched
                                                                .newpassword &&
                                                            Boolean(
                                                                formik.errors
                                                                    .newpassword,
                                                            )
                                                        }
                                                        helperText={
                                                            formik.touched
                                                                .newpassword &&
                                                            formik.errors
                                                                .newpassword
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={11}
                                                    sx={{ mb: 2, mt: 2 }}>
                                                    <TextField
                                                        name="matchpassword"
                                                        label="Re-enter New Password"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        value={
                                                            formik.values
                                                                .matchpassword ||
                                                            ''
                                                        }
                                                        fullWidth
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        placeholder="Re-enter your new password"
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        error={
                                                            formik.touched
                                                                .matchpassword &&
                                                            Boolean(
                                                                formik.errors
                                                                    .matchpassword,
                                                            )
                                                        }
                                                        helperText={
                                                            formik.touched
                                                                .matchpassword &&
                                                            formik.errors
                                                                .matchpassword
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={8}
                                            sx={{ mt: 3, mb: 2 }}>
                                            <Button
                                                variant="checkout"
                                                fullWidth
                                                type="submit"
                                                disabled={formik.isSubmitting}
                                                // onClick={handleChargeCard}>
                                            >
                                                Update Password
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            )}
        </>
    );
};
export default ResetPassword;
