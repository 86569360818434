import { dispatch } from 'store';
import {
    setAlreadyHasAccount,
    setUserEmail,
    setFirstname,
    setLastname,
    setUserPhone,
    setStripeId,
    setUserId,
    setBirthday,
    setIsInLoginLinkFlow,
    setHasSignedInFirebase,
    setHasSetPwdFirebase,
    setStripeIdThisStudio,
    setCountryForMobile
} from 'store/slices/thisuser';
import doesUserHaveAccount from 'actions/doesUserHaveAccount.js';

export const addUserInfoAfterLogin = async (email, dibsId) => {
    await doesUserHaveAccount(email, dibsId).then((acct) => {
        const { hasAccount, info, stripeIdThisStudio } = acct;
        dispatch(setAlreadyHasAccount(hasAccount));
        if (hasAccount) {
            dispatch(setUserEmail(info.email));
            dispatch(setFirstname(info.firstName));
            dispatch(setLastname(info.lastName));
            dispatch(setUserPhone(info.mobilephone));
            dispatch(setStripeId(info.stripeid));
            dispatch(setBirthday(info.birthday));
            dispatch(setUserId(info.id));
            dispatch(setCountryForMobile(info.country));
            dispatch(setIsInLoginLinkFlow(false));
            dispatch(setHasSignedInFirebase(true));
            dispatch(setHasSetPwdFirebase(true));
            dispatch(setStripeIdThisStudio(stripeIdThisStudio));
        } else {
            dispatch(setLastname(''));
            dispatch(setUserPhone(''));
            dispatch(setUserId(0));
        }
    });
};
