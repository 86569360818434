import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Grid } from '@mui/material';

import { useSelector } from 'store';

import setupIntent from 'actions/stripe/createStripeSetupIntent.js';

import SetupForm from './setupForm.js';
import './stripe.css';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey, { apiVersion: '2023-10-16' });

const StripeCaptureCard = ({ color, location }) => {
    console.log('loading stripe here - index.js');
    const onDibsPlatform = true;
    const { stripeid, userid, firstname, lastname, email } = useSelector(
        (state) => state.thisuser,
    );
    const [requestedIntent, setRequestedIntent] = useState(false);
    const { itemsInCartHere } = useSelector((state) => state.cart);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [clientSecretValue, setClientSecret] = useState('');
    useEffect(() => {
        const getIntent = async () => {
            setRequestedIntent(true);
            console.log(
                `getting intent and stripeid is: ${stripeid} userid: ${userid} email: ${email} name: ${firstname} ${lastname}`,
            );
            const fullname = `${firstname} ${lastname}`;
            await setupIntent(stripeid, userid, email, fullname, dibsStudioId, onDibsPlatform)
                .then((intent) => {
                    console.log('the intent that is found is', intent);
                    if (intent.clientSecret !== undefined) {
                        setClientSecret(intent.clientSecret);
                    } else {
                        console.log('there was an error with the intent');
                        // todo - handle this error - tell user to reset their stripe info
                        // problem getting your credit card info - please go to billing update billing info
                    }
                })
                .catch((err) => {
                    console.log('\n\n\n\nerror when setting up intent');
                    console.log(err);
                });
        };
        if (!requestedIntent) {
            getIntent();
        }
    }, [
        dibsStudioId,
        email,
        firstname,
        itemsInCartHere,
        lastname,
        requestedIntent,
        stripeid,
        userid,
    ]);
    const appearance = {
        theme: 'flat',
        // labels: 'floating',
        variables: {
            // fontFamily: ' "Roboto", sans-serif',
            fontLineHeight: '1.25',
            fontSizeBase: '14px',
            borderRadius: '5px',
            colorBackground: '#f7f7f7',
            colorPrimaryText: '#262626',
            colorPrimary: '#ff6900',
        },
        rules: {
            '.Label': {
                marginBottom: '12px',
                fontWeight: '300',
            },
            '.Block': {
                // backgroundColor: 'var(--colorBackground)',
                backgroundColor: '#fff',
                boxShadow: 'none',
                padding: '5px',
            },
            '.Input': {
                padding: '12px',
                border: '1px solid',
                // borderColor: 'var(--colorPrimary)',
                borderColor: '#ddd',
                // boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                boxShadow: 'none',
                marginBottom: '3px',
            },
            '.Input:disabled, .Input--invalid:disabled': {
                color: 'lightgray',
            },
            '.Tab': {
                padding: '10px 12px 8px 12px',
                border: 'none',
                backgroundColor: '#fff',
            },
            '.Tab:hover': {
                border: 'none',
                boxShadow:
                    '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
            },
            '.Tab--selected, .Tab--selected:focus, .Tab--selected:hover': {
                border: 'none',
                backgroundColor: '#fff',
                boxShadow:
                    '0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)',
            },
        },
    };
    const options = {
        // passing the client secret obtained in step 3
        clientSecret: clientSecretValue,
        // Fully customizable with appearance API.
        appearance,
        mode: 'payment',
        amount: 1099,
        currency: 'usd',
    };
    return (
        <Grid
            item
            xs={12}
            backgroundColor="#fff"
            border={0}
            id="grid-just-above-form">
            {clientSecretValue.length < 2 && (
                <Grid item xs={12} sx={{ ml: 4, mt: 1 }}>
                    Loading...
                </Grid>
            )}
            {clientSecretValue.length > 2 && (
                <Elements
                    key={`elements-${clientSecretValue}`}
                    options={options}
                    stripe={stripePromise}>
                    <SetupForm color={color} location={location} />
                </Elements>
            )}
        </Grid>
    );
};
export default StripeCaptureCard;
