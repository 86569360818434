import axios from 'axios';
export default async function addToWaitlist(
    userid,
    eventid,
    purchasePlace,
    employeeid,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/add-to-waitlist`;
    let datatosend = {};
    const waitlist = async () => {
        await axios
            .post(basicurl, {
                userid,
                eventid,
                purchasePlace,
                employeeid,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error adding to waitlist: ${err}`);
                return err;
            });
    };
    await waitlist();
    return datatosend;
}
