import { Link, Typography, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useSelector } from 'store';

// ==============================|| FOOTER ||============================== //

const theme = createTheme({
    typography: {
        subtitle2: {
            fontSize: '0.65rem',
            fontWeight: 400,
            color: '#999',
        },
    },
});

const DibsFooter = () => {
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    let dibsTextPowered = 'Powered by Dibs';
    if (dibsStudioId === '226') dibsTextPowered = '';
    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ mt: 4, mb: 3 }}>
                <Typography
                    variant="subtitle2"
                    component={Link}
                    href="https://getondibs.com"
                    target="_blank"
                    underline="none">
                    {dibsTextPowered}
                </Typography>
            </Grid>
        </ThemeProvider>
    );
};

export default DibsFooter;
