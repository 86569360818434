import axios from 'axios';

export default async function createStripeUserConnected(
    userid,
    dibsId,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/create-user-connected`;
    let datatosend = {};
    const reqbody = {
        userid,
        dibsId,
    };
    const createuser = async () => {
        if (dibsId === 0) {
            return null;
        }
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                // returns stripeinfo
                const { data } = res;
                console.log(`data: ${JSON.stringify(data)}`);
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from creating new stripe connected user: ${err}`);
                return err;
            });
    };

    await createuser();
    return datatosend;
}
