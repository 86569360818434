export default function splitEventsForProcessing(k) {
    const items = [...k];
    const itemsToReturn = [];
    items.forEach((item) => {
        if (item.quantity > 1) {
            let quantity = item.quantity;
            for (let i = 0; i < quantity; i++) {
                itemsToReturn.push({
                    ...item,
                    quantity: 1,
                });
            }
        } else {
            itemsToReturn.push(item);
        }
    });
    return itemsToReturn;
}
