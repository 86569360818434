import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import { dispatch } from 'store';


export default function FormControlLabelPosition({ marginR, typographyVariant, labels, functionDispatch }) {
    const handleChange = (event) => {
        const { checked, value } = event.target;
        dispatch(functionDispatch({ [value]: checked }));
    };
    return (
        <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
                {labels.map((label) => {
                    return (
                        <FormControlLabel
                            key={label.key}
                            value={label.id}
                            control={<Checkbox checked={label.checked} sx={{ mr: marginR }} onChange={handleChange} />}
                            label={<Typography variant={typographyVariant}>{label.label}</Typography>}
                            labelPlacement="start"
                        />
                    );
                })
                }


            </FormGroup>
        </FormControl>
    );
}