import { Grid } from '@mui/material';

import { useSelector } from 'store';

const EmailSent = () => {
    const { email } = useSelector((state) => state.thisuser);
    const textToDisplay = `An email has been sent to ${email} from Dibs. Please check your email and click the link to login. If you don't see the email, please check your spam folder.`;
    return <Grid>{textToDisplay}</Grid>;
};
export default EmailSent;
