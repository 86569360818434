import axios from 'axios';
export default async function checkoutFreePackage(userid, cartItems) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/checkout-free-package`;
    let datatosend = {};
    const checkout = async () => {
        await axios
            .post(basicurl, {
                userid,
                cartItems,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error checking out with passes: ${err}`);
                return err;
            });
    };
    await checkout();
    return datatosend;
}
