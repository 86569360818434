import { Grid, Box } from '@mui/material';

import UserPaymentForm from './userPaymentForm';

const UserInfo = () => {
    return (
        <Box
            sx={{
                border: 1,
                borderRadius: 2,
                borderColor: '#d3d3d3',
                px: 2,
                pt: 2,
                minHeight: 100,
                mt: 0,
            }}>
            <Grid container>
                <Grid item xs={12} sx={{ mx: 2, mt: 2, mb: 6 }}>
                    <UserPaymentForm />
                </Grid>
            </Grid>
        </Box>
    );
};
export default UserInfo;
