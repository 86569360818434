import { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Box,
    Typography,
    Stack,
    FormControl,
    Checkbox,
    Link,
} from '@mui/material';
import { useSelector } from 'store';

const DifferentCardOption = ({ pack, showccaction }) => {
    const { studioConfig } = useSelector((state) => state.dibsstudio);
    const { ccinfo } = useSelector((state) => state.thisuser);
    const { cardlastfour } = useSelector((state) => state.cart);
    const { color } = studioConfig;
    const colortouse = `#${color}`;
    const [checked, setChecked] = useState(true);
    const [showDiffCardOption, setShowDiffCardOption] = useState(true);
    const processPromoCode = (code) => {
        console.log('code is: ', code);
        return null;
    };
    useEffect(() => {
        if (pack.totaltopay === 0) {
            setShowDiffCardOption(false);
        } else if (cardlastfour === 'xxxx') {
            setShowDiffCardOption(false);
        } else {
            setShowDiffCardOption(true);
        }
    }, [pack.totaltopay, cardlastfour]);
    const showcc = () => {
        showccaction(true);
    };

    if (!showDiffCardOption) return null;
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            border={0}
            sx={{ mr: 1.75 }}>
            <Link onClick={showcc} underline="none" color="#4b4949" style={{ cursor: 'pointer' }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: '200',
                        borderBottom: '1px dashed',
                        borderColor: '#ff6900',
                        p: 0,
                    }}>
                    Use a different card
                </Typography>
            </Link>
        </Grid>
    );
};
export default DifferentCardOption;
