import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import { dispatch } from 'store';
import {
    setPageSelected,
    setShowingSchedule,
    setShowingAccount,
    setShowingAppointments,
} from 'store/slices/menu';

const AppointmentIcon = ({ on, color }) => {
    let { dibsId } = useParams();
    const colortoshow = `#${color}`;
    const navigate = useNavigate();
    const location = useLocation();
    const [oncolor, setOnColor] = useState(colortoshow);
    useEffect(() => {
        if (!on) {
            setOnColor('#CED4DA');
        }
        if (on) {
            setOnColor(colortoshow);
        }
    }, [colortoshow, on]);
    const goAppointments = () => {
        dispatch(setPageSelected('appointments'));
        dispatch(setShowingSchedule(false));
        dispatch(setShowingAccount(false));
        dispatch(setShowingAppointments(true));
        navigate(`/appointments/${dibsId}${location.search}`);
    };

    return (
        <Grid item onClick={() => goAppointments()}>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                    icon="fa-circle"
                    color={oncolor}
                    transform="grow-12"
                />
                <FontAwesomeIcon
                    icon="fa-light fa-clock"
                    color="#fff"
                    transform="grow-3"
                />
            </span>{' '}
        </Grid>
    );
};
export default AppointmentIcon;
