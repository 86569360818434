import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function sendConfirmationEmail(
    dibsStudioId,
    userid,
    cartPreparedForCheckout,
    creditApplied,
    totalAfterCreditApplied,
    packageItemsInCart,
) {
    const datatosend = {
        dibsId: dibsStudioId,
        userid,
        templateName: 'confirmation',
        cartPreparedForCheckout,
        source: 'widget',
        creditApplied,
        totalAfterCreditApplied,
        packageItemsInCart,
    };
    const url = getApiUrl('/send-email-confirmations');
    await axios
        .post(url, { data: datatosend })
        .then((res) => {
            const { data } = res;
            console.log('\nEmail normal confirmation was sent', data);
        })
        .catch((error) => {
            console.log(
                'there was an error - sending confirmaton email',
                error,
            );
        });
}
