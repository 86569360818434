import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import getNewStatus from 'actions/isUserNew';
import ListOfPackages from './packagesList';

import { dispatch, useSelector } from 'store';
import {
    setIsNewUser,
    setUserQualifiesPromos,
    setCardInfo,
    setStripeId,
    setStripeIdThisStudio,
    setRefreshPaymentOptions,
} from 'store/slices/thisuser';

// actions
import getPaymentMethodsForUser from 'actions/stripe/getAllStripePayments';
import createNewStripeUser from 'actions/users/getStripeIdForDibs';
import createNewStripeUserAtStudio from 'actions/users/getStripeIdForStudio';

import Cart from 'assets/icons/fabCart';
import CartFromTop from 'assets/icons/fabCartTop';
import setPaymentOptionsFromStripe from 'actions/stripe/users/setPaymentOptionsFromStripe';

const PackagesList = () => {
    const { email, refreshPaymentOptions } = useSelector(
        (state) => state.thisuser,
    );
    const [showCart, setShowCart] = useState(false);
    const [checked, setChecked] = useState(false);

    const { config } = useSelector((state) => state.dibsstudio);
    const { userid, stripeid, stripeidForThisStudio } = useSelector(
        (state) => state.thisuser,
    );
    const { cardlastfour } = useSelector((state) => state.cart);
    const { dibsStudioId } = config;
    useEffect(() => {
        const checktoseeifnewuser = async () => {
            await getNewStatus(email, dibsStudioId).then((status) => {
                const { isNew, lastVisit, newAgain, alreadyPurchasedPromo } =
                    status;
                if (alreadyPurchasedPromo) {
                    dispatch(setUserQualifiesPromos(false));

                    dispatch(setIsNewUser(false));
                } else if (newAgain || isNew) {
                    dispatch(setIsNewUser(true));
                } else {
                    dispatch(setIsNewUser(false));
                }
                if (!newAgain || alreadyPurchasedPromo) {
                    dispatch(setUserQualifiesPromos(false)); // set true for testing, false for production
                } else {
                    dispatch(setUserQualifiesPromos(true));
                }
            });
        };
        if (
            email !== '' &&
            dibsStudioId !== 0 &&
            email !== undefined &&
            email !== null
        ) {
            checktoseeifnewuser();
        }
    }, [dibsStudioId, email]);
    useEffect(() => {
        // if (itemsInCart.length > 0 || packageItemsInCart.length > 0) {
        //     setShowCart(true);
        // }
        const getPaymentMethods = async () => {
            await getPaymentMethodsForUser(dibsStudioId, userid).then(
                async (res) => {
                    if (res) {
                        const { paymentsDibs, paymentsConnectedAccount } = res;
                        await setPaymentOptionsFromStripe(res)
                            .then((response) => {
                                console.log(
                                    'setting payment options from stripe - res should be 1',
                                    response,
                                );
                            })
                            .catch((err) => {
                                console.log(
                                    'error setting payment options from stripe',
                                    err,
                                );
                            });
                        if (
                            paymentsConnectedAccount === null &&
                            paymentsDibs === null
                        ) {
                            return;
                        }
                        if (paymentsConnectedAccount.length > 0) {
                            // go to dibs payment options
                            // list cc info from connected account
                            const { card, id } = paymentsConnectedAccount[0];
                            const tosendfromconnected = {
                                pmid: null,
                                pmidstudio: id,
                                cardinfo: card,
                            };
                            dispatch(setCardInfo(tosendfromconnected));
                        } else if (paymentsDibs.length > 0) {
                            // go to dibs payment options
                            // list cc info from dibs
                            const { card, id } = paymentsDibs[0];
                            const tosendfromdibs = {
                                pmid: id,
                                pmidstudio: null,
                                cardinfo: card,
                            };
                            dispatch(setCardInfo(tosendfromdibs));
                        }
                    } else {
                        console.log('no payment methods found');
                    }
                },
            );
        };
        const setStripeInRedux = async () => {
            setChecked(true);
            console.log('CREATING A NEW STRIPE USER NOW');
            await createNewStripeUser(userid, dibsStudioId).then((res) => {
                console.log(
                    'res from createNewStripeUser - result should be stripeid: ',
                    res,
                );
                if (res !== undefined) {
                    dispatch(setStripeId(res));
                    // dispatch(setStripeIdThisStudio(res.stripeidStudio));
                }
                // come back to add stripeid for this studio
            });
        };
        if (
            (cardlastfour === 'xxxx' && stripeid !== null) ||
            refreshPaymentOptions
        ) {
            getPaymentMethods();
            dispatch(setRefreshPaymentOptions(false));
        }
        if (stripeid === null && !checked && userid !== 0) {
            setStripeInRedux();
        }
        const checkForStripeIdAtStudio = async () => {
            console.log('creating new stripe user for this studio');
            await createNewStripeUserAtStudio(userid, dibsStudioId).then(
                (res) => {
                    if (res !== '' && res !== null && res !== undefined) {
                        if (res.length > 5)
                            dispatch(setStripeIdThisStudio(res));
                    }
                },
            );
        };
        if (stripeidForThisStudio === null) {
            checkForStripeIdAtStudio();
        }
    }, [
        cardlastfour,
        checked,
        dibsStudioId,
        email,
        stripeid,
        userid,
        refreshPaymentOptions,
        stripeidForThisStudio,
    ]);

    return (
        <Box
            id="Box-holding-packages"
            sx={{
                border: 1,
                borderRadius: 2,
                borderColor: '#d3d3d3',
                // px: 2,
                // pt: 2,
                minHeight: 100,
                // mt: 0,
            }}>
            <Grid container id="next-container">
                {showCart && <Cart />}
                <Grid item xs={12} sx={{ mt: 4 }}>
                    <ListOfPackages />
                </Grid>
            </Grid>
        </Box>
    );
};
export default PackagesList;
