import { Grid, Fade, Typography, Link } from '@mui/material';
import StripeCard from './new/loadStripe';

import './stripe.css';


const StripeWrapper = ({ color, actionToCancel, location }) => {
    const onClickDoThis = () => {
        actionToCancel();
    };
    return (
        <Fade in>
            <Grid container justifyContent="center" display="flex" border={0}>
                <Grid
                    item
                    border={0}
                    id="item-package-card-wrapper"
                    sx={{
                        mb: 3,
                        display: 'flex',
                        px: 1,
                    }}>
                    <StripeCard color={color} location={location} setup />
                </Grid>
                <Grid item xs={12}>
                    <Link onClick={onClickDoThis} sx={{ cursor: 'pointer' }}>
                        <Typography variant="body2" align="center">
                            Cancel
                        </Typography>
                    </Link>
                </Grid>
            </Grid>
        </Fade>
    );
};
export default StripeWrapper;
