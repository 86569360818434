import { differenceBetweenTimes } from './index';

export const isEarlyCancel = (classdate, cancelTime) => {
    let isEarly = false;
    const classDate = new Date(classdate);
    const nowdate = new Date();
    const difference = differenceBetweenTimes(nowdate, classDate);
    const differenceinHours = difference / 60;
    if (differenceinHours >= cancelTime) {
        isEarly = true;
    }
    return isEarly;
};
