import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PackagesListItemTiny from './packagesListItemTiny';
import { useSelector, dispatch } from 'store';
import checkNewUser from 'actions/isUserNew';

import {
    setIsNewUser,
    setUserQualifiesPromos
} from 'store/slices/thisuser';

const PackagesListOnCheckout = ({ matchDownXl, matchUpSM }) => {

    const isWideButNotTooWide = (matchDownXl && matchUpSM)
    const { packagesForCheckoutPromo } = useSelector((state) => state.packages);
    const { isNewUser, email } = useSelector((state) => state.thisuser);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { editingPaymentMethodInCart, packEditingFor } = useSelector((state) => state.cart);
    const [packageEditing, setPackageEditing] = useState(0);
    useEffect(() => {
        if (editingPaymentMethodInCart) {
            setPackageEditing(packEditingFor);
        }
    }, [editingPaymentMethodInCart, packEditingFor])

    // if user is new - get top 3 options no null
    // if user is not new - get regular options no new
    const [packages, setPackages] = useState([]);
    const titleString = 'Unlock Savings With Our Exclusive Packages';
    useEffect(() => {
        checkNewUser(email, dibsStudioId).then((res) => {
            const { newAgain, alreadyPurchasedPromo, isNew, lastVisit } = res;
            if (alreadyPurchasedPromo) {
                dispatch(setIsNewUser(false));
                dispatch(setUserQualifiesPromos(false));
            }
            else if (newAgain || isNew) {
                dispatch(setIsNewUser(true));
                dispatch(setUserQualifiesPromos(true));
            } else {
                dispatch(setIsNewUser(false));
                dispatch(setUserQualifiesPromos(false));
            }
        }).catch((err) => {
            console.log('there was an error gettin the users new status', err);
        });
    }, [email, dibsStudioId]);
    useEffect(() => {
        setPackages(packagesForCheckoutPromo);
    }, [packagesForCheckoutPromo, isNewUser]);
    useEffect(() => {
        if (isNewUser) {
            const newPackages = packagesForCheckoutPromo.filter((pack) => {
                return pack.promo_sort_order != null;
            });
            setPackages(newPackages);
        } else {
            const newPackages = packagesForCheckoutPromo.filter((pack) => {
                return !pack.onlyFirstPurchase;
            });
            setPackages(newPackages);
        }
    }, [isNewUser, packagesForCheckoutPromo]);


    return (
        <Grid container spacing={matchDownXl ? 1 : 1.5} borderRadius={2} border={0} justifyContent="space-evenly" sx={{
            backgroundColor: '#F5F5F5',
            // p: 0,
            px: isWideButNotTooWide ? 0.75 : 1,
            py: 0.5
        }}>
            <Grid item xs={12} align="center">
                <Typography variant="coloredHeader">
                    {titleString}
                </Typography>
            </Grid>

            {packages.map((pack, i) => {
                if (i < 3) {
                    let buttonText = 'Buy Package';
                    if (pack.price === 0) {
                        buttonText = 'Add Package';
                    }
                    const packInfo = {
                        ...pack,
                        buttonText,
                    }

                    if (editingPaymentMethodInCart) {
                        if (pack.id === packageEditing && editingPaymentMethodInCart) {
                            return (
                                <Grid item xs={12} key={pack.id} id={`item-holding-package-tiny-${pack.id}`} sx={{ pb: 1.25, mt: 0.75, mb: 0.75 }}>
                                    <Grid container justifyContent="center">
                                        <PackagesListItemTiny pack={packInfo} isWideButNotTooWide={isWideButNotTooWide} confirmingPurchase={false} />
                                    </Grid>
                                </Grid>
                            );
                        } else {
                            return null;
                        }
                    }

                    return (
                        <Grid item xs={12} sm={6} md={3} key={pack.id} id={`item-holding-package-tiny-${pack.id}`} sx={{ pb: 1.25, mt: 0.75, mb: 0.75 }}>
                            <PackagesListItemTiny pack={packInfo} isWideButNotTooWide={isWideButNotTooWide} confirmingPurchase={false} />
                        </Grid>
                    );
                } else {
                    return null;
                }

            })
            }

        </Grid>
    );
};
export default PackagesListOnCheckout;