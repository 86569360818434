import * as React from 'react';
import { Grid } from '@mui/material';

import { useSelector, dispatch } from 'store';
import { setWdth } from 'store/slices/dibsstudio';

// component imports
import Header from './header';
import DibsFooter from 'components/footers/dibs-footer';

import ValidateLink from 'components/auth/ValidateLinkOuter';

const AuthLayout = () => {
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [cdwidth, setCdWidth] = React.useState(1000);
    React.useEffect(() => {
        setCdWidth(setCdWidth);
        const windowWidth = window.innerWidth;
        const cdWidth = parseInt(windowWidth * 0.6);
        setCdWidth(cdWidth);
        // dispatch(setWdth(cdWidth));
    }, [dibsStudioId]);

    return (
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <Grid item xs={11.5} md={11} lg={11} xl={10.5} sx={{ mt: 1 }}>
                <Grid
                    container
                    sx={{ mt: 1 }}
                    justifyContent="center"
                    alignItems="flex-start">
                    {/* <Header /> */}
                    {/* <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"> */}
                    <Grid
                        item
                        xs={12}
                        id="validat"
                        sx={{ mt: 5, width: cdwidth, px: 2 }}>
                        <ValidateLink />
                    </Grid>
                    {/* </Grid>
                    </Grid> */}
                    {/* <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start">
                            <Grid item xs={12}>
                                <DibsFooter />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AuthLayout;
