import axios from 'axios';
export default async function getAllStripePayments(dibsStudioId, userid) {
    // get all stripe payment methods
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/get-all-payments`;
    let datatosend = {};
    const reqbody = {
        dibsStudioId,
        userid,
    };
    const intent = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error from getting stripe payments Action: ${err}`,
                );
                return err;
            });
    };
    await intent();
    return datatosend;
}
