import * as React from 'react';
import { Grid, Fade } from '@mui/material';

import { useSelector, dispatch } from 'store';
import { setWdth } from 'store/slices/dibsstudio';

// component imports
import AppointmentList from 'components/appointment-types/index';

const AppointmentLayout = () => {
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId, buttonControlsWidget, widgetOpenStyle, widgetWidth } =
        config;

    React.useEffect(() => {
        // setCdWidth(setCdWidth);
        const windowWidth = window.innerWidth;
        const cdWidth = window.isTiny
            ? parseInt(windowWidth * 0.97)
            : parseInt(windowWidth * 0.8);
        // setCdWidth(cdWidth);

        // dispatch(setWdth(cdWidth));
    }, [dibsStudioId]);
    let marginTopToUse = buttonControlsWidget ? 0 : 4;
    if (dibsStudioId === '226') {
        marginTopToUse = 0;
    }

    return (
        <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            border={0}
            id="container-holding-appts"
            width={widgetWidth}
            alignItems="flex-start">
            <Grid
                item
                xs={buttonControlsWidget ? 12 : 11.5}
                md={buttonControlsWidget ? 12 : 11}
                lg={buttonControlsWidget ? 12 : 10.5}
                sx={{ mt: 1 }}>
                <Fade in={true} timeout={600}>
                    <Grid
                        container
                        id="appointments-highest-level-container"
                        border={0}
                        sx={{ mt: marginTopToUse }}
                        justifyContent="center"
                        alignItems="flex-start">
                        {/* <Header /> */}
                        {/* {showCart && <Cart />} */}
                        <Grid item xs={12} sx={{ mt: 1 }}>
                            <Grid
                                container
                                direction="row"
                                id="appointment-container-holds-list"
                                justifyContent="flex-start"
                                border={0}
                                alignItems="center">
                                <Grid item xs={12}>
                                    <AppointmentList />
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <Grid item xs={12}>
                                <DibsFooter />
                            </Grid>
                        </Grid>
                    </Grid> */}
                        {/* <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <Grid item xs={12}>
                                Test Logout Button
                            </Grid>
                        </Grid>
                    </Grid> */}
                    </Grid>
                </Fade>
            </Grid>
        </Grid>
    );
};

export default AppointmentLayout;
