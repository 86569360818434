import axios from 'axios';
export default async function reinstateMembership(
    dibsId,
    userid,
    passid,
    packageid,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/reinstate-membership`;
    let datatosend = {};
    const tosend = {
        dibsId,
        userid,
        passid,
        packageid,
    };
    const cancel = async () => {
        await axios
            .post(basicurl, tosend)
            .then((res) => {
                console.log(
                    `res from reinstate membership: ${JSON.stringify(res)}`,
                );
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error checking whether class exists: ${err}`);
                return err;
            });
    };
    await cancel();
    return datatosend;
}
