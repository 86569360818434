import * as React from 'react';
import axios from 'axios';
import { Grid } from '@mui/material';

import { useSelector, dispatch } from 'store';

import {
    setColor,
    setTimeZone,
    setDibsStudioId,
} from 'store/slices/dibsstudio';

// component imports
import Header from './header';
import PaymentList from 'components/payment/index';
import DibsFooter from 'components/footers/dibs-footer';

const PaymentLayout = ({ dibsId }) => {
    // dispatch(setPageSelected('checkout'));

    return (
        <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid item xs={11.5} md={11} lg={11} xl={9.5}>
                <Grid container sx={{ mt: 1 }}>
                    {/* <Header /> */}
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Grid item xs={12}>
                                <PaymentList dibsId={dibsId} />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <Grid item xs={12}>
                                <DibsFooter />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PaymentLayout;
