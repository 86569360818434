import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import RightChevronIcon from 'assets/icons/rightChevron';
import LeftChevronIcon from 'assets/icons/leftChevron';
import DatesFormatted from './datesFormatted';

import { useSelector } from 'store';

const findGridSizeBasedOnScreen = async (buttonControlsWidget, widgetWidth) => {
    let windowsize = window.innerWidth;
    if (buttonControlsWidget) {
        windowsize = widgetWidth;
    }
    const sizes = { oneChevron: 11, twoChevron: 9.8 };
    if (windowsize <= 500) {
        sizes.oneChevron = 10.7;
        sizes.twoChevron = 9.5;
    } else if (windowsize <= 700) {
        sizes.oneChevron = 11;
        sizes.twoChevron = 10.2;
    } else if (windowsize <= 800) {
        sizes.oneChevron = 11.4;
        sizes.twoChevron = 10.9;
    } else if (windowsize <= 900) {
        sizes.oneChevron = 11.5;
        sizes.twoChevron = 11;
    } else if (windowsize <= 1100) {
        sizes.oneChevron = 11.2;
        sizes.twoChevron = 10.6;
    } else if (windowsize <= 1300) {
        sizes.oneChevron = 11.3;
        sizes.twoChevron = 11;
    } else {
        sizes.oneChevron = 11.5;
        sizes.twoChevron = 11;
    }
    return sizes;
};

const DateHeaders = ({ dateToFilterTo, filterDate }) => {
    const { dateSelected, movedScheduleOut, daystomove } = useSelector(
        (state) => state.menu,
    );
    const [isTinyScreen, setIsTinyScreen] = useState(window.innerWidth < 500);
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const { color, intervalEnd } = studioConfig;
    const { buttonControlsWidget, widgetWidth } = config;
    const [showBackChevron, setShowBackChevron] = useState(false);
    const [dateSelectedToPass, setDateSelectedToPass] = useState(dateSelected);
    const [showForwardChevron, setShowForwardChevron] = useState(true);
    const [sizeDateGrid, setSizeDateGrid] = useState(11.5);
    const [numChevronsShow, setNumChevronsShow] = useState(1);

    useEffect(() => {
        if (!filterDate) {
            if (movedScheduleOut > 0) {
                setShowBackChevron(true);
            } else {
                setShowBackChevron(false);
            }
            if (movedScheduleOut <= 0) {
                setShowBackChevron(false);
            }
            if (movedScheduleOut + daystomove >= intervalEnd) {
                setShowForwardChevron(false);
            }
            if (movedScheduleOut + daystomove < intervalEnd) {
                setShowForwardChevron(true);
            }
        }
        if (window.innerWidth < 500) {
            setIsTinyScreen(true);
        } else {
            setIsTinyScreen(false);
        }

        const establishGridSize = async () => {
            await findGridSizeBasedOnScreen(
                buttonControlsWidget,
                widgetWidth,
            ).then((gridsize) => {
                if (showBackChevron && showForwardChevron) {
                    setNumChevronsShow(2);
                    setSizeDateGrid(gridsize.twoChevron);
                } else {
                    setSizeDateGrid(gridsize.oneChevron);
                    setNumChevronsShow(1);
                }
            });
        };
        establishGridSize();
    }, [
        buttonControlsWidget,
        daystomove,
        intervalEnd,
        movedScheduleOut,
        showBackChevron,
        showForwardChevron,
        widgetWidth,
        filterDate,
    ]);
    useEffect(() => {
        if (filterDate) {
            setDateSelectedToPass(dateToFilterTo);
            setShowForwardChevron(false);
            setShowBackChevron(false);
        }
    }, [dateToFilterTo, filterDate]);
    return (
        <Grid
            container
            justifyContent="space-between"
            border={0}
            overflow="hidden"
            height={isTinyScreen ? '55px' : '64px'}
            sx={{
                pl: buttonControlsWidget && !isTinyScreen ? 1 : 0,
                pr: buttonControlsWidget && !isTinyScreen ? 1 : 0,
            }}
            // borderTop={2}
            // borderColor="#d3d3d3"
            id="container-holds-dates-and-chevrons-inner">
            {showBackChevron && (
                <Grid item sx={{ border: 0 }}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={{ minHeight: '100%' }}>
                        <LeftChevronIcon />
                    </Grid>
                </Grid>
            )}
            <Grid item xs={sizeDateGrid} sx={{ mr: 0, mt: 1, mb: 1 }}>
                <Grid
                    container
                    border={0}
                    display="flex"
                    justifyContent="flex-start"
                    // sx={{ border: 4, borderColor: 'pink' }}
                >
                    <Grid item xs={12}>
                        <DatesFormatted
                            selected={dateSelectedToPass}
                            color={color}
                            numChevronsShow={numChevronsShow}
                            filterDate={filterDate}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {showForwardChevron && (
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        sx={{ minHeight: '100%' }}>
                        <RightChevronIcon />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
export default DateHeaders;
