import React from 'react';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Provider } from 'react-redux';
import axios from 'axios';
import './App.css';
// import { Typography } from '@mui/material';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import sendMessageToParent from 'helpers/sendMessageToParent';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import WidgetLoader from 'loaders/widgetLoader';

import {
    faSackDollar,
    faDollarSign,
    faPlus,
    faCheck,
    faSquare,
    // faCheckSquare,
    faXmarkSquare,
    faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons';
import {
    faCircle,
    faChevronRight,
    faChevronLeft,
    faCreditCard,
    faX,
    faCheckSquare,
    faPlus as fasPlus,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faUser,
    faBagShopping,
    faCalendar,
    faClock,
    faCalendarWeek,
    faCalendarLines,
} from '@fortawesome/pro-light-svg-icons';

import { faCalendarDays } from '@fortawesome/pro-thin-svg-icons';

import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
} from '@fortawesome/free-brands-svg-icons';

import { store } from 'store';
import typography from 'themes/typography';
import defaultColor from 'assets/scss/_themes-vars.module.scss';

if (window.location !== window.parent.location) {
    window.isIframe = true;
} else {
    // The page is not in an iframe
    window.isIframe = false;
}
if (window.innerWidth < 550) {
    window.isTiny = true;
} else {
    window.isTiny = false;
}

library.add(
    faBagShopping,
    faSackDollar,
    faDollarSign,
    faCalendarLines,
    faCircle,
    faCalendarDays,
    faUser,
    faCalendar,
    faChevronRight,
    faPlus,
    fasPlus,
    faCalendarWeek,
    faChevronLeft,
    faXmarkSquare,
    faCheck,
    faSquare,
    faCheckSquare,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faCreditCard,
    faX,
    faClock,
    faPenToSquare,
);

let theme = createTheme(typography);
const colors = defaultColor;

const Components = {
    MuiButton: {
        defaultProps: {
            elevation: 0,
        },
        styleOverrides: {
            root: {
                color: '#fff',
                padding: '5px 15px',
            },
            outlined: {
                width: '125px',
                my: 0.5,
                lineHeight: 1,
            },
        },
    },
    MuiInputLabel: {
        styleOverrides: {
            root: {
                lineHeight: '0.8rem',
            },
        },
    },

    MuiInputBase: {
        styleOverrides: {
            root: {
                borderRadius: 4,
                fontWeight: 300,
                fontSize: '0.875rem',
                lineHeight: '1rem',
                // paddingTop: 80.25,
                // padding: 3,
                color: theme.palette.text.dark,
            },
            input: {
                '&.MuiOutlinedInput-input': {
                    padding: '12px',
                    '&:hover': {
                        border: 1,
                        borderColor: theme.palette.primary.light,
                    },
                },
            },
        },
    },

    // MuiPaper: {
    //     styleOverrides: {
    //         root: {
    //             borderRadius: 16,
    //         },
    //     },
    // },
    MuiOutlinedInput: {
        styleOverrides: {
            root: {
                lineHeight: 1,
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            elevation: 0,
        },
        styleOverrides: {
            root: {
                lineHeight: 1.2,
            },
            outlined: {
                width: '125px',
                my: 0.5,
                lineHeight: 1,
            },
            // input: {
            //     padding: '5px',
            // },
        },
    },
};

theme = createTheme({ ...theme, components: Components });
const Typography = {
    typography: {
        subtitle1: {
            fontSize: window.isTiny ? 12 : 14,
            lineHeight: 1.7,
        },
        subtitle2: {
            fontSize: 14,
            lineHeight: 1.7,
            fontWeight: 300,
        },
        guidance: {
            fontSize: '0.85rem',
            color: colors.primaryDark,
            fontWeight: 500,
        },
        checkout: {
            fontSize: window.isTiny ? 13 : 15,
            lineHeight: 1.1,
        },
        selectProviderAgain: {
            fontSize: window.isTiny ? 10 : 15,
            lineHeight: 1.1,
        },
        h3: {
            fontSize: '1.25rem',
            fontWeight: 300,
        },
        bookNowText: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: 1.2,
        },
        packagehead: {
            fontSize: '1rem',
            fontWeight: 500,
            lineHeight: '1.4rem',
        },
        h6: {
            fontSize: '0.65rem',
            fontWeight: 400,
            color: '#999',
        },
        eventtitle: {
            fontSize: window.isTiny ? '0.85rem' : '0.9rem',
            fontWeight: 600,
            lineHeight: 1.5,
        },
        eventtime: {
            fontSize: window.isTiny ? '0.75rem' : '0.8rem',
            fontWeight: 500,
        },
        subeventtime: {
            fontSize: '0.70rem',
            fontWeight: 400,
            color: '#ADB5BD',
            lineHeight: 1,
        },
        eventtimewithcolor: {
            fontSize: '0.75rem',
            fontWeight: 500,
            color: '#0820a3',
            lineHeight: '1.4rem',
        },
        eventtimewithcolorsmalltext: {
            color: '#0820a3',
            fontWeight: 500,
            fontSize: '0.7rem',
        },
        eventlocation: {
            fontSize: window.isTiny ? '0.75rem' : '0.85rem',
            lineHeight: window.isTiny ? 1.1 : 1.2,
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventname: {
            fontSize: window.isTiny ? '0.75rem' : '0.85rem',
            lineHeight: window.isTiny ? 1.1 : 1.2,
            fontWeight: 400,
        },
        eventinstructor: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventprice: {
            fontSize: window.isTiny ? '1rem' : '1rem',
            fontWeight: 400,
            color: '#6C757D',
            marginTop: '0.5rem',
        },
        strikethroughprice: {
            fontSize: window.isTiny ? '1rem' : '1.25rem',
            fontWeight: 400,
            color: '#C0C4C8',
            textDecoration: 'line-through',
        },
        headers: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        packageNames: {
            fontSize: '0.8rem',
            fontWeight: 500,
            lineHeight: '1.2rem',
        },
        packageNamesTiny: {
            fontSize: '0.8rem',
            fontWeight: 500,
            lineHeight: '1rem',
        },
        numClasses: {
            fontSize: '0.8rem',
            fontWeight: 400,
            // color: '#6C757D',
        },
        packageSummary: {
            fontSize: '0.7rem',
            fontWeight: 400,
            // color: '#6C757D',
        },
        packageSummaryTotal: {
            fontSize: '0.7rem',
            fontWeight: 500,
            // color: '#6C757D',
        },
        expiration: {
            fontSize: '0.6rem',
            fontWeight: 400,
            color: '#ADB5BD',
            lineHeight: '0.8rem',
            fontStyle: 'italic',
        },
        packageSubheaders: {
            fontSize: '0.8rem',
            fontWeight: 300,
            color: '#ADB5BD',
        },
        packageClasses: {
            fontSize: '0.9rem',
            fontWeight: 400,
        },
        subMenuTitle: {
            fontSize: 15,
            lineHeight: 1.7,
            fontWeight: 500,
        },
        regularText: {
            fontSize: 14,
            lineHeight: 1.7,
            fontWeight: 400,
        },
        // h1: {
        //     fontSize: '1.2rem',
        //     fontWeight: 500,
        // },
        h1title: {
            fontSize: '1.1rem',
            fontWeight: 300,
        },
        h2: {
            fontSize: '1.3rem',
            fontWeight: 400,
            lineHeight: '1.3',
        },
        h1: {
            fontSize: '1.3rem',
            fontWeight: 700,
            lineHeight: '1.3',
        },
        titleforbookservice: {
            fontSize: '1.3rem',
            fontWeight: 700,
            lineHeight: '1.3',
        },
        waitlist: {
            fontColor: '#414141',
            fontSize: '0.65rem',
            fontWeight: 700,
            paddingTop: '6px',
        },
        provider: {
            fontSize: '0.8rem',
            fontWeight: 400,
        },
        h4: {
            fontSize: '0.8rem',
            fontWeight: 500,
        },
        h4tiny: {
            fontSize: '0.6rem',
            fontWeight: 500,
        },
        apptTitle: {
            fontColor: '#060606',
            fontWeight: 500,
            // fontSize: window.isTiny ? '0.8rem' : '1rem',
            lineHeight: '1.3rem',
        },
        apptTitleNext: {
            fontColor: '#060606',
            fontWeight: 500,
            // fontSize: window.isTiny ? '0.8rem' : '1rem',
            lineHeight: '1rem',
        },
        apptSub: {
            color: '#a09d9d',
            fontWeight: 300,
            fontSize: '0.8rem',
        },
        apptMoreInfoText: {
            fontColor: '#060606',
            fontWeight: 400,
            fontSize: '0.8rem',
            lineHeight: '1.2rem',
        },
        apptMoreInfo: {
            fontColor: '#ff6900',
            fontWeight: 500,
            fontSize: '0.8rem',
            lineHeight: '1.2rem',
        },
        hidden: {
            fontSize: '0.8rem',
            color: '#fff',
        },
        h5staffname: {
            fontSize: '1.1rem',
            fontWeight: 400,
        },
        h5staffnametiny: {
            fontSize: '0.8rem',
            fontWeight: 500,
            lineHeight: '0.6rem',
        },
        h5stafftitle: {
            fontSize: '0.9rem',
            fontWeight: 400,
        },
        h5stafftitletiny: {
            fontSize: '0.7rem',
            fontWeight: 400,
            lineHeight: '1rem',
        },
        h5: {
            fontSize: '1.1rem',
            fontWeight: 400,
        },
        h5SmallTitle: {
            fontSize: '0.8rem',
            fontWeight: 400,
        },
        h5title: {
            fontSize: '1.1rem',
            fontWeight: 300,
            lineHeight: '1.5rem',
        },
        creditcard: {
            fontSize: '0.8rem',
            fontWeight: 300,
        },
        creditcardtiny: {
            fontSize: '0.65rem',
            fontWeight: 500,
        },
        applyCredit: {
            fontSize: '0.65rem',
            fontWeight: 500,
            color: '#ADB5BD',
        },
        errorText: {
            fontSize: '0.8rem',
            fontWeight: 500,
            color: colors.errorMain,
        },
        successText: {
            fontSize: '0.8rem',
            fontWeight: 500,
            color: colors.successMain,
        },
        boldHeader: {
            fontSize: '0.85rem',
            fontWeight: 600,
            color: colors.grey650,
            lineHeight: '1.1rem',
        },
        lightHeader: {
            fontSize: '0.85rem',
            lineHeight: '1rem',
            fontWeight: 300,
            color: colors.grey650,
        },
        lightHeadertiny: {
            fontSize: '0.65rem',
            fontWeight: 300,
            color: colors.grey650,
        },

        regHeader: {
            fontSize: '0.85rem',
            fontWeight: 400,
            lineHeight: '1.1rem',
            color: colors.grey650,
        },
        packageUsed: {
            fontSize: '0.85rem',
            fontWeight: 500,
            color: colors.grey400,
        },
    },
};
theme = createTheme({ ...theme, ...Typography });

function App() {
    let { dibsId } = useParams();
    const [primaryColor, setPrimaryColor] = React.useState('#eeeeee');
    // const [dibsWidth, setDibsWidth] = React.useState('100vw');
    const [useCaps, setUseCaps] = React.useState(false);
    const [hasSpecialFont, setHasSpecialFont] = React.useState(false);
    const [loadingWithModal, setLoadingWithModal] = React.useState(false);
    const [backgroundColor, setBackgroundColor] = React.useState('transparent');
    const [specialFont, setSpecialFont] =
        React.useState(`'Futura', sans-serif`);
    const dibsIdNum = parseInt(dibsId, 10);
    React.useEffect(() => {
        const baseURL = process.env.REACT_APP_BASE_URL;

        const basicurl = `${baseURL}/widget/get-basic-config`;

        // const basicurl =
        //     'http://192.168.0.36:3001/api/v2/widget/get-basic-config';
        //  console.log('basic url to connect to is', basicurl);

        if (dibsIdNum === 226) {
            setUseCaps(true);
            setHasSpecialFont(true);
            setSpecialFont(`'Futura', sans-serif`);
            setBackgroundColor('#D1CECD');
        } // hard code for now until we determine it is right
        if (dibsIdNum === 223) {
            // setUseCaps(true);
            setHasSpecialFont(true);
            setSpecialFont(`'freight-sans-pro', sans-serif`);
        } // hard code for now until we determine it is right
        const getColor = async () => {
            await axios
                .post(basicurl, { dibsStudioId: dibsIdNum })
                .then((response) => {
                    const colortosend = `#${response.data.color}`;
                    setPrimaryColor(colortosend);
                    sendMessageToParent({ primaryColor: colortosend });
                    if (response.data.buttonControlsWidget) {
                        setLoadingWithModal(true);
                        // setDibsWidth('100vw');
                        window.bookButtonUsed = true;
                        if (response.data.widgetOpenStyle === 'full-page') {
                            window.overlay = true;
                        } else if (response.data.widgetOpenStyle === 'right') {
                            window.opensRight = true;
                            window.isTiny = true;
                        } else {
                            window.opensRight = false;
                            window.overlay = false;
                            window.bookButtonUsed = false;
                        }
                    }
                });
        };
        getColor();
    }, [dibsId, dibsIdNum, loadingWithModal]);
    theme = createTheme({
        ...theme,
        palette: {
            ...theme.palette,
            background: {
                default: backgroundColor,
            },
            success: {
                main: colors.successMain,
            },
            primary: {
                main: primaryColor,
            },
            warning: {
                main: '#ffc107',
            },
            text: {
                primary: colors.grey700,
                secondary: colors.grey500,
                dark: colors.grey900,
                hint: colors.grey100,
            },
            common: {
                black: colors.darkPaper,
                lightgrey: colors.grey300,
                border: colors.greyborder,
            },
            profile: {
                main: colors.primary300,
            },
            error: {
                main: colors.errorMain,
            },
            contrastThreshold: 3,
            tonalOffset: 0.4,
        },
    });
    const fontToUse = hasSpecialFont ? specialFont : `'Roboto', sans-serif`;

    theme = createTheme({
        ...theme,
        typography: {
            ...theme.typography,
            fontFamily: [fontToUse],
            body1: {
                fontFamily: hasSpecialFont
                    ? specialFont
                    : `'Roboto', sans-serif`,
                // color: '#ff6900',
                lineHeight: 1,
                textTransform: useCaps ? 'uppercase' : 'none',
            },
            body2: {
                fontFamily: hasSpecialFont
                    ? specialFont
                    : `'Roboto', sans-serif`,
                // color: '#ff6900',
                textTransform: useCaps ? 'uppercase' : 'none',
            },
            providerUpdated: {
                fontSize: '0.8rem',
                fontWeight: dibsIdNum === 226 ? 600 : 400,
            },
            eventnameWBold: {
                fontSize: window.isTiny ? '0.75rem' : '0.85rem',
                lineHeight: window.isTiny ? 1.1 : 1.2,
                fontWeight: dibsIdNum === 226 ? 600 : 400,
            },
            coloredHeader: {
                fontSize: 14,
                lineHeight: 1.7,
                fontWeight: 500,
                color: primaryColor,
            },
            largeColoredText: {
                fontSize: 20,
                lineHeight: 1.7,
                fontWeight: 500,
                color: primaryColor,
            },
            eventwithcolor: {
                color: primaryColor,
                fontWeight: 500,
                fontSize: '0.7rem',
                cursor: 'pointer',
            },
            creditDisplay: {
                fontSize: 18,
                lineHeight: 1.7,
                fontWeight: 500,
                color: primaryColor,
            },
            apptSubUpdated: {
                color: dibsIdNum === 226 ? '#5E5A5A' : '#a09d9d',
                fontWeight: 300,
                fontSize: '0.8rem',
            },
        },
    });

    theme = createTheme({
        ...theme,
        transitions: {
            duration: {
                shortest: 150,
                shorter: 200,
                short: 250,
                // most basic recommended timing
                standard: 300,
                // this is to be used in complex animations
                complex: 375,
                // recommended when something is entering screen
                enteringScreen: 225,
                // recommended when something is leaving screen
                leavingScreen: 195,
            },
            easing: {
                // This is the most common easing curve.
                easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
                // Objects enter the screen at full velocity from off-screen and
                // slowly decelerate to a resting point.
                easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
                // Objects leave the screen at full velocity. They do not decelerate when off-screen.
                easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
                // The sharp curve is used by objects that may return to the screen at any time.
                sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
            },
        },
        components: {
            ...theme.components,
            MuiTypography: {
                styleOverrides: {
                    root: {
                        fontFamily: hasSpecialFont
                            ? specialFont
                            : theme.typography.fontFamily,
                    },
                },
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        fontFamily: hasSpecialFont
                            ? specialFont
                            : theme.typography.fontFamily,
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    outlined: {
                        borderColor: primaryColor,
                    },
                },
            },
            // MuiTouchRipple: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: '#f69900',
            //         },
            //     },
            // },
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        maxWidth: '100%',
                        minWidth: '100%',
                        elevation: 1,
                        borderRadius: 0,
                    },
                },
            },

            MuiPaper: {
                // defaultProps: {
                //     elevation: 0,
                // },
                // ...theme.components.MuiPaper,
                styleOverrides: {
                    root: {
                        // ...theme.components.MuiPaper,
                        // backgroundColor: 'transparent',
                        backgroundColor:
                            dibsIdNum === 226 ? backgroundColor : '#fff',
                        // borderTopLeftRadius: 8,
                        // borderBottomLeftRadius: 8,
                        borderRadius: 5,
                        // width: '100%',
                        // maxWidth: '100%',
                        // '&:hover': {
                        //     borderColor: primaryColor,
                        // },
                        '&.MuiPopover-paper': {
                            borderRadius: '5px',
                            '&:hover': {
                                border: 1,
                                borderColor: theme.palette.primary.light,
                            },
                        },
                    },
                    outlined: {
                        '&:hover': {
                            borderColor: primaryColor,
                        },
                    },
                },
            },
            MuiButton: {
                ...theme.components.MuiButton,
                styleOverrides: {
                    root: {
                        ...theme.components.MuiButton.styleOverrides.root,
                        fontFamily: hasSpecialFont
                            ? specialFont
                            : `'Roboto', sans-serif`,
                        '&.Mui-disabled': {
                            color: '#fff',
                        },
                    },
                    outlined: {
                        color: theme.palette.primary.main,
                        border: `1px solid`,
                        borderRadius: 6,
                        my: 0.5,
                        lineHeight: 1,
                        fontSize: window.isTiny ? 10 : 12,
                    },
                    contained: {
                        lineHeight: 1.1,
                        elevation: 0,
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                            boxShadow: 'none',
                        },
                    },
                    greenButton: {
                        color: '#fff',
                        border: `1px solid`,
                        borderRadius: 6,
                        backgroundColor: theme.palette.success.main,
                        my: 0.5,
                        lineHeight: 1,
                        fontSize: window.isTiny ? 10 : 12,
                        '&:hover': {
                            backgroundColor: theme.palette.success.main,
                        },
                    },
                    confirmation: {
                        color: '#ff851c',
                        border: `2px solid`,
                        borderRadius: 6,
                    },
                    confirmationcancel: {
                        color: '#9e9e9e',
                        border: `2px solid`,
                        borderRadius: 6,
                    },
                    checkout: {
                        backgroundColor: primaryColor,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                        '&.Mui-disabled': {
                            color: '#eee',
                        },
                    },
                    checkoutUpdated: {
                        backgroundColor: primaryColor,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                        padding: '3px 9px',
                        fontSize: window.isTiny ? '0.7rem' : '0.9rem',
                        cursor: 'pointer',
                    },
                    selectProvider: {
                        backgroundColor: primaryColor,
                        fontSize: window.isTiny ? 10 : 14,
                        padding: window.isTiny ? '2px 5px' : '3px 8px',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                        minWidth: '50px',
                    },
                    checkoutinverse: {
                        backgroundColor: '#fff',
                        color: primaryColor,
                        borderColor: primaryColor,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                    text: {
                        color: primaryColor,
                        fontWeight: dibsIdNum === 226 ? 600 : 400,
                        padding: window.isTiny ? '5px 8px' : '5px 15px',
                    },
                },
            },

            MuiTabScrollButton: {
                styleOverrides: {
                    root: {
                        width: window.isTiny ? '30px' : '40px',
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        fontFamily: hasSpecialFont
                            ? specialFont
                            : `'Roboto', sans-serif`,
                        lineHeight: 1.2,
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
                        // transition: theme.transitions.create(
                        //     ['background-color', 'transform'],
                        //     {
                        //         duration: theme.transitions.duration.standard,
                        //     },
                        // ),
                        // '&:hover': {
                        //     // borderRadius: 1.3,
                        //     transform: 'opacity 0.3s',
                        // },
                    },
                },
            },
            MuiInputBase: {
                ...theme.components.MuiInputBase,
                styleOverrides: {
                    ...theme.components.MuiInputBase.styleOverrides,
                    root: {
                        ...theme.components.MuiInputBase.styleOverrides.root,
                        borderRadius: 4,
                        color: theme.palette.text.dark,

                        '&.Mui-focused': {
                            border: 1,
                            borderColor: theme.palette.primary.light,
                        },
                        '&:hover': {
                            borderColor: theme.palette.primary.light,
                        },
                        '&::placeholder': {
                            color: theme.palette.text.secondary,
                            // fontSize: '1.875rem',
                            fontWeight: 200,
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                ...theme.components.MuiOutlinedInput,
                styleOverrides: {
                    ...theme.components.MuiOutlinedInput.styleOverrides,
                    root: {
                        padding: '0px',
                        ...theme.components.MuiOutlinedInput.styleOverrides
                            .root,
                        '&.Mui-focused': {
                            border: 1,
                            borderColor: theme.palette.primary.light,
                        },
                    },
                },
            },
            MuiAutocomplete: {
                ...theme.components.MuiAutocomplete,
                styleOverrides: {
                    inputRoot: {
                        padding: '4px',
                        paddingLeft: '5px',
                        paddingRight: '0px',
                    },
                },
            },
            MuiFab: {
                ...theme.components.MuiFab,
                styleOverrides: {
                    widgetBookButton: {
                        color: '#fff',
                        transform: 'rotate(-25deg)',
                        width: '85px',
                        height: '85px',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                },
            },
        },
    });

    // window.localStorage.setItem('intentPlatform', 'dibs');
    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <CSSTransition
                        in={true}
                        timeout={900}
                        classNames="display"
                        unmountOnExit>
                        <WidgetLoader />
                    </CSSTransition>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}

export default App;
