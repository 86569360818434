// material-ui
import { useEffect } from 'react';
import { Fab, Box, Typography } from '@mui/material';
import { dispatch } from 'store';
import { setWidgetIsOpen } from 'store/slices/menu';

// ==============================|| BOOK NOW - FLOATING BUTTON ||============================== //
const Style = {
    position: 'absolute',
    bottom: 20,
    right: 20,
    pt: 0.5,
};

const FloatingActionButtons = ({ buttonaction, buttonShouldSetOpen }) => {
    const setWidgetIsOpenTrue = () => {
        dispatch(setWidgetIsOpen(true));
        if (buttonShouldSetOpen) buttonaction('right', true);
    };

    return (
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <Fab
                color="primary"
                aria-label="add"
                sx={Style}
                onClick={setWidgetIsOpenTrue}
                variant="widgetBookButton">
                <Typography variant="bookNowText">Book Now</Typography>
            </Fab>
        </Box>
    );
};

export default FloatingActionButtons;
