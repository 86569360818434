import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Book from 'layouts/book';

import { dispatch } from 'store';
import { setPageSelected } from 'store/slices/menu';
import { setLastLocation } from 'store/slices/thisuser';

const PaymentPageView = () => {
    let { dibsId } = useParams();
    const location = useLocation();

    useEffect(() => {
        dispatch(setPageSelected('book'));
        dispatch(setLastLocation(location.pathname));
    }, [dibsId, location]);
    return <Book dibsId={dibsId} />;
};

export default PaymentPageView;
