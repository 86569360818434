import axios from 'axios';

export default async function getPasses(userid, dibsStudioId) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/get-passes`;
    let datatosend = {};
    const checkPasses = async () => {
        await axios
            .post(basicurl, {
                userid,
                dibsStudioId
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error getting passes for this user ${userid}: ${err}`);
                return err;
            });
    };
    await checkPasses();
    return datatosend;
}
