import { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { capitalizeString } from 'helpers';
import { useSelector, dispatch } from 'store';

import { setCardToCharge, setLastFourCard, setExpy } from 'store/slices/cart';

const DefaultCard = ({ id, defaultLast4, exp, cardBrand }) => {
    const theme = useTheme();
    const [last4, setLast4] = useState(defaultLast4);
    const [cardBrandHere, setCardBrandHere] = useState(cardBrand);
    const { cardtocharge } = useSelector((state) => state.cart);
    const { studioConfig } = useSelector((state) => state.dibsstudio);
    const { color } = studioConfig;
    const coloruse = `#${color}`;
    const [bcolor, setBColor] = useState(coloruse);

    useEffect(() => {
        setLast4(defaultLast4);

        if (cardBrand !== undefined) {
            setCardBrandHere(capitalizeString(cardBrand));
        }
        if (cardtocharge !== id) {
            setBColor(theme.palette.common.lightgrey);
        }
        if (cardtocharge === id) {
            setBColor(coloruse);
            dispatch(setCardToCharge(id));
            dispatch(setLastFourCard(last4));
            dispatch(setExpy(exp));
        }
    }, [
        last4,
        exp,
        cardBrandHere,
        defaultLast4,
        cardBrand,
        cardtocharge,
        id,
        theme.palette.common.lightgrey,
        coloruse,
    ]);
    const cardnum = `**** **** **** ${last4}`;
    const clickBox = () => {
        dispatch(setCardToCharge(id));
        dispatch(setLastFourCard(last4));
        dispatch(setExpy(exp));
    };
    return (
        <Box
            backgroundColor={theme.palette.common.lightgrey}
            borderRadius={1.2}
            border={2}
            borderColor={bcolor}
            // sx={{ borderColor: bcolor }}
            width={250}
            onClick={clickBox}>
            <Grid item xs={12} sx={{ m: 2 }}>
                <Grid container id="default-card">
                    <Grid item xs={12} sx={{ mb: 2, ml: 0.5 }}>
                        {cardBrandHere}
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="h4">Card Number</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, ml: 1 }}>
                        <Typography variant="creditcard">{cardnum}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="h4">Expiration</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="creditcard">{exp}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
export default DefaultCard;
