// material-ui
import {
    // Avatar,
    Grid,
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';

import AccountInfo from 'components/account/profile/info';
import UpdatePassword from 'components/account/profile/password';
import SetAPassword from 'components/account/profile/setAPassword';
import Communication from 'components/account/profile/communication';
// import Notifications from 'components/account/profile/notifications';

// assets
// import Avatar1 from 'assets/images/users/avatar-1.png';

// ==============================|| PROFILE 3 - PROFILE ||============================== //

const Profile = () => {
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12} sx={{ ml: window.isTiny ? 0 : 2, mb: 1, mt: 2 }}>
                <Grid
                    container
                    alignItems="stretch"
                    justifyContent={window.isTiny ? 'center' : 'flex-start'}>
                    <SetAPassword />
                    <AccountInfo />
                    <UpdatePassword />
                    <Communication />
                    {/* <Notifications /> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Profile;
