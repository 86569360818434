import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@mui/material';
import AuthenticationLayout from 'layouts/validationFromLink';

import { dispatch, useSelector } from 'store';
import {
    setColor,
    setTimeZone,
    setDibsStudioId,
} from 'store/slices/dibsstudio';

const LoginPageNoPwdView = () => {
    const { dibsId } = useParams();
    const navigate = useNavigate();
    const { config, studioConfig } = useSelector((state) => state.dibsstudio);
    const { isLoggedIn, lastLocation } = useSelector((state) => state.thisuser);
    const { timeZone } = config;
    const { color } = studioConfig;
    const [doneLoading, setDoneLoading] = React.useState(false);
    const baseURL = process.env.REACT_APP_BASE_URL;

    // const loadingDisplay = (
    //     <Grid
    //         container
    //         direction="row"
    //         justifyContent="center"
    //         alignItems="flex-start">
    //         <Grid item xs={10}>
    //             Loading...
    //         </Grid>
    //     </Grid>
    // );
    // const valuetouse = doneLoading ? <AuthenticationLayout /> : loadingDisplay;
    return <AuthenticationLayout />;
};

export default LoginPageNoPwdView;
