
import sortPasses from './sortPassesByPriority';
import confirmAvailable from 'actions/passes/confirmPassAvailable';
import reassignPasses from './reassignPasses';
import setPasses from './setPassesWithEventsCartViewNew';

export default async function associatePassesWithEvents(events, passes, credit) {

    let needToResetCart = false;
    let eventstoreturn;

    try {

        const checkavailability = await events.map(async (ev) => {
            const { howtopay } = ev;
            const { passid } = howtopay;
            if (passid === 0) return ev;
            const available = await confirmAvailable(passid);
            const { isAvailable } = available;
            if (!isAvailable) {
                needToResetCart = true;
            }
            return ev;
        });

        return await setPasses(events, passes, credit).then((eventsWithPasses) => {
            return eventsWithPasses;

        });



        const checkQuantity = () => {
            const passesused = [];
            events.forEach((ev) => {
                const { howtopay } = ev;
                const { passid } = howtopay;
                if (passid > 0) passesused.push(passid);

            });
            passes.forEach((pass) => {
                const { totalUses, usesCount } = pass;
                const numtimesbeingused = passesused.filter((p) => p === pass.id).length;
                const totalAvailable = totalUses - usesCount;
                if (numtimesbeingused > totalAvailable) {
                    needToResetCart = true;
                }
            });
        };
        // const newevents = await Promise.all(checkavailability);

        // checkQuantity();
        if (needToResetCart) {
            console.log('need to reset cart');
            // const reassignedEvents = await reassignPasses(events, passes);
            console.log('returning reassignedevents');
            return events;
        }
        // return events;


    } catch (err) {
        console.log('err from association passes with events', err);
        return err;
    }

}
