import checkoutWithPasses from './checkoutWithPassesAndOrCredit';
import chargeCardCheckout from 'actions/stripe/chargeSavedCardCheckout';
import checkoutFreeTransaction from './checkoutFreeEvents';
import finishTransactionAfterCharge from 'actions/stripe/finishTransactionAfterCharge';

export default async function checkoutGeneral(
    userid,
    cartItems,
    creditApplied,
    totalAfterCreditApplied,
    dibsId,
    stripeidForThisStudio,
    cardtocharge,
    paymentOptionsAll,
    stripeIdDibs,
) {
    const freeitems = [];
    const cardcharges = [];
    const passredemptions = [];
    const creditredemptions = [];
    let valuestoreturn;
    let freeitemsDone = 1;
    let cardchargesDone = 1;
    let passredemptionsDone = 1;
    let creditredemptionsDone = 1;
    await cartItems.forEach((item) => {
        const { howtopay } = item;
        const { passid, amountToCharge } = howtopay;
        let amountToChargeToUse = 0;
        if (typeof amountToCharge === 'number') {
            amountToChargeToUse = amountToCharge;
        } else {
            amountToChargeToUse = amountToCharge.total;
        }
        if (passid === 0 && amountToChargeToUse > 0) {
            if (totalAfterCreditApplied === 0) {
                creditredemptions.push(item);
            } else {
                cardcharges.push(item);
            }
        } else if (passid > 0) {
            passredemptions.push(item);
        } else if (passid === 0 && amountToChargeToUse === 0) {
            freeitems.push(item);
        }
    });

    let datatosend;
    let returnvalue;
    const checkoutGeneralInner = async () => {
        if (passredemptions.length > 0) {
            passredemptionsDone = 0;
            await checkoutWithPasses(
                userid,
                passredemptions,
                creditApplied,
                totalAfterCreditApplied,
            ).then((res) => {
                datatosend = res;
                valuestoreturn = cartItems;
                passredemptionsDone = 1;
            });
        }
        if (creditredemptions.length > 0) {
            creditredemptionsDone = 0;
            await checkoutWithPasses(
                userid,
                creditredemptions,
                creditApplied,
                totalAfterCreditApplied,
            )
                .then((res) => {
                    // valuestoreturn = res;
                    const newcartitems = cartItems.map((cartItem) => {
                        const { item } = cartItem;
                        return {
                            ...cartItem,
                            ...item,
                        };
                    });
                    valuestoreturn = newcartitems;
                    creditredemptionsDone = 1;
                })
                .catch((err) => {
                    console.log('error checking out with credit', err);
                });
        }
        if (cardcharges.length > 0) {
            cardchargesDone = 0;

            await chargeCardCheckout(
                userid,
                dibsId,
                stripeidForThisStudio,
                cartItems,
                cardtocharge,
                totalAfterCreditApplied,
                creditApplied,
                paymentOptionsAll,
                stripeIdDibs,
            ).then(async (res) => {
                const { uuid, stripechid } = res;
                await finishTransactionAfterCharge(userid, uuid, stripechid)
                    .then((res) => {
                        if (res.msg === 'success') {
                            const newcartitems = cartItems.map((item) => {
                                return {
                                    ...item,
                                    stripe_charge_id: stripechid,
                                };
                            });
                            valuestoreturn = newcartitems;
                        }
                    })
                    .catch((err) => {
                        console.log(
                            'error finishing transaction after charge',
                            err,
                        );
                        return {
                            msg: 'error finishing transaction after charge',
                            error: err,
                        };
                    });
            });
            cardchargesDone = 1;
        }
        if (freeitems.length > 0) {
            freeitemsDone = 0;
            console.log('checking out free items');
            const checkedout = await checkoutFreeTransaction(
                userid,
                cartItems,
                dibsId,
                freeitems,
            );
            console.log(
                '\n\n\n\nresponse after checkedout with free items are',
                checkedout,
            );
            const { success, eventsProcessed } = checkedout;
            if (success) {
                valuestoreturn = eventsProcessed;
                freeitemsDone = 1;
            }
        }
    };
    await checkoutGeneralInner();
    if (
        passredemptionsDone === 1 &&
        cardchargesDone === 1 &&
        freeitemsDone === 1 &&
        creditredemptionsDone === 1
    ) {
        if (datatosend === 0) {
            returnvalue = { success: false };
        } else {
            returnvalue = { success: true, data: valuestoreturn };
        }
    } else {
        returnvalue = { success: false };
    }
    return returnvalue;
}
