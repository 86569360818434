import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import router from 'routes/index';

import { ConfigProvider } from './contexts/ConfigContext';
import Locales from 'components/Locales';

// const language = navigator.language.split(/[-_]/)[0];

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ConfigProvider>
            <Locales>
                <AnimatePresence mode="wait">
                    <RouterProvider router={router}>
                        <App />
                    </RouterProvider>
                </AnimatePresence>
            </Locales>
        </ConfigProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
