import * as React from 'react';

import { Grid } from '@mui/material';

import { useSelector } from 'store';

// component imports
import AccountList from 'components/account/index';
import CartLoader from 'loaders/cartLoader';

const AccountLayout = ({ dibsId }) => {
    const [showCart, setShowCart] = React.useState(false);
    const { itemsInCartHere } = useSelector((state) => state.cart);
    const { config } = useSelector((state) => state.dibsstudio);
    const [accountTopMargin, setAccountTopMargin] = React.useState(0);
    const { widgetOpenStyle } = config;
    React.useEffect(() => {
        if (itemsInCartHere.length > 0) {
            setShowCart(true);
        }
    }, [itemsInCartHere.length]);
    if (dibsId === '226') {
        setAccountTopMargin(3);
    }
    return (
        <Grid
            container
            spacing={0}
            direction="row"
            border={0}
            justifyContent="center"
            id="account-layout-container"
            alignItems="flex-start"
            sx={{ m: 0 }}>
            <Grid
                item
                id="account-layout-container-item"
                xs={12}
                md={11}
                lg={widgetOpenStyle === 'right' ? 10.75 : 9.5}
                sx={{ p: 0, m: 0 }}>
                <Grid
                    container
                    sx={{ mt: 1 }}
                    border={0}
                    id="account-layout-container-item-container"
                    // justifyContent="center"
                    alignItems="flex-start">
                    {/* <Header /> */}
                    {showCart && <CartLoader />}
                    <Grid
                        item
                        xs={12}
                        sx={{ mt: accountTopMargin }}
                        id="grid-account-list">
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Grid
                                item
                                xs={12}
                                sx={{ m: 0, mt: accountTopMargin }}>
                                <AccountList dibsId={dibsId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AccountLayout;
