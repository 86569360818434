import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

export const constructDateinUtc = (dateAsString) => {
    const date = dayjs(dateAsString, 'M/D/YY');

    // Convert it to UTC and format it in ISO 8601 format
    const dateInUtc = date.utc().format();

    return dateInUtc;
};
export const constructDatePlusOneDayinUtc = (dateAsString) => {
    const date = dayjs(dateAsString, 'M/D/YY');

    // Add one day to the date
    const datePlusOneDay = date.add(1, 'day');

    // Convert it to UTC and format it in ISO 8601 format
    const dateInUtc = datePlusOneDay.utc().format();

    return dateInUtc;
};
