import { useEffect, useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports
import { useSelector, dispatch } from 'store';
import {
    setUpcomingClasses,
    setPastClasses,
    setClassesNeedRefresh,
} from 'store/slices/thisuser';

// assets
import ShowClasses from 'components/account/upcoming-classes/upcomingClasses';
import PreviousClasses from 'components/account/upcoming-classes/pastClasses';

import getUpcomingApptsAction from 'actions/getUpcomingAppts';

// ==============================|| PROFILE - CLASSES ||============================== //

const Classes = () => {
    const { userid, upcomingClasses, pastClasses, classesNeedRefresh } =
        useSelector((state) => state.thisuser);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [doneLoading, setDoneLoading] = useState(false);

    useEffect(() => {
        // get upcoming classes
        const getUpcomingClasses = async () => {
            await getUpcomingApptsAction(dibsStudioId, userid)
                .then((res) => {
                    if (res.upcomingAppts) {
                        dispatch(setUpcomingClasses(res.upcomingAppts));
                    }
                    if (res.previousAppts) {
                        dispatch(setPastClasses(res.previousAppts));
                    }
                    if (res.futureClasses) {
                        dispatch(setUpcomingClasses(res.futureClasses));
                    }
                    if (res.pastClasses) {
                        dispatch(setPastClasses(res.pastClasses));
                    }


                    setDoneLoading(true);
                })
                .catch((err) => {
                    console.log(`error getting upcoming classes: ${err}`);
                    setDoneLoading(false);
                });
        };
        getUpcomingClasses();
    }, [dibsStudioId, userid, classesNeedRefresh]);
    return (
        <Grid container justifyContent="center" id="account-classes-container">
            {doneLoading && (
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{ ml: window.isTiny ? 0 : 2, mt: 2 }}>
                        <ShowClasses classes={upcomingClasses} />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{ ml: window.isTiny ? 0 : 2, mt: 2 }}>
                        <PreviousClasses classes={pastClasses} />
                    </Grid>
                </Grid>
            )}
            {!doneLoading && (
                <Grid item xs={12} sx={{ ml: 4, mt: 1 }}>
                    Loading...
                </Grid>
            )}
        </Grid>
    );
};

export default Classes;
