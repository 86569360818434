
import axios from 'axios';
export default async function updateEventCounts(uuid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/update-event-counts`;
    let datatosend = {};
    const checkout = async () => {
        await axios.post(basicurl, { uuid }).then((res) => {
            datatosend = res.data;
        }).catch((err) => {
            console.log('there was an error updating the event counts for uuid booked', err);
            datatosend = { errorreturned: err };
        });
    };
    await checkout();
    return datatosend;
}
