import { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    Checkbox,
} from '@mui/material';
import { useSelector } from 'store';
import { capitalizeString } from 'helpers';
import updateCardToChargePostStripeUpdate from 'actions/stripe/users/updateCardToChargeAfterStripeUpdate';

const AbbreviatedCreditCard = ({ pack, showccaction }) => {

    const { cardbrand, cardlastfour, cardtocharge } = useSelector((state) => state.cart);
    const { paymentOptionsAll } = useSelector((state) => state.thisuser);
    const { totaltopay } = pack;
    const brandCap = capitalizeString(cardbrand);
    const [checked, setChecked] = useState(true);
    const [showCCOption, setShowCCOption] = useState(true);
    const [ccText, setCcText] = useState('');
    useEffect(() => {
        if (cardlastfour === 'xxxx') {
            if (cardtocharge.length > 3 && paymentOptionsAll.length > 0) {
                updateCardToChargePostStripeUpdate(cardtocharge, paymentOptionsAll);
            }
        }
    }, [cardlastfour, cardtocharge, paymentOptionsAll, paymentOptionsAll.length]);
    useEffect(() => {
        if (totaltopay === 0) {
            setShowCCOption(false);
        } else if (cardlastfour === 'xxxx') {
            setShowCCOption(false);
        } else {
            setShowCCOption(true);
            setCcText(`Use ${brandCap} ending in ${cardlastfour}`);
        }
    }, [totaltopay, cardlastfour, brandCap]);

    const changeValue = (e) => {
        setChecked(!checked);
        showccaction(true);
    };
    if (!showCCOption) return null;
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            border={0}
        >
            <Checkbox
                id="creditcard-checkbox-field"
                checked={checked}
                onChange={changeValue}
            />
            <Typography variant="h4">
                {ccText}
            </Typography>
        </Grid>
    );
};
export default AbbreviatedCreditCard;
