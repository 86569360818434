import { dispatch } from 'store';

import { setSelectedId, setSelectedStaff, setApptToConfirm, clearSelectedIdsMulti, setApptIsConfirmed, clearSelectedStaff } from 'store/slices/appointments';

export default async function resetApptScheduleUser() {
    dispatch(setSelectedId(null));
    dispatch(setSelectedStaff(null));
    dispatch(setApptToConfirm(null));
    dispatch(clearSelectedIdsMulti());
    dispatch(setApptIsConfirmed(false));
    dispatch(clearSelectedStaff());
    dispatch(setApptToConfirm(null));
    return true;

}
