import { useEffect, useState, useRef } from 'react';
import {
    Grid,
    Typography,
    CardActions,
    Button,
    Card,
    CardContent,
    Stack,
    Fade,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// actions
import purchaseFreePackage from 'actions/checkout/purchasePackage';
import checkoutFreePackage from 'actions/checkout/checkoutFreePackageDibsid';
import getPaymentMethodsForUser from 'actions/stripe/getAllStripePayments';
import createStripeCharge from 'actions/stripe/createChargeForPackage';
import createStripeSubscription from 'actions/stripe/createSubscription';
import getAvailablePasses from 'actions/getAvailablePasses';
import sendConfirmationEmailPackage from 'actions/emails/send-confirmation-package';
import createSetupIntent from 'actions/stripe/createStripeSetupIntent';
import DisplayCardsPackages from 'components/account/cc-display/displayCardsPackages';
import AddCard from 'components/credit-cards/AddCardOption';

import StripeWrapper from 'components/stripe/new/wrapperForCard';

import { useTheme } from '@mui/material/styles';

import { useSelector, dispatch } from 'store';
import {
    setPurchasingPackage,
    setTotal,
    setPurchasingPackageReducer,
} from 'store/slices/cart';
import {
    setIsNewUser,
    setUserQualifiesPromos,
    setAvailablePasses,
    setPmIdStudio,
    setRefreshAvailablePasses,
} from 'store/slices/thisuser';
import Progress from 'assets/CircularIndeterminate';

import { formatDollarAmountTwoDigits, roundtoN } from 'helpers';
import PackageListBreakdown from './packagesListBreakdown';
import XIcon from 'assets/icons/XIcon';
import BottomSectionForPackages from './bottomSectionActivePackage';
import {
    setHasSuccessReducer,
    setSuccessMessageReducer,
} from 'store/slices/error';
import startDibsTransactionCheckout from 'actions/checkout/startDibsTransactionsCheckout';
import purchasePassFromCheckoutPage from 'actions/checkout/purchasePassFromCheckoutPage';
import setAlert from 'actions/status/setAlert';
import cancelSetupIntent from 'actions/stripe/intents/cancelSetupIntent';
const getTaxAmount = (packinfo) => {
    let tax = packinfo.price * (packinfo.taxAmount / 100);
    if (packinfo.discountfrompromo > 0) tax = packinfo.tax;
    return roundtoN(tax, 2);
};

const getclassesText = (numClasses) => {
    if (numClasses === 1) {
        return '1 class';
    } else if (numClasses >= 999) {
        return 'Unlimited classes';
    } else {
        return `${numClasses} classes`;
    }
};
const getexpiresText = (validity, increment, commitment, autopay) => {
    let incrementText = increment;
    if (validity > 1) {
        incrementText = `${increment}s`;
    }
    let expiresText = `Expires ${validity} ${incrementText} from purchase date`;
    if (autopay === 'FORCE' || autopay === 'ALLOW') {
        expiresText = `Renews ${validity} ${incrementText} after purchase date`;
    }
    if (commitment > 0) {
        expiresText = `Renews ${increment}ly.\n ${commitment} ${increment} minimum commitment`;
    }
    return expiresText;
};
const getCreditToApply = (credit, total) => {
    if (credit > total) {
        return total;
    } else {
        return credit;
    }
};
const calculatePricePerClass = (price, numClasses, pricePerClassOverride) => {
    const pricePerClass = price / numClasses;
    let p = 10;
    if (Number.isInteger(pricePerClass)) p = pricePerClass;
    else p = pricePerClass.toFixed(2);
    if (pricePerClassOverride) return '($' + pricePerClassOverride + '/class)';
    return '($' + p + '/class)';
};
const PackagesListItem = ({ pack, confirmingPurchase }) => {
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [showThisPackage, setShowThisPackage] = useState(true);
    const [mustAddCC, setMustAddCC] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [typeOfEmail, setTypeOfEmail] = useState('autopayNo');
    const [showGreenChooseButton, setShowGreenChooseButton] = useState(false);
    const { showPricesWidget, color } = useSelector(
        (state) => state.dibsstudio.studioConfig,
    );
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const {
        isNewUser,
        credit,
        userid,
        email,
        ccinfo,
        stripeid,
        stripeidForThisStudio,
        firstname,
        lastname,
        paymentOptionsAll,
    } = useSelector((state) => state.thisuser);
    const {
        subtotal,
        tax,
        cardtocharge,
        platformHoldingCardToCharge,
        cardlastfour,
    } = useSelector((state) => state.cart);
    const [addedToCart, setAddedToCart] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const [taxtocharge, setTaxToCharge] = useState(0);
    const [isConfirming, setIsConfirming] = useState(confirmingPurchase);
    const [hadSuccessfulPurchase, setHadSuccessfulPurchase] = useState(false);
    // const [tax, setTax] = useState(0);
    const [total, setThisTotal] = useState(0);
    const [credittoapply, setCreditToApply] = useState(0);
    const [onDibsPlatform, setOnDibsPlatform] = useState(true);
    const [showAddCCComponent, setShowAddCCComponent] = useState(false);
    const [showCCDisplayInfo, setShowCCDisplayInfo] = useState(false);
    const [setupIntentId, setSetupIntentId] = useState(null);
    const [checkedMethods, setCheckedMethods] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    useEffect(() => {
        if (paymentOptionsAll.length === 0) {
            setShowAddCard(true);
        } else {
            setShowAddCard(false);
        }
    }, [paymentOptionsAll]);

    useEffect(() => {
        if (cardlastfour === 'xxxx') {
            setMustAddCC(true);
        } else {
            setMustAddCC(false);
        }
        const thistax = getTaxAmount(pack);
        setTaxToCharge(thistax);

        // setTax(thistax);
        setThisTotal(pack.price + thistax);
        const cctoapply = getCreditToApply(credit, pack.price + thistax);
        setCreditToApply(cctoapply);
        const totalAmount = pack.price + thistax - cctoapply;
        setThisTotal(totalAmount);
    }, [cardlastfour, credit, dibsStudioId, pack, userid]);
    // console.log('must add cc', mustAddCC);
    const cancelPackagePurchase = async () => {
        const pinfo = {
            packageid: null,
            isPurchasing: false,
        };
        setMustAddCC(false);
        dispatch(setPurchasingPackage(pinfo));
        setShowAddCCComponent(false);
        setIsPurchasing(false);
        setIsConfirming(false);
        if (setupIntentId !== null) {
            await cancelSetupIntent(setupIntentId, onDibsPlatform, dibsStudioId)
                .then((res) => {
                    console.log(
                        'response after canceling setup intent is',
                        res,
                    );
                })
                .catch((err) => {
                    console.log('error canceling setup intent', err);
                });
        }
    };
    const userClickedAddCCFromCheckout = async () => {
        // setShowCCDisplayInfo(true);
        await createSetupIntent(
            stripeid,
            userid,
            email,
            `${firstname} ${lastname}`,
            dibsStudioId,
            onDibsPlatform,
        )
            .then((res) => {
                console.log('the response from the setup intent is', res);
                if (res.clientSecret) {
                    window.localStorage.setItem(
                        'clientSecret',
                        res.clientSecret,
                    );
                    console.log('setting client secret - packages list item 214', res.clientSecret)
                    window.localStorage.setItem('clientSecretDate', new Date().toISOString());
                    window.localStorage.setItem('intentPlatform', 'dibs');
                    window.localStorage.setItem('intentType', 'setup');
                    window.localStorage.setItem('intentSetFrom', 'packages');
                    setSetupIntentId(res.setupIntentId);
                    setShowAddCCComponent(true);
                }

                // setShowAddCCComponent(true);
            })
            .catch((err) => {
                console.log('the error returning setup intent is', err);
            });
    };
    const userClickedChangeCC = async () => {
        // setShowAddCCComponent(true);
        setShowCCDisplayInfo(true);
    };
    const userClickedChooseSelectCard = () => {
        setShowCCDisplayInfo(false);
    };
    const addItemToCart = async (packageinfo) => {
        dispatch(setTotal(roundtoN(total, 2)));
        // add alert if there is no card on file and they're purchasing

        if (confirmingPurchase) {
            if (userid === 0) {
                setShowLogin(true);
                console.log('user is not logged in');
                setTimeout(() => {
                    navigate(`/login/${dibsStudioId}`);
                }, 1500);
                return;
            }

            if (mustAddCC && total > 0 && userid !== 0) {
                // setShowAddCCComponent(true);
                setAlert({
                    msg: "You don't have a payment option on file. Add a payment option to complete your purchase.",
                    seconds: 10,
                    warning: true,
                });
                return;
            }
            // entering checkout process
            setIsConfirming(false);
            setIsPurchasing(true);
            if (total <= 0) {
                console.log('this is a free package');
                packageinfo.priceatcheckout = subtotal;
                packageinfo.packagetax = taxtocharge;
                packageinfo.creditToApply = credittoapply;
                await checkoutFreePackage(
                    userid,
                    packageinfo,
                    dibsStudioId,
                    email,
                ).then(async (res) => {
                    console.log('this is the - purchase free - response', res);
                    const passin = {
                        packageid: null,
                        isPurchasing: false,
                    };
                    console.log('check to see if res is 1 or status 1');
                    if (res === 1 || res.status === 1) {
                        packageinfo.expirationdatetosend = res.expirationdate;
                        dispatch(
                            setSuccessMessageReducer(
                                'Your new package has been added to your account.',
                            ),
                        );
                        console.log(
                            'sending the confirmation email for the package',
                        );
                        console.log('line 287 - type of email is', typeOfEmail);
                        sendConfirmationEmailPackage(
                            dibsStudioId,
                            userid,
                            credittoapply,
                            packageinfo.totalToCharge,
                            packageinfo,
                            typeOfEmail,
                        );
                        setCheckedMethods(false);
                        setHadSuccessfulPurchase(true);
                        dispatch(setIsNewUser(false));
                        dispatch(setUserQualifiesPromos(false));
                        dispatch(setHasSuccessReducer(true));
                        setIsPurchasing(false);
                        setIsConfirming(false);
                        // setIsConfirming(true);
                        setHadSuccessfulPurchase(false);
                        dispatch(setRefreshAvailablePasses(true));
                        dispatch(setPurchasingPackageReducer(passin));
                    } else {
                        // need to handle error
                        console.log('there was an error with the charge');
                        dispatch(setPurchasingPackageReducer(passin));
                        setHadSuccessfulPurchase(false);
                        setIsPurchasing(false);
                        setIsConfirming(false);
                        setIsPurchasing(false);
                    }
                });
            } else {
                // create a stripe charge for this package
                pack.packagepriceatcheckout = subtotal;
                pack.packagetax = taxtocharge;
                pack.creditToApply = credittoapply;
                pack.isSubscribingToAutopay =
                    pack.autopay === 'FORCE' ? true : false;

                const packdetails = {
                    ...pack,
                    dibsStudioId,
                    totalToCharge: pack.totaltopay,
                };
                await purchasePassFromCheckoutPage(
                    userid,
                    packdetails,
                    0,
                    cardtocharge,
                    paymentOptionsAll,
                    false,
                ).then(async (res) => {
                    console.log('TO DO - handle in the case of subscription');
                    if (res === 1) {
                        setIsPurchasing(false);
                        setIsConfirming(false);
                        setCheckedMethods(false);
                        setMustAddCC(false);
                        setAlert({
                            msg: 'Success! Your new package has been added to your account.',
                            seconds: 5,
                            success: true,
                        });
                        const passin = {
                            packageid: null,
                            isPurchasing: false,
                        };
                        console.log('\n\n\n\n\nLOG 102 - package info is causing issue - value being passed is', packageinfo);
                        dispatch(setPurchasingPackageReducer(passin));
                        setHadSuccessfulPurchase(true);
                        dispatch(setIsNewUser(false));
                        dispatch(setUserQualifiesPromos(false));
                        console.log('line 357 - type of email is', typeOfEmail);
                        let packagetouse;
                        if (Array.isArray(packagetouse)) {
                            packagetouse = packageinfo[0];
                        } else {
                            packagetouse = packageinfo;
                        }
                        sendConfirmationEmailPackage(
                            dibsStudioId,
                            userid,
                            credittoapply,
                            packagetouse.totalToCharge,
                            packageinfo,
                            typeOfEmail,
                        );
                        dispatch(setRefreshAvailablePasses(true));
                    }
                });

                //             if (createSubscription) {
                //                 await createStripeSubscription(
                //                     userid,
                //                     dibsStudioId,
                //                     pack.id,
                //                     pack.priceAutopay,
                //                     pack.taxAmount,
                //                     pmidCardCharged,
                //                     expirationdatetosend,
                //                     newpasscreated,
                //                 )
                //                     .then((res) => {
                //                         if (res.status === 1) {
                //                             setTypeOfEmail('autopayYes');
                //                         }
                //                     })
                //                     .catch((err) => {
                //                         console.log(
                //                             'error creating the subscription - send an error email TODO',
                //                             err,
                //                         );
                //                     });
                //             }

                //             setTimeout(async () => {
                //                 dispatch(setHasSuccessReducer(false));
                //                 setHadSuccessfulPurchase(false);
                //                 getAvailablePasses(dibsStudioId, userid);
                //                 await getAvailablePasses(dibsStudioId, userid)
                //                     .then((res) => {
                //                         dispatch(setAvailablePasses(res));
                //                     })
                //                     .catch((err) => {
                //                         console.log(
                //                             `2b - error getting available passes: ${err}`,
                //                         );
                //                     });
                //             }, 2000);
                //         }
                //     });
            }

            return;
        }
        const passin = {
            packageid: packageinfo.id,
            isPurchasing: true,
        };
        // setIsPurchasing(true);
        setIsConfirming(true);
        // dispatch(addPackageToCart(passin));
        dispatch(setPurchasingPackage(passin));
        setAddedToCart(true);
    };
    useEffect(() => {
        if (!isNewUser) {
            if (pack.limitedToNewClients) {
                setShowThisPackage(true);
                // setShowThisPackage(false);
            }
        }
    }, [isNewUser, pack]);
    let priceFormatted = '';
    if (showPricesWidget) {
        priceFormatted = `$${pack.price}`;
    }
    const packName = pack.packageName;
    const formattedName = packName.toUpperCase();
    const numclassestext = getclassesText(pack.numClasses);
    const expirestext = getexpiresText(
        pack.validity,
        pack.validIncrement,
        pack.commitmentPeriod,
        pack.autopay,
    );
    const colortoshow = `#${color}`;
    const newtheme = createTheme({
        ...theme,
        palette: {
            primary: {
                main: colortoshow,
            },
        },
    });
    if (showLogin) {
        return (
            <Fade in>
                <Grid
                    item
                    border={0}
                    height={200}
                    id="redirect-to-login-page"
                    sx={{ pt: 3 }}>
                    <Typography>
                        You must be logged in to purchase a package. You will be
                        redirected to the login page now...
                    </Typography>
                </Grid>
            </Fade>
        );
    }
    if (showCCDisplayInfo)
        return (
            <Fade in>
                <Grid
                    item
                    border={0}
                    id="item-package-card-cc-show-display-info"
                    xs={12}
                    md={10}
                    lg={9}
                    xl={6}
                    sx={{
                        mb: 3,
                        display: 'flex',
                        px: 1,
                    }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} lg={11}>
                            <DisplayCardsPackages
                                payments={paymentOptionsAll}
                                setShowGreenChooseButton={
                                    setShowGreenChooseButton
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center">
                                {showGreenChooseButton && (
                                    <Button
                                        onClick={userClickedChooseSelectCard}
                                        variant="greenButton"
                                        sx={{ mx: 4, py: 1.25 }}>
                                        Choose Selected Card
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        );
    if (showAddCCComponent)
        return (
            <Fade in>
                <Grid
                    item
                    border={0}
                    id="item-package-card-cc-comp"
                    sx={{
                        mb: 3,
                        display: 'flex',
                        px: 1,
                    }}>
                    <Stack>
                        <Typography variant="checkout" sx={{ ml: 5, pb: 2 }}>
                            Enter your credit card information to continue.
                        </Typography>
                        <StripeWrapper
                            color={colortoshow}
                            actionToCancel={cancelPackagePurchase}
                            location={location}
                            loadNow={setupIntentId !== null}
                            fromLocation="packages"
                            setShowAddCC={setShowAddCCComponent}
                        />
                    </Stack>
                </Grid>
            </Fade>
        );
    return (
        <>
            {showThisPackage && (
                <Fade in>
                    <Grid
                        item
                        border={0}
                        id="item-package-card-this"
                        sx={{
                            mb: 3,
                            display: 'flex',
                            px: 1,
                        }}>
                        <Stack id="stack-for-package">
                            <Card
                                id="package-card"
                                sx={{
                                    width: 240,
                                    height: '100%',
                                    backgroundColor: '#ffffff',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    // border: 1,
                                    // borderColor: '#dee2e6',
                                }}>
                                <CardContent
                                    sx={{
                                        px: 2,
                                        pt: 2,
                                        pb: 0.7,
                                    }}>
                                    <Grid
                                        container
                                        display="flex"
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-end">
                                        <Grid
                                            item
                                            xs={12}
                                            border={0}
                                            sx={{ mb: 1.5 }}>
                                            <Stack>
                                                {confirmingPurchase && (
                                                    <Grid
                                                        container
                                                        justifyContent="flex-end"
                                                        onClick={
                                                            cancelPackagePurchase
                                                        }
                                                        sx={{ mb: 1 }}>
                                                        <XIcon color={color} />
                                                    </Grid>
                                                )}
                                                <Typography
                                                    variant="packageNames"
                                                    align="center"
                                                    sx={{ mb: 1 }}>
                                                    {formattedName}
                                                </Typography>
                                                <Typography
                                                    variant="packageSubheaders"
                                                    align="center"
                                                    color={colortoshow}
                                                    sx={{ fontWeight: 400 }}>
                                                    {pack.description}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                mb: 1,
                                            }}>
                                            {priceFormatted}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                mb: pack.showPricePerClass
                                                    ? 1
                                                    : 2,
                                            }}>
                                            <Typography
                                                align="center"
                                                variant="numClasses">
                                                {numclassestext}
                                            </Typography>
                                        </Grid>
                                        {pack.showPricePerClass && (
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    mb: 3,
                                                    lineHeight: 0.75,
                                                }}>
                                                <Typography
                                                    align="center"
                                                    variant="numClasses"
                                                    sx={{
                                                        fontStyle: 'italic',
                                                        fontWeight: 300,
                                                    }}>
                                                    {calculatePricePerClass(
                                                        pack.price,
                                                        pack.numClasses,
                                                        pack.pricePerClassOverride,
                                                    )}
                                                </Typography>
                                            </Grid>
                                        )}

                                        {confirmingPurchase && (
                                            <PackageListBreakdown pack={pack} />
                                        )}
                                        <Grid
                                            item
                                            xs={10}
                                            // border={3}
                                            sx={{ display: 'flex', pb: 0 }}>
                                            <Grid
                                                container
                                                justifyContent="center">
                                                <Typography variant="expiration">
                                                    {expirestext}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions
                                    height={70}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mb: 1,
                                        pt: 0.75,
                                        mx: 1,
                                    }}>
                                    {showAddCard && (
                                        <AddCard
                                            pack={pack}
                                            showCreditCardAction={
                                                userClickedAddCCFromCheckout
                                            }
                                            cancelAction={cancelPackagePurchase}
                                        />
                                    )}

                                    {!hadSuccessfulPurchase && (
                                        <ThemeProvider theme={newtheme}>
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    addItemToCart(pack)
                                                }
                                                sx={{
                                                    width: '100px',
                                                    mt: 0.75,
                                                    mb: 0.75,
                                                    color: '#ffffff',
                                                    backgroundColor:
                                                        newtheme.palette.primary
                                                            .main,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            newtheme.palette
                                                                .primary.light,
                                                    },
                                                }}>
                                                {!isPurchasing &&
                                                    (!isConfirming
                                                        ? 'Buy'
                                                        : 'Confirm')}
                                                {isPurchasing && (
                                                    <Progress
                                                        size={24}
                                                        color="white"
                                                    />
                                                )}
                                            </Button>
                                        </ThemeProvider>
                                    )}
                                    {hadSuccessfulPurchase && (
                                        <ThemeProvider theme={newtheme}>
                                            <Button
                                                size="small"
                                                onClick={() =>
                                                    addItemToCart(pack)
                                                }
                                                sx={{
                                                    width: '100px',
                                                    mt: 0.75,
                                                    mb: 0.75,
                                                    color: '#ffffff',
                                                    backgroundColor:
                                                        newtheme.palette.primary
                                                            .light,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            newtheme.palette
                                                                .primary.light,
                                                    },
                                                }}>
                                                {!isPurchasing &&
                                                    (!isConfirming
                                                        ? 'Added!'
                                                        : 'Confirm')}
                                                {isPurchasing && (
                                                    <Progress
                                                        size={24}
                                                        color="white"
                                                    />
                                                )}
                                            </Button>
                                        </ThemeProvider>
                                    )}
                                </CardActions>
                            </Card>
                            {confirmingPurchase && (
                                <BottomSectionForPackages
                                    pack={pack}
                                    userid={userid}
                                    email={email}
                                    credit={credit}
                                    showCreditCardAction={userClickedChangeCC}
                                />
                            )}
                        </Stack>
                    </Grid>
                </Fade>
            )}
        </>
    );
};

export default PackagesListItem;
