import { Grid, Typography } from '@mui/material';

import { useSelector } from 'store';

import OrderSummary from 'components/checkout/OrderSummary';
import ChargeCard from 'components/stripe/chargeCard';
import CartSummaryTable from 'components/checkout/summaryTable'

const PurchaseConfirmation = () => {
    const {
        itemsInCartHere,
        cardtocharge,
        packageItemsInCart,
        expy,
        lastfour,
        totalAfterCreditApplied,
        creditApplied,
    } = useSelector((state) => state.cart);
    return (
        <Grid container>
            <Grid item xs={12} md={8} sx={{ mt: 2, mb: 2 }}>
                <Typography variant="h3">Confirm your purchase</Typography>
            </Grid>
            <Grid item xs={12}>
                <CartSummaryTable rows={itemsInCartHere} />
            </Grid>
            <Grid item xs={12}>
                <OrderSummary
                    itemsincart={itemsInCartHere}
                    packageItemsInCart={packageItemsInCart}
                    totalPostCredit={totalAfterCreditApplied}
                    creditApplied={creditApplied}
                />
            </Grid>
            <Grid item xs={12} sx={{ mb: 9 }}>
                <ChargeCard
                    cardid={cardtocharge}
                    exp={expy}
                    lastfour={lastfour}
                />
            </Grid>
        </Grid>
    );
};
export default PurchaseConfirmation;
