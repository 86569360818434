import { createSlice } from '@reduxjs/toolkit';

const getMaxDateComparison = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + 1);
    return date;
};
const getStartToday = () => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
};
const getStartOffset = (numdays) => {
    const date = new Date();
    const daystooffset = Math.max(numdays, 0);
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() + daystooffset);
    return date;
};
const getMaxComparisonOffset = (numdays) => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    const daystooffset = Math.max(numdays, 0);
    date.setDate(date.getDate() + (daystooffset + 1));
    return date;
};

const initialState = {
    showingSchedule: false,
    showingAppointments: false,
    showingAccount: false,
    dateSelected: getStartToday(),
    maxDateForComparison: getMaxDateComparison(),
    movedScheduleOut: 0,
    pageSelected: 'schedule',
    accountPageSelected: 'appts-classes',
    pageSentAuthFrom: '',
    holdDataForPostAuth: {},
    needsRefresh: true,
    daystomove: 6,
    firstload: true,
    widgetIsOpen: false,
    addedtocalendar: false,
    appointmentCategoryShowing: 'ALL SERVICE CATEGORIES',
    isFiltered: false,
    typeOfFilter: 'date',
    dateFilteredTo: '8/24/24',
};

const menu = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setShowingSchedule(state, action) {
            return {
                ...state,
                showingSchedule: action.payload,
            };
        },
        setApptCategoryShowing(state, action) {
            return {
                ...state,
                appointmentCategoryShowing: action.payload,
            };
        },
        setShowingAppointments(state, action) {
            return {
                ...state,
                showingAppointments: action.payload,
            };
        },
        setShowingAccount(state, action) {
            return {
                ...state,
                showingAccount: action.payload,
            };
        },
        setWidgetIsOpen(state, action) {
            return {
                ...state,
                widgetIsOpen: action.payload,
            };
        },
        setFilteredTrue(state, action) {
            const { dateFilteredToPassedIn, typeOfFilterPassedIn } =
                action.payload;
            return {
                ...state,
                dateFilteredTo: dateFilteredToPassedIn,
                typeOfFilter: typeOfFilterPassedIn,
                isFiltered: true,
            };
        },
        setFilteredFalse(state, action) {
            return {
                ...state,
                isFiltered: false,
            };
        },
        setAddedToCalendar(state, action) {
            return {
                ...state,
                addedtocalendar: action.payload,
            };
        },
        setDateSelected(state, action) {
            return {
                ...state,
                dateSelected: action.payload,
            };
        },
        setMoveOverOneDay(state, action) {
            const datePlusOne = getStartOffset(1);
            return {
                ...state,
                dateSelected: datePlusOne,
            };
        },
        setNeedsRefresh(state, action) {
            return {
                ...state,
                needsRefresh: action.payload,
            };
        },
        setMaxDateForComparison(state, action) {
            return {
                ...state,
                maxDateForComparison: action.payload,
            };
        },
        setFirstLoad(state, action) {
            return {
                ...state,
                firstload: action.payload,
            };
        },
        setPageSelected(state, action) {
            return {
                ...state,
                pageSelected: action.payload,
            };
        },
        setPageSentAuthFrom(state, action) {
            return {
                ...state,
                pageSentAuthFrom: action.payload,
            };
        },
        setHoldDataForPostAuth(state, action) {
            return {
                ...state,
                holdDataForPostAuth: action.payload,
            };
        },

        updateNumDaysToMove(state, action) {
            // const daystoMoveOver = state.daystomove + action.payload;
            // const daystoMoveOver = action.payload;
            const daystoMoveOver = Math.max(action.payload, 0);
            return {
                ...state,
                daystomove: daystoMoveOver,
            };
        },
        setMovedScheduleOut(state, action) {
            // console.log(`action.payload: ${action.payload}`);
            // console.log(
            //     `current state.movedScheduleOut: ${state.movedScheduleOut}`,
            // );
            const scheduleOutPrelimNum =
                state.movedScheduleOut + action.payload;
            // console.log(
            //     'schedulePrelimNum for comparison is',
            //     scheduleOutPrelimNum,
            // );
            const moveScheduleNum = Math.max(scheduleOutPrelimNum, 0);
            // console.log(`moveScheduleNum to be set is: ${moveScheduleNum}`);
            return {
                ...state,
                movedScheduleOut: moveScheduleNum,
                dateSelected: getStartOffset(moveScheduleNum),
                maxDateForComparison: getMaxComparisonOffset(moveScheduleNum),
            };
        },
        setMovedScheduleBack(state, action) {
            // console.log(
            //     `moving back now --> action.payload: ${action.payload}`,
            // );
            // console.log(`state.movedScheduleOut: ${state.movedScheduleOut}`);
            const moveScheduleNum = state.movedScheduleOut - action.payload;
            const numToUse = Math.max(moveScheduleNum, 0);
            // console.log(`moveScheduleNum: ${numToUse}`);
            return {
                ...state,
                movedScheduleOut: numToUse,
                dateSelected: getStartOffset(moveScheduleNum),
                maxDateForComparison: getMaxComparisonOffset(moveScheduleNum),
            };
        },
        setAccountPageSelected(state, action) {
            return {
                ...state,
                accountPageSelected: action.payload,
            };
        },
    },
});

export default menu.reducer;
export const {
    setShowingSchedule,
    setShowingAppointments,
    setShowingAccount,
    setAccountPageSelected,
    setPageSentAuthFrom,
    setDateSelected,
    setWidgetIsOpen,
    setMaxDateForComparison,
    setApptCategoryShowing,
    setMovedScheduleOut,
    setMovedScheduleBack,
    setFilteredTrue,
    setFilteredFalse,
    setMoveOverOneDay,
    setPageSelected,
    setHoldDataForPostAuth,
    setNeedsRefresh,
    updateNumDaysToMove,
    setFirstLoad,
    setAddedToCalendar,
} = menu.actions;
