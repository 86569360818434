import { useEffect, useState } from 'react';
import {
    Grid,
    Typography,
    CardActions,
    Button,
    Card,
    CardContent,
    Stack,
    Fade,
    Link,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useTheme } from '@mui/material/styles';

import { useSelector, dispatch } from 'store';
import {
    addPackageToCart,
    setPurchasingPackage,
    setCreditApplied,
    setTotalAfterCreditApplied,
    setTotalTax,
    setSubTotal,
} from 'store/slices/cart';

import { formatDollarAmountTwoDigits, roundtoN } from 'helpers';
import XIcon from 'assets/icons/XIcon';

const getTaxAmount = (packinfo, salestax) => {
    let tax = packinfo.price * (salestax / 100);
    if (packinfo.discountfrompromo > 0) tax = packinfo.tax;
    return roundtoN(tax, 2);
};

const getclassesText = (numClasses) => {
    if (numClasses === 1) {
        return '1 class';
    } else if (numClasses >= 999) {
        return 'Unlimited classes';
    } else {
        return `${numClasses} classes`;
    }
};
const getexpiresText = (validity, increment, commitment) => {
    let incrementText = increment;
    if (validity > 1) {
        incrementText = `${increment}s`;
    }
    let expiresText = `Expires ${validity} ${incrementText} from purchase date`;
    if (commitment > 0) {
        expiresText = `${commitment} ${increment} minimum commitment`;
    }
    return expiresText;
};
const getCreditToApply = (credit, total) => {
    if (credit > total) {
        return total;
    } else {
        return credit;
    }
};
const PackagesListItem = ({ pack, confirmingPurchase }) => {
    const theme = useTheme();
    const [showThisPackage, setShowThisPackage] = useState(true);
    const { showPricesWidget, color, salesTax } = useSelector(
        (state) => state.dibsstudio.studioConfig,
    );
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { isNewUser, credit } = useSelector((state) => state.thisuser);
    const [addedToCart, setAddedToCart] = useState(false);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const [tax, setTax] = useState(0);
    const [total, setTotal] = useState(0);
    const [credittoapply, setCreditToApply] = useState(0);

    useEffect(() => {
        const thistax = getTaxAmount(pack, salesTax);
        setTax(thistax);
        setTotal(roundtoN(pack.price + thistax, 2));

        let cctoapply = roundtoN(
            getCreditToApply(credit, pack.price + thistax),
            2,
        );
        dispatch(setCreditApplied(roundtoN(cctoapply, 2)));
        let totalAmount = pack.price + thistax - cctoapply;
        dispatch(setTotalAfterCreditApplied(roundtoN(totalAmount, 2)));
        dispatch(setTotalTax(roundtoN(thistax, 2)));
        let totaltopasstoredux = pack.price + thistax;
        dispatch(setSubTotal(roundtoN(totaltopasstoredux, 2)));
        if (pack.discountfrompromo > 0) {
            cctoapply = getCreditToApply(
                credit,
                pack.discountedprice + pack.tax,
            );
            totalAmount = pack.discountedprice + pack.tax - cctoapply;
        }
        setCreditToApply(cctoapply);
        setTotal(totalAmount);
        pack.totaltopay = roundtoN(totalAmount, 2);
    }, [credit, pack, salesTax, pack.tax, pack.discountedprice]);

    const cancelPackagePurchase = () => {
        const pinfo = {
            packageid: null,
            isPurchasing: false,
        };
        dispatch(setPurchasingPackage(pinfo));
    };

    const addItemToCart = (packageinfo) => {
        const passin = {
            packageid: packageinfo.id,
            isPurchasing: true,
        };
        setIsPurchasing(true);
        // dispatch(addPackageToCart(passin));
        dispatch(setPurchasingPackage(passin));
        setAddedToCart(true);
    };

    let priceFormatted = '';
    if (showPricesWidget) {
        priceFormatted = `$${pack.price}`;
    }
    const packName = pack.packageName;
    const formattedName = packName.toUpperCase();
    const numclassestext = getclassesText(pack.numClasses);
    const expirestext = getexpiresText(
        pack.validity,
        pack.validIncrement,
        pack.commitmentPeriod,
    );

    return (
        <>
            {showThisPackage && (
                <Fade in>
                    <Grid item xs={12} sx={{ mt: 3, pb: 1.5 }}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="packageSummary">
                                    Package Price:
                                </Typography>
                            </Grid>
                            <Grid item>
                                {' '}
                                <Typography variant="packageSummary">
                                    {formatDollarAmountTwoDigits(pack.price)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {pack.discountfrompromo > 0 && (
                            <>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="packageSummary">
                                            Promo Applied:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {' '}
                                        <Typography variant="packageSummary">
                                            -
                                            {formatDollarAmountTwoDigits(
                                                pack.discountfrompromo,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    sx={{ borderTop: 1, mb: 2 }}>
                                    <Grid item>
                                        <Typography variant="packageSummary">
                                            Subtotal:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {' '}
                                        <Typography variant="packageSummary">
                                            {formatDollarAmountTwoDigits(
                                                pack.discountedprice,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="packageSummary">
                                    Tax Applied:
                                </Typography>
                            </Grid>
                            <Grid item>
                                {' '}
                                <Typography variant="packageSummary">
                                    {formatDollarAmountTwoDigits(tax)}
                                </Typography>
                            </Grid>
                        </Grid>
                        {pack.discountfrompromo > 0 && (
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ borderTop: 1, mb: 2 }}>
                                <Grid item>
                                    <Typography variant="packageSummary">
                                        Total:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {' '}
                                    <Typography variant="packageSummary">
                                        {formatDollarAmountTwoDigits(
                                            pack.totalprice,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {credittoapply > 0 && (
                            <Grid container justifyContent="space-between">
                                <Grid item>
                                    <Typography variant="packageSummary">
                                        Credit Applied:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {' '}
                                    <Typography variant="packageSummary">
                                        -
                                        {formatDollarAmountTwoDigits(
                                            credittoapply,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="packageSummaryTotal">
                                    Total Amount:
                                </Typography>
                            </Grid>
                            <Grid item>
                                {' '}
                                <Typography variant="packageSummaryTotal">
                                    {formatDollarAmountTwoDigits(total)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fade>
            )}
        </>
    );
};

export default PackagesListItem;
