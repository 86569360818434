import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { gridSpacing } from 'store/constant';

import { useSelector } from 'store';

// material-ui
import { Grid, Typography, Link } from '@mui/material';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCardConfirmation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dibsId } = useParams();
    const { lastLocation } = useSelector((state) => state.thisuser);
    const locationToSendBackTo = window.localStorage.getItem('lastpagevisited');
    console.log('last location in local storage', locationToSendBackTo);

    const goToSchedule = () => {
        navigate(`/schedule/${dibsId}${location.search}`);
    };
    const goToAccount = () => {
        navigate(`/account/${dibsId}${location.search}`);
    };
    React.useEffect(() => {
        if (locationToSendBackTo) {
            console.log('removing item lastpagevisited', locationToSendBackTo);

            window.localStorage.removeItem('lastpagevisited');
            navigate(locationToSendBackTo);
        } else if (lastLocation !== '') {
            navigate(lastLocation);
        }
    }, [lastLocation, locationToSendBackTo, navigate]);
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <LoginCard title="Account Created">
                    <Grid
                        container
                        spacing={gridSpacing}
                        justifyContent="flex-start">
                        <ErrorMessage />
                        <Grid item xs={12} sx={{ ml: 1, mr: 1, mt: 1 }}>
                            <Typography variant="regularText">
                                Thanks! You're logged in.
                            </Typography>
                            <Grid
                                container
                                justifyContent="space-between"
                                sx={{ mt: 2 }}>
                                <Grid item xs={8} md={4} sx={{ mt: 1 }}>
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => goToAccount()}>
                                        <Typography variant="guidance">
                                            Go to your account page
                                        </Typography>
                                    </Link>
                                </Grid>
                                <Grid item xs={8} md={4} sx={{ mt: 1 }}>
                                    <Link
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => goToSchedule()}>
                                        <Typography variant="guidance">
                                            Go to the schedule page
                                        </Typography>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </LoginCard>
            </Grid>
        </Grid>
    );
};
export default LoginAuthenticationCardConfirmation;
