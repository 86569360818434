import { Grid, Box } from '@mui/material';
import PaymentComponents from './paymentList';

const PaymentList = ({ dibsId }) => {
    return (
        <Box
            sx={{
                border: 1,
                borderRadius: 2,
                borderColor: '#d3d3d3',
                px: 2,
                pt: 2,
                minHeight: 100,
                mt: 0,
            }}>
            <Grid container>
                <Grid item xs={12} sx={{ mx: 3, mt: 5 }}>
                    <PaymentComponents dibsId={dibsId} />
                </Grid>
            </Grid>
        </Box>
    );
};
export default PaymentList;
