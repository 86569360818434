import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getCustomerServiceEmail(dibsId) {
    const url = getApiUrl('/studio/get-customer-service-email');
    let datatosend = {};
    const getEmail = async () => {
        await axios
            .post(url, {
                dibsId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error getting customer service email: ${err}`);
                return err;
            });
    };
    await getEmail();
    return datatosend;
}
