import { useEffect, useState } from 'react';
import { Grid, Box, Button, Stack, Typography } from '@mui/material';
// import PaymentComponents from './paymentList';
import { useNavigate, useLocation } from 'react-router-dom';
import { dispatch, useSelector } from 'store';
import { setPageSelected } from 'store/slices/menu';
import { clearCart } from 'store/slices/cart';
import { setAvailablePasses } from 'store/slices/thisuser';

import checkoutuserpasses from 'actions/checkout/checkoutWithPassesAndOrCredit';
import getAvailablePasses from 'actions/getAvailablePasses';

const BookConfirmation = ({ dibsId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const goBackSchedule = () => {
        dispatch(setPageSelected('schedule'));
        navigate(`/schedule/${dibsId}${location.search}`);
    };
    const { config } = useSelector((state) => state.dibsstudio);
    const [hasError, setHasError] = useState(false);

    const { dibsStudioId } = config;
    const { cartPreparedForCheckout } = useSelector((state) => state.cart);
    const { userid } = useSelector((state) => state.thisuser);
    useEffect(() => {
        const checkoutuserwithpasses = async () => {
            await checkoutuserpasses(userid, cartPreparedForCheckout).then(
                async (res) => {
                    if (res === 1) {
                        dispatch(clearCart(dibsStudioId));
                        await getAvailablePasses(dibsStudioId, userid)
                            .then((res) => {
                                dispatch(setAvailablePasses(res));
                            })
                            .catch((err) => {
                                console.log(
                                    `2c - error getting available passes: ${err}`,
                                );
                            });
                    } else {
                        setHasError(true);
                    }
                },
            );
        };
        if (cartPreparedForCheckout.length > 0 && userid) {
            checkoutuserwithpasses();
        }
    }, [dibsStudioId, cartPreparedForCheckout, userid]);
    return (
        <Box
            sx={{
                border: 1,
                borderRadius: 2,
                borderColor: '#d3d3d3',
                px: 2,
                pt: 2,
                minHeight: 100,
                mt: 0,
            }}>
            <Grid container>
                <Grid item xs={12} sx={{ mx: 3, mt: 5 }}>
                    <Grid container justifyContent="center">
                        <Stack justifyContent="center">
                            {!hasError && (
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography variant="h3">
                                        Thank you for your purchase. If you
                                        don't see your purchase reflected in
                                        your account, please refresh your
                                        screen.
                                    </Typography>
                                </Grid>
                            )}
                            {hasError && (
                                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                                    <Typography variant="errorText">
                                        There was an error checking out. Please
                                        go back and try again.
                                    </Typography>
                                </Grid>
                            )}

                            <Grid item sx={{ mt: 4, mb: 5 }}>
                                <Button
                                    // component={Link}
                                    // to={backtoScheduleUrl}
                                    onClick={() => goBackSchedule()}
                                    variant="text"
                                    // sx={{
                                    //     color: colortouse,
                                    // }}
                                >
                                    Go back to the schedule
                                </Button>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
export default BookConfirmation;
