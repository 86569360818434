import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import updateFillRates from 'actions/schedule/updateFillRates';
export default async function checkoutFreeEvents(
    userid,
    cartItems,
    dibsId,
    freeitems,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    let basicurl = `${baseURL}/checkout-free-event`;
    try {
        const checkoutUUID = uuidv4();
        const checkedout = await axios.post(basicurl, {
            userid,
            cartItems,
            dibsId,
            freeitems,
            checkoutUUID,
        });
        console.log(
            'action for checking out - response - free event',
            checkedout,
        );

        const { data } = checkedout;
        updateFillRates(cartItems);
        return data;
    } catch (err) {
        console.log('there was an error checking out with free events', err);
        console.log('tried to check out with userid', userid);
        console.log('cart items', cartItems);
        console.log('free items are', freeitems);
    }
}
