import { useIntl } from 'react-intl';

const useTranslate = () => {
    const intl = useIntl();

    const translate = (id, defaultMessage) => {
        return intl.formatMessage({
            id,
            defaultMessage,
        });
    };

    return translate;
};

export default useTranslate;
