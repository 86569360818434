import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function clonePaymentMethod(dibsId, stripeid, paymentmethod, stripeIdConnected) {
    const url = getApiUrl('/stripe/clone-payment-method-only');
    let datatosend;
    const reqbody = {
        dibsId, stripeid, paymentmethod, stripeIdConnected
    };
    await axios
        .post(url, reqbody)
        .then((res) => {
            const { data } = res;
            datatosend = data;

        })
        .catch((err) => {
            console.log(
                `error from clonePaymentMethod ONLY Action: ${err}`,
            );
            return err;
        });

    return datatosend;
}
