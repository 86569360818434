import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getUniqueCategories(dibsId) {
    const url = getApiUrl('/appts/get-unique-categories');

    const res = await axios.post(url, { dibsId });
    const { success } = res.data;
    if (success) {
        const { serviceCategories } = res.data;
        return {
            success: true,
            serviceCategories,
        };
    } else {
        return {
            success: false,
        };
    }
}
