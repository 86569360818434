import React from 'react';
import { lowercaseString } from 'helpers';
import { gridSpacing } from 'store/constant';

import { dispatch, useSelector } from 'store';
import {
    setUserEmail,
    setEncryptedPassword,
    setHasSetPwdFirebase,
    setUserId,
    setIsRegistering,
} from 'store/slices/thisuser';
import checkUserExists from 'actions/users/userAlreadyExists';

// material-ui
import {
    Grid,
    Button,
    InputLabel,
    Typography,
    TextField,
    Fade,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Your email address looks incorrect. Please try again')
        .required('Required'),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const EnterEmailAuthCard = ({ setCurrentStep, setEmailAddy }) => {
    const [mdsize, setMdSize] = React.useState(7);
    const { config } = useSelector((state) => state.dibsstudio);
    const { widgetOpenStyle, dibsStudioId } = config;
    React.useEffect(() => {
        if (widgetOpenStyle === 'right' || widgetOpenStyle === 'left') {
            setMdSize(8);
        } else {
            setMdSize(5);
        }
    }, [widgetOpenStyle]);
    const formik = useFormik({
        initialValues: {
            // email: 'alicia.ulin+5@gmail.com', // remove before production
            email: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                const emailtosend = lowercaseString(values.email);
                dispatch(setUserEmail(emailtosend));
                dispatch(setIsRegistering(true));
                setEmailAddy(emailtosend);
                const userExists = await checkUserExists(emailtosend);
                console.log('user already exists is', userExists);
                if (userExists) {
                    const {
                        userid,
                        hasAccount,
                        encryptedPassword,
                        hasSetFirebasedPwd,
                    } = userExists;
                    console.log('has an account is', hasAccount);
                    if (hasAccount) {
                        if (userid) dispatch(setUserId(userid));
                        if (hasSetFirebasedPwd) {
                            dispatch(setHasSetPwdFirebase(hasSetFirebasedPwd));
                        }
                        if (encryptedPassword)
                            dispatch(setEncryptedPassword(encryptedPassword));
                        setCurrentStep('password');
                    } else {
                        setCurrentStep('register');
                        dispatch(setIsRegistering(true));
                    }
                }
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={mdsize} xl={4} sx={{ mb: 5 }}>
                <Fade in={true} timeout={500}>
                    <LoginCard title="Sign In" dibsstudioid={dibsStudioId}>
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid
                                item
                                xs={12}
                                sx={{ ml: 1 }}
                                id="enter-email-to-login">
                                <Typography variant="regularText">
                                    Enter your email address to sign in or
                                    create an account
                                </Typography>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}>
                                    <Grid container justifyContent="flex-start">
                                        <Grid
                                            item
                                            xs={10}
                                            md={9}
                                            sx={{ mt: 2, ml: 0.5 }}>
                                            <InputLabel>Email</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.email &&
                                                    Boolean(formik.errors.email)
                                                }
                                                helperText={
                                                    formik.touched.email &&
                                                    formik.errors.email
                                                }
                                                sx={{ mt: 1 }}>
                                                Email
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10.5}
                                            sx={{ mt: 4, mb: 2.5, mr: 1 }}>
                                            <Button
                                                variant="checkout"
                                                id="login-with-email-btn"
                                                type="submit"
                                                fullWidth
                                                sx={{ height: '42px' }}
                                                disabled={formik.isSubmitting}>
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};
export default EnterEmailAuthCard;
