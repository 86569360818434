import { dispatch } from 'store';
import {

    setAddedToShopify,
    setShopifyCustId
} from 'store/slices/thisuser';

export default async function setShopifyStatusInStore(profile) {
    const { info } = profile;
    if (info.added_to_shopify) {
        dispatch(setAddedToShopify(info.added_to_shopify));
    }
    if (info.shopify_customer_id) {
        dispatch(setShopifyCustId(info.shopify_customer_id));
    }

    return 1;
}
