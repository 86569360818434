import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { roundtoN } from 'helpers';
import { useTheme } from '@mui/material/styles';
// material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    useMediaQuery,
    Grid,
    Collapse,
} from '@mui/material';

// third-party
import currency from 'currency.js';

// project imports
import PayPerClassCard from './PayPerClassCard';
import { dispatch, useSelector } from 'store';

import {
    setTotal,
    setSubTotal,
    setTotalTax,
    setShowChooseSingleOrPackage,
} from 'store/slices/cart';
import PackagesListOnCheckout from 'components/packages-list/packagesListOnCheckout';
import PackagesList from 'components/packages-list';

// ==============================|| CHECKOUT CART - CHOOSE PAYMENT METHOD ||============================== //

const SelectPaymentOption = ({
    itemsincart,
    totalPostCredit,
    creditApplied,
    setShowConfirmButton,
    setShowCCForm,
    setHasChosen,
}) => {
    const { needstopay, cartPreparedForCheckout } = useSelector(
        (state) => state.cart,
    );
    const theme = useTheme();
    const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
    const matchUpSM = useMediaQuery(theme.breakpoints.up('sm'));
    const [showPaymentOptions, setShowPaymentOptions] = useState(true);

    const [showOrderSummary, setShowOrderSummary] = useState(true);
    const cart = useMemo(() => {
        if (itemsincart) {
            const subtotalClasses = itemsincart.reduce(
                (acc, item) => acc + item.price * item.quantity,
                0,
            );
            const getvaluetoadd = (price, quantity, howtopay) => {
                let p = price * quantity;
                if (howtopay !== undefined && howtopay.passid > 0) {
                    p = 0;
                }
                return p;
            };
            const testSubtotalClasses = cartPreparedForCheckout.reduce(
                (acc, item) =>
                    acc +
                    getvaluetoadd(item.price, item.quantity, item.howtopay),
                0,
            );
            const classItemTaxToAdd = itemsincart.reduce(
                (acc, item) => acc + item.itemtax * item.quantity,
                0,
            );

            const testClassItemTaxToAdd = cartPreparedForCheckout.reduce(
                (acc, item) =>
                    acc +
                    getvaluetoadd(item.itemtax, item.quantity, item.howtopay),
                0,
            );
            const subtotal = subtotalClasses;
            const testSubtotal = testSubtotalClasses;
            const discount = 0; // take care of discounts later
            // const tax = (subtotal - discount) * 0.045;
            const tax = roundtoN(classItemTaxToAdd, 2);
            const testTax = roundtoN(testClassItemTaxToAdd, 2);
            const total = subtotal + tax;
            const testTotal = testSubtotal + testTax;

            const numitems = itemsincart.reduce(
                (acc, item) => acc + item.quantity,
                0,
            );
            return {
                subtotal: testSubtotal,
                tax: testTax,
                total: testTotal,
                numitems,
                totalPostCredit: totalPostCredit,
                creditApplied: creditApplied,
            };
        } else {
            return null;
        }
    }, [cartPreparedForCheckout, creditApplied, itemsincart, totalPostCredit]);

    useEffect(() => {
        if (!needstopay) {
            // dispatch(setTotal(0));
            // setShowOrderSummary(false);
        } else if (cart.total > 0) {
            dispatch(setSubTotal(cart.subtotal));
            dispatch(setTotal(cart.total));
            dispatch(setTotalTax(cart.tax));
        }
    }, [cart, needstopay]);
    return (
        <>
            <Collapse
                in={showPaymentOptions}
                timeout={500}
                appear
                easing="ease-in-out"
                mountOnEnter
                unmountOnExit>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sx={{ mt: 5, ml: matchDownXL ? 0.75 : 3 }}
                        border={0}
                        id="choose-payment-method">
                        <Typography variant="subtitle1">
                            Choose Your Payment Method
                        </Typography>
                        <Grid
                            container
                            spacing={matchDownXL ? 1 : 2}
                            sx={{ mt: 0 }}
                            alignItems="center"
                            border={0}
                            direction="row">
                            <Grid
                                item
                                xs={12}
                                md={2.8}
                                id="item-holding-first-group-payperclass"
                                border={0}
                                sx={{ mt: 0.6 }}>
                                <PayPerClassCard
                                    setShowConfirmButton={setShowConfirmButton}
                                    setShowCCForm={setShowCCForm}
                                    setShowPaymentOptions={
                                        setShowPaymentOptions
                                    }
                                    setHasChosen={setHasChosen}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={1}
                                id="item-holding-first-group-or-text"
                                border={0}>
                                <Typography variant="largeColoredText">
                                    - OR -
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={8.2}
                                id="item-holding-second-group-packageoptions"
                                border={0}>
                                <Grid
                                    container
                                    spacing={matchDownXL ? 1 : 2}
                                    justifyContent="flex-start">
                                    {/* <Grid item xs={12} sx={{ pb: 0.5 }}>
                                        <Typography variant="subtitle1">Save with a package</Typography>
                                    </Grid> */}
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={11.5}
                                        id="item-holding-list-packages-checkout"
                                        sx={{
                                            mt: 2,
                                            ml: matchUpSM ? 1 : 2,
                                            mr: matchUpSM ? 0 : 2,
                                        }}>
                                        <PackagesListOnCheckout
                                            matchDownXl={matchDownXL}
                                            matchUpSM={matchUpSM}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Collapse>
        </>
    );
};

SelectPaymentOption.propTypes = {
    checkout: PropTypes.object,
};

export default SelectPaymentOption;
