import axios from 'axios';

import { dispatch } from 'store';
import { setHasErrorReducer, setErrorMessageReducer } from 'store/slices/error';

import { getApiUrl } from 'helpers';

export default async function cancelSetupIntent(setupintentId, onDibs, dibsId) {
    const baseURL = getApiUrl('/stripe/cancel-setup-intent');
    let datatosend = {};
    const cancelIntent = async () => {
        await axios
            .post(baseURL, { setupintentId, onDibs, dibsId })
            .then((res) => {
                console.log('canceled the setup intent - cancel set up intent function', res);
                window.localStorage.removeItem('clientSecret');
                window.localStorage.removeItem('intentType');
                // window.localStorage.removeItem('intentPlatform');
                window.localStorage.removeItem('stripeReady');
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from canceling setup options from stripe: ${err}`);
                dispatch(setHasErrorReducer(true));
                setTimeout(() => {
                    dispatch(setHasErrorReducer(false));
                    dispatch(setErrorMessageReducer(''));
                }, 5000);
                return err;
            });
    };
    if (setupintentId !== null && setupintentId !== undefined) {
        if (setupintentId.length > 3) await cancelIntent();

    }

    return datatosend;
}
