import { dispatch } from 'store';
import { setWidgetWidth, setWdth } from 'store/slices/dibsstudio';

const calculateWidth = (wdth) => {
    if (wdth > 1600) {
        return 700;
    } else if (wdth >= 1000) {
        return 600;
    } else if (wdth >= 800) {
        return wdth - 50;
    } else {
        return wdth - 15;
    }
};

const calculateWidthFullPageWidget = (wdth) => {
    if (wdth < 500) return parseInt(wdth * 0.95);
    // const wdt = '100vw';
    // console.log('wdth is', wdth)
    switch (true) {
        case wdth < 600:
            return parseInt(wdth * 0.96);
        case wdth < 960:
            return parseInt(wdth * 0.9);
        case wdth < 1280:
            // console.log('width to return is', parseInt(wdth * 0.9))
            return parseInt(wdth * 0.9);
        default:
            return parseInt(wdth * 0.9);
    }
};
const calculateWidgthRightWidget = (wdth) => {
    if (wdth <= 600) return parseInt(wdth * 0.95);
    if (wdth >= 1600) return 700;
    if (wdth >= 1000) return 600;
    return parseInt(wdth * 0.8);
};

export default async function updateWidths(typeofWidget) {
    const widthOfWindow = window.innerWidth;
    if (widthOfWindow > 10) {
        const widthToSet = calculateWidth(widthOfWindow);
        dispatch(setWidgetWidth(widthToSet));
        if (typeofWidget === 'full-page') {
            const widgetReturned = calculateWidthFullPageWidget(widthOfWindow);
            dispatch(setWidgetWidth(widgetReturned));
        } else {
            dispatch(setWidgetWidth(calculateWidgthRightWidget(widthOfWindow)));
        }
    }
    window.addEventListener('message', (event) => {
        try {
            const { windowWidth } = event.data;
            if (windowWidth > 0) {
                if (windowWidth < 550) {
                    window.isTiny = true;
                } else {
                    window.isTiny = false;
                }
                if (typeofWidget === 'full-page') {
                    const widgetReturned =
                        calculateWidthFullPageWidget(windowWidth);
                    dispatch(setWidgetWidth(widgetReturned));
                } else {
                    const widthtouse = calculateWidgthRightWidget(windowWidth);

                    dispatch(setWidgetWidth(widthtouse));
                }
            }
        } catch (error) {
            console.log(
                'error from iframe event listener (updateWidths) is: ',
                error,
            );
        }
    });
}
