import React from 'react'
import { Collapse } from '@mui/material'


const CollapseComp = ({ children, showThis }) => {
    return (
        <Collapse in={showThis} timeout={500} appear easing="ease-in-out" mountOnEnter unmountOnExit children={children} sx={{ width: '100%' }} />
    )
};

export default CollapseComp;

