import axios from 'axios';

import { dispatch } from 'store';
import { setHasErrorReducer, setErrorMessageReducer } from 'store/slices/error';

export default async function removeCardFromStripe(cardInfo, dibsStudioId, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/remove-card`;
    let datatosend = {};

    const removeCard = async () => {
        const tosend = {
            cardInfo,
            dibsId: dibsStudioId,
            userid
        }
        await axios
            .post(basicurl, tosend)
            .then((res) => {
                console.log('returning from remove card from stripe - value is', res);
                const { data } = res;

                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from removing card from stripe: ${err}`);
                dispatch(
                    setErrorMessageReducer(
                        `There was an error removing your card from your profile. ${err}`,
                    ),
                );
                dispatch(setHasErrorReducer(true));
                setTimeout(() => {
                    dispatch(setHasErrorReducer(false));
                    dispatch(setErrorMessageReducer(''));
                }, 5000);
                return err;
            });
    };

    await removeCard();
    return datatosend;
}
