import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CheckoutLayout from 'layouts/checkout';

import { dispatch, useSelector } from 'store';
// import { verifyUserClickedEmailLink } from 'firebaseConfig';
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
import { setPageSelected } from 'store/slices/menu';
import { setLastLocation, setAvailableCredit } from 'store/slices/thisuser';
import {
    setItemsInCartWithPasses,
    setTotal,
    setSubTotal,
    setTotalTax,
    setNeedsToPay,
    setTotalAfterCreditApplied,
    setCreditApplied,
    setNewCartPreparedForCheckout,
    updatePackagesBeingUsed,
    determineExtraAvailability,
    determineExtraAvailabilityNew,
    updatePotentialRemainingCredit,
    setManualOverridePackageApplicationAlone
} from 'store/slices/cart';
import setPassesWithEventsCartView from 'actions/checkout/setPassesWithEventsCartView';
import getValuesForTotals from 'actions/checkout/getValuesForTotals';
import getCreditToApply from 'actions/checkout/getCreditToApply';
import getCreditFromDB from 'actions/getCredit';
import associatePassesWithEvents from 'actions/checkout/associatePassesWithEventsNew';
import getConfigs from 'actions/getConfigs';

const CheckoutPageView = () => {
    let { dibsId } = useParams();

    const { passes, credit, userid } = useSelector((state) => state.thisuser);
    const { itemsInCartHere, packageItemsInCart, cartPreparedForCheckout, manualOverridePackageApplication } =
        useSelector((state) => state.cart);
    const [calledPasses, setCalledPasses] = useState(true);
    const location = useLocation();
    useEffect(() => {
        const getConfig = async () => {
            await getConfigs(dibsId).then((res) => {
                if (res.stripeId) window.localStorage.setItem('stripeKeyConnected', res.stripeId);
            });
        };
        getConfig();
    }, [dibsId]);

    useEffect(() => {
        dispatch(setPageSelected('checkout'));
        dispatch(setLastLocation(location.pathname));
        setCalledPasses(false);


        // setPasses(); // not sure whether this should be commented out
    }, [
        credit,
        itemsInCartHere,
        location.pathname,
        packageItemsInCart,
        passes,
    ]);
    useEffect(() => {
        dispatch(setPageSelected('checkout'));
        dispatch(setLastLocation(location.pathname));
        setCalledPasses(false);
        const associatePassesWithCart = async () => {
            await associatePassesWithEvents(itemsInCartHere, passes, credit).then((newcart) => {

                if (newcart.length > 0) {
                    dispatch(setNewCartPreparedForCheckout(newcart));
                    dispatch(updatePackagesBeingUsed(newcart));
                    const extraAvailability = {
                        passes,
                        newcart
                    }
                    dispatch(determineExtraAvailabilityNew(extraAvailability));
                }
            });
        };
        if (!manualOverridePackageApplication) {
            associatePassesWithCart();
        }
    }, [credit, itemsInCartHere, location.pathname, manualOverridePackageApplication, passes])
    useEffect(() => {
        return () => {
            dispatch(setManualOverridePackageApplicationAlone(false));
        };
    }, []);
    useEffect(() => {
        dispatch(updatePackagesBeingUsed(cartPreparedForCheckout));
        const extraAvailability = {
            passes,
            newcart: cartPreparedForCheckout
        }
        dispatch(determineExtraAvailabilityNew(extraAvailability));
    }, [cartPreparedForCheckout, passes])
    useEffect(() => {
        const updateCreditApplied = async () => {
            await getCreditFromDB(dibsId, userid).then((res) => {
                dispatch(setAvailableCredit(res));
            }).then(async () => {
                await getCreditToApply(cartPreparedForCheckout, credit).then((res) => {
                    const { remainingtotal, creditapplied, remainingcreditbalance } = res;
                    dispatch(setTotalAfterCreditApplied(remainingtotal));
                    dispatch(setCreditApplied(creditapplied));
                    dispatch(updatePotentialRemainingCredit(remainingcreditbalance));

                })
            });

        };
        updateCreditApplied();
    }, [cartPreparedForCheckout, credit, userid, dibsId])
    return <CheckoutLayout dibsId={dibsId} />;
};

export default CheckoutPageView;
