

import axios from 'axios';
export default async function getConfigs(dibsStudioId) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/get-stripe-account-id`;
    let datatosend = {};
    const reqbody = {
        dibsId: parseInt(dibsStudioId),
    };
    const intent = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from getting config: ${err}`);
                return err;
            });
    };
    await intent();
    return datatosend;
}
