import React from 'react';
import { gridSpacing } from 'store/constant';

import { dispatch, useSelector } from 'store';
import {
    setHasSetPwdFirebase,
    setUserId,
    setIsNewUser,
    setIsLoggedIn,
    setRecentlyLoggedIn,
    setAlreadyHasAccount,
    setIsRegistering,
} from 'store/slices/thisuser';
import { registerWithEmailAndPassword } from 'firebaseConfig';

// material-ui
import {
    Grid,
    Button,
    InputLabel,
    Typography,
    TextField,
    Fade,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';
import createNewDibsUser from 'actions/users/createNewDibsUser';
import createNewShopifyUser from 'actions/shopify/createNewShopifyUser';

const validationSchema = yup.object().shape({
    newpassword: yup
        .string()
        .required('Password is required')
        .min(
            7,
            'Your password must be at least 8 characters. Please try again.',
        ),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCard = ({ setCurrentStep, emailAddyPwd }) => {
    const { email, firstname, lastname, phone, birthday } = useSelector(
        (state) => state.thisuser,
    );
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const [buttonText, setButtonText] = React.useState('Submit');
    const formik = useFormik({
        initialValues: {
            // newpassword: 'password', // remove before production
            newpassword: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                const newpwd = values.newpassword;
                setButtonText('Submitting...');
                await registerWithEmailAndPassword(
                    emailAddyPwd,
                    values.newpassword,
                ).then(async (res) => {
                    dispatch(setHasSetPwdFirebase(true));
                    const datatosend = {
                        email: emailAddyPwd,
                        firstname,
                        lastname,
                        phone,
                        birthday: null,
                        dibsStudioId,
                        newpwd,
                    };
                    console.log('LOG 12 - datatosend to create a new dibs user is', datatosend);

                    await createNewDibsUser(datatosend).then((res) => {
                        setCurrentStep('confirmation');
                        setButtonText('Submit');
                        if (res) {
                            dispatch(setUserId(res.userid));
                            dispatch(setRecentlyLoggedIn(true));
                            dispatch(setIsNewUser(true));
                            dispatch(setIsLoggedIn(true));
                            dispatch(setAlreadyHasAccount(true));
                            setTimeout(() => {
                                dispatch(setRecentlyLoggedIn(false));
                            }, 300000);
                            dispatch(setIsRegistering(false));
                        }
                    });
                    if (dibsStudioId === '226') {
                        await createNewShopifyUser(datatosend).then((res) => {
                            console.log(
                                'shopify customer account was created',
                                res,
                            );
                        });
                    }
                });
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <Fade in>
                    <LoginCard title="Register" dibsstudioid={dibsStudioId}>
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ ml: 1, mr: 1 }}>
                                <Typography variant="regularText">
                                    And finally, enter a password for your
                                    account
                                </Typography>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}>
                                    <Grid container justifyContent="flex-start">
                                        <Grid
                                            item
                                            xs={10}
                                            md={6}
                                            sx={{ mt: 2, ml: 1 }}>
                                            <InputLabel>Password</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="newpassword"
                                                value={
                                                    formik.values.newpassword ||
                                                    ''
                                                }
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="********"
                                                type="password"
                                                error={
                                                    formik.touched
                                                        .newpassword &&
                                                    Boolean(
                                                        formik.errors
                                                            .newpassword,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched
                                                        .newpassword &&
                                                    formik.errors.newpassword
                                                }
                                                sx={{ mt: 1 }}>
                                                Password
                                            </TextField>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={11}
                                            sx={{ mt: 4, mb: 2.5 }}>
                                            <Grid
                                                container
                                                justifyContent="flex-start">
                                                <Grid item xs={11} md={7.5}>
                                                    <Button
                                                        variant="checkout"
                                                        type="submit"
                                                        fullWidth
                                                        sx={{ height: '42px' }}
                                                        disabled={
                                                            formik.isSubmitting
                                                        }>
                                                        {buttonText}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};
export default LoginAuthenticationCard;
