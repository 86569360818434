import * as React from 'react';
import { Grid, Fade, Box, AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { setHasErrorReducer, setErrorMessageReducer } from 'store/slices/error';

import { useSelector } from 'store';
import { dispatch } from 'store';

const ErrorMessage = () => {
    const theme = useTheme();
    const { hasError, errorMessage } = useSelector((state) => state.error);

    if (!hasError) return null;
    const removeError = () => {
        dispatch(setHasErrorReducer(false));
        dispatch(setErrorMessageReducer(''));
    };
    return (
        <Fade in>
            <Box sx={{ flexGrow: 1, display: 'flex', width: '100%' }}>
                <Grid
                    item
                    xs={12}
                    onClick={removeError}
                    sx={{
                        backgroundColor: theme.palette.error.light,
                        mt: 1,
                        ml: 1,
                    }}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item xs={11} sx={{ mx: 1 }}>
                            <Typography align="center">
                                {errorMessage}
                            </Typography>
                        </Grid>
                        <Grid item alignItems="right">
                            <IconButton
                                color="dark"
                                aria-label="close"
                                size="small"
                                onClick={() =>
                                    dispatch(setHasErrorReducer(false))
                                }>
                                <CloseIcon fontSize="small" align="right" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );
};

export default ErrorMessage;
