import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Grid } from '@mui/material';

import CheckoutForm from './checkoutForm';
import CheckoutFormSetup from './checkoutFormSetup';
import Progress from 'assets/CircularIndeterminate';

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const connectedAccount = window.localStorage.getItem('stripeKeyConnected');
const platformUsed = window.localStorage.getItem('intentPlatform');
const dibsId = window.localStorage.getItem('dibsId');
console.log('load stripe - dibs ID is', dibsId);
let studioAcceptsPayments = dibsId === 226 ? false : true;
if (!dibsId) {
    studioAcceptsPayments = false;
}
let stripeObject;
let stripePromise;
if (platformUsed !== null && studioAcceptsPayments) {
    if (platformUsed === 'dibs') {
        stripeObject = { apiVersion: '2023-10-16' };
        window.localStorage.setItem('stripeloadedwith', 'dibs');
    } else {
        stripeObject = {
            apiVersion: '2023-10-16',
            stripeAccount: connectedAccount,
        };
        window.localStorage.setItem('stripeloadedwith', 'connected');
    }
    stripePromise = loadStripe(stripeKey, stripeObject);
    console.log('stripe promise is', stripePromise);
}

export default function StripeLoad({
    includeHeader,
    includeButton,
    setup,
    cancelAction,
    loadNow,
    fromLocation,
    setShowAddCC,
}) {
    // let stripeClientSecret = sessionStorage.getItem("clientSecret");
    const stripeClientSecretToUse = window.localStorage.getItem('clientSecret');
    // sessionStorage.setItem('stripeReady', false);

    const setStripeReady = () => {
        sessionStorage.setItem('stripeReady', true);
        window.localStorage.setItem('stripeReady', true);
    };

    let fromLocationToUse = fromLocation;
    if (!fromLocationToUse) {
        fromLocationToUse = 'notSpecified';
    }
    let backgroundColortouse = '#f7f7f7';
    if (fromLocationToUse === 'packages') {
        backgroundColortouse = '#fff';
    }

    const options = {
        // passing the client secret obtained from the Stripe Dashboard
        clientSecret: stripeClientSecretToUse,
        appearance: {
            theme: 'flat',
            labels: 'floating',
            variables: {
                fontFamily: ' "Roboto", sans-serif',
                fontLineHeight: '1.1',
                fontSizeBase: '13px',
                borderRadius: '5px',
                colorBackground: backgroundColortouse,
                colorPrimaryText: '#999',
                colorPrimary: '#ff6900',
            },
            rules: {
                '.Label': {
                    marginBottom: '12px',
                    fontWeight: '300',
                },

                '.Input': {
                    padding: '4px',
                    border: '1px solid',
                    // borderColor: 'var(--colorPrimary)',
                    borderColor: '#ddd',
                    // boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                    boxShadow: 'none',
                    marginBottom: '3px',
                },
            },
        },
        loader: 'always',
    };
    if (!stripeClientSecretToUse) {
        const loading = <Grid>Loading...</Grid>;
        return loading;
    }
    console.log('stripe promise load stripe new');
    if (!stripePromise) return <Progress />;
    console.log('SHOULD NOT BE SEEING THIS');
    console.log(
        'new load stripe - platform used to load stripe is',
        platformUsed,
    );
    if (setup) {
        return (
            <Elements
                stripe={stripePromise}
                options={options}
                onReady={setStripeReady()}>
                <CheckoutFormSetup
                    includeButton
                    platformUsed={platformUsed}
                    cancelAction={cancelAction}
                    setShowAddCC={setShowAddCC}
                />
            </Elements>
        );
    } else {
        return (
            <Elements
                stripe={stripePromise}
                options={options}
                onReady={setStripeReady()}>
                <CheckoutForm includeButton />
            </Elements>
        );
    }
}
