import { useEffect, useState, useRef } from 'react';
import {
    Grid,
    Typography,
    CardActions,
    Button,
    Card,
    CardContent,
    Stack,
    Fade,
    Link,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { capitalizeString } from 'helpers';

import { setShowPrices } from 'store/slices/dibsstudio';

// actions
import purchaseFreePackage from 'actions/checkout/purchasePackage';
import getPaymentMethodsForUser from 'actions/stripe/getAllStripePayments';
import createStripeCharge from 'actions/stripe/createChargeForPackage';
import createStripeSubscription from 'actions/stripe/createSubscription';
import getAvailablePasses from 'actions/getAvailablePasses';
import sendConfirmationEmailPackage from 'actions/emails/send-confirmation-package';
import checkoutFreePackage from 'actions/checkout/checkoutFreePackageDibsid';
import associatePassesWithEvents from 'actions/checkout/associatePassesWithEvents';
import purchasePassFromCheckoutPage from 'actions/checkout/purchasePassFromCheckoutPage';
import DisplayCards from 'components/account/cc-display/displayTinyCards';

import DisplayAddingCCNoPayments from 'components/account/cc-display/displayAddingCCNoPayments';

import StripeWrapper from 'components/stripe/wrapperForCard';

import { useTheme } from '@mui/material/styles';

import { useSelector, dispatch } from 'store';
import {
    setPurchasingPackage,
    setTotal,
    setNewCartPreparedForCheckout,
    setShowCCCapture,
    setEditingPaymentMethod,
    setCardToCharge,
    setIsUsingCreditToCoverPackage,
    setCardBrand,
    setLastFourCard,
    setExpy,
    setPlatformHoldingCardToCharge,
} from 'store/slices/cart';
import {
    setAvailablePasses,
    setRefreshAvailablePasses,
} from 'store/slices/thisuser';
import Progress from 'assets/CircularIndeterminate';
import setAlert from 'actions/status/setAlert';

import { formatDollarAmountTwoDigits, roundtoN } from 'helpers';
import PackageListBreakdown from './packagesListBreakdown';
import XIcon from 'assets/icons/XIcon';
import BottomSectionForPackages from './bottomSectionActivePackage';

import EditIcon from 'assets/icons/EditIcon';
import {
    clearAllStatusMessageReducer,
    setErrorMessageReducer,
    setHasErrorReducer,
} from 'store/slices/error';
import setupIntent from 'actions/stripe/createStripeSetupIntent';
import cancelSetupIntent from 'actions/stripe/intents/cancelSetupIntent';

const getclassesText = (numClasses) => {
    if (numClasses === 1) {
        return '1 class';
    } else if (numClasses >= 999) {
        return 'Unlimited classes';
    } else {
        return `${numClasses} classes`;
    }
};
const getexpiresText = (
    validity,
    increment,
    commitment,
    autopay,
    isWideButNotTooWide,
) => {
    let incrementText = increment;

    let expiresText = `Expires ${validity} ${incrementText} after purchase`;
    if (isWideButNotTooWide) {
        expiresText = `Expires in ${validity} ${incrementText}`;
    }
    if (autopay === 'FORCE' || autopay === 'ALLOW') {
        expiresText = `Renews ${validity} ${incrementText} after purchase date`;
    }
    if (commitment > 0) {
        expiresText = `Renews ${increment}ly.\n ${commitment} ${increment} minimum commitment`;
    }
    return expiresText;
};
const PackagesListItemTiny = ({
    pack,
    confirmingPurchase,
    isWideButNotTooWide,
}) => {
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [showThisPackageEdit, setShowThisPackageEdit] = useState(true);
    const [mustAddCC, setMustAddCC] = useState(false);
    const [showCCScreenDisplay, setShowCCScreenDisplay] = useState(true);
    const [showLogin, setShowLogin] = useState(false);
    const [sendingToLoginPage, setSendingToLoginPage] = useState(false);
    const [userHasCardOnFile, setUserHasCardOnFile] = useState(false);
    const [showConfirmButtonText, setShowConfirmButtonText] = useState(true);
    const [totalAmountToCharge, setTotalAmountToCharge] = useState('');
    const [emailType, setEmailType] = useState('no-autopay');
    const [includeApplyCreditText, setIncludeApplyCreditText] = useState(false);
    const { showPricesWidget, color } = useSelector(
        (state) => state.dibsstudio.studioConfig,
    );
    const [widthtouse, setWidthToUse] = useState(185);
    const [userIsAddingNewCard, setUserIsAddingNewCard] = useState(false);

    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const {
        isNewUser,
        credit,
        userid,
        email,
        lastname,
        firstname,
        stripeid,
        paymentOptionsAll,
    } = useSelector((state) => state.thisuser);
    const {
        subtotal,
        tax,
        cartPreparedForCheckout,
        cardtocharge,
        cardlastfour,
        cardbrand,
        editingPaymentMethodInCart,
        packEditingFor,
        isUsingCreditToCoverPackage,
    } = useSelector((state) => state.cart);

    const [requestedSetupIntent, setRequestedSetupIntent] = useState(false);
    const [setupIntentIdCreated, setSetupIntentIdCreated] = useState(null);
    const onDibsPlatform = true;
    const [totalCost, setTotalCost] = useState(0);
    const [isPurchasing, setIsPurchasing] = useState(false);
    const [didPurchase, setDidPurchase] = useState(false);
    const [isConfirming, setIsConfirming] = useState(confirmingPurchase);
    const [showThisPackage, setShowThisPackage] = useState(true);
    const [confirmingText, setConfirmingText] = useState('Paying with...');

    // const [tax, setTax] = useState(0);
    const [showAddCCComponent, setShowAddCCComponent] = useState(false);
    const [buttonText, setButtonText] = useState(pack.buttonText);

    useEffect(() => {
        return () => {
            setRequestedSetupIntent(false);
            setSetupIntentIdCreated(null);
        };
    }, []);
    useEffect(() => {
        if (paymentOptionsAll.length > 0) {
            setUserHasCardOnFile(true);
        } else {
            setUserHasCardOnFile(false);
        }
    }, [paymentOptionsAll]);
    useEffect(() => {
        if (credit === 0) {
            dispatch(setIsUsingCreditToCoverPackage(false));
        }
    }, [credit]);

    useEffect(() => {
        if (credit >= totalCost) {
            setIncludeApplyCreditText(true);
        } else {
            setIncludeApplyCreditText(false);
        }
    }, [credit, totalCost]);


    useEffect(() => {
        if (
            cardtocharge === '' ||
            cardbrand === 'Brand' ||
            cardlastfour === 'xxxx'
        ) {
            if (paymentOptionsAll.length > 0) {
                const thiscard = paymentOptionsAll[0].id;
                const thisbrand = paymentOptionsAll[0].card.brand;
                const thislastfour = paymentOptionsAll[0].card.last4;
                const expyYr = paymentOptionsAll[0].card.exp_year;
                const expyMo = paymentOptionsAll[0].card.exp_month;
                const platformCard = paymentOptionsAll[0].platform;
                const expy = `${expyMo}/${expyYr}`;
                dispatch(setCardToCharge(thiscard));
                dispatch(setCardBrand(thisbrand));
                dispatch(setLastFourCard(thislastfour));
                dispatch(setExpy(expy));
                dispatch(setPlatformHoldingCardToCharge(platformCard));
            } else {
                // set text to say that there ain't no card on file.
                if (!isUsingCreditToCoverPackage) {
                    const thistext = `No card on file. Click edit to add a new card.`;
                    setConfirmingText(thistext);
                }
            }
        }
    }, [
        cardtocharge,
        cardbrand,
        cardlastfour,
        paymentOptionsAll,
        isUsingCreditToCoverPackage,
    ]);
    useEffect(() => {
        let thiswidth = isWideButNotTooWide ? 160 : 185;
        if (editingPaymentMethodInCart) thiswidth = 450;
        setWidthToUse(thiswidth);
    }, [editingPaymentMethodInCart, isWideButNotTooWide]);
    useEffect(() => {
        if (editingPaymentMethodInCart && showCCScreenDisplay) {
            setShowConfirmButtonText(false);
        } else {
            setShowConfirmButtonText(true);
        }
    }, [isPurchasing, editingPaymentMethodInCart, showCCScreenDisplay]);
    // console.log('must add cc', mustAddCC);
    const cancelPackagePurchase = async () => {
        const pinfo = {
            packageid: null,
            isPurchasing: false,
        };
        setMustAddCC(false);
        dispatch(setPurchasingPackage(pinfo));
        setShowAddCCComponent(false);
        setIsPurchasing(false);
        setIsConfirming(false);
        dispatch(
            setEditingPaymentMethod({
                isEditingMethod: false,
                packageId: 0,
            }),
        );
        setUserIsAddingNewCard(false);
        if (setupIntentIdCreated !== null) {
            await cancelSetupIntent(
                setupIntentIdCreated,
                onDibsPlatform,
                dibsStudioId,
            ).catch((err) => {
                console.log('error canceling the intent', err);
            });
            setSetupIntentIdCreated(null);
        }
    };

    const selectPaymentMethod = () => {
        setShowCCScreenDisplay(false);
        setIsConfirming(true);
        dispatch(setIsUsingCreditToCoverPackage(false));
    };
    const addNewCardFunction = async () => {
        console.log('user clicked add new card - no payment on file');
        setUserIsAddingNewCard(true);
        dispatch(clearAllStatusMessageReducer());
        if (!requestedSetupIntent) {
            console.log('has not requested setup intent YET');
            setRequestedSetupIntent(true);
            await setupIntent(
                stripeid,
                userid,
                email,
                `${firstname} ${lastname}`,
                dibsStudioId,
                onDibsPlatform,
            )
                .then((res) => {
                    console.log('returned from setting up intent', res);
                    window.localStorage.setItem(
                        'clientSecret',
                        res.clientSecret,
                    );
                    console.log('setting client secret - line 299 - oackage tiny', res.clientSecret)
                    setSetupIntentIdCreated(res.setupIntentId);
                })
                .catch((err) => {
                    console.log(
                        'error is - after creating stripe intent on tiny cards - let the user know what to do',
                        err,
                    );
                    setAlert({
                        msg: 'There was an error loading the credit card capture element. Please refresh your screen and try again.',
                        seconds: 5,
                        error: true,
                    });
                    setRequestedSetupIntent(false);
                });
        }
    };
    const endingAction = () => {
        console.log('user clicked to call ending action - list item tiny');
        setUserIsAddingNewCard(false);
        setRequestedSetupIntent(false);
        setSetupIntentIdCreated(null);

    };
    const selectPayWithCredit = () => {
        setShowCCScreenDisplay(false);
        setIsConfirming(true);
        dispatch(setIsUsingCreditToCoverPackage(true));
    };

    const updatePassOptions = async () => {
        await getAvailablePasses(dibsStudioId, userid)
            .then(async (passes) => {

                dispatch(setAvailablePasses(passes));
                await associatePassesWithEvents(
                    cartPreparedForCheckout,
                    passes,
                ).then((newcart) => {
                    dispatch(setNewCartPreparedForCheckout(newcart));
                });
                setIsPurchasing(false);
                setIsConfirming(false);
            })
            .catch((err) => {
                console.log(
                    `Error getting available passes after checkout free package: ${err}`,
                );
                setIsPurchasing(false);
            });
    };
    const addItemToCart = async (packageinfo, event) => {
        // dispatch(setTotal(roundtoN(total, 2)));  // was setting the cart value to 0 for the package for some reason
        const { price, taxrate } = packageinfo;

        const taxTotal = roundtoN((price * taxrate) / 100, 2);
        const totalToCharge = price + taxTotal;

        // setIsPurchasing(true);
        const newpackinfo = {
            ...packageinfo,
            taxTotal,
            totalToCharge,
        };

        if (userid === 0) {
            setShowLogin(true);
            console.log('user is not logged in');
            window.localStorage.setItem('lastLocation', location.pathname);
            window.localStorage.setItem('lastpagevisited', location.pathname);
            setSendingToLoginPage(true);
            setTimeout(() => {
                navigate(`/login/${dibsStudioId}`);
            }, 2000);
            setButtonText('Login...');
            return;
        }
        // need to add something for isConfirming - should not go through this process if not on second step

        if (price === 0) {
            const packageInfoToPass = [];
            const thispack = {
                ...newpackinfo,
                dibsId: dibsStudioId,
            };
            packageInfoToPass.push(thispack);
            setIsPurchasing(true);
            await checkoutFreePackage(
                userid,
                packageInfoToPass,
                dibsStudioId,
                email,
            ).then(async (res) => {
                setDidPurchase(true);
                setButtonText('Added!');
                setIsPurchasing(false);
                setIsConfirming(false);
                updatePassOptions();
                sendConfirmationEmailPackage(
                    dibsStudioId,
                    userid,
                    0,
                    packageInfoToPass[0].totalToCharge,
                    packageInfoToPass,
                    emailType,
                )
            });
        } else {
            if (!isConfirming) {
                setIsConfirming(true);
                setIsPurchasing(false);
            }
            if (isConfirming) {
                // if there is no credit card on file, need to set alert to add a credit card

                if (!userHasCardOnFile) {
                    setAlert({
                        msg: 'You don\'t have a credit card on file. Please click the edit button to add a credit card to continue.',
                        seconds: 8,
                        warning: true,
                    });
                    return;
                }

                setIsPurchasing(true);
                setIsConfirming(false);


                await purchasePassFromCheckoutPage(
                    userid,
                    newpackinfo,
                    credit,
                    cardtocharge,
                    paymentOptionsAll,
                    isUsingCreditToCoverPackage,
                ).then(async (res) => {
                    if (res === 1) {
                        setDidPurchase(true);
                        setIsPurchasing(false);
                        setButtonText('Added!');
                        setIsConfirming(false);
                        updatePassOptions();
                        dispatch(setIsUsingCreditToCoverPackage(true));
                        sendConfirmationEmailPackage(
                            dibsStudioId,
                            userid,
                            0,
                            newpackinfo.totalToCharge,
                            newpackinfo,
                        )

                    } else if (res === 3) {
                        setIsConfirming(true);
                        setIsPurchasing(false);
                        dispatch(
                            setErrorMessageReducer(
                                'There was an error processing your payment. Please check your credit card information and try again.',
                            ),
                        );
                        dispatch(setHasErrorReducer(true));
                    } else {
                        setIsConfirming(true);
                        setIsPurchasing(false);
                        dispatch(
                            setErrorMessageReducer(
                                'There was an unknown error. Please refresh your screen and try again.',
                            ),
                        );
                        dispatch(setHasErrorReducer(true));
                    }
                    dispatch(setRefreshAvailablePasses(true));
                });
            }
        }

        return;
    };
    useEffect(() => {
        const taxAmount = roundtoN((pack.price * pack.taxrate) / 100, 2);
        const totalAmount = roundtoN(pack.price + taxAmount, 2);
        setTotalCost(totalAmount);
        setTotalAmountToCharge(formatDollarAmountTwoDigits(totalAmount));
        if (credit > pack.price && isUsingCreditToCoverPackage) {
            const thistext = `Appling ${formatDollarAmountTwoDigits(
                totalAmount,
            )} in credit. (Includes tax)`;
            setConfirmingText(thistext);
        } else if (credit > 0 && isUsingCreditToCoverPackage) {
            const remainder = pack.price - credit;
            const thistext = `Applying ${formatDollarAmountTwoDigits(
                totalAmount,
            )} in credit. \nPaying ${formatDollarAmountTwoDigits(
                remainder,
            )} w / ${capitalizeString(
                cardbrand,
            )} ending in ${cardlastfour}. (Includes tax)`;
            setConfirmingText(thistext);
            dispatch(setIsUsingCreditToCoverPackage(true));
        } else {
            if (paymentOptionsAll.length > 0) {
                const thistext = `Paying ${formatDollarAmountTwoDigits(
                    totalAmount,
                )} w / ${capitalizeString(
                    cardbrand,
                )} ending in ${cardlastfour} (includes tax)`;
                setConfirmingText(thistext);
            }
        }
    }, [
        pack,
        cardbrand,
        cardlastfour,
        credit,
        isUsingCreditToCoverPackage,
        paymentOptionsAll.length,
    ]);
    useEffect(() => {
        if (!isNewUser) {
            if (pack.limitedToNewClients) {
                setShowThisPackage(true);
                // setShowThisPackage(false);
            }
        }
    }, [isNewUser, pack]);
    useEffect(() => {
        if (editingPaymentMethodInCart) {
            const thispack = pack.id;
            if (packEditingFor === thispack) {
                setShowThisPackageEdit(true);
            } else {
                setShowThisPackageEdit(false);
            }
        } else {
            setShowThisPackageEdit(true);
        }
    }, [editingPaymentMethodInCart, pack.id, packEditingFor]);
    let priceFormatted = '';

    priceFormatted = `$${pack.price}`;
    if (pack.price === 0) {
        priceFormatted = 'FREE';
    }

    const packName = pack.packageName;
    const formattedName = packName.toUpperCase();
    const numclassestext = getclassesText(pack.numClasses);
    const expirestext = getexpiresText(
        pack.passesValidFor,
        pack.validForInterval,
        pack.commitmentPeriod,
        pack.autopay,
        isWideButNotTooWide,
    );
    const colortoshow = `#${color}`;
    const newtheme = createTheme({
        ...theme,
        palette: {
            primary: {
                main: colortoshow,
            },
        },
    });
    const editPayMethod = async () => {
        dispatch(clearAllStatusMessageReducer());
        dispatch(
            setEditingPaymentMethod({
                isEditingMethod: true,
                packageId: pack.id,
            }),
        );
        setIsConfirming(false);
        setShowCCScreenDisplay(true);
        // dispatch(setShowCCCapture(true));
    };
    // if (showLogin) {
    //     return (
    //         <Fade in>
    //             <Grid
    //                 item
    //                 border={0}
    //                 height={200}
    //                 id="redirect-to-login-page"
    //                 sx={{ pt: 3 }}>
    //                 <Typography>
    //                     You must be logged in to purchase a package. You will be
    //                     redirected to the login page now...
    //                 </Typography>
    //             </Grid>
    //         </Fade>
    //     );
    // }
    if (showAddCCComponent)
        return (
            <Fade in>
                <Grid
                    item
                    border={0}
                    id="item-package-card-cc-tiny"
                    sx={{
                        mb: 3,
                        display: 'flex',
                        px: 1,
                    }}>
                    <Stack>
                        <Typography variant="checkout" sx={{ ml: 5, pb: 2 }}>
                            Enter your credit card information to continue.
                        </Typography>
                        <StripeWrapper
                            color={colortoshow}
                            actionToCancel={cancelPackagePurchase}
                            location={location}
                        />
                    </Stack>
                </Grid>
            </Fade>
        );
    if (sendingToLoginPage) {
        console.log('sending to login page');
    }
    if (!showThisPackageEdit) return null;
    return (
        <>
            {showThisPackage && (
                <Fade in>
                    <Grid
                        item
                        border={0}
                        id="item-package-card-this-tiny"
                        sx={{
                            // mb: 3,
                            display: 'flex',
                            px: 0.5,
                            height: '100%',
                        }}>
                        <Stack id="stack-for-package-tiny">
                            <Card
                                id="package-card"
                                sx={{
                                    width: widthtouse,
                                    // height: 120,
                                    backgroundColor: '#ffffff',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    flexDirection: 'column',
                                    height: '100%',
                                    // border: 1,
                                    // borderColor: '#dee2e6',
                                }}>
                                <CardContent
                                    sx={{
                                        px: 1,
                                        pt: 2,
                                        pb: 0,
                                    }}>
                                    <Grid
                                        container
                                        display="flex"
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="flex-end">
                                        <Grid
                                            item
                                            id="item-holding-package-name-tiny"
                                            xs={12}
                                            border={0}
                                            sx={{ mb: 0 }}>
                                            <Stack>
                                                {confirmingPurchase && (
                                                    <Grid
                                                        container
                                                        justifyContent="flex-end"
                                                        onClick={
                                                            cancelPackagePurchase
                                                        }
                                                        sx={{ mb: 1 }}>
                                                        <XIcon color={color} />
                                                    </Grid>
                                                )}
                                                {!showLogin && (
                                                    <Typography
                                                        variant="packageNamesTiny"
                                                        align="center"
                                                        color={colortoshow}
                                                        sx={{ mb: 1 }}>
                                                        {formattedName}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Grid>
                                        {!showLogin && (
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    mb: 1,
                                                }}>
                                                {priceFormatted}
                                            </Grid>
                                        )}

                                        {confirmingPurchase && (
                                            <PackageListBreakdown pack={pack} />
                                        )}
                                        <Grid
                                            item
                                            xs={12}
                                            // border={3}
                                            sx={{ display: 'flex', pb: 0 }}>
                                            <Grid
                                                container
                                                justifyContent="center">
                                                {showLogin && (
                                                    <Typography variant="coloredHeader">
                                                        You must be logged in to
                                                        add this package.
                                                        Redirecting you to login
                                                        now.
                                                    </Typography>
                                                )}
                                                {!showLogin && (
                                                    <Typography variant="expiration">
                                                        {expirestext}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions
                                    // height={60}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        mb: 0.5,
                                        pt: 0.75,
                                        mx: 1,
                                    }}>
                                    {editingPaymentMethodInCart &&
                                        showCCScreenDisplay && (
                                            <Grid container>
                                                <Grid
                                                    item
                                                    id="package-card-display-tinys"
                                                    xs={12}
                                                    sx={{ my: 2 }}
                                                    border={0}>
                                                    {paymentOptionsAll.length > 0 &&
                                                        !userIsAddingNewCard && (
                                                            <DisplayCards
                                                                payments={
                                                                    paymentOptionsAll
                                                                }
                                                                exemptFromSelect
                                                            />
                                                        )}
                                                    <Grid
                                                        container
                                                        justifyContent="center">
                                                        {paymentOptionsAll.length >
                                                            0 &&
                                                            !userIsAddingNewCard && (
                                                                <Button
                                                                    variant="greenButton"
                                                                    onClick={
                                                                        selectPaymentMethod
                                                                    }>
                                                                    Use Selected
                                                                    Payment Method
                                                                </Button>
                                                            )}

                                                        {paymentOptionsAll.length ===
                                                            0 &&
                                                            !userIsAddingNewCard && (
                                                                <Button
                                                                    id="add-new-card-checkout"
                                                                    variant="contained"
                                                                    onClick={
                                                                        addNewCardFunction
                                                                    }>
                                                                    Add New Card
                                                                </Button>
                                                            )}
                                                        {userIsAddingNewCard && (
                                                            <DisplayAddingCCNoPayments
                                                                endingAction={
                                                                    endingAction
                                                                }
                                                                intentId={
                                                                    setupIntentIdCreated
                                                                }
                                                            />
                                                        )}
                                                        {includeApplyCreditText && (
                                                            <Grid
                                                                container
                                                                justifyContent="center">
                                                                <Grid
                                                                    item
                                                                    sx={{ mt: 2 }}>
                                                                    <Link
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                            textDecorationColor:
                                                                                '#ADB5BD',
                                                                        }}
                                                                        onClick={
                                                                            selectPayWithCredit
                                                                        }>
                                                                        <Typography
                                                                            variant="applyCredit"
                                                                            align="center">
                                                                            {`Or Apply ${totalAmountToCharge} in Credit`}
                                                                        </Typography>
                                                                    </Link>
                                                                </Grid>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}

                                    {isConfirming && !didPurchase && (
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ ml: 1, mb: 1, mt: 3 }}
                                            border={0}>
                                            <Grid
                                                container
                                                justifyContent="center">
                                                <Grid item xs={9}>
                                                    <Typography variant="h6">
                                                        {confirmingText}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    border={0}
                                                    sx={{ pr: 0.5 }}>
                                                    <Grid
                                                        container
                                                        justifyContent="flex-end"
                                                        onClick={editPayMethod}>
                                                        <EditIcon
                                                            color={color}
                                                            size="small"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}

                                    {showConfirmButtonText && (
                                        <ThemeProvider theme={newtheme}>
                                            <Button
                                                size="small"
                                                disabled={didPurchase}
                                                onClick={(e) =>
                                                    addItemToCart(pack, e)
                                                }
                                                sx={{
                                                    width: '125px',
                                                    mt: 0.5,
                                                    mb: 0.5,
                                                    lineHeight: 1,
                                                    color: '#ffffff',
                                                    backgroundColor:
                                                        newtheme.palette.primary
                                                            .main,
                                                    '&:hover': {
                                                        backgroundColor:
                                                            newtheme.palette
                                                                .primary.light,
                                                    },
                                                }}>
                                                {/* {!isPurchasing &&
                                                    (buttonText)} */}
                                                {!isPurchasing &&
                                                    (!isConfirming
                                                        ? buttonText
                                                        : 'Confirm')}
                                                {isPurchasing && (
                                                    <Progress
                                                        size={13}
                                                        color="white"
                                                    />
                                                )}
                                            </Button>
                                        </ThemeProvider>
                                    )}
                                    {!didPurchase &&
                                        (isConfirming ||
                                            editingPaymentMethodInCart) && (
                                            <Grid item xs={12}>
                                                <Button
                                                    id="cancel-package-purchase"
                                                    onClick={
                                                        cancelPackagePurchase
                                                    }>
                                                    cancel
                                                </Button>
                                            </Grid>
                                        )}

                                </CardActions>
                            </Card>
                            {confirmingPurchase && (
                                <BottomSectionForPackages
                                    pack={pack}
                                    userid={userid}
                                    email={email}
                                    credit={credit}
                                    showCreditCardAction={setShowAddCCComponent}
                                />
                            )}
                        </Stack>
                    </Grid>
                </Fade>
            )}
        </>
    );
};

export default PackagesListItemTiny;
