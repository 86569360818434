
import axios from 'axios';
export default async function updateDibsTransactionsAddAttendees(uuid, stripeChargeId, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/update-dibs-transactions-uuid`;
    let datatosend = {};
    const checkout = async () => {
        await axios.post(basicurl, { uuid, stripeChargeId, userid }).then((res) => {
            datatosend = res.data;
        }).catch((err) => {
            console.log('there was an error updating the dibs transaction uuid', err);
            datatosend = { errorreturned: err };
        });
    };
    await checkout();
    return datatosend;
}
