import * as React from 'react';
import { Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';
import AccountLayout from 'layouts/account';
import { logout } from 'firebaseConfig';

import { dispatch, useSelector } from 'store';
import {
    setShowingSchedule,
    setShowingAppointments,
    setShowingAccount,
    setPageSelected,
} from 'store/slices/menu';
import { setLastLocation } from 'store/slices/thisuser';

const AccountPageView = () => {
    console.log('account page view');
    const location = useLocation();
    const { userid } = useSelector((state) => state.thisuser);

    React.useEffect(() => {
        if (userid === 0) {
            logout();
        }
    }, [userid]);
    React.useEffect(() => {
        dispatch(setShowingSchedule(false));
        dispatch(setShowingAppointments(false));
        dispatch(setShowingAccount(true));
        dispatch(setLastLocation(location.pathname));
        dispatch(setPageSelected('account'));
    }, [location]);
    console.log('account page loading');
    return (
        <Grid container>
            <Grid item xs={12} border={0} id="grid-item-holding-account-layout">
                <AccountLayout />
            </Grid>
        </Grid>
    );
};

export default AccountPageView;
