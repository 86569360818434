import { Grid, Typography, Button, Fade } from '@mui/material';

import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';

import { useSelector, dispatch } from 'store';
import { setIsProcessing, setShowCCCapture } from 'store/slices/cart';

import SubCard from 'ui-component/cards/AccountSubCardTiny';
import IndividualCard from 'components/payment/profileIndividualCardDisplayTiny';
import CollectCC from 'components/stripe/CollectCardInfoTiny';
import setupIntent from 'actions/stripe/createStripeSetupIntent';
import cancelSetupIntent from 'actions/stripe/intents/cancelSetupIntent';

// ==============================|| DISPLAY CC CARDS ||============================== //
import './credit-card-tiny.css';


const countTimesInArray = (arr, val) => {
    return arr.filter(x => x === val).length;
};

const DisplayCardsTiny = ({ payments, exemptFromSelect }) => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [paymentsToDisplay, setPaymentsToDisplay] = useState([]);
    const [hasPayments, setHasPayments] = useState(false);
    const [subCardTitle, setSubCardTitle] = useState('Choose A Payment Method');
    const [showPayments, setShowPayments] = useState(false);
    const [includeSecondary, setIncludeSecondary] = useState(true);
    const [showCardCapture, setShowCardCapture] = useState(false);
    const [setupIntentIsReady, setSetupIntentIsReady] = useState(false);
    const [didRequestSetupIntent, setDidRequestSetupIntent] = useState(false);
    const [showTinyCardCapture, setShowTinyCardCapture] = useState(true);
    const [setupIntentCreatedId, setSetupIntentCreatedId] = useState(null);
    const [onDibsPlatform, setOnDibsPlatform] = useState(true);
    const { userid, firstname, lastname, email, stripeid } =
        useSelector((state) => state.thisuser);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);



    useEffect(() => {
        return () => {
            setSetupIntentIsReady(false);
            setDidRequestSetupIntent(false);
            setShowTinyCardCapture(false);
            setShowCardCapture(false);
            setShowPayments(false);
            setSubCardTitle('Choose A Payment Method');
        };
    }, []);
    useEffect(() => {
        if (payments.length > 0) {
            setHasPayments(true);
            setSubCardTitle('Choose A Payment Method')
            setIncludeSecondary(true);
        } else {
            setHasPayments(false);
            setSubCardTitle('Add A Payment Method')
            setIncludeSecondary(false);
        }
    }, [payments]);

    useEffect(() => {
        if (payments.length > 0) {
            // we will filter for payments so that we do not get dupes
            const filteredPayments = [];
            const alreadyAdded = [];
            const filteredFinal = [];
            const fingerprints = [];
            payments.forEach((payment, i) => {
                const { card, platform } = payment;
                const { fingerprint, exp_month, exp_year, brand, last4 } = card;
                const together = `${platform}${fingerprint}${exp_month}${exp_year}${brand}${last4}`;
                const fingerprintmonthyear = `${fingerprint}${exp_month}${exp_year}${brand}${last4}`;

                if (!alreadyAdded.includes(together)) {
                    filteredPayments.push(payment);
                    alreadyAdded.push(together);
                    fingerprints.push(fingerprintmonthyear);
                }
            });
            filteredPayments.forEach((payment) => {
                const { card, platform } = payment;
                const { fingerprint, exp_month, exp_year, brand, last4 } = card;
                const fingerprintmonthyear = `${fingerprint}${exp_month}${exp_year}${brand}${last4}`;
                const numTimes = countTimesInArray(fingerprints, fingerprintmonthyear);

                if (numTimes <= 1) {
                    filteredFinal.push(payment);
                } else {
                    if (platform !== 'Dibs') {
                        filteredFinal.push(payment);
                    }
                }

            });
            setPaymentsToDisplay(filteredFinal);
            setShowPayments(true);
        }
    }, [payments]);

    const cancelAction = async () => {
        setShowTinyCardCapture(false);
        setSetupIntentIsReady(false);
        setDidRequestSetupIntent(false);
        await cancelSetupIntent(setupIntentCreatedId, onDibsPlatform, dibsStudioId)
            .then((res) => {
                console.log('intent was canceled - response was', res);
                setSetupIntentIsReady(false);
                setDidRequestSetupIntent(false);
            })
            .catch((err) => {
                console.log('error canceling the intent', err);
            });

        console.log('canceled the setup intent - on cancel - display tiny cards', setupIntentCreatedId);
    }
    const endingActionDefn = async () => {
        setShowTinyCardCapture(false);
        setSetupIntentIsReady(false);
        setDidRequestSetupIntent(false);
        // await cancelSetupIntent(setupIntentCreatedId, onDibsPlatform, dibsStudioId)
        //     .then((res) => {
        //         console.log('intent was canceled - response was', res);
        //         setSetupIntentIsReady(false);
        //         setDidRequestSetupIntent(false);
        //     })
        //     .catch((err) => {
        //         console.log('error canceling the intent', err);
        //     });

        console.log('completed the setup intent - display tiny cards for:', setupIntentCreatedId);
    }


    const toggleShowCardCapture = async () => {
        setShowTinyCardCapture(!showTinyCardCapture);
        dispatch(setIsProcessing(false));
        setOnDibsPlatform(true);
        if (!showTinyCardCapture) {
            let platformtouse = 'dibs';
            if (!onDibsPlatform) platformtouse = 'connected';
            window.localStorage.setItem('stripeReady', false);
            window.localStorage.setItem('intentPlatform', platformtouse);
            window.localStorage.setItem('intentType', 'setup');
            window.localStorage.setItem('intentSetFrom', 'cc-display');
            if (!didRequestSetupIntent) {
                setDidRequestSetupIntent(true);
                await setupIntent(
                    stripeid,
                    userid,
                    email,
                    `${firstname} ${lastname}`,
                    dibsStudioId,
                    onDibsPlatform,
                )
                    .then((res) => {
                        console.log('setting client secret - line 145 - tiny cards', res.clientSecret)
                        window.localStorage.setItem('clientSecret', res.clientSecret);
                        window.localStorage.setItem('clientSecretDate', new Date().toISOString());
                        setSetupIntentCreatedId(res.setupIntentId);
                        // dispatch(setShowCCCapture(true));
                        setSetupIntentIsReady(true);
                        // NEXT STEP
                        // remove it if it exists
                        // cancel it if there was an intent
                    })
                    .catch((err) => {
                        console.log('error is - after creating stripe intent on tiny cards', err);

                        cancelAction();
                    });

            } else {
                console.log('\n\n\nsetup intent was already requested, so we will not request it again');
            }

        } else {
            // user has clicked go back - 
            // need to cancel the intent
            console.log('\n\n\n\ncc capture was true, now setting the credit card capture to false');


        }
    };
    const secondaryButton = (
        <Grid item xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                id="display-cards-tiny-add-new-card-button"
                alignItems="flex-end">
                <Button
                    variant="text"
                    onClick={toggleShowCardCapture}
                    sx={{
                        justifyContent: 'flex-end',
                        pl: 0.5,
                        fontSize: '0.7rem',
                        lineHeight: '0.7rem',
                    }}>
                    {showTinyCardCapture ? 'Go Back' : 'Add New Card'}
                </Button>
            </Grid>
        </Grid>
    );


    return (
        <Fade in timeout={600}>
            <Grid container justifyContent="center">
                <Grid
                    item
                    id="display-tiny-card-container"
                    border={0}
                    xs={window.isTiny ? 12 : 11}
                    sx={{ mb: 2 }}>
                    <SubCard title={subCardTitle} secondary={secondaryButton} newVariantTitle="h5SmallTitle" includeSecondary={true}>
                        <Grid container spacing={gridSpacing}>
                            {showTinyCardCapture && setupIntentIsReady && (
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12}>
                                            <CollectCC intentType="setup" cancelAction={cancelAction} endingAction={endingActionDefn} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid item xs={12} border={0}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-evenly">
                                    {/* {showCardCapture && addPaymentMethod} */}
                                    {showPayments &&
                                        !showCardCapture &&
                                        paymentsToDisplay.map((payment, i) => (
                                            <Grid
                                                key={i}
                                                id="card-holder-display-tiny-cards"
                                                item
                                                lg={6}
                                                md={6.5}
                                                xs={7}
                                                sx={{ mb: 2, mr: 1, mt: 2, pl: 1 }}>
                                                <IndividualCard
                                                    key={i}
                                                    cc={payment}
                                                    exemptFromSelect
                                                />
                                            </Grid>
                                        ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        </Fade>
    );
};
export default DisplayCardsTiny;
