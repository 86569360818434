import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useSelector } from 'store';
import { dispatch } from 'store';
import { updateItemsInCartHere } from 'store/slices/cart';

const UpdateCartThis = ({ children }) => {
    const { dibsId } = useParams();
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    // useEffect(() => {
    //     dispatch(
    //         updateItemsInCartHere({
    //             dibsIdParams: dibsId,
    //         }),
    //     );
    // }, [dibsId, dibsStudioId]);

    return children;
};
export default UpdateCartThis;
UpdateCartThis.propTypes = {
    children: PropTypes.node,
};
