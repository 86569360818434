import { Grid, Typography, TextField, Button } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';

import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';

import SubCard from 'ui-component/cards/AccountSubCard';
import { setAPassword } from 'firebaseConfig';
import setFirebaseAuthPwd from 'actions/setFirebaseAuthenticationPwd';
import { dispatch } from 'store';
import { setRecentlyLoggedIn } from 'store/slices/thisuser';

const validationSchema = yup.object().shape({
    newpassword: yup
        .string()
        .required('Password is required')
        .min(
            7,
            'Your password must be at least 8 characters. Please try again.',
        ),
    matchpassword: yup
        .string()
        .oneOf(
            [yup.ref('newpassword'), null],
            'Your passwords must match. Please try again.',
        ),
});

// ==============================|| SET PASSWORD FROM PROFILE ||============================== //

const SetAPassword = () => {
    const { userid, hasSetPwdFirebase, recentlyLoggedIn } = useSelector(
        (state) => state.thisuser,
    );
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [showSetPassword, setShowSetPassword] = useState(false);
    useEffect(() => {
        if (!hasSetPwdFirebase && recentlyLoggedIn) {
            setShowSetPassword(true);
        } else if (hasSetPwdFirebase) {
            setShowSetPassword(false);
        }
    }, [hasSetPwdFirebase, recentlyLoggedIn]);
    const formik = useFormik({
        initialValues: {
            newpassword: '',
            matchpassword: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                if (recentlyLoggedIn) {
                    await setAPassword(values.newpassword)
                        .then((status) => {
                            console.log(
                                `response from set a password is: ${status}`,
                            );
                            if (status === 0) {
                                setErrorMessage(
                                    'Your password could not be set. Please try it again. Contact support@ondibs.com if you continue to have problems.',
                                );
                                setHasError(true);
                                setTimeout(() => {
                                    setHasError(false);
                                    setErrorMessage('');
                                }, 10000);
                            }

                            if (status === 1) {
                                setSuccessMessage(
                                    'Your password has been set. Thanks!',
                                );
                                setHasSuccess(true);
                                setFirebaseAuthPwd(userid);
                                setTimeout(() => {
                                    setHasSuccess(false);
                                    setSuccessMessage('');
                                }, 10000);
                            }
                        })
                        .catch((error) => {
                            console.log(
                                `caught error is: ${JSON.stringify(
                                    error,
                                    null,
                                    2,
                                )}`,
                            );
                            dispatch(setRecentlyLoggedIn(false));
                        });
                }
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <>
            {showSetPassword && (
                <Grid item xs={10} sx={{ mb: 2, mr: 3 }}>
                    <SubCard title="Set A Password">
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} sx={{ mt: 1 }}>
                                <Typography variant="guidance">
                                    You do not have a password associated with
                                    your account yet. Set your password now.
                                </Typography>
                            </Grid>
                            {hasError && (
                                <Grid item xs={12}>
                                    <Typography variant="errorText">
                                        {errorMessage}
                                    </Typography>
                                </Grid>
                            )}
                            {hasSuccess && (
                                <Grid item xs={12}>
                                    <Typography variant="successText">
                                        {successMessage}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{ mt: 1 }}>
                                            <Grid container>
                                                <Grid item xs={11}>
                                                    <TextField
                                                        name="newpassword"
                                                        label="New Password"
                                                        autoComplete="new-password"
                                                        value={
                                                            formik.values
                                                                .newpassword ||
                                                            ''
                                                        }
                                                        fullWidth
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        placeholder="Enter your new password"
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        type="password"
                                                        error={
                                                            formik.touched
                                                                .newpassword &&
                                                            Boolean(
                                                                formik.errors
                                                                    .newpassword,
                                                            )
                                                        }
                                                        helperText={
                                                            formik.touched
                                                                .newpassword &&
                                                            formik.errors
                                                                .newpassword
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={11}
                                                    sx={{ mb: 2, mt: 2 }}>
                                                    <TextField
                                                        name="matchpassword"
                                                        label="Re-enter New Password"
                                                        type="password"
                                                        autoComplete="new-password"
                                                        value={
                                                            formik.values
                                                                .matchpassword ||
                                                            ''
                                                        }
                                                        fullWidth
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                        placeholder="Re-enter your new password"
                                                        onBlur={
                                                            formik.handleBlur
                                                        }
                                                        error={
                                                            formik.touched
                                                                .matchpassword &&
                                                            Boolean(
                                                                formik.errors
                                                                    .matchpassword,
                                                            )
                                                        }
                                                        helperText={
                                                            formik.touched
                                                                .matchpassword &&
                                                            formik.errors
                                                                .matchpassword
                                                        }
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={8}
                                            sx={{ mt: 3, mb: 2 }}>
                                            <Button
                                                variant="checkout"
                                                fullWidth
                                                type="submit"
                                                disabled={formik.isSubmitting}
                                                // onClick={handleChargeCard}>
                                            >
                                                Set Your Password
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            )}
        </>
    );
};
export default SetAPassword;
