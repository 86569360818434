import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function getFirebaseStatus(email) {

    const url = getApiUrl('/user/get-firebase-status');

    let datatosend = {};
    const getStatus = async () => {
        await axios
            .post(url, {
                email,
            })
            .then((res) => {
                const { data } = res;
                // console.log('\n\ndata to return from getting firebase status', data);
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error updating user phone number - in getting firebase status: ${err} email addy is ${email}`);
                return err;
            });
    };
    await getStatus();
    return datatosend;
}
