import axios from 'axios';

export default async function confirmClassExists(items) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/confirm-class-exists`;
    let datatosend = {};
    const checkExists = async () => {
        await axios
            .post(basicurl, {
                items,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error checking whether class exists: ${err}`);
                return err;
            });
    };
    await checkExists();
    return datatosend;
}
