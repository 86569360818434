import axios from 'axios';

export default async function doesUserHaveAccount(email, dibsId) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/widget/get-user-account`;
    let datatosend = {};
    const hasAccount = async () => {
        await axios
            .post(basicurl, {
                email,
                dibsId,
            })
            .then((res) => {
                const { data } = res;

                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from getting UserAccount Action: ${err}`);
                return err;
            });
    };
    await hasAccount();
    return datatosend;
}
