import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Grid } from '@mui/material';

import CheckoutForm from './checkoutForm';
import CheckoutFormSetup from './checkoutFormSetupTiny';
import Progress from 'assets/CircularIndeterminate';

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const connectedAccount = window.localStorage.getItem('stripeKeyConnected');
const platformUsed = window.localStorage.getItem('intentPlatform');

let stripeObject;
let stripePromise;
if (platformUsed !== null) {
    if (platformUsed === 'dibs') {
        stripeObject = { apiVersion: '2023-10-16' }
        window.localStorage.setItem('stripeloadedwith', 'dibs');
    } else {
        stripeObject = { apiVersion: '2023-10-16', stripeAccount: connectedAccount }
        window.localStorage.setItem('stripeloadedwith', 'connected');
    }
    stripePromise = loadStripe(stripeKey, stripeObject);
}


export default function StripeLoad({ setup, cancelAction, donotshowcancelbutton, endingAction }) {
    // let stripeClientSecret = sessionStorage.getItem("clientSecret");
    let stripeClientSecretToUse = window.localStorage.getItem('clientSecret');
    const [stripePromiseLoaded, setStripePromiseLoaded] = useState(false);

    sessionStorage.setItem('stripeReady', false);
    useEffect(() => {
        if (stripePromise) {
            setStripePromiseLoaded(true);
        } else {
            setStripePromiseLoaded(false);
        }
    }, []);

    const setStripeReady = () => {
        sessionStorage.setItem('stripeReady', true);
        window.localStorage.setItem('stripeReady', true);
    };

    const options = {
        // passing the client secret obtained from the Stripe Dashboard
        clientSecret: stripeClientSecretToUse,
        appearance: {
            theme: 'flat',
            labels: 'floating',
            variables: {
                fontFamily: ' "Roboto", sans-serif',
                fontLineHeight: '1.1',
                fontSizeBase: '13px',
                borderRadius: '5px',
                colorBackground: '#f7f7f7',
                colorPrimaryText: '#999',
                colorPrimary: '#ff6900',
            },
            rules: {
                '.Label': {
                    marginBottom: '12px',
                    fontWeight: '300',
                },

                '.Input': {
                    padding: '4px',
                    border: '1px solid',
                    // borderColor: 'var(--colorPrimary)',
                    borderColor: '#ddd',
                    // boxShadow: '0px 3px 10px rgba(18, 42, 66, 0.08)',
                    boxShadow: 'none',
                    marginBottom: '3px',
                },
            }
        },
        loader: 'always'
    };
    if (!stripeClientSecretToUse) {
        const loading = (
            <Grid>Loading...</Grid>
        )
        return loading;
    }
    console.log('stripe promise tiny load stripe new');
    if (!stripePromiseLoaded) return <Progress />
    if (setup) {
        return (
            <Elements key={stripeClientSecretToUse} stripe={stripePromise} options={options} onReady={setStripeReady()} >
                <CheckoutFormSetup includeButton platformUsed={platformUsed} cancelAction={cancelAction} donotshowcancelbutton={donotshowcancelbutton} endingAction={endingAction} setShowAddCC={null} />
            </Elements >
        );
    } else {
        return (
            <Elements stripe={stripePromise} options={options} onReady={setStripeReady()}>
                <CheckoutForm includeButton />
            </Elements>
        );
    }

};