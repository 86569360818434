import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { Grid } from '@mui/material';

import { useSelector } from 'store';
import { setPageSelected } from 'store/slices/menu';

import { logout, auth } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

// layout
import LayoutBox from 'ui-component/LayoutBox';

// components
import EnterEmail from 'components/new-auth/enterEmail';
import EnterPassword from 'components/new-auth/enterPassword';
import ForgotPassword from 'components/new-auth/forgotPassword';
import RegisterNewAccount from 'components/new-auth/registerNewAccount';
import SetBirthdayPhone from 'components/new-auth/setBirthdayPhone';
import SetPassword from 'components/new-auth/setPassword';
import ConfirmRegistration from 'components/new-auth/confirmNewRegistration';
import ConfirmLoggedIn from 'components/new-auth/confirmLoggedIn';

import { dispatch } from 'store';
import { clearOldUserExceptEmail } from 'store/slices/thisuser';

// ===========================|| AUTHENTICATION - LOGIN - THIS IS BEING USED ||=========================== //

const Login = () => {
    let { dibsId } = useParams();

    const [marginTop, setMarginTop] = React.useState(3);
    const { lastLocation, userid, isLoggedIn } = useSelector(
        (state) => state.thisuser,
    );
    const locationFromWindow = window.localStorage.getItem('lastpagevisited');
    const { buttonControlsWidget, widgetOpenStyle } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const navigate = useNavigate();

    React.useEffect(() => {
        dispatch(setPageSelected('login'));
        if (buttonControlsWidget) {
            setMarginTop(5);
        }
        const windowHeight = window.innerHeight;
        if (windowHeight < 600) {
            setMarginTop(1);
        }
    }, [buttonControlsWidget]);
    const setDibsIdLocalStorage = (dibsId) => {
        window.localStorage.setItem('dibsId', dibsId);
    };
    React.useEffect(() => {
        setDibsIdLocalStorage(dibsId);
    }, [dibsId]);
    const [user, loading] = useAuthState(auth);

    React.useEffect(() => {
        if (user && !loading) {
            if (locationFromWindow) {
                // window.localStorage.removeItem('lastpagevisited');
                navigate(locationFromWindow);
            } else if (lastLocation) {
                navigate(lastLocation);
            } else {
                navigate('/account/' + dibsId);
            }
        }
    }, [user, loading, dibsId, navigate, locationFromWindow, lastLocation]);

    const [currentStep, setCurrentStep] = React.useState('email');
    const [emailAddy, setEmailAddy] = React.useState('');

    // React.useEffect(() => {
    //     dispatch(clearOldUserExceptEmail());
    // }, []);
    const getStep = () => {
        if (currentStep === 'email') {
            return (
                <EnterEmail
                    setCurrentStep={setCurrentStep}
                    setEmailAddy={setEmailAddy}
                />
            );
        } else if (currentStep === 'password') {
            return (
                <EnterPassword
                    setCurrentStep={setCurrentStep}
                    emailAddy={emailAddy}
                />
            );
        } else if (currentStep === 'forgotpassword') {
            console.log('next step will be to forgotpassword');
            return (
                <ForgotPassword
                    setCurrentStep={setCurrentStep}
                    emailAddyFp={emailAddy}
                />
            );
        } else if (currentStep === 'register') {
            console.log('next step will be to register');
            return <RegisterNewAccount setCurrentStep={setCurrentStep} />;
        } else if (currentStep === 'phone') {
            console.log('next step will be to phone and birthday');
            return <SetBirthdayPhone setCurrentStep={setCurrentStep} />;
        } else if (currentStep === 'setpassword') {
            return (
                <SetPassword
                    setCurrentStep={setCurrentStep}
                    emailAddyPwd={emailAddy}
                />
            );
        } else if (currentStep === 'confirmation') {
            return <ConfirmRegistration setCurrentStep={setCurrentStep} />;
        } else if (currentStep === 'loggedin') {
            return <ConfirmLoggedIn setCurrentStep={setCurrentStep} />;
        }
    };
    console.log('current step is', currentStep);
    return (
        <LayoutBox mheight="25vh" margTop={marginTop} idtoreference="newlogin">
            <Grid
                container
                id="login-container"
                direction="row"
                flex={1}
                justifyContent="center"
                alignItems="flex-start"
                sx={{ height: 'auto' }}>
                <Grid item xs={12} sx={{ mt: marginTop }}>
                    {getStep()}
                </Grid>
            </Grid>
        </LayoutBox>
    );
};

export default Login;
