import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import { dispatch } from 'store';
import {
    setShowingSchedule,
    setPageSelected,
    setShowingAccount,
    setShowingAppointments,
} from 'store/slices/menu';

const UserIcon = ({ on, color }) => {
    let { dibsId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const colortoshow = `#${color}`;
    const [oncolor, setOnColor] = useState(colortoshow);
    useEffect(() => {
        if (on) {
            setOnColor(colortoshow);
        }
        if (!on) {
            setOnColor('#CED4DA');
        }
    }, [colortoshow, on]);
    const goAccount = () => {
        dispatch(setPageSelected('account'));
        dispatch(setShowingSchedule(false));
        dispatch(setShowingAccount(true));
        dispatch(setShowingAppointments(false));
        navigate(`/account/${dibsId}${location.search}`);
    };
    return (
        <Grid item onClick={() => goAccount()}>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                    icon="fa-circle"
                    color={oncolor}
                    transform="grow-10"
                />
                <FontAwesomeIcon
                    icon="fa-light fa-user"
                    color="#fff"
                    // transform="shrink-7"
                />
            </span>{' '}
        </Grid>
    );
};
export default UserIcon;
