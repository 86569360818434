import { useEffect, useState } from 'react';

// material-ui
import { Grid, Fade } from '@mui/material';

// project imports
import { useSelector, dispatch } from 'store';
import {
    setStripeIdForStudios,
    setRefreshPaymentOptions,
    setPaymentOptions,
    setNumCreditCardsOnFile,
} from 'store/slices/thisuser';
import {
    setExpy,
    setLastFourCard,
    setCardBrand,
    setCardToCharge
} from 'store/slices/cart';

// assets
import DisplayCards from 'components/account/cc-display/displayCards';

import getAllStripePayments from 'actions/stripe/getAllStripePayments';
import matchPaymentMethods from 'actions/stripe/matchPaymentMethods';

// ==============================|| PROFILE 3 - BILLING ||============================== //

const Billing = () => {
    const { userid, refreshPaymentOptions, paymentOptionsAll } = useSelector(
        (state) => state.thisuser,
    );

    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const { cardtocharge } = useSelector((state) => state.cart)
    const [gotPayments, setGotPayments] = useState(false);
    const [payments, setPayments] = useState([]);
    const [doneLoading, setDoneLoading] = useState(false);
    useEffect(() => {
        // get payments
        const paymentsalreadyincluded = [];
        const getPayments = async () => {
            await getAllStripePayments(dibsStudioId, userid)
                .then(async (res) => {
                    // dispatch(setStripeIdForStudios(res.stripeConnectedInfo));
                    const { paymentsDibs, paymentsConnectedAccount } = res;
                    if ((paymentsDibs === null && paymentsConnectedAccount === null) || (paymentsDibs.length === 0 && paymentsConnectedAccount.length === 0)) {
                        setDoneLoading(true);
                        setPayments([]);
                        const tosend = {
                            totalNumCC: 0,
                            dibsNumCC: 0,
                            studioNumCC: 0
                        }
                        dispatch(setNumCreditCardsOnFile(tosend));
                        return;
                    } else {
                        const paymentstosend = [];
                        paymentsConnectedAccount.forEach((pay) => {
                            const { card } = pay;
                            const { brand, exp_month, exp_year, last4, fingerprint } = card;
                            const together = `${brand}${last4}${exp_month}${exp_year}${fingerprint}`;
                            if (!paymentsalreadyincluded.includes(together)) {
                                paymentsalreadyincluded.push(together);
                                const newobj = {
                                    ...pay,
                                    platform: 'Studio'
                                }
                                paymentstosend.push(newobj);
                            }
                        });
                        paymentsDibs.forEach((pay) => {
                            const { card } = pay;
                            const { brand, exp_month, exp_year, last4, fingerprint } = card;
                            const together = `${brand}${last4}${exp_month}${exp_year}${fingerprint}`;
                            if (!paymentsalreadyincluded.includes(together)) {
                                paymentsalreadyincluded.push(together);
                                const newobj = {
                                    ...pay,
                                    platform: 'Dibs'
                                }
                                paymentstosend.push(newobj);
                            }
                        });
                        const tosend = {
                            totalNumCC: paymentsConnectedAccount.length + paymentsDibs.length,
                            dibsNumCC: paymentsDibs.length,
                            studioNumCC: paymentsConnectedAccount.length
                        }
                        setPayments(paymentstosend);
                        dispatch(setNumCreditCardsOnFile(tosend));
                        dispatch(setPaymentOptions(paymentstosend));
                        if (paymentstosend.length > 0) {
                            await matchPaymentMethods(paymentstosend, cardtocharge).then((res) => {
                                const { exp_month, exp_year, last4, brand, pmid } = res;
                                dispatch(setExpy(`${exp_month}/${exp_year}`));
                                dispatch(setLastFourCard(last4));
                                dispatch(setCardBrand(brand));
                                dispatch(setCardToCharge(pmid));

                            })
                        }

                        setDoneLoading(true);
                        return;

                    }
                })
                .catch((err) => {
                    console.log(`error getting payments: ${err}`);
                    setDoneLoading(false);
                });
            setGotPayments(true);
        };
        if (!gotPayments || refreshPaymentOptions) {
            getPayments();
            dispatch(setRefreshPaymentOptions(false));
        }
    }, [dibsStudioId, gotPayments, userid, refreshPaymentOptions, cardtocharge]);
    return (
        <Fade in timeout={400}>
            <Grid container justifyContent="center">
                {doneLoading && (
                    <Grid
                        item
                        xs={12}
                        sx={{ ml: window.isTiny ? 0 : 2, mt: 2 }}>
                        <DisplayCards payments={payments} />
                    </Grid>
                )}
                {!doneLoading && (
                    <Grid item xs={12} sx={{ ml: 4, mt: 1 }}>
                        Loading...
                    </Grid>
                )}
            </Grid>
        </Fade>
    );
};

export default Billing;
