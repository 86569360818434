const moment = require('moment');

export default async function associatePassToEvent(event, passes, packagesBeingUsed) {
    let howyapaying = {
        passid: null,
        passname: null,
    };
    if (event.price === 0) {
        howyapaying = {
            passid: 0,
            passname: '',
            quantity: 1,
            amountToCharge: {
                total: 0,
            },
        };
        return howyapaying;
    }
    let eventprivatestatus = false;
    if (event.private === true) eventprivatestatus = true;
    const passpostfilter = await passes
        .filter((p) => p.private_pass === eventprivatestatus)
        .sort((a, b) => {
            if (!a.autopay && !b.autopay) return 0;
            if (a.autopay && !b.autopay) return -1;
            if (!a.autopay && b.autopay) return 1;
            return moment(a.expiresAt) - moment(b.expiresAt);
        })
        .sort((a, b) => {
            if (!a.expiresAt && !b.expiresAt) return 0;
            if (a.expiresAt && !b.expiresAt) return -1;
            if (!a.expiresAt && b.expiresAt) return 1;
            return moment(a.expiresAt) - moment(b.expiresAt);
        });
    const choosePassToUse = async () => {
        let passToUse = 0;
        let passChosen = false;
        let passname = '';
        passpostfilter.forEach((pass) => {
            if (!passChosen) {
                // do something
                const totalAvailable = pass.totalUses - pass.usesCount;
                const numtimesbeingused = packagesBeingUsed.filter((p) => p === pass.id).length;

                if (totalAvailable > numtimesbeingused) {
                    passToUse = pass.id;
                    passname = pass.studioPackage.packageName;
                    passChosen = true;
                }
            }
        });
        return { passid: passToUse, passname: passname }
    };

    if (passpostfilter.length > 0) {
        const { passid, passname } = await choosePassToUse();

        howyapaying = {
            passid: passid,
            passname: passname,
            quantity: 1,
            amountToCharge: {
                total: 0,
            }
        };
        return howyapaying;
    } else {
        // console.log('no passes');

        return { passid: 0, passname: '', quantity: 1, amountToCharge: { total: event.price } };
    }
}
