export const dibsSuperAdmin = 'dibs-super-admin';
export const superAdminEmail = 'alicia@ondibs.com';
export const superAdminPermissions = [
    'financial',
    'administrative',
    'dibs-super-admin',
];
export const studioIsDifferent = [
    {
        studioid: '226',
        border: 0,
        bgColorAppts: '#D1CECD',
        borderColorAppts: '#adacb5',
        transparencyAppts: '#D1CECD',
    },
    {
        studioid: '123',
        border: 5,
    },
];
