import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function getPromoPackagesForCheckout(dibsId) {
    const url = getApiUrl('/checkout/get-promo-packages');
    let datatosend = {};
    const checkExists = async () => {
        await axios
            .post(url, {
                dibsId,
            })
            .then((res) => {
                const { data } = res;
                const { promoPackages } = data;
                datatosend = promoPackages;
            })
            .catch((err) => {
                console.log(`error getting packages for the checkout process: ${err}`);
                return err;
            });
    };
    await checkExists();
    return datatosend;
}
