import createNewUserIfNeeded from 'actions/users/createNewDibsUser';
import createNewEvent from 'actions/schedule/createNewEventid';
import addApptDibsTrans from 'actions/appointments/addApptDibsTrans';
import notifyAdminNewAppt from 'actions/appointments/notifyAdminNewAppt';
import addApptAttendees from 'actions/appointments/addApptAttendees';
import assignInstructor from 'actions/appointments/assignInstructor';
import createShopifyCustomer from 'actions/shopify/createNewShopifyUser';
import recordShopifyStatus from 'actions/shopify/recordShopifyStatus';
import notifyViaTwilio from 'actions/twilio/notifyViaTwilio';
import { capitalizeString, lowercaseString } from 'helpers';

const createAppt = async (
    appt,
    countryAbbr,
    smsTransactionsforThisUser,
    thislanguage,
) => {
    const promises = [];
    let userid = appt.userid;
    let addedToShopify = false;
    // let eventidnew = 0;
    let eventscreated = [];
    let isComplete = 0;
    // let newEvents;
    const firstname = capitalizeString(appt?.firstname);
    const lastname = capitalizeString(appt?.lastname);
    const email = lowercaseString(appt?.email);

    const phoneNumber = appt.phone;
    const {
        apptToConfirm,
        sendSMSConfirmation,
        customTwilioNumUS,
        studioName,
        customerServicePhone,
        timeZone,
        alreadyAddedShopify,
        customerServiceInfo,
    } = appt;
    const userData = {
        firstname,
        lastname,
        email,
        phone: appt.phone,
        countryAbbr,
    };
    const createEventid = async () => {
        const apptData = {
            dibsId: appt.dibsId,
            eventname: appt.apptType,
            // startdate: appt.start_time,
            // enddate: appt.end_time,
            trainerid: appt.instructorid,
            price: appt.priceAppt,
            timezone: appt.timeZone,
            apptToConfirm: {
                ...apptToConfirm,
                convertedStartTime: apptToConfirm?.start_time,
                convertedEndTime: apptToConfirm?.end_time,
            },
            enddate: apptToConfirm?.end_time,
            convertedEndTime: apptToConfirm?.end_time,
            isPrivate: true,
        };
        await createNewEvent(apptData).then((res) => {
            eventscreated = res;

            // newEvents = res;
            return res;
        });
    };
    const getUserId = async () => {
        const userdata = await createNewUserIfNeeded(userData).then((res) => {
            return { userid: res.userid, shopifyAdded: res.added_to_shopify };
        });
        userid = userdata.userid;
        addedToShopify = userdata.shopifyAdded;
    };
    const addDibsTransaction = async (thisevent) => {
        const addtoAttendees = async (dtid) => {
            isComplete = 1;
            const dataforAttendees = {
                dibsId: appt.dibsId,
                userid,
                eventid: thisevent.eventid,
                attendeeId: dtid.toString(),
                firstname,
                lastname,
                email,
                serviceName: 'Dibs Reservation',
                visitDate: thisevent.start_date,
                cost: thisevent.price_dibs,
            };
            await addApptAttendees(dataforAttendees).then((res) => {
                isComplete = 2;
                return res;
            });
        };
        // then add to attendees AFTER dibs transactions

        await addApptDibsTrans({
            dibsId: thisevent.dibs_studio_id,
            userid,
            eventid: thisevent.eventid,
            price: thisevent.price_dibs,
            apptType: thisevent.name,
        }).then(async (res) => {
            promises.push(addtoAttendees(res));
            notifyAdminNewAppt({
                dibsId: thisevent.dibs_studio_id,
                userid,
                eventid: thisevent.eventid,
                price: thisevent.price_dibs,
                apptType: thisevent.name,
            });
            return res;
        });
    };
    const updateEventIds = async () => {
        eventscreated.forEach(async (event) => {
            promises.push(addDibsTransaction(event));
        });
    };

    const assignInstructorsToEvents = async () => {
        const thistrainerid = eventscreated[0].trainerid;
        if (thistrainerid === 1000) {
            await assignInstructor(eventscreated);
        }
    };
    promises.push(await createEventid());
    if (userid === 0) promises.push(await getUserId());
    await Promise.all(promises);

    appt.dibsStudioId = appt.dibsId;
    const updateDibsUserShopifyStatus = async (shopifyResponse) => {
        await recordShopifyStatus(shopifyResponse, userid).then((res) => {
            console.log('res after record shopify status', res);
        });
    };
    const addToShopify = async () => {
        await createShopifyCustomer(appt, userid).then(async (res) => {
            if (res.createdNewCustomer) await updateDibsUserShopifyStatus(res);
        });
    };
    const needtoaddtoshopify =
        !addedToShopify ||
        addedToShopify === null ||
        alreadyAddedShopify === false;
    if (appt.dibsId === '226' && needtoaddtoshopify)
        promises.push(await addToShopify(appt, userid));
    promises.push(await updateEventIds());
    // if appropriate, notify via twilio
    // TURN OFF TWILIO UNTIL IT GETS APPROVED
    if (sendSMSConfirmation) {
        if (smsTransactionsforThisUser) {
            promises.push(
                await notifyViaTwilio(
                    apptToConfirm,
                    customTwilioNumUS,
                    firstname,
                    lastname,
                    phoneNumber,
                    studioName,
                    customerServicePhone,
                    timeZone,
                    appt.dibsId,
                    thislanguage,
                    customerServiceInfo,
                ),
            );
        }
    }

    promises.push(await assignInstructorsToEvents());

    await Promise.all(promises);
    const datatoreturn = {
        isComplete,
        eventscreated,
    };
    return datatoreturn;
};
export default createAppt;
