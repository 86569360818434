import { Grid, Button } from '@mui/material';
import { dispatch } from 'store';
import { clearThisUser } from 'store/slices/thisuser';
import { logout } from 'firebaseConfig';

const logoutfunc = () => {
    console.log('logging out now');
    logout();
    dispatch(clearThisUser());
    window.localStorage.removeItem('dibs-token');
    window.localStorage.removeItem('persist:thisuser');
};

const LogoutButton = (
    <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center">
        <Grid item xs={12}>
            <Button onClick={logoutfunc} variant="text">
                Logout
            </Button>
        </Grid>
    </Grid>
);
export default LogoutButton;
