import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelector } from 'store';

const CircleIconDefault = () => {
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortouse = `#${color}`;

    return (

        <span className="fa-icon-available-on">
            <FontAwesomeIcon
                icon="fa-circle"
                transform="up-2 shrink-11"
                color={colortouse}
            />
        </span>

    );
};
export default CircleIconDefault;
