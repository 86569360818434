import { Grid, Typography, Button, Fade } from '@mui/material';

import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';

import { useSelector, dispatch } from 'store';
import { setIsProcessing, setShowCCCapture } from 'store/slices/cart';

import SubCard from 'ui-component/cards/AccountSubCard';
import IndividualCard from 'components/payment/profileIndividualCardDisplay';
import CollectCC from 'components/stripe/CollectCardInfo';
import setupIntent from 'actions/stripe/createStripeSetupIntent';
import cancelSetupIntent from 'actions/stripe/intents/cancelSetupIntent';

// ==============================|| DISPLAY CC CARDS ||============================== //
import './credit-card.css';
import { clearAllStatusMessageReducer } from 'store/slices/error';

const DisplayCardsPackages = ({ payments, setShowGreenChooseButton }) => {

    const [paymentsToDisplay, setPaymentsToDisplay] = useState([]);
    const [showPayments, setShowPayments] = useState(false);
    const [showCardCapture, setShowCardCapture] = useState(false);
    const [setupIntentIsReady, setSetupIntentIsReady] = useState(false);
    const [didRequestSetupIntent, setDidRequestSetupIntent] = useState(false);
    const [setupIntentCreatedId, setSetupIntentCreatedId] = useState(null);
    const [onDibsPlatform, setOnDibsPlatform] = useState(true);
    const [subCardTitle, setSubCardTitle] = useState('Choose A Payment Method');
    const [secondaryToUse, setSecondaryToUse] = useState(null);
    const { userid, firstname, lastname, email, stripeid } =
        useSelector((state) => state.thisuser);
    const { showCCCapture } = useSelector((state) => state.cart);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);

    const cancelAction = async () => {
        setSetupIntentIsReady(false);
        setDidRequestSetupIntent(false);
        await cancelSetupIntent(setupIntentCreatedId, onDibsPlatform, dibsStudioId)
            .then((res) => {
                console.log('setupintent id created was', setupIntentCreatedId);
                console.log('intent was canceled - package page - response was', res);
                setSetupIntentIsReady(false);
                setDidRequestSetupIntent(false);
            })
            .catch((err) => {
                console.log('error canceling the intent', err);
            });

        console.log('canceled the setup intent - on cancel - display package cards', setupIntentCreatedId);
    }
    const toggleShowCardCapture = async () => {
        window.localStorage.setItem('stripeReady', false);
        setShowCardCapture(!showCardCapture);
        dispatch(setShowCCCapture(!showCardCapture))
        dispatch(setIsProcessing(false));
        dispatch(clearAllStatusMessageReducer());

        window.localStorage.setItem('intentPlatform', 'dibs');
        window.localStorage.setItem('intentType', 'setup');
        window.localStorage.setItem('intentSetFrom', 'display-cards-packages');
        if (!didRequestSetupIntent) {
            await setupIntent(
                stripeid,
                userid,
                email,
                `${firstname} ${lastname}`,
                dibsStudioId,
                onDibsPlatform,
            )
                .then((res) => {
                    console.log('setting client secret - line 72 - packages', res.clientSecret)
                    window.localStorage.setItem('clientSecret', res.clientSecret);
                    window.localStorage.setItem('clientSecretDate', new Date().toISOString());
                    setSetupIntentCreatedId(res.setupIntentId);
                    dispatch(setShowCCCapture(true));
                    setSetupIntentIsReady(true);
                    setDidRequestSetupIntent(true);
                })
                .catch((err) => {
                    console.log('error is - after creating stripe intent', err);
                    cancelAction();
                });
        }

    };

    useEffect(() => {
        if (showCardCapture && setupIntentIsReady) {
            setShowGreenChooseButton(false);
        } else {
            setShowGreenChooseButton(true);
        }
    }, [setShowGreenChooseButton, setupIntentIsReady, showCardCapture]);
    useEffect(() => {
        if (!showCCCapture) {
            setShowCardCapture(false);
        }
    }, [showCCCapture]);

    useEffect(() => {
        if (payments.length > 0) {
            const paymentsToAdd = [];
            payments.forEach((payment) => {
                // const { card } = payment;
                // const { exp_year, exp_month } = card;
                if (payment.id) paymentsToAdd.push(payment);
            });
            setPaymentsToDisplay(paymentsToAdd);
            setShowPayments(true);
        }
    }, [payments]);
    const secondaryButton = (
        <Grid item xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                id="display-cards-package-container-button"
                alignItems="flex-end">
                <Button
                    variant="text"
                    onClick={toggleShowCardCapture}
                    sx={{
                        justifyContent: 'flex-end',
                        pl: 0.5,
                    }}>
                    {showCardCapture ? 'Go Back' : 'Add New Card'}
                </Button>
            </Grid>
        </Grid>
    );
    useEffect(() => {
        console.log('paymentsToDisplay', paymentsToDisplay);
        if (paymentsToDisplay.length === 0) {
            setSubCardTitle('Add Payment Method');
        } else {
            setSubCardTitle('Choose A Payment Method');
        }
    }, [paymentsToDisplay]);

    return (
        <Fade in timeout={600}>
            <Grid item xs={12}>
                <Grid container justifyContent="center" border={0}>
                    <Grid
                        item
                        id="display-cards-package-container"
                        xs={window.isTiny ? 12 : 11}
                        md={window.isTiny ? 11.5 : 11}
                        sx={{ mb: 2, mr: window.isTiny ? 0 : 0 }}>
                        <SubCard title={subCardTitle} secondary={paymentsToDisplay.length > 0 ? secondaryButton : null}>
                            <Grid container spacing={gridSpacing}>
                                {showCardCapture && setupIntentIsReady && (
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="center">
                                            <Grid item xs={12} minWidth={400}>
                                                <CollectCC intentType="setup" cancelAction={cancelAction} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid item xs={12} border={0} id="grid-holding-payment-options-packages">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-evenly">
                                        {/* {showCardCapture && addPaymentMethod} */}
                                        {showPayments &&
                                            !showCardCapture &&
                                            paymentsToDisplay.map((payment, i) => (
                                                <Grid
                                                    key={i}
                                                    item
                                                    border={0}
                                                    xs={5.5}
                                                    sx={{ mb: 2, mx: 2, mt: 2, pl: 0 }}>
                                                    <IndividualCard
                                                        key={i}
                                                        cc={payment}
                                                        exemptFromSelect
                                                    />
                                                </Grid>
                                            ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </Fade >
    );
};
export default DisplayCardsPackages;
