import * as React from 'react';
import { Grid } from '@mui/material';
import { setPageSelected } from 'store/slices/menu';

// component imports
import Header from './header';
import PackagesList from 'components/packages-list/index';
import DibsFooter from 'components/footers/dibs-footer';
import { dispatch } from 'store';

const PackagesLayout = () => {
    React.useEffect(() => {
        dispatch(setPageSelected('packages'));
    }, []);
    return (
        <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid item xs={11.5} md={12} lg={11} xl={9.5} id="packages-layout" border={0}>
                <Grid container sx={{ mt: 1 }} border={0} justifyContent="center">
                    {/* <Header /> */}
                    <Grid item xs={12} md={11} sx={{ mt: 3 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            // border={8}
                            id="holding-container"
                            alignItems="center">
                            <Grid item xs={12} sx={{ mt: 3 }}>
                                <PackagesList />
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center">
                            <Grid item xs={12}>
                                <DibsFooter />
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PackagesLayout;
