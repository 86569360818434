import React from 'react';
import { Grid, Button, Fade, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { getApiUrl, capitalizeEntireString } from 'helpers';
import XIcon from 'assets/icons/XIcon';
import {
    getTimezoneText,
    getDateAsString,
    gettimeasstring,
    getShortTimezoneText,
} from 'helpers/getTimezoneText';

import updateBasicConfigs from 'actions/updateBasicConfigs';

import { useSelector, dispatch } from 'store';
import { setApptToConfirm } from 'store/slices/appointments';

import useTranslate from 'hooks/useTranslate';
import useGetCurrentLanguage from 'hooks/useTranslateGetLanguage';

export default function AvailableAppointments({
    setIsConfirming,
    resetSearch,
    setResetSearch,
    gobackaction,
}) {
    const translate = useTranslate();
    const getLanguage = useGetCurrentLanguage();
    const [searchingAppts, setSearchingAppts] = React.useState(false);
    const [availableAppts, setAvailableAppts] = React.useState(null);
    const [apptIsSelected, setApptIsSelected] = React.useState(false);
    const [apptSelected, setApptSelected] = React.useState(null);
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortoshow = `#${color}`;
    const { config } = useSelector((state) => state.dibsstudio);
    const [tzText, setTzText] = React.useState(null);
    const [shortTzText, setShortTzText] = React.useState(null);
    const { dibsStudioId, timeZone, currency } = config;
    const { datetosearch, selectedId, selectedStaff, selectedIdsMulti } =
        useSelector((state) => state.appointments);

    const { isLoggedIn } = useSelector((state) => state.thisuser);
    const [currSymbol, setCurrSymbol] = React.useState('$');
    React.useEffect(() => {
        if (currency === 'USD') setCurrSymbol('$');
        if (currency === 'EUR') setCurrSymbol('€');
    }, [currency]);
    React.useEffect(() => {
        updateBasicConfigs(dibsStudioId);
    }, [dibsStudioId]);

    React.useEffect(() => {
        setTzText(getTimezoneText(timeZone));
        setShortTzText(getShortTimezoneText(timeZone));
        if (resetSearch) {
            setAvailableAppts(null);
            setResetSearch(false);
            setApptIsSelected(false);
            setSearchingAppts(false);
        }
        const getAppts = async () => {
            setApptIsSelected(false);
            setSearchingAppts(true);
            const timezonetest =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
            const url = getApiUrl('/appts/get-availability');
            const bodytosend = {
                dibsId: dibsStudioId,
                date: datetosearch,
                provider: selectedStaff,
                apptType: selectedId,
                tz: timezonetest,
                minuteIncrement: 30,
                multiIds: selectedIdsMulti,
                locationid: 1, // TO DO add locationid if more locations - placeholder
            };
            await axios
                .post(url, bodytosend)
                .then((res) => {
                    setAvailableAppts(res.data);
                    setSearchingAppts(false);
                })
                .catch((error) => {
                    console.log('error was ', error);
                });
        };
        if (datetosearch !== null) getAppts();
    }, [
        datetosearch,
        dibsStudioId,
        resetSearch,
        selectedId,
        selectedIdsMulti,
        selectedStaff,
        setResetSearch,
        timeZone,
    ]);
    const providerTxt = translate('provider', 'Provider');
    const priceTxt = translate('price', 'Price');
    const bookTxt = translate('book-this-appt', 'Book this appointment');
    const gobackTxt = translate('go-back', 'Go back');
    const searchingTxt = translate('searching', 'Searching...');
    const whatTime = translate('what-time-best', 'What time works best');
    const timeDisplayedTxt = translate(
        'time-display',
        'Times are displayed in',
    );
    const noAvailabilityTxt = translate(
        'no-availability-this-date',
        'There are no available appointments on this date.',
    );
    const choosedateTxt = translate(
        'choose-date-and-time',
        'CHOOSE A DATE TO SEE AVAILABLE APPOINTMENT TIMES',
    );
    const dateTxt = translate('date', 'Date');
    const timeTxt = translate('time', 'Time');
    const setApptDate = (date, appt) => {
        setApptIsSelected(true);
        setApptSelected(appt);
    };
    const unselectThisAppt = () => {
        if (!apptIsSelected) {
            gobackaction();
        }
        setApptIsSelected(false);
        setApptSelected(null);
    };
    const bookAppt = () => {
        if (!isLoggedIn) console.log('user is not logged in');
        setIsConfirming(true);
        const fullapptvalues = {
            ...apptSelected,
            firstname: selectedStaff.firstname,
            lastname: selectedStaff.lastname,
            bookingLocation: 'widget',
        };
        dispatch(setApptToConfirm(fullapptvalues));
    };
    let apptTypetoDisplay = '';
    if (apptSelected) apptTypetoDisplay = apptSelected.apptType;

    if (!searchingAppts && availableAppts === null)
        return (
            <>
                <Grid item sx={{ mt: 2, px: 1 }}>
                    <Typography variant="apptMoreInfoText">
                        {choosedateTxt}.
                    </Typography>
                </Grid>
                <Fade in sx={{ mt: 8, pr: 4 }}>
                    <Grid container justifyContent="flex-end">
                        <Grid
                            item
                            // onClick={gobackaction}
                            onClick={() => unselectThisAppt()}
                            sx={{ cursor: 'pointer' }}>
                            <Typography variant="apptMoreInfo">
                                <span
                                    style={{
                                        color: colortoshow,
                                    }}>
                                    {gobackTxt}
                                </span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Fade>
            </>
        );
    if (searchingAppts)
        return (
            <Fade in={searchingAppts} timeout={900}>
                <Grid item sx={{ mt: 5 }}>
                    {searchingTxt}...
                </Grid>
            </Fade>
        );
    if (availableAppts !== null) {
        const getdate = (datehere, language = 'en') => {
            const date = new Date(datehere);
            const hours = date.getUTCHours();
            const minutes = date.getUTCMinutes();

            if (language === 'it') {
                // Italian format: 24-hour without AM/PM
                const hoursStr = hours < 10 ? `0${hours}` : hours;
                const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
                return `${hoursStr}:${minutesStr}`;
            } else {
                // Default format: 12-hour with AM/PM
                const ampm = hours >= 12 ? 'pm' : 'am';
                let hours12 = hours % 12;
                if (hours12 === 0) hours12 = 12;
                const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
                return `${hours12}:${minutesStr} ${ampm}`;
            }
        };
        return (
            <AnimatePresence>
                {availableAppts.length > 0 && !apptIsSelected && (
                    <Grid
                        item
                        key="available-times-header"
                        id="available-times-header"
                        border={0}
                        sx={{ mb: 3, mt: 3, ml: window.isTiny ? 0 : 1 }}>
                        <Grid
                            container
                            id="container-with-available-times-header"
                            direction="row"
                            justifyContent="center"
                            spacing={0}
                            sx={{ m: 0, p: 0 }}
                            border={0}
                            alignItems="flex-end">
                            <Grid
                                item
                                xs={10}
                                sx={{
                                    ml: 0,
                                    mt: window.isTiny ? 0 : 4,
                                    pb: 1,
                                }}>
                                <Typography variant="eventtime">
                                    {whatTime}
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography
                                    variant="eventtimewithcolor"
                                    sx={{ lineHeight: 1.2 }}>
                                    * {timeDisplayedTxt} {tzText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item id="holding-item-motion" xs={12} sx={{ mx: 0 }}>
                    <Grid
                        container
                        component={motion.div}
                        initial={{ opacity: 0.1 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0.3 }}
                        transition={{
                            duration: 0.4,
                            type: 'tween',
                            ease: 'easeInOut',
                        }}
                        spacing={0}
                        id="available-appts-container"
                        border={0}
                        justifyContent={
                            window.isTiny ? 'space-between' : 'space-evenly'
                        }
                        sx={{ mt: 0, mx: 0 }}>
                        {availableAppts.length === 0 && (
                            <Grid item sx={{ mb: 3, mt: 10, mr: 0 }}>
                                <Grid
                                    container
                                    id="available-appts-container-text-no-appts"
                                    direction="row"
                                    justifyContent="flex-end"
                                    sx={{ mt: 0 }}
                                    alignItems="flex-end">
                                    <Typography
                                        variant="eventname"
                                        sx={{ fontStyle: 'italic' }}>
                                        {noAvailabilityTxt}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {!apptIsSelected &&
                            availableAppts.length > 0 &&
                            availableAppts.map((appt, i) => (
                                <Grid
                                    item
                                    id={`appt-time-button-${i}`}
                                    xs={window.isTiny ? 5 : 4}
                                    border={0}
                                    key={`appt-${appt.id}-${i}`}
                                    sx={{ pb: 1, pr: 0, mx: 0.5, mb: 1 }}>
                                    <Button
                                        variant="checkout"
                                        // width={600}
                                        onClick={() =>
                                            setApptDate(appt.start_time, appt)
                                        }
                                        sx={{
                                            fontSize: window.isTiny ? 12 : 14,
                                        }}
                                        fullWidth={true}>
                                        {getdate(appt.start_time, getLanguage)}
                                    </Button>
                                </Grid>
                            ))}
                        {apptIsSelected && (
                            <Grid item xs={12} md={10.5}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    border={0}
                                    id="container-with-appt-selected"
                                    borderColor="primary.main"
                                    borderRadius={1.5}
                                    sx={{ mt: 5.5 }}>
                                    <Grid
                                        item
                                        id="unselect-appt"
                                        xs={11}
                                        sx={{ mb: 1 }}
                                        onClick={() => unselectThisAppt()}>
                                        <Grid
                                            container
                                            justifyContent="flex-end">
                                            <XIcon size="small" />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mb: 1 }}>
                                        <Typography variant="eventtitle">
                                            {capitalizeEntireString(
                                                apptTypetoDisplay,
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                                        <Typography variant="eventnameWBold">
                                            {dateTxt}:{' '}
                                        </Typography>
                                        <Typography variant="eventname">
                                            {getDateAsString(
                                                apptSelected.start_time,
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                                        <Typography variant="eventnameWBold">
                                            {timeTxt}:{' '}
                                        </Typography>
                                        <Typography variant="eventname">
                                            {gettimeasstring(
                                                apptSelected.start_time,
                                            )}{' '}
                                            {shortTzText}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                                        <Typography variant="eventnameWBold">
                                            {providerTxt}:{' '}
                                        </Typography>
                                        <Typography variant="eventname">
                                            {selectedStaff.firstname}{' '}
                                            {selectedStaff.lastname}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 0.5 }}>
                                        <Typography variant="eventnameWBold">
                                            {priceTxt}:{' '}
                                        </Typography>
                                        <Typography variant="eventname">
                                            {currSymbol}
                                            {apptSelected.priceAppt}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 3 }}
                                        id="book-appt-btn">
                                        <Button
                                            variant="checkoutUpdated"
                                            onClick={bookAppt}>
                                            {bookTxt}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    <Fade in sx={{ mt: 3, mr: 4 }}>
                        <Grid container justifyContent="flex-end">
                            <Grid
                                id="go-back-button"
                                item
                                // onClick={gobackaction}
                                onClick={() => unselectThisAppt()}
                                sx={{ cursor: 'pointer' }}>
                                <Typography variant="apptMoreInfo">
                                    <span
                                        style={{
                                            color: colortoshow,
                                        }}>
                                        {gobackTxt}
                                    </span>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Fade>
                </Grid>
            </AnimatePresence>
        );
    }
    return <Grid>Appts</Grid>;
}
