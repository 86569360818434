import { Grid, Avatar, Typography, Button } from '@mui/material';

import { dispatch } from 'store';
import { setSelectedStaff } from 'store/slices/appointments';

import useTranslate from 'hooks/useTranslate';

const StaffItem = ({ staff, setIsChoosingProvider }) => {
    const staffname = staff.firstname + ' ' + staff.lastname;
    const handleSelectStaff = () => {
        dispatch(setSelectedStaff(staff));
        setIsChoosingProvider(false);
    };
    const translate = useTranslate();
    const selectTxt = translate('select', 'Select');

    return (
        <Grid
            container
            id={`staff-item-container-${staff.id}`}
            border={1}
            borderRadius={1.2}
            borderColor="grey.400"
            alignContent="center"
            // backgroundColor="white"
            justifyContent="space-between"
            overflow="hidden"
            sx={{
                mt: 2,
                p: window.isTiny ? 1 : 3,
            }}>
            <Grid
                item
                xs={1.5}
                sm={2}
                md={2}
                border={0}
                id="item-holding-staff-image"
                sx={{ ml: window.isTiny ? 0.25 : 0 }}>
                <Avatar
                    id={`avatar-${staffname}`}
                    alt={staffname}
                    src={staff.image_url}
                    sx={{
                        width: window.isTiny ? 39 : 50,
                        height: window.isTiny ? 39 : 50,
                    }}
                />
            </Grid>
            <Grid
                item
                xs={9.5}
                sm={7}
                md={6.75}
                id="staff-name"
                border={0}
                sx={{ pl: window.isTiny ? 1 : 0 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ mb: 0.5 }}>
                        <Typography
                            variant={
                                window.isTiny
                                    ? 'h5staffnametiny'
                                    : 'h5staffname'
                            }>
                            {staff.firstname} {staff.lastname}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} id="staff-title">
                        <Typography
                            variant={
                                window.isTiny
                                    ? 'h5stafftitletiny'
                                    : 'h5stafftitle'
                            }>
                            {staff.staff_title}
                        </Typography>
                    </Grid>
                    {/* {staff.id === 3865385 && (
                        <Grid item xs={12} id="staff-title" sx={{ mt: 1 }}>
                            <Typography
                                variant={
                                    window.isTiny
                                        ? 'h5stafftitletiny'
                                        : 'h5stafftitletiny'
                                }>
                                Next Availability June 18th, 2024
                            </Typography>
                        </Grid>
                    )} */}
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                sm={2}
                border={0}
                alignItems="center"
                id="staff-select-button">
                <Grid
                    container
                    justifyContent={window.isTiny ? 'flex-end' : 'flex-start'}
                    alignContent="center"
                    height="100%"
                    border={0}
                    sx={{
                        mt: window.isTiny ? 0.5 : 0,
                        mb: window.isTiny ? 0.5 : 0,
                    }}>
                    <Button
                        id={`select-staff-${staffname}`}
                        variant="selectProvider"
                        onClick={handleSelectStaff}>
                        {selectTxt}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StaffItem;
