import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Fade } from '@mui/material';

const EditIcon = ({ color, size }) => {
    const colortoshow = `#${color}`;
    const [growtext, setGrowtext] = useState('grow-2');
    useEffect(() => {
        if (size === 'small') setGrowtext('shrink-2');
    }, [size]);

    return (
        <Fade in>
            <Grid item id="edit-icon">
                <FontAwesomeIcon icon="fa-regular fa-pen-to-square" color={colortoshow}
                    transform={growtext} />
            </Grid>
        </Fade>
    );
};
export default EditIcon;
