import { dispatch } from 'store';
import { setCardBrand, setExpy, setLastFourCard } from 'store/slices/cart';

export default async function updateCardToChargePostStripeUpdate(
    cardtocharge, paymentOptionsAll
) {

    paymentOptionsAll.forEach((pay) => {
        const { id, card } = pay;
        const { brand, exp_month, exp_year, last4 } = card;
        if (id === cardtocharge) {
            console.log('id is same as cardtocharge');
            dispatch(setCardBrand(brand));
            dispatch(setExpy(`${exp_month}/${exp_year}`));
            dispatch(setLastFourCard(last4));
        }
    });

    return 1;
}
