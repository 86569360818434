const dayjs = require('dayjs');

export default async function associatePassesWithEvent(passes) {
    const isExpiringSoon = [];
    const isUnlimited = [];
    const isOnAutopay = [];
    const allOthers = [];
    const passestoreturn = [];
    const privateFinalPasses = [];
    const groupFinalPasses = [];

    await passes.forEach((pass) => {
        const { expiresAt, studioPackage, autopay } = pass;
        const { unlimited } = studioPackage;
        const dateToday = dayjs();
        const expirationDate = dayjs(expiresAt);
        const daystilexpires = expirationDate.diff(dateToday, 'day');
        if (daystilexpires < 6) {
            isExpiringSoon.push(pass);
        } else if (unlimited) {
            isUnlimited.push(pass);
        } else if (autopay) {
            isOnAutopay.push(pass);
        } else {
            allOthers.push(pass);
        }
    });
    const isExpiringSoonSorted = isExpiringSoon.sort((a, b) => {
        return dayjs(a.expiresAt) - dayjs(b.expiresAt);
    });
    const isUnlimitedSorted = isUnlimited.sort((a, b) => {
        return dayjs(a.expiresAt) - dayjs(b.expiresAt);
    });
    const isOnAutopaySorted = isOnAutopay.sort((a, b) => {
        return dayjs(a.expiresAt) - dayjs(b.expiresAt);
    }
    );
    const allOthersSorted = allOthers.sort((a, b) => {
        return dayjs(a.expiresAt) - dayjs(b.expiresAt);
    });
    isExpiringSoonSorted.forEach((pass) => {
        passestoreturn.push(pass);
    });
    isUnlimitedSorted.forEach((pass) => {
        passestoreturn.push(pass);
    });
    isOnAutopaySorted.forEach((pass) => {
        passestoreturn.push(pass);
    });
    allOthersSorted.forEach((pass) => {
        passestoreturn.push(pass);
    });
    passestoreturn.forEach((pass) => {
        const { private_pass } = pass;
        if (private_pass) {
            privateFinalPasses.push(pass);
        } else {
            groupFinalPasses.push(pass);
        }
    });
    const returnValues = {
        privateFinalPasses,
        groupFinalPasses,
    };

    return returnValues;

}
