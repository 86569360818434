import axios from 'axios';

export default async function updateCommunication(userid, emailMarketing, smsMarketing, smsTransactions, dibsStudioId) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/user/update-communication-preferences`;

    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(basicurl, {
                userid,
                emailMarketing,
                smsMarketing,
                smsTransactions,
                dibsStudioId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error updating user communication preferences : ${err} userid is ${userid} `);
                return err;
            });
    };
    await createUser();
    return datatosend;
}
