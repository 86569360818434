import { dispatch } from 'store';
import {
    setAlreadyHasAccount,
    setUserEmail,
    setFirstname,
    setLastname,
    setUserPhone,
    setStripeId,
    setUserId,
    setHasSetPwdFirebase,
    setBirthday,
    setHasSignedInFirebase,
    setAddedToShopify,
    setShopifyCustId,
    setCountryForMobile
} from 'store/slices/thisuser';

export default async function setUserInStore(profile) {
    const { info } = profile;
    dispatch(setAlreadyHasAccount(profile.hasAccount));
    if (profile.hasAccount) {
        dispatch(setUserEmail(info.email));
        dispatch(setFirstname(info.firstName));
        dispatch(setLastname(info.lastName));
        if (info.firebase_auth_pwd) {
            dispatch(setHasSetPwdFirebase(true));
        }
        if (info.added_to_shopify) {
            setAddedToShopify(info.added_to_shopify);
        }
        if (info.shopify_customer_id) {
            setShopifyCustId(info.shopify_customer_id);
        }

        if (info.mobilephone) {
            dispatch(setUserPhone(info.mobilephone));
        }
        if (info.stripeid) {
            dispatch(setStripeId(info.stripeid));
        }
        if (info.birthday) {
            dispatch(setBirthday(info.birthday));
        }
        if (info.country) {
            dispatch(setCountryForMobile(info.country))
        }

        dispatch(setUserId(info.id));

    }
    dispatch(setHasSignedInFirebase(true));
    return 1;
}
