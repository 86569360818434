import PropTypes from 'prop-types';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { Box, Tab, Tabs } from '@mui/material';

import { useSelector } from 'store';
import { logout, auth } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

// project imports
import Profile from './Profile';
import Billing from './Billing';
import FriendReferrals from './FriendReferral';
import MainCard from 'ui-component/cards/MainCard';
import Classes from './Classes';
import PassesCredits from './PassesCredits';

import LogoutButton from 'ui-component/LogoutButton';

// tabs
function TabPanel({ children, value, index, ...other }) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && <Box sx={{ p: 0, m: 0 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

// ==============================|| ACCOUNT LIST ||============================== //

const AccountList = ({ cat }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { search } = useLocation();
    const [value, setValue] = React.useState(0);
    const [user, loading] = useAuthState(auth);

    const [secondary, setSecondary] = React.useState('Logout');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [classesTitle, setClassesTitle] = React.useState('Classes');

    const { isLoggedIn } = useSelector((state) => state.thisuser);
    const { pageSelected, accountPageSelected } = useSelector(
        (state) => state.menu,
    );
    const { config } = useSelector((state) => state.dibsstudio);
    const { clientCcOnFile, dibsStudioId } = config;
    const [showBilling, setShowBilling] = React.useState(
        dibsStudioId === '226' ? false : true,
    );
    React.useEffect(() => {
        if (!user && !loading) {
            logout();
            navigate(`/login/${dibsStudioId}${location.search}`);
        } else if (user && !loading) {
            setSecondary(LogoutButton);
            window.localStorage.setItem(
                'lastpagevisited',
                `/account/${dibsStudioId}${location.search}`,
            );
        }
    }, [user, loading, navigate, dibsStudioId, location.search]);
    React.useEffect(() => {
        // set first page of the account tab
        if (pageSelected === 'account-passes') setValue(0);
        if (pageSelected === 'billing') setValue(2);
        if (pageSelected === 'profile') setValue(3);
        if (pageSelected === 'appts-classes') setValue(1);
        if (accountPageSelected === 'appts-classes')
            setValue(dibsStudioId === '226' ? 0 : 0);

        if (dibsStudioId === '226') {
            setClassesTitle('Appointments');
            setShowBilling(false);
        } else {
            setShowBilling(true);
            // setValue(1)
        }
        // if (!isLoggedIn) setSecondary(null);
    }, [pageSelected, dibsStudioId, navigate, accountPageSelected]);
    const profileValue = dibsStudioId === '226' ? 1 : 3;

    return (
        <MainCard title="Account" contentSX={{ px: 1 }} secondary={secondary}>
            <div>
                <Tabs
                    id="account-tabs"
                    value={value}
                    indicatorColor="primary"
                    onChange={handleChange}
                    allowScrollButtonsMobile={true}
                    visibleScrollbar={false}
                    sx={{
                        mb: 3,
                        pl: 1,
                        minHeight: 'auto',
                        '& button': {
                            minWidth: 100,
                        },
                        '& a': {
                            minHeight: 'auto',
                            minWidth: 10,
                            py: 1.5,
                            px: 1,
                            mr: window.isTiny ? 0 : 2.25,
                            color: 'grey.600',
                        },
                        '& a.Mui-selected': {
                            color: 'primary.main',
                        },
                    }}
                    aria-label="simple tabs example"
                    variant="scrollable">
                    <Tab
                        component={Link}
                        to={`${location.search}`}
                        label={classesTitle}
                        {...a11yProps(0)}
                    />
                    {clientCcOnFile && showBilling && (
                        <Tab
                            component={Link}
                            to={`${location.search}`}
                            label="Passes/Credits"
                            {...a11yProps(1)}
                        />
                    )}
                    {clientCcOnFile && showBilling && (
                        <Tab
                            component={Link}
                            to={`${location.search}`}
                            label="Billing"
                            {...a11yProps(2)}
                        />
                    )}
                    <Tab
                        component={Link}
                        to={`${location.search}`}
                        label="Profile"
                        {...a11yProps(profileValue)}
                    />

                    {/* <Tab
                        component={Link}
                        to="#"
                        label="Friend Referrals"
                        {...a11yProps(4)}
                    /> */}
                </Tabs>
                <TabPanel
                    value={value}
                    index={clientCcOnFile && showBilling ? 0 : 0}>
                    <Classes />
                </TabPanel>
                {clientCcOnFile && showBilling && (
                    <TabPanel value={value} index={1}>
                        <PassesCredits />
                    </TabPanel>
                )}

                {clientCcOnFile && showBilling && (
                    <TabPanel value={value} index={2}>
                        <Billing />
                    </TabPanel>
                )}
                <TabPanel value={value} index={profileValue}>
                    <Profile />
                </TabPanel>

                {/* <TabPanel
                    value={value}
                    index={clientCcOnFile && showBilling ? 4 : 2}>
                    <FriendReferrals />
                </TabPanel> */}
            </div>
        </MainCard>
    );
};

export default AccountList;
