import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function updatePhoneNumberIfNull(
    userid,
    email,
    phonenumber,
    countryAbbr,
) {
    const url = getApiUrl('/user/update-phone-if-null');

    let datatosend = {};
    const updatePhoneIfNull = async () => {
        await axios
            .post(url, {
                userid,
                email,
                phoneNumber: phonenumber,
                countryAbbrev: countryAbbr,
            })
            .then((res) => {
                const { data } = res;
                // console.log('\n\ndata to return from updating user phonenumber if null', data);
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error updating user phone number - IN update phone # IF NULL: ${err} userid is ${userid} phonenumber is ${phonenumber}`,
                );
                return err;
            });
    };
    await updatePhoneIfNull();
    return datatosend;
}
