import PropTypes from 'prop-types';
import React from 'react';
import { Grid, Box } from '@mui/material';
import { useSelector } from 'store';

const LayoutBox = React.forwardRef(
    ({ children, mheight, margTop, idtoreference }, ref) => {
        const idtouseitem = `${idtoreference}-layout-box-item`;
        const idtousecontainer = `${idtoreference}-layout-box-container`;
        const { widgetWidth } = useSelector((state) => state.dibsstudio.config);
        return (
            <Grid item xs={12} sx={{ height: '100%' }} id={idtouseitem}>
                <Grid container id={idtousecontainer}>
                    <Box
                        ref={ref}
                        flex={1}
                        sx={{
                            display: 'flex',
                            minHeight: mheight,
                            height: 'auto',
                            // backgroundColor: 'green',
                            mt: margTop,
                            width: widgetWidth,
                        }}>
                        {children}
                    </Box>
                </Grid>
            </Grid>
        );
    },
);
LayoutBox.propTypes = {
    children: PropTypes.node,
};

export default LayoutBox;
