import { Box, Grid, Stack, Typography } from '@mui/material';

const displayDate = (date, eventTime) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const dayOfWeek = dateObj.toLocaleString('en-us', { weekday: 'long' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${dayOfWeek} - ${month} ${day}, ${year} @ ${eventTime}`;
    return formattedDate;
};

const checkoutItem = ({ row, bglighter, showSpotsBooked }) => {
    let showSpotWords = false;
    let spotWords = '';
    if (showSpotsBooked) {
        showSpotWords = true;
        const q = row.quantity;
        if (q > 1) {
            spotWords = `${q} spots booked`;
        } else {
            spotWords = `${q} spot booked`;
        }
    }

    return (
        <Grid container alignItems="center" spacing={2}>
            <Grid item>
                <Stack>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            ml: window.isTiny ? 0 : 1,
                            mt: 1.7,
                        }}>
                        {displayDate(row.startDate, row.eventTime)}
                    </Typography>
                    <Stack direction="column" alignItems="left" spacing={1}>
                        <Box
                            // key={`}-box-${Math.random()}`}
                            backgroundColor={bglighter}
                            border={0}
                            px={window.isTiny ? 0.5 : 1}
                            py={0.1}
                            mb={0}
                            borderRadius={1.2}>
                            <Typography variant="subtitle1">
                                {row.eventName}
                            </Typography>
                        </Box>
                        <Typography
                            variant="checkout"
                            sx={{
                                // fontWeight: 500,
                                pl: window.isTiny ? 0.5 : 1,
                                pt: 0,
                            }}>
                            Instructor:{' '}
                            <Typography
                                variant="checkout"
                                sx={{
                                    fontWeight: 300,
                                }}>
                                {row.instructorName}
                            </Typography>
                        </Typography>
                        <Typography
                            variant="checkout"
                            sx={{
                                // fontWeight: 500,
                                pl: window.isTiny ? 0.5 : 1,
                                pb: 2,
                            }}>
                            Location:{' '}
                            <Typography
                                variant="checkout"
                                component="span"
                                sx={{
                                    fontWeight: 300,
                                }}>
                                {row.locationName}
                            </Typography>
                        </Typography>
                        {showSpotWords && (
                            <Typography
                                variant="eventname"
                                sx={{
                                    // fontWeight: 300,
                                    pl: window.isTiny ? 0.5 : 1,
                                    pb: 2,
                                }}>
                                {spotWords}
                            </Typography>
                        )}

                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};
export default checkoutItem;
