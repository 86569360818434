import React from 'react';

import { useSelector } from 'store';

import Cart from 'assets/icons/fabCart';
import CartFromTop from 'assets/icons/fabCartTop';
import CartModalSide from 'assets/icons/fabCartModal';

const CartLoader = () => {
    const { buttonControlsWidget, widgetOpenStyle } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const [cartToShow, setCartToShow] = React.useState(<Cart />);
    const sizeofscreen = window.innerWidth;
    const [isSmallWidget, setIsSmallWidget] = React.useState(false);

    const isLargerWidget =
        buttonControlsWidget && widgetOpenStyle === 'full-page';
    React.useEffect(() => {
        const isSmallW = buttonControlsWidget && widgetOpenStyle === 'right';
        setIsSmallWidget(isSmallW);
        if (sizeofscreen <= 400 && !isSmallWidget) {
            setCartToShow(<CartModalSide higher />);
        } else if (isSmallWidget) {
            setCartToShow(<CartFromTop />);
        } else if (sizeofscreen <= 800 || isSmallWidget) {
            setCartToShow(<CartFromTop />);
        } else if (isLargerWidget) {
            setCartToShow(<CartModalSide />);
        } else {
            setCartToShow(<Cart />);
        }
    }, [
        buttonControlsWidget,
        isLargerWidget,
        isSmallWidget,
        sizeofscreen,
        widgetOpenStyle,
    ]);
    return cartToShow;
};
export default CartLoader;
