import { useEffect, useState } from 'react';
import { Grid, Box, ButtonBase, useMediaQuery, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'store';
import {
    setDateSelected,
    setMaxDateForComparison,
    updateNumDaysToMove,
} from 'store/slices/menu';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

const MenuButton = styled(ButtonBase)(({ theme }) => ({
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
    },
}));
const checkSelectedIsToday = (selected, comparison) => {
    const selectedDay = dayjs.utc(selected).date();
    const selectedMonth = dayjs.utc(selected).month() + 1;
    const comparisonDay = dayjs.utc(comparison).date();
    const comparisonMonth = dayjs.utc(comparison).month() + 1;
    const selectedDateString = `${selectedMonth}-${selectedDay}`;
    const comparisonDateString = `${comparisonMonth}-${comparisonDay}`;
    if (selectedDateString === comparisonDateString) {
        return true;
    } else {
        return false;
    }
};
const getDatesToShow = (numDays, offset) => {
    const dates = [];
    for (let i = 0; i < numDays; i++) {
        const offsetDays = i + offset;
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        date.setDate(date.getDate() + offsetDays);
        const dayOfWeek = date.toLocaleString('en-us', { weekday: 'short' });
        const dateFormatted = date.toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
        });
        const dayOfWeekCap = dayOfWeek.toUpperCase();

        dates.push({
            dateFormatted,
            dow: dayOfWeekCap,
            rawdate: date,
        });
    }

    return dates;
};

const DatesFormatted = ({ selected, numChevronsShow, filterDate }) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.down('md'));
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const { color } = studioConfig;
    const { buttonControlsWidget, widgetOpenStyle } = config;
    const [colortoshow, setColorToShow] = useState('#eeeeee');
    const [numDaysToShow, setNumDaystoShow] = useState(7);
    const [isTinyScreen, setIsTinyScreen] = useState(window.innerWidth < 500);
    const { movedScheduleOut } = useSelector((state) => state.menu);
    const setSelectedDateRedux = (dateSelected) => {
        dispatch(setDateSelected(dateSelected));
        const thisnewdate = new Date(dateSelected);
        thisnewdate.setDate(thisnewdate.getDate() + 1);
        dispatch(setMaxDateForComparison(thisnewdate));
    };

    useEffect(() => {
        const colorcode = `#${color}`;
        setColorToShow(colorcode);
        let smallWidget = buttonControlsWidget && widgetOpenStyle === 'right';
        if (window.innerWidth < 500) {
            setIsTinyScreen(true);
        } else {
            setIsTinyScreen(false);
        }

        if (isSm) {
            setNumDaystoShow(4);
            dispatch(updateNumDaysToMove(1));
            if (numChevronsShow === 2) {
                setNumDaystoShow(3);
                // dispatch(updateNumDaysToMove(1));
            }
        } else if (isMd || smallWidget) {
            setNumDaystoShow(5);
            dispatch(updateNumDaysToMove(4));
            if (numChevronsShow === 2) {
                setNumDaystoShow(5);
            }
        }
        // if (movedScheduleOut > 0) {
        //     // setNumDaystoShow((n) => n - 1);
        //     dispatch(updateNumDaysToMove(numDaysToShow - 1));
        // }
    }, [
        buttonControlsWidget,
        color,
        dispatch,
        isMd,
        isSm,
        movedScheduleOut,
        numChevronsShow,
        numDaysToShow,
        widgetOpenStyle,
    ]);
    // const dateValueData = getDatesToShow(numDaysToShow, movedScheduleOut);
    const datesToShow = getDatesToShow(numDaysToShow, movedScheduleOut);

    return (
        <Grid
            container
            id="container-holds-dates"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            component={motion.div}
            initial={{ x: 0 }}
            animate={{ width: '100%' }}
            key={movedScheduleOut}
            border={0}
            exit={{
                x: window.innerWidth,
                transition: { duration: 200 },
            }}
            spacing={buttonControlsWidget ? 1 : 3}
            sx={{ pl: 0.5 }}>
            {datesToShow.map((date) => {
                let colorDate = '#8f8f8f';
                let colorDay = '#696969';
                let bgColor = 'transparent';
                const shouldHighlight = checkSelectedIsToday(
                    selected,
                    date.rawdate,
                );
                if (shouldHighlight) {
                    colorDate = '#fff';
                    colorDay = '#fff';
                    bgColor = colortoshow;
                }

                return (
                    <Fade in key={date.rawdate} appear easing="ease-out">
                        <Grid
                            item
                            key={date.rawdate}
                            border={0}
                            id="grid-item-surrounding-date-box">
                            <Box
                                id="box-surrounding-date"
                                sx={{
                                    pl: 1,
                                    pt: 0.5,
                                    backgroundColor: filterDate
                                        ? '#fff'
                                        : bgColor,
                                    borderRadius: 2,
                                    width: isTinyScreen ? '60px' : '85px',
                                }}>
                                {filterDate ? null : (
                                    <MenuButton
                                        onClick={() => {
                                            setSelectedDateRedux(date.rawdate);
                                        }}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                            id="menu-button-date-container">
                                            <Grid
                                                item
                                                id="menu-button-date"
                                                xs={12}
                                                sx={{
                                                    color: colorDate,
                                                    fontSize: isTinyScreen
                                                        ? 13
                                                        : 14,
                                                    fontWeight: 500,
                                                    // backgroundColor: '#ff0000',
                                                    pt: 0,
                                                    lineHeight: 1,
                                                    display: 'flex',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                }}>
                                                {date.dateFormatted}
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    color: colorDay,
                                                    fontSize: isTinyScreen
                                                        ? 20
                                                        : 28,
                                                    fontWeight: 500,
                                                    pt: 0,
                                                    display: 'flex',
                                                    justifyContent:
                                                        'flex-start',
                                                    alignItems: 'flex-start',
                                                    pb: isTinyScreen ? 0.5 : 0,
                                                }}>
                                                {date.dow}
                                            </Grid>
                                        </Grid>
                                    </MenuButton>
                                )}
                            </Box>
                        </Grid>
                    </Fade>
                );
            })}
        </Grid>
    );
};
export default DatesFormatted;
