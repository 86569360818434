import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

const BuyIcon = ({ color, id }) => {
    const iconToUse = 'fa-solid fa-plus';

    return (
        <Grid item border={0} id={`div-around-button-buy-${id}`}>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                    icon={iconToUse}
                    color={color}
                    // transform="grow-1"
                />
            </span>
        </Grid>
    );
};
export default BuyIcon;
