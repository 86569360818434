import { studioIsDifferent } from 'constants/config-constants';

const getDifferences = (dibsstudioid, type) => {
    const studio = studioIsDifferent.find(
        (studio) => studio.studioid === dibsstudioid,
    );
    if (studio) {
        const typeused = type;
        return studio[typeused];
    }
    return studio;
};
export default getDifferences;
