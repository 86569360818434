import { useState, useEffect } from 'react';
import { Button, Grid, Chip, Typography, Divider } from '@mui/material';

import CollapseComponent from 'views/wrappers/collapse';
import { dispatch, useSelector } from 'store';
import { setCardToCharge, setShowCCCapture } from 'store/slices/cart';
import { setClickedChangePaymentMethod, setRefreshPaymentOptions } from 'store/slices/thisuser';
import { capitalizeString } from 'helpers';
import CheckoutButton from 'components/checkout/buttons/checkout';
import AddPaymentMethod from './AddPaymentMethods';

import { setIsProcessing, setShowCheckoutButton, setShowAllExistingPaymentOptions, setShowSummaryPaymentScreen } from 'store/slices/cart';


const DisplayPaymentMethods = ({ pl, showThis, buyAction }) => {
    const { paymentOptionsAll, clickedChangePaymentMethod } = useSelector((state) => state.thisuser);
    const { showCCCapture, cardtocharge, cardexpy, cardlastfour, cardbrand } = useSelector((state) => state.cart);
    const [cardToUse, setCardToUse] = useState(paymentOptionsAll[0]);
    const [cardDetails, setCardDetails] = useState(null);
    const [buttonText, setButtonText] = useState('Change Payment Method');
    const [clickableStatus, setClickableStatus] = useState(false);
    const [addingPaymentMethod, setAddingPaymentMethod] = useState(false);
    const [disableCheckout, setDisableCheckout] = useState(true);
    const { totalAfterCreditApplied } = useSelector((state) => state.cart);


    useEffect(() => {
        if (cardToUse === null) {
            return;
        }
        if (paymentOptionsAll.length === 0) {
            const cardDetails = 'No payment method selected';
            setButtonText('Add Payment Method');
            setCardDetails(cardDetails);
            if (totalAfterCreditApplied > 0) setDisableCheckout(true);
            else setDisableCheckout(false);
            return;
        }
        const getCardDetails = () => {
            const { card } = cardToUse;
            const { brand, last4 } = card;
            let capBrand = capitalizeString(cardbrand);
            let cardDetails = `Paying with ${capBrand} ending in ${cardlastfour}`;
            if (cardlastfour === 'xxxx') {
                capBrand = capitalizeString(brand);
                cardDetails = `Paying with ${capBrand} ending in ${last4}`;
            }
            setButtonText('Change Payment Method');
            setCardDetails(cardDetails);
            setDisableCheckout(false);
        };

        getCardDetails();
    }, [cardToUse, cardbrand, cardlastfour, cardtocharge, paymentOptionsAll.length, totalAfterCreditApplied]);
    // useEffect(() => {
    //     dispatch(setShowCheckoutButton(true));
    // }, []);


    useEffect(() => {
        if (showCCCapture) {
            setAddingPaymentMethod(true);
        } else {
            setAddingPaymentMethod(false);
        }
    }, [showCCCapture]);

    const changeCCPaymentMethod = () => {
        dispatch(setRefreshPaymentOptions(true));
        dispatch(setShowSummaryPaymentScreen(false));
        dispatch(setShowAllExistingPaymentOptions(true));
        dispatch(setIsProcessing(false));
        if (paymentOptionsAll.length === 0) {
            dispatch(setShowCCCapture(true));
            setAddingPaymentMethod(true);
            dispatch(setShowAllExistingPaymentOptions(true));
            dispatch(setShowSummaryPaymentScreen(false));
        } else {
            console.log('THERE are payment options on file');

        }

    };


    const paymentSummaryInfo = (
        <>
            <Grid
                container
                justifyContent="flex-end"
                alignContent="center"
                alignItems="center"
                border={0}
                sx={{ mt: 4 }}>
                <Grid item sx={{ pl: pl }} border={0}>
                    <Chip label={cardDetails} clickable={clickableStatus} />
                </Grid>
                <Grid item sx={{ pl: pl }} border={0}>
                    <Button onClick={() => changeCCPaymentMethod()}>
                        {buttonText}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <CheckoutButton
                        buttonText="Pay Now"
                        clickAction={buyAction}
                        pl={3}
                        isDisabled={disableCheckout}
                    />
                </Grid>
            </Grid>

        </>
    );

    return <CollapseComponent showThis={true} children={paymentSummaryInfo} />;
};
export default DisplayPaymentMethods;
