import { dispatch } from 'store';
import { setCardBrand, setCardToCharge, setExpy, setLastFourCard } from 'store/slices/cart';
import { setNumCreditCardsOnFile, setPaymentOptions, setStripeId, setStripeIdThisStudio } from 'store/slices/thisuser';

export default async function setPaymentOptionsFromStripe(
    data
) {
    const { paymentsDibs, paymentsConnectedAccount, stripeidDibs, stripeidStudio } = data;
    dispatch(setStripeId(stripeidDibs));
    dispatch(setStripeIdThisStudio(stripeidStudio));
    console.log('setting payment options from stripe here');

    const paymentsalreadyincluded = [];
    if (paymentsDibs.length === 0 && paymentsConnectedAccount.length === 0) {
        dispatch(setCardToCharge(''));
        dispatch(setLastFourCard('xxxx'));
        dispatch(setCardBrand(''));
        dispatch(setExpy(''));
        const tosend = {
            totalNumCC: 0,
            dibsNumCC: 0,
            studioNumCC: 0,
        };
        dispatch(setNumCreditCardsOnFile(tosend));
        dispatch(setPaymentOptions([]));
        return 1;
    } else {
        const paymentstosend = [];
        paymentsDibs.forEach((pay) => {
            const { card } = pay;
            const { brand, exp_month, exp_year, last4, fingerprint } = card;
            const together = `${brand}${last4}${exp_month}${exp_year}${fingerprint}`;
            if (!paymentsalreadyincluded.includes(together)) {
                paymentsalreadyincluded.push(together);
                const newobj = {
                    ...pay,
                    platform: 'Dibs',
                };
                paymentstosend.push(newobj);
            }
        });
        paymentsConnectedAccount.forEach((pay) => {
            const { card } = pay;
            const { brand, exp_month, exp_year, last4, fingerprint } = card;
            const together = `${brand}${last4}${exp_month}${exp_year}${fingerprint}`;
            if (!paymentsalreadyincluded.includes(together)) {
                paymentsalreadyincluded.push(together);
                const newobj = {
                    ...pay,
                    platform: 'Studio',
                };
                paymentstosend.push(newobj);
            }
        });
        const tosend = {
            totalNumCC: paymentsConnectedAccount.length + paymentsDibs.length,
            dibsNumCC: paymentsDibs.length,
            studioNumCC: paymentsConnectedAccount.length,
        };
        dispatch(setNumCreditCardsOnFile(tosend));
        dispatch(setPaymentOptions(paymentstosend));
    }
    return 1;
}
