import React, { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import App from 'App';
// import Root from './root';

import WidgetPage from 'views/index';
import AppointmentPage from 'views/appointments';
import PackagesPage from 'views/packages';
import CheckoutPage from 'views/checkout';
import ConfirmCheckoutPage from 'views/confirm-checkout';
import PaymentPage from 'views/payment';
import PurchaseConfirmationPage from 'views/reviewPurchase';
import PurchaseCompletePage from 'views/purchaseIsComplete';
import AccountPage from 'views/account';
import Protected from 'views/auth/protected';
import ConfigWrapper from 'views/wrappers/updateConfigs';
import LoginPageView from 'views/auth/login';
import RegisterPage from 'views/authentication/register';
import RegisterAddProfile from 'views/authentication/registerAddProfile';
import ForgotPassword from 'views/authentication/forgotPassword';
import LoginEmailLink from 'views/auth/loginNoPwd';
import LoginNew from 'views/new-authentication/login-new';
import RegisterFromFriend from 'views/new-authentication/register-from-friend';
import ShopifyView from 'views/shopify/shopifyIntro';
import ValidateLink from 'views/auth/validationFromLink';
import BookClass from 'views/bookClass';
import GoogleAuthComplete from 'hooks/googleAuthComplete';
import ChargePage from 'views/charging';
import ErrorPage from 'views/error';
// http://localhost:3000/shopify-redirect?token=shpua_2bc48bc259d5fcd86048b42448f9bed8

const AccountPageLazy = lazy(() => import('views/account'));
const WidgetPageLazy = lazy(() => import('views/index'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: '/google/redirect',
                element: <GoogleAuthComplete />,
            },
            {
                path: 'shopify-redirect/:dibsId/:token',
                element: <ShopifyView />,
            },
            {
                path: 'schedule/:dibsId',
                element: (
                    <ConfigWrapper>
                        <WidgetPage />
                    </ConfigWrapper>
                ),
            },
            {
                path: 'appointments/:dibsId',
                element: (
                    <ConfigWrapper>
                        <AppointmentPage />
                    </ConfigWrapper>
                ),
            },
            {
                path: 'appointments/:dibsId/:category',
                element: (
                    <ConfigWrapper>
                        <AppointmentPage />
                    </ConfigWrapper>
                ),
            },
            {
                path: 'login/:dibsId',
                element: (
                    <ConfigWrapper>
                        <LoginNew />
                    </ConfigWrapper>
                ),
            },
            {
                path: 'signup/:dibsId',
                element: (
                    <ConfigWrapper>
                        <RegisterFromFriend />
                    </ConfigWrapper>
                ),
            },
            {
                path: 'login-email-link/:dibsId',
                element: <LoginNew />,
            },
            {
                path: 'login-new/:dibsId',
                element: <LoginNew />,
            },
            { path: 'validate-link/:dibsId', element: <ValidateLink /> },
            {
                path: 'forgot-password/:dibsId',
                element: <LoginNew />,
            },
            {
                path: 'register/:dibsId',
                element: <LoginNew />,
            },
            {
                path: 'register/add-profile',
                element: <LoginNew />,
            },
            {
                path: 'packages/:dibsId',
                element: (
                    <ConfigWrapper>
                        <PackagesPage />
                    </ConfigWrapper>
                ),
            },
            {
                path: 'checkout/:dibsId',
                element: <CheckoutPage />,
            },
            {
                path: 'checkout/confirm/:dibsId',
                element: <ConfirmCheckoutPage />,
            },
            {
                path: 'checkout/processing/:dibsId',
                element: <ChargePage />,
            },
            {
                path: 'payment/:dibsId',
                element: (
                    <Protected pagetogo="payment">
                        <PaymentPage />
                    </Protected>
                ),
            },
            {
                path: 'book/:dibsId',
                element: (
                    <Protected>
                        <BookClass />
                    </Protected>
                ),
            },
            {
                path: 'confirm/:dibsId',
                element: (
                    <Protected>
                        <PurchaseConfirmationPage />
                    </Protected>
                ),
            },
            {
                path: 'purchase-complete/:dibsId',
                element: (
                    <Protected>
                        <PurchaseCompletePage />
                    </Protected>
                ),
            },
            {
                path: 'account/:dibsId',
                element: (
                    <Protected pagetogo="account">
                        <AccountPage />
                    </Protected>
                ),
            },
            {
                path: 'account/:dibsId/:accountCat',
                element: (
                    <Protected pagetogo="account">
                        <AccountPage />
                    </Protected>
                ),
            },
        ],
    },
]);
export default router;
