const phoneUtil =
    require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

export const capitalizeString = (str) => {
    // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const lowercaseString = (str) => {
    // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    let result = str.toLowerCase();
    return result;
};
export const capitalizeEntireString = (str) => {
    let result = str.toUpperCase();
    return result;
};
export const subtractDaysFromDate = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
};

export const differenceBetweenTimes = (t1, t2) => {
    const t1time = new Date(t1);
    const t2time = new Date(t2);
    var diff = (t1time.getTime() - t2time.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
};
export const addDaysToDate = (date, days) => {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};
export const getApiUrl = (path) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}${path}`;
    return basicurl;
};
export const convertEpochNormal = async (value) => {
    const newdate = new Date(Number(value));
    if (isNaN(newdate.valueOf())) {
        return '';
    }
    const day = newdate.getDate();

    const mth = newdate.getMonth() + 1;
    const year = newdate.getFullYear();
    const shortyear = year.toString().substring(2, 4);
    return `${mth}/${day}/${shortyear}`;
};
export const convertEpoch = async (value) => {
    // if (!value) {
    //     return '';
    // }
    const newdate = new Date(Number(value) * 1000);
    if (isNaN(newdate.valueOf())) {
        return '';
    }
    const day = newdate.getDate();

    const mth = newdate.getMonth() + 1;
    const year = newdate.getFullYear();
    const shortyear = year.toString().substring(2, 4);
    return `${mth}/${day}/${shortyear}`;
};
export const capitalizeWords = (sentence) => {
    return sentence
        .split(' ')
        .map(
            (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(' ');
};

export const getToken = async () => {
    const dibsToken = localStorage.getItem('dibs-token');

    return dibsToken;
};
export const getHeaders = async () => {
    const dibsToken = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${dibsToken}`,
    };
    return headers;
};
export const determineWhetherIsValid = (phoneNumberString, countrycode) => {
    const number = phoneUtil.parse(phoneNumberString, countrycode);
    const valid = phoneUtil.isPossibleNumber(number);
    const phoneString = phoneUtil.format(number, PNF.INTERNATIONAL);
    const msg = {
        valid,
        phoneString,
    };
    return msg;
};

export const formatPhoneNumber = (phoneNumberString) => {
    console.log(
        'this should not be used - if you are seeing this, look into the issue',
    );
    const number = phoneUtil.parse(phoneNumberString, 'US');
    const valid = phoneUtil.isPossibleNumber(number);
    const phoneString = phoneUtil.format(number, PNF.INTERNATIONAL);
    const msg = {
        valid,
        phoneString,
    };
    return msg;
};
export const formatPhoneNumberUSAlreadyValidated = (
    phoneNumberString,
    countryAbbr,
) => {
    let countryToUse = countryAbbr;
    if (!countryAbbr || countryAbbr === null || countryAbbr === undefined) {
        countryToUse = 'US';
    }
    if (!phoneNumberString) return '';
    if (phoneNumberString.length < 5) return '';

    ////// NUMBER TO PROCESS
    const number = phoneUtil.parse(phoneNumberString, countryToUse);
    let phoneString;
    // BUT WHAT IF IT IS NOT A US NUMBER?
    const valid = phoneUtil.isValidNumber(number);
    if (valid) {
        phoneString = phoneUtil.formatInOriginalFormat(number, countryToUse);
    } else {
        console.log('says it is not valid - could be from another country');
        try {
            phoneString = phoneUtil.format(number, PNF.INTERNATIONAL);
        } catch (err) {
            console.log('got error with phonestring ', phoneString);
            console.log('error is', err);
            phoneString = '';
        }
    }
    return phoneString;
};

export const roundtoN = (n, digits) => {
    if (digits === undefined) {
        digits = 0;
    }

    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    return Math.round(n) / multiplicator;
};
export const calculatecredit = async (credit, total) => {
    if (credit > total) {
        return total;
    } else {
        return credit;
    }
};
export const calculateTax = async (iteminfo) => {
    const tax = iteminfo.price * (iteminfo.taxAmount / 100);
    return roundtoN(tax, 2);
};
export const calculateDiscountedTax = async (iteminfo) => {
    const tax = iteminfo.discountedprice * (iteminfo.taxAmount / 100);
    return roundtoN(tax, 2);
};

export const formatDollarAmount = (dollarAmount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(dollarAmount);
};
export const formatDollarAmountTwoDigits = (dollarAmount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(dollarAmount);
};

const formattedTime = (datePassed) => {
    const datep = `${datePassed}`;
    const date = new Date(datep);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    if (hours12 === 0) hours12 = 12;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours12}:${minutesFormatted} ${ampm}`;
    return time;
};

export const breakupDate = async (datetouse) => {
    const datep = `${datetouse}`;
    const dateObj = new Date(datep);
    const month = dateObj.getUTCMonth() + 1; // months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const shortyear = year.toString().substring(2, 4);
    let formatteddate = `${month}/${day}/${shortyear}`;
    const formattedtime = formattedTime(datep);
    const msg = {
        formatteddate,
        formattedtime,
    };
    return msg;
};
export const displayDate = async (date) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const dayOfWeek = dateObj.toLocaleString('en-us', { weekday: 'long' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}`;
    return formattedDate;
};
export const displayDateNew = (date, eventTime) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const dayOfWeek = dateObj.toLocaleString('en-us', { weekday: 'long' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${dayOfWeek} - ${month} ${day}, ${year} @ ${eventTime}`;
    return formattedDate;
};
export const calculateDifferenceDate = async (
    startDateEpoch,
    endsDateEpoch,
    period,
    value,
) => {
    const starting = await convertEpoch(startDateEpoch);
    const ending = await convertEpoch(endsDateEpoch);
    const began = new Date(starting);
    let minEndTime;
    if (period === 'month') {
        minEndTime = began.setMonth(began.getMonth() + value);
    }
    const minEnd = await convertEpochNormal(minEndTime);
    if (ending >= minEnd) {
        return true;
    }
    return false;
};

export default capitalizeString;
