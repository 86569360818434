import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import { setPageSelected } from 'store/slices/menu';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const UpcomingClasses = ({ text }) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    const navigate = useNavigate();
    const location = useLocation();
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortouse = `#${color}`;
    const gotoaccount = () => {
        dispatch(setPageSelected('account'));
        navigate(`/account/${dibsStudioId}${location.search}`);
    };
    return (
        <Grid item xs={12} sx={{ mb: 5 }}>
            <Grid
                direction={matchDownMD ? 'column-reverse' : 'row'}
                container
                spacing={3}
                alignItems={matchDownMD ? '' : 'center'}>
                <Grid item xs={12} md={12} lg={12} sx={{ mt: 1, mb: 3 }}>
                    <Button
                        // component={Link}
                        // to={backtoScheduleUrl}
                        onClick={() => gotoaccount()}
                        variant="text"
                        sx={{
                            color: colortouse,
                        }}>
                        {text}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default UpcomingClasses;
