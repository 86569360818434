import { useState, useEffect } from 'react';
import {
    Grid,
    Button,
    Typography,
    Paper,
    Stack,
    IconButton,
    Tooltip,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { convertEpoch, breakupDate, calculateDifferenceDate } from 'helpers';

import { dispatch, useSelector } from 'store';
import { setNeedsRefresh } from 'store/slices/menu';

import cancelRenewal from 'actions/stripe/cancelRenewal';
import reinstateMembership from 'actions/stripe/reinstateMembership';

const Pass = ({ pass, dibsId, userid }) => {
    const { studioConfig } = useSelector((state) => state.dibsstudio);
    const { color } = studioConfig;
    const colortouse = color === undefined ? '#333333' : `#${color}`;
    const {
        autopay,
        usesCount,
        studioPackage,
        totalUses,
        expiresAt,
        stripeInfo,
        studio_package_id,
    } = pass;
    const {
        packageName,
        customDescription,
        isPrivate,
        commitment_period,
        on_demand_access,
        autopayIncrement,
        unlimited,
    } = studioPackage;
    const remaining = totalUses - usesCount || 'Unlimited';
    const [chargeSentence, setChargeSentence] = useState('');
    const [canCancel, setCanCancel] = useState(false);
    const [buttonText, setButtonText] = useState('Cancel Renewal');
    const [specialText, setSpecialText] = useState('');
    const [wasCanceled, setWasCanceled] = useState(false);
    useEffect(() => {
        const findDate = async () => {
            await breakupDate(expiresAt).then((res) => {
                if (autopay) {
                    setChargeSentence(
                        `This membership will automatically renew on ${res.formatteddate}`,
                    );
                } else {
                    setChargeSentence(
                        `This pass will expire on ${res.formatteddate}`,
                    );
                }
            });
        };
        findDate();
        // if (!autopay) {
        //     findDate();
        // }
        if (stripeInfo !== undefined) {
            const { ends, amount, started, isCanceled } = stripeInfo;
            // TODO handle error
            if (isCanceled) {
                setWasCanceled(true);
            }
            const conv = async () => {
                await convertEpoch(ends).then((res) => {
                    if (!isCanceled) {
                        setChargeSentence(
                            `You will be charged $${amount} on ${res}`,
                        );
                    } else {
                        setChargeSentence(
                            `Your membership was cancelled. It will end on ${res}. If this was a mistake, reinstate it below.`,
                        );
                        setWasCanceled(true);
                        setButtonText('Reinstate Membership');
                    }
                });
            };
            conv();
            const calc = async () => {
                await calculateDifferenceDate(
                    started,
                    ends,
                    autopayIncrement,
                    commitment_period,
                ).then((res) => {
                    if (res) {
                        setCanCancel(true);
                    }
                });
            };
            if (commitment_period > 1) {
                calc();
            } else {
                setCanCancel(true);
            }
        }
    }, [autopay, autopayIncrement, commitment_period, expiresAt, stripeInfo]);
    const updateMembership = async () => {
        if (!wasCanceled) {
            console.log(`running cancelRenewal`);
            await cancelRenewal(dibsId, userid, pass.id, studio_package_id)
                .then((res) => {
                    console.log(res);
                    setButtonText('Renew Membership');
                    setSpecialText('Your membership was cancelled.');
                    setWasCanceled(true);
                    setTimeout(() => {
                        setSpecialText('');
                    }, 4000);
                    dispatch(setNeedsRefresh(true));
                })
                .catch(() => {
                    setSpecialText(
                        'There was an error cancelling your membership. Please contact us at support@ondibs.com if you continue to have this problem.',
                    );
                    setTimeout(() => {
                        setSpecialText('');
                    }, 4000);
                });
        } else {
            console.log(`running reinstatemembership`);
            await reinstateMembership(
                dibsId,
                userid,
                pass.id,
                studio_package_id,
            )
                .then((res) => {
                    console.log(res);
                    setButtonText('Cancel Renewal');
                    setSpecialText('Your membership was reinstated.');
                    setTimeout(() => {
                        setSpecialText('');
                    }, 4000);
                })
                .catch(() => {
                    setSpecialText(
                        'There was an error reinstating your membership. Please contact us at support@ondibs.com',
                    );
                    setTimeout(() => {
                        setSpecialText('');
                    }, 4000);
                });
        }
    };
    return (
        <Grid item xs={3.6} sx={{ mt: 3, mr: 2 }}>
            <Paper
                elevation={1}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    border: 1,
                    borderRadius: 2,
                    borderColor: '#e5e5e5',
                    height: 225,
                    width: 225,
                }}>
                <Grid item xs={12} sx={{ p: 2, ml: 0.5, mt: 0.5, mr: 0.5 }}>
                    <Stack>
                        <Typography variant="coloredHeader">
                            {packageName}
                        </Typography>
                        <Typography
                            variant="regularText"
                            sx={{ fontStyle: 'italic' }}>
                            {unlimited
                                ? '(Unlimited uses)'
                                : `(${remaining} classes remaining)`}
                        </Typography>
                        {/* <Typography variant="regularText" sx={{ mt: 2 }}>
                            {expText}
                        </Typography> */}
                        <Typography variant="regularText" sx={{ mt: 2.5 }}>
                            {chargeSentence}
                        </Typography>
                        <Typography variant="boldHeader" sx={{ mt: 1.5 }}>
                            {specialText}
                        </Typography>
                    </Stack>
                    {canCancel && (
                        <Stack direction="row">
                            <Grid item sx={{ mt: 1.5, ml: 0, mb: 2 }}>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    onClick={() => updateMembership()}
                                    sx={{ p: 0, px: 1, my: 0.5 }}>
                                    {buttonText}
                                </Button>
                            </Grid>
                            {isPrivate && (
                                <Grid item xs>
                                    <Grid
                                        container
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        height="100%">
                                        <Grid item>
                                            <Tooltip title="Applies to privates">
                                                <IconButton>
                                                    <LockIcon
                                                        sx={{
                                                            color: colortouse,
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                        </Stack>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};
export default Pass;
