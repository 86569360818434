import React from 'react';
import { capitalizeString } from 'helpers';
import { gridSpacing } from 'store/constant';

import { dispatch, useSelector } from 'store';
import { setFirstname, setLastname, setPhone } from 'store/slices/thisuser';

// material-ui
import {
    Grid,
    Button,
    InputLabel,
    Typography,
    TextField,
    Fade,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

const validationSchema = yup.object().shape({
    firstname: yup
        .string()
        .min(2, 'Your name is too short. Please try again.')
        .max(90, 'Your name is too long. Please try again.')
        .required('Required'),
    lastname: yup
        .string()
        .min(2, 'Your lastname is too short. Please try again.')
        .max(90, 'Your name is too long. Please try again.')
        .required('Required'),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCardNew = ({ setCurrentStep }) => {
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const formik = useFormik({
        initialValues: {
            // firstname: 'Anna',
            // lastname: 'Strindt',
            firstname: '',
            lastname: '',
            phone: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                const firstnametosend = capitalizeString(values.firstname);
                const lastnametosend = capitalizeString(values.lastname);
                dispatch(setFirstname(firstnametosend));
                dispatch(setLastname(lastnametosend));
                dispatch(setPhone(values.phone));
                setCurrentStep('setpassword');
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <Fade in={true} timeout={500}>
                    <LoginCard title="Register" dibsstudioid={dibsStudioId}>
                        <form
                            className="form-profile"
                            onSubmit={formik.handleSubmit}>
                            <Grid
                                container
                                id="holding-registration-data"
                                sx={{ ml: 1, mr: 1 }}
                                justifyContent="space-around">
                                <Grid item xs={12} sx={{ mt: 2.5 }}>
                                    <Typography variant="regularText">
                                        Enter your first and last name
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ mb: gridSpacing, ml: 0.5, mt: 2 }}>
                                    <Grid container justifyContent="flex-start">
                                        <Grid
                                            item
                                            xs={10}
                                            md={5}
                                            sx={{ mt: 0.5, ml: 0 }}>
                                            <InputLabel>Firstname</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="firstname"
                                                value={formik.values.firstname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.firstname &&
                                                    Boolean(
                                                        formik.errors.firstname,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.firstname &&
                                                    formik.errors.firstname
                                                }
                                                sx={{ mt: 1 }}>
                                                Firstname
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            md={5}
                                            sx={{
                                                mt: 0.5,
                                                ml: window.isTiny ? 1.5 : 3,
                                            }}>
                                            <InputLabel>Last Name</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="lastname"
                                                value={formik.values.lastname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.lastname &&
                                                    Boolean(
                                                        formik.errors.lastname,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.lastname &&
                                                    formik.errors.lastname
                                                }
                                                sx={{ mt: 1 }}>
                                                Last Name
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ mb: gridSpacing, mt: 2.5 }}>
                                    <Typography variant="regularText">
                                        Enter your phone number
                                    </Typography>
                                </Grid>
                                <Grid container id="registration-phone">
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{
                                            mb: gridSpacing,
                                            mt: 0.5,
                                            ml: 0.5,
                                        }}>
                                        <InputLabel>Phone #</InputLabel>
                                        <TextField
                                            fullWidth
                                            name="phone"
                                            value={formik.values.phone || ''}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            placeholder="XXX-XXX-XXXX"
                                            error={
                                                formik.touched.phone &&
                                                Boolean(formik.errors.phone)
                                            }
                                            helperText={
                                                formik.touched.phone &&
                                                formik.errors.phone
                                            }
                                            sx={{ mt: 1 }}>
                                            Phone
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2.5 }}>
                                    <Grid item xs={11}>
                                        <Button
                                            variant="checkout"
                                            type="submit"
                                            fullWidth
                                            disabled={formik.isSubmitting}
                                            sx={{ height: '42px' }}>
                                            Next
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};
export default LoginAuthenticationCardNew;
