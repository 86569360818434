import axios from 'axios';

export default async function confirmSpaceInClass(items) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/confirm-space`;
    let datatosend = {};
    const checkSpace = async () => {
        await axios
            .post(basicurl, {
                items,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error checking space in class: ${err}`);
                return err;
            });
    };
    await checkSpace();
    return datatosend;
}
