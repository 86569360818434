import { useState, useEffect } from 'react';
import { Grid, TextField, Typography, Stack, Link, Fade } from '@mui/material';
import { useSelector, dispatch } from 'store';
import { setPageSelected } from 'store/slices/menu';
import { setDiscountedPackageInfo } from 'store/slices/cart';
import LinearProgress from 'assets/LinearProgress';
import verifyCode from 'actions/promo-codes/verifyCode';
import applyPromoCode from 'actions/promo-codes/applyPromoCode';
import { calculatecredit } from 'helpers';

// THIS PROMO FIELD IS for packages
const PromoField = ({ pack, userid, email, credit }) => {
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const promocodeArray = [];
    const { color } = studioConfig;
    const { dibsStudioId } = config;
    const loginLink = `/login-new/${dibsStudioId}`;
    const colortouse = `#${color}`;
    const [promo, setPromo] = useState('');
    const [isApplyingPromo, setIsApplyingPromo] = useState(false);
    const [errorMsg, setErrorMsg] = useState(
        // 'This promo code does not exist. Please try again.',
        '',
    );
    const [hasError, setHasError] = useState(false);
    const [hasSuccess, setHasSuccess] = useState(false);
    const [promoCodeApplied, setPromoCodeApplied] = useState([]);
    const successMsg = 'Successfully applied promo code.';
    const setError = (msg) => {
        setHasSuccess(false);
        setErrorMsg(msg);
        setHasError(true);
        setTimeout(() => {
            setErrorMsg('');
            setHasError(false);
        }, 5000);
    };
    const processPromoCode = async () => {
        setIsApplyingPromo(true);
        // call the action to check promo code
        await verifyCode(promo, userid, pack, dibsStudioId).then(
            async (res) => {
                const {
                    promoExists,
                    isExpired,
                    restrictions,
                    product,
                    promoCodeInfo,
                } = res;
                if (!promoExists) {
                    setError(
                        'This promo code does not exist. Please try again.',
                    );
                    return;
                }
                if (product === 'CLASS' && pack.numClasses > 1) {
                    setError(
                        'This promo code can only be applied towards individual classes.',
                    );
                    return;
                }
                if (product === 'CLASS' && pack.private) {
                    setError(
                        'This promo code cannot be applied towards private packages.',
                    );
                    return;
                }
                if (product === 'RETAIL') {
                    setError(
                        'This promo code can only be applied towards retail products.',
                    );
                    return;
                }
                if (isExpired) {
                    setError('This promo code has expired.');
                    return;
                }
                if (restrictions && userid === 0) {
                    setErrorMsg(
                        'You must be logged in to use this promo code. Click here to login now.',
                    );
                    setHasError(true);
                    dispatch(setPageSelected('purchasingPackage'));
                    return;
                }
                console.log(
                    'promoCodeApplied right before check is:',
                    promoCodeApplied,
                );
                console.log('promocode id', promoCodeInfo.id);
                console.log(
                    'promoCodeApplie includes',
                    promoCodeApplied.includes(promoCodeInfo.id),
                );
                if (promoCodeApplied.includes(promoCodeInfo.id)) {
                    console.log('setting error msg');
                    setErrorMsg(
                        'You have already applied this promo code. Promo codes can only be applied once per purchase.',
                    );
                    setHasError(true);
                    return;
                }
                await applyPromoCode(
                    // promo,
                    userid,
                    pack,
                    dibsStudioId,
                    promoCodeInfo,
                    email,
                ).then(async (applied) => {
                    console.log('applied is:', applied);
                    if (applied) {
                        if (!applied.eligible) {
                            setError(applied.reason);
                            return;
                        } else {
                            const { newpackage } = applied;
                            const { price, tax, totalprice } = newpackage;
                            await calculatecredit(credit, totalprice).then(
                                (cred) => {
                                    console.log('res from calculate', cred);
                                    const values = {
                                        packprice: price,
                                        packtax: tax,
                                        packcredit: cred,
                                        packtotal: totalprice,
                                    };
                                    dispatch(setDiscountedPackageInfo(values));
                                },
                            );
                        }
                    }
                    setHasError(false);
                    setHasSuccess(true);
                    console.log('pushing value into there');
                    promocodeArray.push(promoCodeInfo.id);
                    setPromoCodeApplied(promocodeArray);
                    console.log('promoCodeApplied is:', promoCodeApplied);
                    setPromo('');
                    setTimeout(() => {
                        setHasSuccess(false);
                    }, 5000);
                    setIsApplyingPromo(false);
                });
            },
        );
        return null;
    };
    useEffect(() => {
        return function cleanup() {
            setIsApplyingPromo(false);
        };
    });
    const changeValue = (e) => {
        setPromo(e.target.value);
    };
    if (isApplyingPromo) {
        return (
            <Grid container sx={{ my: 2 }}>
                <LinearProgress />
            </Grid>
        );
    }
    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            display="flex"
            maxWidth={240}
            // wordWrap="break-word"
            border={0}
            sx={{
                p: 0,
                m: 0,
                lineHeight: '1.2',
            }}>
            <Stack sx={{ minWidth: 0 }}>
                {hasError && (
                    <Fade in={hasError} timeout={500}>
                        <Link
                            underline="none"
                            href={loginLink}
                            sx={{
                                textDecorationStyle: 'none',
                                cursor: 'pointer',
                            }}>
                            <Grid item xs={12} border={0} sx={{ mx: 2 }}>
                                <Typography
                                    variant="errorText"
                                    textAlign="center"
                                    noWrap={false}>
                                    {errorMsg}
                                </Typography>
                            </Grid>
                        </Link>
                    </Fade>
                )}
                {hasSuccess && (
                    <Fade in={hasSuccess} timeout={500}>
                        <Grid item xs={12} border={0} sx={{ mx: 2 }}>
                            <Typography
                                variant="successText"
                                textAlign="center"
                                noWrap={false}>
                                {successMsg}
                            </Typography>
                        </Grid>
                    </Fade>
                )}
                <Fade in timeout={500}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <TextField
                                    id="promo-code-field"
                                    variant="standard"
                                    placeholder="Promo Code"
                                    margin="dense"
                                    value={promo}
                                    onChange={changeValue}
                                    fullWidth
                                    sx={{ pl: 1 }}
                                    inputProps={{
                                        style: {
                                            padding: 5,
                                            paddingBottom: 0,
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <Grid
                                    container
                                    alignItems="center"
                                    height="100%"
                                    border={0}
                                    sx={{
                                        mt: 1,
                                        ml: 0.5,
                                        mb: 1,
                                        pb: 0.5,
                                        cursor: 'pointer',
                                    }}
                                    onClick={processPromoCode}>
                                    <Typography variant="h4" color={colortouse}>
                                        Add
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Fade>
            </Stack>
        </Grid>
    );
};
export default PromoField;
