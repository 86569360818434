import axios from 'axios';
import getCreditToApply from './getCreditToApplyPackage';
import getStripeUserInfo from 'actions/stripe/getStripeUserInfo';

import { getApiUrl } from 'helpers';

const getPlatform = (paymentOptions, cardid) => {
    const paymentInfo = paymentOptions.find((option) => option.id === cardid);
    const { platform } = paymentInfo;

    return platform;
};

export default async function purchasePassFromCheckoutPage(
    userid,
    packageinfo,
    credit,
    cardtocharge,
    paymentOptionsAll,
    isUsingCreditToCoverPurchase,
) {
    const url = getApiUrl('/checkout-package-using-credit');
    const { price, taxrate } = packageinfo;
    let statustoreturn = 0;
    if (credit > 0 && isUsingCreditToCoverPurchase) {
        const creditToApply = await getCreditToApply(price, taxrate, credit);
        const { creditCovers } = creditToApply;
        if (creditCovers) {
            await axios
                .post(url, {
                    userid,
                    packageinfo,
                    creditToApply,
                    locationPurchased: 'widget',
                })
                .then((res) => {
                    const { status } = res.data;
                    statustoreturn = status;
                    return statustoreturn;
                });
        } else {
            console.log(
                'credit does not cover completely - so need to make a partial charge',
            );
            // will need to handle case where it is partially covered by credit
            return 3;
        }
    } else {
        // charging the full amount to the card

        const stripeUserInfo = await getStripeUserInfo(
            packageinfo.dibsStudioId,
            userid,
        );
        const { dibsStripeId, studioStripeId } = stripeUserInfo;
        const platform = getPlatform(paymentOptionsAll, cardtocharge);
        const urlcc = getApiUrl('/checkout-package-credit-card');
        if (studioStripeId || dibsStripeId) {
            await axios
                .post(urlcc, {
                    userid,
                    packageinfo,
                    locationPurchased: 'widget',
                    platform,
                    cardtocharge,
                    dibsStripeId,
                    studioStripeId,
                    chargetotal: packageinfo.packagepriceatcheckout
                })
                .then((res) => {

                    const { status } = res.data;
                    statustoreturn = status;
                    return statustoreturn;
                })
                .catch((err) => {
                    console.log('there was an error charging the card', err);
                    statustoreturn = 3;
                    return statustoreturn;
                });
        } else {
            console.log('there is no stripe id - we have to do something else');
            statustoreturn = 3;
        }
    }
    return statustoreturn;
}
