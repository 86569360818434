import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Grid, Button } from '@mui/material';
import CheckoutButton from 'components/checkout/buttons/checkout';
import { useSelector, dispatch } from 'store';

import { setCartCompletedIsReadyToShow, setIsProcessingCreditCardNow, setShowAllExistingPaymentOptions, setShowCCCapture, setShowSummaryPaymentScreen } from 'store/slices/cart';
import startDibsTransactionCheckout from 'actions/checkout/startDibsTransactionsCheckout';
import cancelPaymentIntent from 'actions/stripe/intents/cancelPaymentIntent';

import { formatDollarAmountTwoDigits } from 'helpers';

import setError from 'actions/status/setAlert';


// import './../stripe.css';
import { setRefreshPaymentOptions } from 'store/slices/thisuser';

const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const { total, cartPreparedForCheckout } = useSelector((state) => state.cart);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { userid } = useSelector((state) => state.thisuser);
    const [isLoading, setIsLoading] = useState(false);
    const options = {
        layout: 'tabs',
        business: {
            name: 'BUSINESS GOES HERE'
        },
        terms: {
            card: 'never'
        },
        // fields: {
        //     billingDetails: {
        //         address: {
        //             country: 'never',
        //             postalCode: 'never',
        //         }
        //     }
        // }
    }

    if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        const loading = (
            <Grid>Loading...</Grid>
        )
        return loading;
    }

    const processCcInfo = async (event) => {
        console.log('setting up the payment intent');
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        setIsLoading(true);
        const hostname = window.location.host;
        let http = 'https://';
        if (hostname.includes('localhost')) http = 'http://';
        const urltosend = `${http}${hostname}/checkout/confirm/${dibsStudioId}`;

        // here - create the dibs transactions starting point
        const checkoutuuid = await startDibsTransactionCheckout(cartPreparedForCheckout, userid);
        console.log('checkout UUID is', checkoutuuid);
        const { uuid, errorreturned } = checkoutuuid;
        if (uuid) {
            window.localStorage.setItem('checkoutuuid', uuid);
        } else {
            console.log('there was an error starting the dibs transaction', errorreturned);
        }
        dispatch(setCartCompletedIsReadyToShow(false));

        await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: urltosend,
            },
            redirect: 'if_required',
        })
            .then(async (cp) => {
                console.log(
                    '\n\n\n\nconfirm payment result is - CHECK FOR SECRET',
                    cp,
                );
                dispatch(setRefreshPaymentOptions(true));
                // const { setupIntent } = cs;
                // const { status } = setupIntent;
                // if (status === 'succeeded') {
                //     console.log('success capturing this credit card');
                //     dispatch(setShowCCCapture(false));
                //     dispatch(setShowAllExistingPaymentOptions(true));
                //     console.log(
                //         'the setupintent is - do we have the card info????',
                //         setupIntent,
                //     );
                //     const { payment_method } = setupIntent;
                //     console.log('payment method returned is', payment_method);
                //     dispatch(setRefreshPaymentOptions(true));
                //     dispatch(setCardToCharge(payment_method));
                //     console.log(
                //         'setupIntent - need to set the card last 4, brand, and expy here',
                //         setupIntent,
                //     );
                //     reSortPaymentOptions(paymentOptionsAll, payment_method);
                //     dispatch(setIsProcessing(false));
                // }
            })
            .catch((err) => {
                console.log('error is', err);
                if (
                    err.type === 'card_error' ||
                    err.type === 'validation_error'
                ) {
                    setMessage(err.message);
                    setError({
                        msg: err.message,
                        seconds: 5,
                        error: true,
                    });
                }
            });
        // const { error } = confirmpayment;
        // if (error.type === "card_error" || error.type === "validation_error") {
        //     setMessage(error.message);
        // } else {
        //     setMessage("An unexpected error occurred.");
        // }
        setIsLoading(false);
    };
    const onCancel = async () => {
        console.log('canceled this action --> Add functionality to cancel stripe payment intent');
        dispatch(setShowCCCapture(false));
        const intenttocancel = window.localStorage.getItem('clientSecret');

        window.localStorage.setItem('clientSecretDate', new Date().toISOString());
        const onDibs = false; // whether the intent was made on dibs or the studio website
        await cancelPaymentIntent(intenttocancel, onDibs).then((res) => {
            console.log('payment intent was canceled');
            dispatch(setShowSummaryPaymentScreen(true));
            dispatch(setShowAllExistingPaymentOptions(false));
        })
            .catch((err) => {
                console.log('error canceling the payment intent', err);
            });
    }
    const dollarAmount = formatDollarAmountTwoDigits(total);
    return (
        <form>
            <PaymentElement type="payment" options={options} />
            <CheckoutButton buttonText={`Pay ${dollarAmount} Now`} clickAction={processCcInfo} isBuying={isLoading} pl={0} showConfirmButton={true} />
            <Grid container justifyContent="flex-end">
                <Button sx={{ color: '#7e92a3' }} onClick={() => onCancel()}>Cancel</Button>
            </Grid>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
};

export default CheckoutForm;