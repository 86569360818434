import React from 'react';
import {
    useNavigate,
    useParams,
    useSearchParams,
    useLocation,
} from 'react-router-dom';
import {
    auth,
    sendEmailAuthLink,
    verifyUserClickedEmailLink,
} from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
// import { lowercaseString } from 'helpers';
// import setLastLogin from 'actions/setLastLogin';
import { gridSpacing } from 'store/constant';
import LinearProgress from 'assets/LinearProgress';

import { dispatch } from 'store';
import { setIsInLoginLinkFlow, setIsLoggedIn } from 'store/slices/thisuser';

// material-ui
import {
    Grid,
    Button,
    // Divider,
    // Grid,
    InputLabel,
    Typography,
    TextField,
    Link,
    // useMediaQuery,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector } from 'store';

// project imports
import SubCard from 'ui-component/cards/AccountSubCard';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

// import Google from 'assets/images/icons/social-google.svg';
// import { addStudioData } from 'store/slices/dibsstudio';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Your email address looks incorrect. Please try again')
        .required('Required'),
});

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginAuthenticationCard = () => {
    const {
        lastLocation,
        hasSignedInFirebase,
        hasSetPwdFirebase,
        recentlyLoggedIn,
        isInLoginLinkFlow,
        email,
    } = useSelector((state) => state.thisuser);
    const { dibsId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [user, loading] = useAuthState(auth);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [isVerifying, setIsVerifying] = React.useState(false);
    const [searchParams] = useSearchParams();
    // if logged in, take them to the page they were on
    React.useEffect(() => {
        const userJustClicked = searchParams.get('mode') === 'signIn';
        if (user) {
            console.log('logging in line 72 nopwd - firebase');
            dispatch(setIsLoggedIn(true));
        }
        const verifyEmailLink = async () => {
            dispatch(setIsInLoginLinkFlow(false));
            setIsVerifying(true);
            await verifyUserClickedEmailLink(email).then((res) => {
                if (res === 1) {
                    // then redirect the user here
                    if (!hasSetPwdFirebase) {
                        navigate(`/account/${dibsId}${location.search}`, {
                            replace: false,
                        });
                    }
                    if (lastLocation.length > 2) {
                        console.log(
                            'would send user to lastLocation --> ',
                            lastLocation,
                        );
                        navigate(lastLocation, { replace: false });
                    }
                    // if no other conditions, send user to the schedule page
                    console.log('would send to schedule page');
                    navigate(`/schedule/${dibsId}${location.search}`, {
                        replace: false,
                    });
                } else if (res === 0) {
                    console.log('error verifying email link');
                    setShowError(true);
                }
                setIsVerifying(false);
            });
        };
        // user just clicked on email link
        if (userJustClicked && !user && isInLoginLinkFlow && !loading) {
            console.log(`case 1`);
            verifyEmailLink();
        }
        // redirect user that just clicked on the email link w/ no password set
        if (user && !isInLoginLinkFlow && !loading && !isVerifying) {
            console.log(`case 2`);
            console.log(`recenly logged in: ${recentlyLoggedIn}`);
            console.log(`has set pwd firebase: ${hasSetPwdFirebase}`);
            if (!hasSetPwdFirebase && recentlyLoggedIn) {
                console.log(`case 3 - sending to account page`);
                navigate(`/account/${dibsId}${location.search}`, {
                    replace: false,
                });
            } else if (lastLocation.length > 2) {
                console.log(`case 4 - sending to last location`);
                navigate(lastLocation, { replace: false });
            } else {
                console.log(`case 5 - sending to schedule`);
                navigate(`/schedule/${dibsId}`, { replace: false });
            }
        }
    }, [
        user,
        location,
        loading,
        navigate,
        lastLocation,
        dibsId,
        hasSignedInFirebase,
        hasSetPwdFirebase,
        recentlyLoggedIn,
        isInLoginLinkFlow,
        email,
        searchParams,
        isVerifying,
    ]);

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                dispatch(setIsInLoginLinkFlow(false));
                await sendEmailAuthLink(
                    values.email,
                    dibsId,
                    lastLocation,
                ).then((res) => {
                    if (res) {
                        dispatch(setIsInLoginLinkFlow(true));
                        setShowSuccess(true);
                    }
                });
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    if (showSuccess) {
        const scheduleLink = `/schedule/${dibsId}`;
        return (
            <Grid container justifyContent="center">
                <Grid item xs={10} sx={{ mb: 5 }}>
                    <SubCard title="Sign In">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography variant="regularText">
                                    An authentication link has been sent to your
                                    email address. It will be sent from Dibs,
                                    our software provider. Please check your
                                    email and click the link to login.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 2 }}>
                                <Typography variant="regularText">
                                    If you don't see the email, please check
                                    your spam folder.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <Link href={scheduleLink}>
                                    Click here to see the schedule
                                </Link>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        );
    }
    if (showError) {
        const emailLink = `/login-email-link/${dibsId}`;
        return (
            <Grid container justifyContent="center">
                <Grid item xs={10} sx={{ mb: 5 }}>
                    <SubCard title="Sign In">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography variant="regularText">
                                    We could not verify your email address using
                                    the link. Please click on the link below to
                                    try again.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <Link href={emailLink}>Try again</Link>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        );
    }
    if (isVerifying) {
        return (
            <Grid container justifyContent="center">
                <Grid item xs={10} md={6} sx={{ mb: 5 }}>
                    <SubCard title="Sign In">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography variant="regularText">
                                    One moment while we verify your login
                                    information...
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
                                <LinearProgress />
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={7} sx={{ mb: 5 }}>
                <SubCard title="Sign In">
                    <Grid
                        container
                        spacing={gridSpacing}
                        justifyContent="flex-start">
                        <ErrorMessage />
                        <Grid item xs={12} id="auth-login-no-pwd">
                            <Typography variant="regularText">
                                Enter your email address to sign in
                            </Typography>
                            <form
                                className="form-profile"
                                onSubmit={formik.handleSubmit}>
                                <Grid container justifyContent="flex-start">
                                    <Grid
                                        item
                                        xs={10}
                                        md={9}
                                        sx={{ mt: 3, ml: 1 }}>
                                        <Grid
                                            container
                                            spacing={1}
                                            justifyContent="center">
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ mt: 0, mr: 1 }}>
                                                <InputLabel>Email</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.email &&
                                                        Boolean(
                                                            formik.errors.email,
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched.email &&
                                                        formik.errors.email
                                                    }
                                                    sx={{ mt: 1 }}>
                                                    Email
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 4, mb: 4, ml: 1 }}>
                                        <Grid
                                            container
                                            spacing={0}
                                            justifyContent="flex-start">
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{ mr: 2, mt: 1 }}>
                                                <Button
                                                    variant="checkout"
                                                    type="submit"
                                                    fullWidth
                                                    disabled={
                                                        formik.isSubmitting
                                                    }>
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default LoginAuthenticationCard;
