import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@mui/material';
import PackagesLayout from 'layouts/packages';

import { dispatch } from 'store';
import { setPackages } from 'store/slices/packages';
import { setLastLocation } from 'store/slices/thisuser';

const PackagesPageView = () => {
    let { dibsId } = useParams();
    const location = useLocation();
    const [doneLoading, setDoneLoading] = React.useState(false);
    const baseURL = process.env.REACT_APP_BASE_URL;
    React.useEffect(() => {
        const bodytosend = {
            dibsStudioId: dibsId,
        };
        dispatch(setLastLocation(location.pathname));
        const url = `${baseURL}/widget/get-packages`;
        const getPackages = async () => {
            await axios
                .post(url, bodytosend)
                .then((response) => {
                    dispatch(setPackages(response.data));
                    setDoneLoading(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        if (dibsId && !doneLoading) {
            getPackages();
        }
    }, [baseURL, dibsId, doneLoading, location.pathname]);
    const loadingDisplay = (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item>Loading...</Grid>
        </Grid>
    );
    const valuetouse = doneLoading ? <PackagesLayout /> : loadingDisplay;
    return valuetouse;
};

export default PackagesPageView;
