import * as React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
    fontSize: '1.3rem', // Set your desired font size here
    fontWeight: '300', // Optional: make the text bold
    lineHeight: '1.6', // Optional: adjust line height
    color: theme.palette.text.primary, // Optional: ensure it uses primary text color
}));

function SimpleDialog(props) {
    const { onClose, selectedValue, open, displayText } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <CustomDialogTitle>{displayText}</CustomDialogTitle>
        </Dialog>
    );
}

export default function GeneralAlert({ isOpen, displayText }) {
    return (
        <div>
            <SimpleDialog open={isOpen} displayText={displayText} />
        </div>
    );
}
