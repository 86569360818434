import { dispatch } from 'store';
import { setAlreadyHasAccount } from 'store/slices/thisuser';
import doesUserHaveAccount from 'actions/doesUserHaveAccount';

export const checkAccountDibs = async (email, dibsId) => {
    let thisuserhasaccount = false;
    const resFromDibs = await doesUserHaveAccount(email, dibsId);
    console.log(
        'total resonse after checking accountf rom dibs is',
        resFromDibs,
    );
    const { hasAccount } = resFromDibs;
    if (hasAccount) {
        thisuserhasaccount = true;
        dispatch(setAlreadyHasAccount(true));
    }
    return { hasAccount: thisuserhasaccount };
};

export default checkAccountDibs;
