import { Grid, Fade } from '@mui/material';

import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector } from 'store';
import { gridSpacing } from 'store/constant';

import SubCard from 'ui-component/cards/AccountSubCard';
import Checkboxes from 'ui-component/Checkboxes';
import { updateCommunicationPreferences } from 'store/slices/thisuser';
import updateCommunication from 'actions/users/updateCommunicationPreferences';




const getCommunicationLabels = (emailMarketing, smsMarketing, smsTransactions) => {
    const communicationLabels = [
        {
            key: 'email_marketing',
            id: 'email_marketing',
            label: 'Email Marketing',
            checked: emailMarketing,
        },
        {
            key: 'sms_transactions',
            id: 'sms_transactions',
            label: 'SMS Transactions',
            checked: smsTransactions,
        },
        {
            key: 'sms_marketing',
            id: 'sms_marketing',
            label: 'SMS Marketing',
            checked: smsMarketing,
        },
    ];
    return communicationLabels;

};

// ==============================|| ACCOUNT PROFILE COMMUNICATION ||============================== //

const AccountProfileCommunication = () => {

    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const { emailMarketing, smsMarketing, smsTransactions, userid } = useSelector((state) => state.thisuser);

    useEffect(() => {
        updateCommunication(userid, emailMarketing, smsMarketing, smsTransactions, dibsStudioId);
    }, [userid, emailMarketing, smsMarketing, smsTransactions, dibsStudioId]);


    const communicationLabels = getCommunicationLabels(emailMarketing, smsMarketing, smsTransactions);

    const communicationTitle = <FormattedMessage id="communication-preferences" />;
    return (
        <Fade in timeout={700}>
            <Grid
                item
                xs={12}
                md={10.3}
                sx={{ mb: 2, mr: window.isTiny ? 0 : 3 }}>
                <SubCard
                    id="account-communication-preferences"
                    title={communicationTitle}
                    dibsstudioid={dibsStudioId}>
                    <Grid container spacing={gridSpacing}>

                        <Grid item xs={12}>
                            <Grid container>
                                <Checkboxes labels={communicationLabels} typographyVariant="eventname" marginR={window.isTiny ? 3 : 6} functionDispatch={updateCommunicationPreferences} />
                            </Grid>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Fade>
    );
};
export default AccountProfileCommunication;
