import React from 'react';
import {
    useParams,
    // Link,
} from 'react-router-dom';

// import { lowercaseString } from 'helpers';
// import setLastLogin from 'actions/setLastLogin';
import { gridSpacing } from 'store/constant';
import validatePassword from 'actions/users/validatePassword';
import isUserNew from 'actions/isUserNew';
import checkAccountDibs from 'helpers/accountManagement';

import { dispatch } from 'store';
import {
    setIsLoggedIn,
    setIsNewUser,
    setIsRegistering,
    setUserQualifiesPromos,
} from 'store/slices/thisuser';

import {
    registerWithEmailAndPassword,
    logInWithEmailAndPassword,
} from 'firebaseConfig';

// material-ui
import {
    Grid,
    Button,
    // Divider,
    // Grid,
    InputLabel,
    Typography,
    TextField,
    Link,
    Fade,
    // useMediaQuery,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector } from 'store';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessageNew';
import { setErrorMessageReducer, setHasErrorReducer } from 'store/slices/error';
import setFirebaseAuthenticationPwd from 'actions/setFirebaseAuthenticationPwd';

import setFirebaseAuthentication from 'actions/setFirebaseAuthentication';
import { addUserInfoAfterLogin } from 'actions/updateUserProfileInStore';

const validationSchema = yup.object().shape({
    password: yup
        .string()
        .required('Password is required')
        .min(
            7,
            'Your password must be at least 8 characters. Please try again.',
        ),
});

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginAuthenticationCardPassword = ({ setCurrentStep, emailAddy }) => {
    const { dibsId } = useParams();
    const { encryptedpassword, hasSetPwdFirebase, userid } = useSelector(
        (state) => state.thisuser,
    );
    const [mdsize, setMdSize] = React.useState(5);
    const { config } = useSelector((state) => state.dibsstudio);
    const { widgetOpenStyle } = config;
    React.useEffect(() => {
        if (widgetOpenStyle === 'right' || widgetOpenStyle === 'left') {
            setMdSize(8);
        } else {
            setMdSize(5);
        }
    }, [widgetOpenStyle]);

    const validatePasswordDb = async (password, encryptedpassword) => {
        return await validatePassword(
            emailAddy,
            password,
            encryptedpassword,
        ).then((res) => {
            if (res) {
                // dispatch(setIsLoggedIn(true));
                return res;
            } else {
                console.log('password is BAD AND incorrect');
                return false;
            }
        });
    };
    const formik = useFormik({
        initialValues: {
            password: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                if (!hasSetPwdFirebase) {
                    await validatePasswordDb(
                        values.password,
                        encryptedpassword,
                    ).then(async (res) => {
                        const { passwordsMatch, firebaseAuthenticated } = res;
                        // console.log('firebaseAuthenticated is', firebaseAuthenticated);
                        if (firebaseAuthenticated) {
                            // console.log(
                            //     'firebase is authenticated is verified - logging user in via firebase',
                            // );
                            await logInWithEmailAndPassword(
                                emailAddy,
                                values.password,
                                dibsId,
                            ).then(async (response) => {
                                console.log(
                                    'response from firebase is',
                                    response,
                                );
                            });
                        } else if (passwordsMatch) {
                            // firebase - register with email and password
                            console.log('email that is here is', emailAddy);
                            await registerWithEmailAndPassword(
                                emailAddy,
                                values.password,
                            ).then(async (response) => {
                                console.log(
                                    'response from register with email and password is:',
                                    response,
                                );
                                if (response === 1) {
                                    setFirebaseAuthenticationPwd(userid);
                                    setCurrentStep('loggedin');
                                    dispatch(setIsLoggedIn(true));
                                    dispatch(setIsRegistering(false));
                                    // isUserNew
                                    // isUserNew(email, dibsId);
                                    // updateUserProfile
                                    setFirebaseAuthentication(userid);
                                    addUserInfoAfterLogin(emailAddy, dibsId);
                                } else if (response === 2) {
                                    await logInWithEmailAndPassword(
                                        emailAddy,
                                        values.password,
                                        dibsId,
                                    ).then((response) => {
                                        // if login is verified
                                        if (response) {
                                            const { uid } = response;
                                            if (uid) {
                                                setCurrentStep('loggedin');
                                                dispatch(setIsLoggedIn(true));
                                                dispatch(
                                                    setIsRegistering(false),
                                                );
                                                // isUserNew
                                                // isUserNew(email, dibsId);
                                                // updateUserProfile
                                                const setNew = async () => {
                                                    console.log(
                                                        'being called after password',
                                                    );
                                                    await isUserNew(
                                                        emailAddy,
                                                        dibsId,
                                                    ).then((res) => {
                                                        if (res) {
                                                            const { isNew } =
                                                                res;
                                                            dispatch(
                                                                setIsNewUser(
                                                                    isNew,
                                                                ),
                                                            );
                                                            dispatch(
                                                                setUserQualifiesPromos(
                                                                    isNew,
                                                                ),
                                                            );
                                                        }
                                                    });
                                                };
                                                setNew();
                                                setFirebaseAuthentication(
                                                    userid,
                                                );
                                                setFirebaseAuthenticationPwd(
                                                    userid,
                                                );
                                                setCurrentStep('loggedin');
                                                dispatch(setIsLoggedIn(true));
                                                setFirebaseAuthenticationPwd(
                                                    userid,
                                                );
                                                addUserInfoAfterLogin(
                                                    emailAddy,
                                                    dibsId,
                                                );
                                            }
                                        }
                                    });

                                    // isUserNew
                                    // isUserNew(email, dibsId);
                                    // updateUserProfile
                                    setFirebaseAuthentication(userid);
                                    addUserInfoAfterLogin(emailAddy, dibsId);
                                }
                                await isUserNew(emailAddy, dibsId).then(
                                    (res) => {
                                        if (res) {
                                            const { isNew } = res;

                                            dispatch(setIsNewUser(isNew));
                                            dispatch(
                                                setUserQualifiesPromos(isNew),
                                            );
                                            dispatch(setIsRegistering(false));
                                        }
                                    },
                                );
                            });
                        } else {
                            // say message that password is incorrect
                            dispatch(setHasErrorReducer(true));
                            dispatch(
                                setErrorMessageReducer(
                                    'Your password cannot be authenticated. Please try again or click the link below to reset your password.',
                                ),
                            );
                            setTimeout(() => {
                                dispatch(setHasErrorReducer(false));
                                dispatch(setErrorMessageReducer(''));
                            }, 3000);
                        }
                    });
                } else {
                    // skip to validating password in firebase
                    await logInWithEmailAndPassword(
                        emailAddy,
                        values.password,
                        dibsId,
                    ).then(async (response) => {
                        // if login is verified
                        console.log('response from logging in is', response);
                        if (response) {
                            const { uid } = response;
                            if (uid) {
                                // confirm that they have an account on dibs
                                await checkAccountDibs(emailAddy, dibsId).then(
                                    (res) => {
                                        console.log(
                                            '\n\n\nresponse from checking accountstatus for dibs is',
                                            res,
                                        );
                                        const { hasAccount } = res;
                                        // if the user already has an account, then log them in on Dibs
                                        if (hasAccount) {
                                            setCurrentStep('loggedin');
                                            dispatch(setIsLoggedIn(true));
                                            console.log(
                                                'ADDUSERINFO - line 263 updating user in store',
                                            );
                                            addUserInfoAfterLogin(
                                                emailAddy,
                                                dibsId,
                                            );
                                        }
                                    },
                                );
                                // isUserNew
                                // isUserNew(emailAddy, dibsId);
                                // updateUserProfile
                                const setNew = async () => {
                                    await isUserNew(emailAddy, dibsId).then(
                                        (res) => {
                                            if (res) {
                                                const { isNew } = res;
                                                dispatch(setIsNewUser(isNew));
                                                dispatch(
                                                    setUserQualifiesPromos(
                                                        isNew,
                                                    ),
                                                );
                                            }
                                        },
                                    );
                                };
                                setNew();
                                setFirebaseAuthentication(userid);
                            }
                        }
                    });
                }
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    const setForgotPassword = () => {
        setCurrentStep('forgotpassword');
    };
    const goBackToEmailStep = () => {
        setCurrentStep('email');
    };
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={mdsize} xl={4} sx={{ mb: 5 }}>
                <Fade in={true} timeout={500}>
                    <LoginCard title="Sign In" dibsstudioid={dibsId}>
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ ml: 1 }}>
                                <Typography variant="regularText">
                                    Enter your password to continue
                                </Typography>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}>
                                    <Grid container justifyContent="flex-start">
                                        <Grid
                                            item
                                            xs={10}
                                            md={9}
                                            sx={{ mt: 2, ml: 0.5 }}>
                                            <InputLabel>Password</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="password"
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={
                                                    formik.touched.password &&
                                                    Boolean(
                                                        formik.errors.password,
                                                    )
                                                }
                                                type="password"
                                                helperText={
                                                    formik.touched.password &&
                                                    formik.errors.password
                                                }
                                                sx={{ mt: 1 }}>
                                                Password
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={{ mt: 4, mb: 2.5 }}>
                                            <Button
                                                variant="checkout"
                                                type="submit"
                                                // fullWidth
                                                disabled={formik.isSubmitting}>
                                                Submit
                                            </Button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{ ml: 1, mt: 1 }}>
                                            <Grid
                                                container
                                                justifyContent="space-between">
                                                <Grid item xs={12} md={7}>
                                                    <Link
                                                        onClick={() =>
                                                            setForgotPassword()
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}>
                                                        <Typography
                                                            variant="guidance"
                                                            sx={{
                                                                fontStyle:
                                                                    'italic',
                                                            }}>
                                                            Forgot your
                                                            password? Reset it
                                                            now.
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <Link
                                                        onClick={() =>
                                                            goBackToEmailStep()
                                                        }
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}>
                                                        <Typography
                                                            variant="guidance"
                                                            sx={{
                                                                fontStyle:
                                                                    'italic',
                                                            }}>
                                                            Back to email
                                                        </Typography>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};

export default LoginAuthenticationCardPassword;
