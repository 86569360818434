import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import { dispatch } from 'store';
import { setPageSelected } from 'store/slices/menu';
import { clearPurchasingPackage } from 'store/slices/cart';

const PackagesIcon = () => {
    let { dibsId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const goPackages = () => {
        dispatch(clearPurchasingPackage());
        dispatch(setPageSelected('packages'));
        navigate(`/packages/${dibsId}${location.search}`);
    };
    return (
        <Grid item onClick={() => goPackages()}>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon="fa-circle fa-4x" transform="grow-10" />
                <FontAwesomeIcon
                    icon="fa-light fa-bag-shopping"
                    color="#fff"
                    // transform="shrink-7"
                />
            </span>
        </Grid>
    );
};
export default PackagesIcon;
