import * as React from 'react';
import { Grid } from '@mui/material';

// component imports
import CheckoutList from 'components/checkout/index';

const CheckoutLayout = ({ dibsId }) => {
    return (
        <Grid
            container
            spacing={3}
            id="checkout-layout-container"
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid item xs={11.5} md={11} lg={11} xl={10.5}>
                <Grid container sx={{ mt: 1 }}>
                    {/* <Header /> */}
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Grid
                            container
                            direction="row"
                            id="checkoutlist-container-highest-one"
                            justifyContent="center" // was flex-start
                            alignItems="center">
                            <Grid
                                item
                                xs={window.isTiny ? 11.5 : 11}
                                sx={{ mr: window.isTiny ? 0.5 : 0, mt: 3 }}>
                                <CheckoutList dibsId={dibsId} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CheckoutLayout;
