import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';

export default function CircularIndeterminate({ color, size }) {

    return (
        <Box
            justifyContent="center"
            alignItems="center"
            sx={{ display: 'flex', width: '85%', height: '50%' }}>
            <Grid item>
                <CircularProgress
                    size={size}
                    sx={{ height: '80%', color: color }}
                />
            </Grid>
        </Box>
    );
}
