import { useEffect, useState } from 'react';
import { Grid, Typography, Button, Stack } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSelector, dispatch } from 'store';
import { setUpcomingClasses, setPastClasses } from 'store/slices/thisuser';

import { setPageSelected } from 'store/slices/menu';
import getUpcomingClassesAction from 'actions/getUpcomingClasses';

const PurchaseCompleted = ({ dibsId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const { userid } = useSelector((state) => state.thisuser);
    const colortouse = `#${color}`;
    const goBackSchedule = () => {
        dispatch(setPageSelected('schedule'));
        navigate(`/schedule/${dibsId}${location.search}`);
    };
    // TO DO update the class schedule here
    useEffect(() => {
        // get payments
        const getUpcomingClasses = async () => {
            await getUpcomingClassesAction(dibsId, userid)
                .then((res) => {
                    dispatch(setUpcomingClasses(res.futureClasses));
                    dispatch(setPastClasses(res.pastClasses));
                })
                .catch((err) => {
                    console.log(
                        `error getting upcoming classes - purchaseCompleted: ${err}`,
                    );
                });
        };
        getUpcomingClasses();
    }, [dibsId, userid]);
    return (
        <Grid container justifyContent="center">
            <Stack justifyContent="center">
                <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h3">
                        Thank you for your purchase. If you don't see your
                        purchase reflected in your account, please refresh your
                        screen.
                    </Typography>
                </Grid>
                <Grid item sx={{ mt: 4, mb: 5 }}>
                    <Button
                        // component={Link}
                        // to={backtoScheduleUrl}
                        onClick={() => goBackSchedule()}
                        variant="text"
                        sx={{
                            color: colortouse,
                        }}>
                        Go back to the schedule
                    </Button>
                </Grid>
            </Stack>
        </Grid>
    );
};
export default PurchaseCompleted;
