import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Fade } from '@mui/material';

const SquareIcon = ({ color, size, typeOfIcon, id }) => {
    const colortoshow = `#${color}`;
    const [growtext, setGrowtext] = useState('grow-2');
    useEffect(() => {
        if (size === 'small') setGrowtext('shrink-2');
    }, [size]);
    if (typeOfIcon === 'bad') {
        return (
            <Fade in>
                <Grid item id={`check-icon-${id}`}>
                    <FontAwesomeIcon icon="fa-regular fa-square-xmark" color={colortoshow}
                        transform={growtext} />
                </Grid>
            </Fade>
        );

    }
    return (
        <Fade in>
            <Grid item id="check-icon">
                <FontAwesomeIcon icon="fa-solid fa-square-check" color={colortoshow}
                    checked={false} transform={growtext} />
            </Grid>
        </Fade>
    );


};
export default SquareIcon;
