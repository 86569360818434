import axios from 'axios';

export default async function createStripeSubscription(
    userid,
    dibsStudioId,
    packageid,
    price,
    taxamount,
    pmid,
    subscriptionstartdate,
    passcreated,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/create-subscription`;
    let datatosend = {};
    const reqbody = {
        userid,
        dibsStudioId,
        packageid,
        price,
        taxamount,
        pmid,
        subscriptionstartdate,
        passcreated,
    };

    const subscription = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error from setting up stripe subscription: ${err}`,
                );
                return err;
            });
    };
    await subscription();
    return datatosend;
}
