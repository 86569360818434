// import { AddToCalendarButton } from 'add-to-calendar-button-react';
// import { getJustDate, getJustTime } from 'helpers/getTimezoneText';
import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import { Grid, Button } from '@mui/material';
import loadScripts, { updateEventToConfirm } from 'googleCalendarConfig';
import { correctTime } from 'helpers/correctForTimezone';
import { setAddedToCalendar } from 'store/slices/menu';
import useTranslate from 'hooks/useTranslate';

const CalendarButton = ({ appt, tz, dibsId, calendarurl }) => {
    const translate = useTranslate();
    const { addedtocalendar } = useSelector((state) => state.menu);
    const addToCalendarTxt = translate('add-to-calendar', 'Add to Calendar');
    const gotourl = () => {
        dispatch(setAddedToCalendar(true));
        window.open(calendarurl, '_blank');
    };
    const cursortouse = addedtocalendar ? 'not-allowed' : 'pointer';
    return (
        <Grid id="div-holding-add-to-calendar-button" sx={{ ml: 1 }}>
            <Button
                id="button-add-to-calendar"
                variant={addedtocalendar ? 'text' : 'selectProvider'}
                onClick={() => gotourl()}
                sx={{
                    cursor: cursortouse,
                    fontSize: '0.7rem',
                    fontWeight: '400',
                }}>
                {addToCalendarTxt}
            </Button>
        </Grid>
    );
};
export default CalendarButton;
