
import {
    setHasErrorReducer,
    setErrorMessageReducer,
    setHasSuccessReducer,
    setSuccessMessageReducer,
    setSecondsToShow,
    clearSuccessMessageReducer,
    clearErrorMessageReducer,
    setHasWarningReducer,
    setWarningMessageReducer,
    setHasInfoReducer,
    setInfoMessageReducer,
    setMessageToDisplay,
} from 'store/slices/error';

import { dispatch } from 'store';

const SuccessError = ({ success, msg, seconds, error, warning, info }) => {
    let secondstosend = seconds;
    if (!seconds) secondstosend = 5;
    dispatch(setMessageToDisplay(msg));
    if (success) {
        dispatch(setHasSuccessReducer(true));
        dispatch(setSuccessMessageReducer(msg));
    } else if (error) {
        dispatch(setErrorMessageReducer(msg));
        dispatch(setHasErrorReducer(true));
    } else if (warning) {
        dispatch(setWarningMessageReducer(msg));
        dispatch(setHasWarningReducer(true));
    } else if (info) {
        dispatch(setInfoMessageReducer(msg));
        dispatch(setHasInfoReducer(true));
    }
    dispatch(setSecondsToShow(secondstosend));
    setTimeout(() => {
        dispatch(clearSuccessMessageReducer());
        dispatch(clearErrorMessageReducer());
    }, secondstosend * 1000);
};

export default SuccessError;
