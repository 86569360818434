import { useIntl } from 'react-intl';

const useGetCurrentLanguage = () => {
    const intl = useIntl();
    const thislanguage = intl.locale;

    return thislanguage;
};

export default useGetCurrentLanguage;
