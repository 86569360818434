import React from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as yup from 'yup';

// material-ui
import { Button, Grid, Typography, TextField, InputLabel } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/AccountSubCard';
import { gridSpacing } from 'store/constant';
import { useSelector } from 'store';

import { getApiUrl } from 'helpers';

import FriendTable from './friend-referrals/friendTable';

import sendReferralEmail from 'actions/emails/refer-friend';
import MessageGrid from 'helpers/messages';
import { removeDupes } from 'helpers/arrayFunctions';

const validationSchema = yup.object().shape({
    friendName: yup
        .string()
        .min(2, "Your friend's name is too short. Please try again.")
        .max(90, "Your friend's name is too long. Please try again."),
    friendEmail: yup
        .string()
        .email(
            "Your friend's email address looks incorrect. Please check the email address and try again.",
        )
        .required('Required'),
});

// ==============================|| FRIEND REFERRAL ||============================== //

const friendsreferred = [
    {
        id: 1,
        firstName: '',
        email: '',
        referredUserId: null,
        updatedAt: new Date(),
        createdAt: new Date(),
        creditsAwarded: null,
    },
];

const Notifications = () => {
    const { dibsId } = useParams();
    const { userid } = useSelector((state) => state.thisuser);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [frienddata, setFriendData] = React.useState(friendsreferred);
    const [hasReferredFriends, setHasReferredFriends] = React.useState(true);
    const [status, setStatus] = React.useState(null);
    const [statusMsg, setStatusMsg] = React.useState(null);
    const [referralAmount, setReferralAmount] = React.useState(5);
    const friendsMessage = 'You have not referred any friends yet.';
    const referralText = `You will earn $${referralAmount} for each friend you refer.`;

    React.useEffect(() => {
        const getFriends = async () => {
            const apiUrl = getApiUrl('/get-friend-referrals');
            const tosend = {
                dibsStudioId: dibsId,
                userid,
            };
            const apiUrlAmount = getApiUrl('/get-referral-amount');
            const tosendAmount = { dibsStudioId: dibsId };
            await axios.post(apiUrlAmount, tosendAmount).then((res) => {
                const { data } = res;
                setReferralAmount(data);
            });
            await axios
                .post(apiUrl, tosend)
                .then((res) => {
                    const { data } = res;
                    setFriendData(data);
                    if (data.length === 0) {
                        setHasReferredFriends(false);
                    }
                    const deduped = removeDupes(data);
                    setFriendData(deduped);
                })
                .catch((err) => {
                    console.log(`error getting friends referred: ${err}`);
                    return err;
                });
        };
        getFriends();
    }, [dibsId, userid]);

    const referFriendSubmit = async (email, name) => {
        const friendEmail = email;
        const friendName = name;
        await sendReferralEmail(dibsId, userid, friendEmail, friendName).then(
            (res) => {
                try {
                    const { emailSent, msg } = res;
                    // const emailSent = false;
                    if (emailSent) {
                        setStatus('success');
                        setStatusMsg(
                            `${friendName} has been sent an email invitation.`,
                        );
                        setTimeout(() => {
                            setStatus(null);
                            setStatusMsg(null);
                        }, 8000);
                    } else if (msg === 'already-referred') {
                        setStatus('error');
                        setStatusMsg(
                            `You entered ${friendEmail}. Your friend already has an account here.`,
                        );
                        setTimeout(() => {
                            setStatus(null);
                            setStatusMsg(null);
                        }, 8000);
                    } else {
                        setStatus('error');
                        setStatusMsg(
                            `There was an error sending the email to ${friendEmail}. Please check the email address and try again.`,
                        );
                        setTimeout(() => {
                            setStatus(null);
                            setStatusMsg(null);
                        }, 8000);
                    }
                } catch (err) {
                    console.log(`error sending email: ${err}`);
                    setStatus('error');
                    setStatusMsg(
                        `There was an error sending the email to ${friendEmail}. Please check the email address and try again.`,
                    );
                    setTimeout(() => {
                        setStatus(null);
                        setStatusMsg(null);
                    }, 8000);
                }
            },
        );
    };

    const formik = useFormik({
        initialValues: {
            friendName: '',
            friendEmail: '',
        },
        validationSchema,
        onSubmit: async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm },
        ) => {
            try {
                await referFriendSubmit(
                    values.friendEmail,
                    values.friendName,
                ).then(() => {
                    resetForm();
                });
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    return (
        <Grid
            container
            spacing={gridSpacing}
            justifyContent="center"
            border={0}
            id="friend-referral-container-top-level">
            <Grid item xs={12} sx={{ ml: window.isTiny ? 0 : 2, mt: 2 }}>
                <Grid
                    container
                    id="friend-referral-container"
                    spacing={gridSpacing}
                    sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                    }}>
                    <Grid
                        item
                        xs={12}
                        md={window.isTiny ? 11.5 : 5.5}
                        sx={{ mr: window.isTiny ? 0 : 1 }}>
                        <SubCard
                            title="Refer Your Friends"
                            dibsstudioid={dibsId}>
                            <form
                                className="form-profile"
                                onSubmit={formik.handleSubmit}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            mt: window.overlay ? 1 : 2,
                                            ml: 1,
                                        }}>
                                        <Typography variant="subMenuTitle">
                                            {referralText}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={11} sx={{ mt: 3, ml: 1 }}>
                                        <Grid container spacing={1}>
                                            <MessageGrid
                                                message={statusMsg}
                                                status={status}
                                            />
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ mt: 0, mr: 1 }}>
                                                <InputLabel>
                                                    Enter your friend's email
                                                </InputLabel>
                                                <TextField
                                                    fullWidth
                                                    name="friendEmail"
                                                    value={
                                                        formik.values
                                                            .friendEmail
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched
                                                            .friendEmail &&
                                                        Boolean(
                                                            formik.errors
                                                                .friendEmail,
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched
                                                            .friendEmail &&
                                                        formik.errors
                                                            .friendEmail
                                                    }
                                                    sx={{ mt: 1 }}>
                                                    Email
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 3.5, ml: 1 }}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={11}>
                                                <InputLabel>
                                                    Enter your friend's first
                                                    name
                                                </InputLabel>
                                                <TextField
                                                    fullWidth
                                                    name="friendName"
                                                    value={
                                                        formik.values.friendName
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched
                                                            .friendName &&
                                                        Boolean(
                                                            formik.errors
                                                                .friendName,
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched
                                                            .friendName &&
                                                        formik.errors.friendName
                                                    }
                                                    sx={{ mt: 1 }}>
                                                    First Name
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 3, mb: 4, ml: 1 }}>
                                        <Grid container spacing={0}>
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{ mr: 2, mt: 1 }}>
                                                <Button
                                                    variant="checkout"
                                                    type="submit"
                                                    fullWidth
                                                    disabled={
                                                        formik.isSubmitting
                                                    }>
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </SubCard>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={window.isTiny ? 11.5 : 6}
                        sx={{ mt: window.isTiny ? 1 : 0 }}>
                        <SubCard
                            title="Previous Friend Referrals"
                            dibsstudioid={dibsStudioId}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="subMenuTitle">
                                        Friends you've referred
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        {hasReferredFriends ? (
                                            <Grid item xs={12} sx={{ mb: 2.5 }}>
                                                <FriendTable
                                                    friends={frienddata}
                                                />
                                            </Grid>
                                        ) : (
                                            <Grid item xs={12}>
                                                <Typography variant="regularText">
                                                    {friendsMessage}
                                                </Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Notifications;
