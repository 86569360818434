// CODES
export const USER_NOT_FOUND = 'auth/user-not-found';
export const WRONG_PASSWORD = 'auth/wrong-password';
export const TOO_MANY_REQUESTS = 'auth/too-many-requests';
export const NETWORK_FAILURE = 'auth/network-request-failed';
export const EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use';

// ERROR MESSAGES
export const NO_USER_MSG =
	'There is no account associated with that email address. Check your email address and try again or contact us at studios@ondibs.com for help.';
export const WRONG_PASSWORD_MSG =
	'The password you entered is incorrect. Please try again or reset your password.';
export const TOO_MANY_REQUESTS_MSG = `You've made too many requests. Please wait for a while and try again. Contact us at studios@ondibs.com if you need help.`;
export const NETWORK_FAILURE_MSG = `There was a network failure. Please check your internet connection and try again.`;
export const UNKNOWN_ERROR_MSG = `An unknown error occured. Please refresh your screen and try again. Contact us at studios@ondibs.com if you need help.`;
export const EMAIL_ALREADY_IN_USE_MSG = `The email address you entered is already in use. Please try again with a different email address or reset your password.`;

// SUCCESS MESSAGES
export const SUCCESSFUL_PASSWORD_RESET =
	'Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.';
export const BEING_REDIRECTED_LOGIN_MSG =
	'You are now being redirected to the login page.';
export const DEFAULT_SUCCESS_MSG = 'Success!';
export const NEW_ACCOUNT_CREATED = 'Your account was successfully created.';
