import { Grid, Divider, Typography, Button, Fade } from '@mui/material';

import { useEffect, useState } from 'react';

import { useSelector } from 'store';
import setError from 'actions/status/setAlert';

import IndividualCard from 'components/payment/profileIndividualCardDisplay';
import IndividualCardSelected from 'components/payment/profileIndividualCardSelected';
import CollapseComponent from 'views/wrappers/collapse';
import CircularProgress from 'assets/CircularIndeterminate';

import createSetupIntent from 'actions/stripe/createStripeSetupIntent';
import {
    clearAllStatusMessageReducer
} from 'store/slices/error';

// ==============================|| DISPLAY CC CARDS ||============================== //
import './credit-card.css';
import { dispatch } from 'store';
import {
    setShowAllExistingPaymentOptions,
    setShowCCCapture,
} from 'store/slices/cart';

const DisplayCards = ({ payments }) => {
    const [paymentsToDisplay, setPaymentsToDisplay] = useState([]);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const [paymentToChargeLocally, setPaymentToChargeLocally] = useState(null);
    const {
        numCreditCardsOnFile,
        userid,
        email,
        firstname,
        lastname,
        stripeid,
        paymentOptionsAll
    } = useSelector((state) => state.thisuser);
    const { showAllExistingPaymentOptions, showCCCapture, cardtocharge } = useSelector(
        (state) => state.cart,
    );
    const [headerInfo, setHeaderInfo] = useState(
        'Choose from your available payment options, or add a new one.',
    );
    const [intentReturned, setIntentReturned] = useState(false);
    // get setupintent - when it is ready - say that it is ready

    useEffect(() => {
        if (payments.length > 0) {
            let uniquePayments = [];
            let cardids = [];
            payments.forEach((payment) => {
                const { card } = payment;
                const { brand, exp_month, exp_year, last4 } = card;
                const newcardid = `${brand}-${last4}-${exp_month}-${exp_year}`;
                if (!cardids.includes(newcardid)) {
                    uniquePayments.push(payment);
                    cardids.push(newcardid);
                }
            });
            setPaymentsToDisplay(uniquePayments);
        }
    }, [payments]);
    useEffect(() => {
        if (paymentOptionsAll.length === 0) {
            setHeaderInfo(
                'You do not have any payment methods on file. To check out, add a new payment method.',
            );
        } else {
            setHeaderInfo('Choose from your available payment options, or add a new one.')
        }
    }, [paymentOptionsAll.length]);

    const goBackToCart = () => {
        console.log('going back to the cart');
    };

    const handleAddNewCardClick = async () => {
        dispatch(clearAllStatusMessageReducer());
        const onDibs = true;
        window.localStorage.setItem('intentPlatform', 'dibs');
        window.localStorage.setItem('intentType', 'setup');
        window.localStorage.setItem('intentSetFrom', 'display-cards-checkout');
        await createSetupIntent(
            stripeid,
            userid,
            email,
            `${firstname} ${lastname}`,
            dibsStudioId,
            onDibs,
        )
            .then((res) => {
                console.log('response from creating setup intent: ', res);
                console.log('setting client secret - line 94 - display cards checkout', res.clientSecret)
                window.localStorage.setItem('clientSecret', res.clientSecret);
                window.localStorage.setItem('clientSecretDate', new Date().toISOString());
                setIntentReturned(true);
                dispatch(setShowCCCapture(true));
                dispatch(setShowAllExistingPaymentOptions(false));

            })
            .catch((err) => {
                console.log('error was captured in createSetupIntent: ', err);
                setError({
                    success: false,
                    msg: 'There was a problem adding a new card. Please refresh your screen and try again.',
                    seconds: 3,
                });
            });
    };
    const displaycardsComponent = (
        <Grid item xs={12} sx={{ ml: 1 }} id="display-cards-component">
            <Divider sx={{ mt: 6 }} />
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent={paymentsToDisplay.length > 2 ? "space-evenly" : "flex-start"}>
                <Grid id="display-cards-11" item xs={paymentToChargeLocally ? 8.5 : 11} sx={{ mt: 3, mb: 3, ml: 1 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item id="display-cards-12" xs={8} sx={{ mt: 2 }}>
                            <Typography variant="h5stafftitle">{headerInfo}</Typography>
                        </Grid>
                        <Grid id="display-cards-12" item xs={3.7} sx={{ mr: 2, mt: 2 }}>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    id="add-new-card-button-checkout"
                                    variant="outlined"
                                    onClick={handleAddNewCardClick}
                                    sx={{ mb: 4 }}>
                                    Add New Card
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid id="display-cards-13" item xs={12} border={0} sx={{ mt: 2 }}>
                        <Grid container justifyContent={paymentsToDisplay.length > 2 ? "space-evenly" : "flex-start"}>
                            {paymentsToDisplay.map((payment, i) => (
                                <Grid
                                    key={i}
                                    item
                                    border={0}
                                    xs={4}
                                    md={4}
                                    lg={3.5}
                                    xl={3}
                                    sx={{ mb: 1, mr: 2, mt: 2, pl: 2 }}>
                                    <IndividualCard key={i} cc={payment} setSelected={setPaymentToChargeLocally} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                {paymentToChargeLocally && (
                    <>
                        <Divider orientation="vertical" light flexItem sx={{ mt: 3, mx: 2 }} />
                        <Grid item id="display-cards-14" xs={2.6} sx={{ mt: 3, mb: 3, ml: 1 }}>
                            <Grid item id="display-cards-15" xs={12} border={0}>
                                <Grid container justifyContent="center" sx={{ mt: 3 }}>
                                    <Typography variant="h5stafftitle">Selected Payment Option</Typography>
                                    <Grid item id="display-cards-16" xs={12} sx={{ mt: 3 }}>
                                        <Grid container justifyContent="center">
                                            <Grid item id="display-cards-17" xs={9}>
                                                <Grid container justifyContent="center">
                                                    <IndividualCardSelected key="selected-card" cc={paymentToChargeLocally} useThisCard={goBackToCart} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
            <Divider sx={{ my: 4 }} />
        </Grid>
    );
    const circleProgress = (
        <Grid item xs={12} sx={{ ml: 1 }}>
            <Divider sx={{ mt: 6 }} />
            <CircularProgress />
            <Divider sx={{ mt: 6 }} />
        </Grid>
    );

    if (!showAllExistingPaymentOptions) return null;
    if (intentReturned) return null;
    if (showCCCapture && !intentReturned)
        return <CollapseComponent showThis={true} children={circleProgress} />;

    return (
        <CollapseComponent showThis={true} children={displaycardsComponent} />
    );
};
export default DisplayCards;
