import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import ListOfCartItems from './checkoutList';
import getAvailablePasses from 'actions/getAvailablePasses';
import getCredit from 'actions/getCredit';
import { setAvailablePasses, setAvailableCredit, setStripeId, setStripeIdThisStudio } from 'store/slices/thisuser';
import { useSelector, dispatch } from 'store';
import getAllStripePayments from 'actions/stripe/getAllStripePayments';
import matchPaymentMethods from 'actions/stripe/matchPaymentMethods';
import {
    setRefreshPaymentOptions,
    setPaymentOptions,
    setNumCreditCardsOnFile,
} from 'store/slices/thisuser';
import {
    setExpy,
    setCardBrand,
    setLastFourCard,
    setShowAllExistingPaymentOptions,
    setShowSummaryPaymentScreen,
    setShowChooseSingleOrPackage,
    setShowCCCapture,
    setEditingPaymentMethod,
    setCardToCharge,
} from 'store/slices/cart';

// set a default intentPlatform so we don't error

const CheckoutList = ({ dibsId }) => {
    const { userid, refreshPaymentOptions } = useSelector((state) => state.thisuser);
    const { cardtocharge } = useSelector((state) => state.cart);
    const [payments, setPayments] = useState([]);
    const [gotPayments, setGotPayments] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false);
    useEffect(() => {
        return () => {
            dispatch(setShowAllExistingPaymentOptions(false));
            dispatch(setShowSummaryPaymentScreen(false));
            dispatch(setShowChooseSingleOrPackage(true))
            dispatch(setShowCCCapture(false));
            dispatch(setEditingPaymentMethod({ isEditingMethod: false, packageId: 0 }))
        }
    }, [])

    useEffect(() => {
        const refreshPasses = async () => {
            await getAvailablePasses(dibsId, userid).then((res) => {
                dispatch(setAvailablePasses(res));
            });
            await getCredit(dibsId, userid)
                .then((res) => {
                    dispatch(setAvailableCredit(res));
                })
                .catch((err) => {
                    console.log(`error getting available credit: ${err}`);
                });
        };
        refreshPasses();
    }, [dibsId, userid]);
    useEffect(() => {
        const paymentsalreadyincluded = [];
        const getPayments = async () => {
            await getAllStripePayments(dibsId, userid)
                .then(async (res) => {
                    // dispatch(setStripeIdForStudios(res.stripeConnectedInfo));
                    const { paymentsDibs, paymentsConnectedAccount, stripeidDibs, stripeidStudio } = res;
                    dispatch(setStripeId(stripeidDibs));
                    dispatch(setStripeIdThisStudio(stripeidStudio));
                    if (paymentsDibs.length === 0 && paymentsConnectedAccount.length === 0) {

                        setDoneLoading(true);
                        setPayments([]);
                        dispatch(setCardToCharge(''));
                        dispatch(setLastFourCard('xxxx'));
                        dispatch(setCardBrand('Brand'));
                        dispatch(setExpy(`tbd`));
                        const tosend = {
                            totalNumCC: 0,
                            dibsNumCC: 0,
                            studioNumCC: 0
                        }
                        dispatch(setNumCreditCardsOnFile(tosend));
                        dispatch(setPaymentOptions([]));
                        return;
                    } else {
                        const paymentstosend = [];
                        paymentsDibs.forEach((pay) => {
                            const { card } = pay;
                            const { brand, exp_month, exp_year, last4, fingerprint } = card;
                            const together = `${brand}${last4}${exp_month}${exp_year}${fingerprint}`;
                            if (!paymentsalreadyincluded.includes(together)) {
                                paymentsalreadyincluded.push(together);
                                const newobj = {
                                    ...pay,
                                    platform: 'Dibs'
                                }
                                paymentstosend.push(newobj);
                            }
                        });
                        paymentsConnectedAccount.forEach((pay) => {
                            const { card } = pay;
                            const { brand, exp_month, exp_year, last4, fingerprint } = card;
                            const together = `${brand}${last4}${exp_month}${exp_year}${fingerprint}`;
                            if (!paymentsalreadyincluded.includes(together)) {
                                paymentsalreadyincluded.push(together);
                                const newobj = {
                                    ...pay,
                                    platform: 'Studio'
                                }
                                paymentstosend.push(newobj);
                            }
                        });
                        const tosend = {
                            totalNumCC: paymentsConnectedAccount.length + paymentsDibs.length,
                            dibsNumCC: paymentsDibs.length,
                            studioNumCC: paymentsConnectedAccount.length
                        }
                        setPayments(paymentstosend);
                        dispatch(setNumCreditCardsOnFile(tosend));
                        console.log('\n\n\nsetting payment options to', paymentstosend, '\n\n\n')
                        dispatch(setPaymentOptions(paymentstosend));
                        setDoneLoading(true);

                        await matchPaymentMethods(paymentstosend, cardtocharge).then((res) => {
                            if (res) {
                                const { exp_month, exp_year, last4, brand } = res;
                                dispatch(setExpy(`${exp_month}/${exp_year}`));
                                dispatch(setLastFourCard(last4));
                                dispatch(setCardBrand(brand));
                            }
                        }).catch((err) => {
                            dispatch(setExpy(`tbd`));
                            console.log('error from matching pay methods is', err);
                            dispatch(setLastFourCard('xxxx'));
                            dispatch(setCardBrand('Brand'));
                        });


                        return;

                    }
                })
                .catch((err) => {
                    console.log(`error getting payments: ${err}`);
                    setDoneLoading(false);
                });
            setGotPayments(true);
        };

        if (!gotPayments || refreshPaymentOptions) {
            getPayments();
            dispatch(setRefreshPaymentOptions(false));
        }


    }, [cardtocharge, dibsId, gotPayments, refreshPaymentOptions, userid]);
    useEffect(() => {
        return () => {
            // refresh payment options when if you refresh the page
            dispatch(setRefreshPaymentOptions(true));
        };
    }, []);
    return (
        <Box
            sx={{
                border: 1,
                borderRadius: 2,
                borderColor: '#d3d3d3',
                px: 2,
                pt: 2,
                minHeight: 100,
                mt: 0,
            }}>
            <Grid container id="checkoutlist-nothighest-container">
                <Grid
                    item
                    xs={12}
                    sx={{
                        mx: window.isTiny ? 0 : 3,
                        mt: window.isTiny ? 1 : 5,
                    }}>
                    <ListOfCartItems dibsId={dibsId} payments={payments} />
                </Grid>
            </Grid>
        </Box>
    );
};
export default CheckoutList;
