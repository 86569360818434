import axios from 'axios';
import { getApiUrl } from 'helpers';
import startDibsTransaction from 'actions/checkout/startDibsTransactionsCheckout';
import createNewStripeUserConnected from 'actions/stripe/users/createNewStripeUserConnected';
import clonePaymentMethodOnly from 'actions/stripe/clonePaymentMethodOnly';

export default async function chargeCard(
    userid,
    dibsStudioId,
    stripeIdAtThisStudio,
    cartPreparedForCheckout,
    newPaymentMethod,
    totalAfterCreditApplied,
    creditApplied,
    paymentOptionsAll,
    stripeIdDibs
) {
    const url = getApiUrl('/stripe/charge-card');

    let datatosend;
    let uuidtosend;

    await startDibsTransaction(cartPreparedForCheckout, userid).then((res) => {
        const { uuid } = res;
        uuidtosend = uuid;

    });
    let platformtosend;
    console.log('\n\n\n\nnewPaymentMethod is', newPaymentMethod);
    paymentOptionsAll.forEach((option) => {
        console.log('payment option here is', option);
        if (option.id === newPaymentMethod) {
            console.log('platform is', option.platform);
            platformtosend = option.platform;
        }
    });

    const platformOfCard = platformtosend;
    console.log('\n\n\n\n\n^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log('************************\nplatformOfCard is', platformOfCard);
    let paymentid = newPaymentMethod;
    let stripeidtosend = stripeIdAtThisStudio;
    if (platformOfCard === 'Dibs') {
        // duplicate the card to platform account
        paymentid = 'new paymentid created';

        if (stripeidtosend.length < 4) {
            // create a new stripe account for this user
            await createNewStripeUserConnected(userid, dibsStudioId).then((res) => {
                console.log('response received from creating new stripe user connected', res);
                const { stripeConnectedId } = res;
                stripeidtosend = stripeConnectedId;
            });
        }
        await clonePaymentMethodOnly(dibsStudioId, stripeIdDibs, newPaymentMethod, stripeidtosend).then((res) => {

            const { newPaymentMethod } = res;
            paymentid = newPaymentMethod;
        }).catch((err) => {
            console.log('error from cloning payment method is', err);
            const toreturn = {
                msg: 'There is an issue with your payment method. Please try using a different payment method.',
                success: false
            }
            return toreturn;
        });

    }

    const reqbody = {
        userid,
        dibsId: dibsStudioId,
        stripeid: stripeIdAtThisStudio,
        cartPreparedForCheckout,
        pmid: paymentid,
        total: totalAfterCreditApplied,
        creditApplied,
    };

    await axios
        .post(url, reqbody)
        .then((res) => {
            const { data } = res;
            const toreturn = {
                uuid: uuidtosend,
                stripechid: data.stripechid,
            }
            datatosend = toreturn;


        })
        .catch((err) => {
            console.log(
                `error from charging card from saved card Action: ${err}`,
            );
            return err;
        });

    return datatosend;
}
