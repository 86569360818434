import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Container, Stack, Grid } from '@mui/material';
import axios from 'axios';
import AccountHeader from 'layouts/header-new';
import DibsFooter from 'components/footers/dibs-footer';
import SuccessBar from 'components/error-success/SuccessBar';
import SuccessBarNew from 'components/error-success/SuccessBarForFullPageModal';
import ModalLoader from 'views/wrappers/displayAsModal';
import ModalLoaderFullPage from 'views/wrappers/displayAsModalFullPage';
import updateWidgetWidthAction from 'actions/updateWidths';
import NonModalLoader from 'views/wrappers/updateConfigs';

import { useSelector, dispatch } from 'store';
import { setWidgetOpenStyle, setWdth } from 'store/slices/dibsstudio';

const WidgetLoader = () => {
    const [dibsWidth, setDibsWidth] = React.useState(
        window.isTiny ? '98vw' : '100vw',
    );

    let { dibsId } = useParams();
    const { config } = useSelector((state) => state.dibsstudio);

    const { widgetWidth } = config;
    const [loadingWithModal, setLoadingWithModal] = React.useState(false);
    const [widgetWindowWidth, setWidgetWindowWidth] = React.useState(
        dibsWidth || widgetWidth,
    );
    const [containerSize, setContainerSize] = React.useState(widgetWidth);
    // options include:'right' and 'full-page' - right is phasing out
    const [loadingStyle, setLoadingStyle] = React.useState('full-page');
    const [doneLoadingConfigs, setDoneLoadingConfigs] = React.useState(false);

    window.addEventListener('message', (event) => {
        try {
            const { windowWidth } = event.data;
            let windowWidthFromParent = windowWidth;
            if (windowWidthFromParent > 0) {
                if (windowWidthFromParent > 0) {
                    // dispatch(setWdth(windowWidthFromParent));
                    setWidgetWindowWidth(windowWidthFromParent);
                }
            }
        } catch (error) {
            console.log('error from iframe event listener is v2: ', error);
        }
    });

    React.useEffect(() => {
        updateWidgetWidthAction(loadingStyle);
        const baseURL = process.env.REACT_APP_BASE_URL;
        const basicurl = `${baseURL}/widget/get-basic-config`;
        // const basicurl =
        // ('http://192.168.0.36:3001/api/v2/widget/get-basic-config');
        const dibsIdNum = parseInt(dibsId, 10);
        if (widgetWindowWidth > 0) dispatch(setWdth(widgetWindowWidth));
        if (loadingStyle === 'full-page') {
            setContainerSize(parseInt(widgetWidth * 0.9));
        } else if (loadingStyle === 'right') {
            setContainerSize(widgetWidth);
        }

        const getColor = async () => {
            await axios
                .post(basicurl, { dibsStudioId: dibsIdNum })
                .then((response) => {
                    if (response.data.buttonControlsWidget) {
                        if (!loadingWithModal) setLoadingWithModal(true);
                        setLoadingStyle(response.data.widgetOpenStyle);
                        dispatch(
                            setWidgetOpenStyle(response.data.widgetOpenStyle),
                        );
                        if (response.data.widgetOpenStyle === 'full-page') {
                            // dispatch(setWidgetWidth('100vw'));
                            updateWidgetWidthAction('full-page');
                        } else {
                            updateWidgetWidthAction('normal');
                        }
                        // setDibsWidth('100vw');
                    } else {
                        const windowWidth = window.innerWidth;
                        if (windowWidth > 1600 && !loadingWithModal) {
                            setDibsWidth('75vw');
                        } else if (windowWidth > 1200 && !loadingWithModal) {
                            setDibsWidth('90vw');
                        }
                    }
                    setDoneLoadingConfigs(true);
                });
        };
        getColor();
    }, [
        dibsId,
        loadingStyle,
        loadingWithModal,
        widgetWidth,
        widgetWindowWidth,
    ]);

    const modalContent = (
        <Stack id="this-stack-loader-in-modal" alignItems="center">
            {/* <SuccessBarNew /> */}
            <Container
                id="widget-loader-container-modal"
                disableGutters
                border={0}
                maxWidth={false}
                sx={{
                    width: containerSize,
                    height: 'auto',
                    overflow: 'visible',
                    justifyContent: 'center',
                    minHeight: '50vh',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginRight: 0,
                    marginLeft: 0,
                }}>
                <Outlet />
            </Container>
            <DibsFooter />
        </Stack>
    );

    if (loadingWithModal && loadingStyle === 'full-page') {
        return <ModalLoaderFullPage children={modalContent} />;
    } else if (loadingWithModal && loadingStyle === 'right')
        return <ModalLoader children={modalContent} />;
    // cases where widget should be loaded as normal
    if (!doneLoadingConfigs) return <></>;
    const nonModalContent = (
        <Stack
            id="this-stack-loader-non-modal"
            alignItems="center"
            justifyContent="center"
            display="flex"
            border={0}
            direction="column">
            {/* <SuccessBar /> */}
            <Container
                id="widget-loader-container-normal"
                disableGutters
                border={0}
                maxWidth={false}
                sx={{
                    width: widgetWidth,
                    height: 'auto',
                    overflowY: 'visible',
                    overflowX: 'hidden',
                    minHeight: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    // justifyContent: 'center',
                    marginRight: 0,
                    marginLeft: 0,
                    paddingTop: 1,
                }}>
                {dibsId !== '226' && <AccountHeader />}
                <Outlet />
            </Container>
            <DibsFooter />
        </Stack>
    );
    return <NonModalLoader children={nonModalContent} />;
};
export default WidgetLoader;
