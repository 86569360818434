import React from 'react';

import {
    Typography,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableBody,
    Stack,
} from '@mui/material';
import { alpha } from '@mui/material/styles';

import { useSelector } from 'store';
import CheckoutItem from 'components/checkout/checkoutItem';
import SubCard from 'ui-component/cards/SubCard';
import { formatDollarAmountTwoDigits, capitalizeString } from 'helpers';

const ConfirmationTable = ({ rows }) => {
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortouse = `#${color}`;
    const bglighter = alpha(colortouse, 0.3);

    return (
        <SubCard>
            <TableContainer id="checkout-confirmation-container-table">
                <Table aria-label="simple table">
                    <TableBody>
                        {rows.map((row, index) => {
                            const { howtopay } = row;
                            const { passid } = howtopay;
                            let borderbottomtext = '1px solid #d3d3d3';
                            let paymethod = '';
                            let showSubText = false;
                            let subBrand = '';
                            let sublast4 = '';
                            if (passid > 0) {
                                paymethod = `Applied ${row.howtopay.passname}`;
                            } else {
                                if (row.credittocharge > 0) {
                                    const dollaramount =
                                        formatDollarAmountTwoDigits(
                                            row.credittocharge,
                                        );
                                    paymethod = `Applied ${dollaramount} in credit`;
                                } else if (row.price > 0) {
                                    const totalamount = row.price + row.itemtax;
                                    paymethod = `Paid ${formatDollarAmountTwoDigits(
                                        totalamount,
                                    )}`;
                                    if (row.card) {
                                        subBrand = row.card.brand;
                                        sublast4 = row.card.last4;
                                        showSubText = true;
                                    }
                                } else {
                                    if (row.price === 0) {
                                        paymethod = 'Free Event';
                                    } else {
                                        paymethod = 'Unhandled payment method';
                                    }
                                }
                            }
                            let totalRows = rows.length;
                            if (index === totalRows - 1) {
                                borderbottomtext = 'none';
                            }
                            return (
                                <React.Fragment
                                    key={`${index}-${
                                        row.eventid
                                    }-${Math.random()}`}>
                                    <TableRow
                                        key={`${
                                            row.eventid
                                        }-${index}-${Math.random()}`}>
                                        <TableCell
                                            key={`${
                                                row.eventid
                                            }-${Math.random()}`}
                                            sx={{
                                                borderBottom: borderbottomtext,
                                            }}>
                                            <CheckoutItem
                                                row={row}
                                                bglighter={bglighter}
                                                showSpotsBooked={true}
                                            />
                                        </TableCell>
                                        <TableCell
                                            key={`${
                                                row.eventid
                                            }-${Math.random()}`}
                                            sx={{
                                                borderBottom: borderbottomtext,
                                            }}>
                                            <Stack>
                                                <Typography variant="eventprice">
                                                    {paymethod}
                                                </Typography>
                                                {showSubText && (
                                                    <Typography variant="apptSubUpdated">
                                                        w/{' '}
                                                        {capitalizeString(
                                                            subBrand,
                                                        )}{' '}
                                                        ending in {sublast4}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </SubCard>
    );
};

export default ConfirmationTable;
