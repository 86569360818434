// PaymentStatus.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';


import retrievePaymentIntent from 'actions/stripe/retrievePaymentIntent';
import updateDibsTransactions from 'actions/checkout/updateDibsTransactions';
import updateEventCounts from 'actions/checkout/updateEventCounts';

import { useSelector, dispatch } from 'store';
import { setCartCompletedIsReadyToShow, setCartJustCheckedOut, setIsProcessingCreditCardNow, clearCart, clearPurchasingPackage, setShowCCCapture } from 'store/slices/cart';
import {
    setSuccessMessageReducer,
    setSecondsToShow,
    setHasSuccessReducer,
    clearSuccessMessageReducer
} from 'store/slices/error';


const PaymentStatus = () => {
    const intervalRef = useRef(0);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { userid } = useSelector((state) => state.thisuser);
    const { cartPreparedForCheckout, totalAfterCreditApplied, creditApplied } = useSelector((state) => state.cart);

    useEffect(() => {
        const getIntent = async () => {
            const clientSecret = new URLSearchParams(window.location.search).get(
                'payment_intent'
            );
            const stripeAccountId = window.localStorage.getItem('stripeKeyConnected')
            if (clientSecret) {
                await retrievePaymentIntent(clientSecret, stripeAccountId).then(async (res) => {
                    const { payment } = res;
                    const { latest_charge, card } = payment;
                    const uuidtosend = window.localStorage.getItem('checkoutuuid');
                    // update dibs transactions with the stripe charge and set status to 1
                    await updateDibsTransactions(uuidtosend, latest_charge, userid).then(async (res) => {
                        await updateEventCounts(uuidtosend).then((res) => {
                            const newcart = cartPreparedForCheckout.map((item) => {
                                return {
                                    ...item,
                                    card
                                }
                            });
                            const datatopasstostore = { cartPreparedForCheckout: newcart, creditApplied, totalAfterCreditApplied, orNewCart: [] };
                            dispatch(setShowCCCapture(false));
                            dispatch(setCartJustCheckedOut(datatopasstostore));
                            dispatch(setCartCompletedIsReadyToShow(true));
                            dispatch(setIsProcessingCreditCardNow(false));
                            dispatch(clearCart());
                            dispatch(clearPurchasingPackage());
                            dispatch(setSuccessMessageReducer('Success!'));
                            dispatch(setSecondsToShow(10));
                            dispatch(setHasSuccessReducer(true));
                            setTimeout(() => {
                                dispatch(clearSuccessMessageReducer());
                            }, 6000);

                            console.log('need to clear cart here as well');
                        });
                    });
                    // add user to the attendees table
                    // update event count
                }).catch((err) => {
                    console.log('there was an error verifying the charge', err)
                });
            }

        };
        if (intervalRef.current === 0) {
            intervalRef.current = intervalRef.current + 1;
            getIntent();
        }
    }, [userid, cartPreparedForCheckout, dibsStudioId, totalAfterCreditApplied, creditApplied]);

    return null;
};

export default PaymentStatus;