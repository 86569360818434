import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function addApptAttendees(data) {
    const url = getApiUrl('/appts/add-appt-attendees');
    let valuetoreturn = {};
    await axios
        .post(url, data)
        .then((res) => {
            valuetoreturn = res.data;
        })
        .catch((error) => {
            valuetoreturn = error;
        });
    return valuetoreturn;
}
