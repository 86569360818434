import * as React from 'react';
import {
    useParams,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@mui/material';
import ScheduleLayout from 'layouts/schedule';

import { dispatch, useSelector } from 'store';
import { setSchedule } from 'store/slices/schedule';
import { setAvailablePasses } from 'store/slices/thisuser';
import {
    setShowingSchedule,
    setFirstLoad,
    setShowingAppointments,
    setDateSelected,
    setMaxDateForComparison,
    setPageSelected,
} from 'store/slices/menu';
import { setLastLocation } from 'store/slices/thisuser';
import getAvailablePasses from 'actions/getAvailablePasses';

import Transition from 'views/wrappers/transition';
import {
    constructDateinUtc,
    constructDatePlusOneDayinUtc,
} from 'helpers/datehelp';

const WidgetPageView = () => {
    const [searchParams] = useSearchParams();
    const filtered = searchParams.get('filtered');
    const datetofilterto = searchParams.get('datetofilter');

    const isFiltered = filtered === 'true';
    let { dibsId } = useParams();
    const navigate = useNavigate();
    const { config, studioConfig } = useSelector((state) => state.dibsstudio);
    const { userid } = useSelector((state) => state.thisuser);
    const { timeZone, entryPage, showSchedule } = config;
    const { color } = studioConfig;
    const { firstload } = useSelector((state) => state.menu);
    const [doneLoading, setDoneLoading] = React.useState(false);
    const [filterDate, setFilterDate] = React.useState(false);
    const [dateToFilterTo, setDateToFilterTo] = React.useState('8/24/24');
    const [doneLoadingBasic, setDoneLoadingBasic] = React.useState(true);
    const baseURL = process.env.REACT_APP_BASE_URL;
    const location = useLocation();
    const setDibsIdLocalStorage = (dibsId) => {
        window.localStorage.setItem('dibsId', dibsId);
    };
    React.useEffect(() => {
        setDibsIdLocalStorage(dibsId);
    }, [dibsId]);

    React.useEffect(() => {
        // gonna get the timeZone from here

        const bodytosend = {
            dibsStudioId: dibsId,
            timeZone: 'America/New_York',
            calledFrom: 'widget',
        };
        const sendtoapptpage = () => {
            // navigate to appt page
            dispatch(setShowingSchedule(false));
            dispatch(setShowingAppointments(true));
            dispatch(setFirstLoad(false));
            dispatch(setPageSelected('appointments'));
            navigate(`/appointments/${dibsId}${location.search}`);
        };

        if (entryPage === 'appointments' && firstload) sendtoapptpage();
        if (!showSchedule) sendtoapptpage();
        if (entryPage === 'schedule') {
            dispatch(setShowingSchedule(true));
            dispatch(setPageSelected('schedule'));
        }
        dispatch(setLastLocation(location.pathname));
        const url = `${baseURL}/widget/get-schedule`;
        if (firstload) dispatch(setFirstLoad(false));

        const getSchedule = async () => {
            await axios
                .post(url, bodytosend)
                .then((response) => {
                    dispatch(setSchedule(response.data));
                    setDoneLoading(true);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        if (dibsId && !doneLoading && doneLoadingBasic) {
            getSchedule();
        }
    }, [
        baseURL,
        color,
        dibsId,
        doneLoading,
        doneLoadingBasic,
        entryPage,
        firstload,
        location.pathname,
        navigate,
        showSchedule,
    ]);
    React.useEffect(() => {
        if (isFiltered) {
            setFilterDate(true);
            setDateToFilterTo(datetofilterto);
            const dateInUtc = constructDateinUtc(datetofilterto);
            dispatch(setDateSelected(dateInUtc));
            const dateInUtcPlusOne =
                constructDatePlusOneDayinUtc(datetofilterto);
            dispatch(setMaxDateForComparison(dateInUtcPlusOne));
        } else {
            setFilterDate(false);
        }
    }, [dibsId, isFiltered, datetofilterto]);
    React.useEffect(() => {
        const getPasses = async () => {
            await getAvailablePasses(dibsId, userid)
                .then((res) => {
                    dispatch(setAvailablePasses(res));
                    setDoneLoading(true);
                })
                .catch((err) => {
                    console.log(
                        `Error getting available from schedule page - passes: ${err}`,
                    );
                    setDoneLoading(false);
                });
        };
        if (userid !== 0) {
            getPasses();
        }
    }, [userid, dibsId]);
    const loadingDisplay = (
        <Grid
            container
            id="loading-placeholder"
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item id="loading-schedule">
                Loading...
            </Grid>
        </Grid>
    );
    if (!doneLoading) return loadingDisplay;

    return (
        <Grid container id="container-schedule">
            <Grid
                item
                xs={12}
                border={0}
                id="grid-item-holding-schedule-view-layout">
                <ScheduleLayout
                    dateToFilterTo={dateToFilterTo}
                    filterDate={filterDate}
                />
            </Grid>
        </Grid>
    );
};

export default Transition(WidgetPageView);
