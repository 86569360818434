import React from 'react';
import { Grid, Typography, Button } from '@mui/material';

const removePaymentMethod = ({ message, action }) => {
    return (
        <Grid container>
            <Grid item xs={12} sx={{ m: 0 }}>
                <Typography variant="regularText" sx={{ lineHeight: 1.2 }}>
                    {message}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ m: 0, my: 1.5 }}>
                <Button
                    id="yes-answer"
                    variant="confirmation"
                    onClick={() => action('yes')}
                    sx={{
                        height: '25px',
                        p: 0,
                        m: 0,
                        minWidth: '35px',
                        mr: 2,
                    }}>
                    Yes
                </Button>
                <Button
                    id="no-answer"
                    variant="confirmationcancel"
                    onClick={() => action('no')}
                    sx={{ height: '25px', p: 0, m: 0, minWidth: '35px' }}>
                    No
                </Button>
            </Grid>
        </Grid>
    );
};

export default removePaymentMethod;
