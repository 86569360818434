import { Button, Grid } from '@mui/material';
import {
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import CheckoutButton from 'components/checkout/buttons/checkout';
import React, { useState, useEffect } from 'react';
import { dispatch, useSelector } from 'store';

import {
    setCardToCharge,
    setShowAllExistingPaymentOptions,
    setShowCCCapture,
    setIsProcessing,
} from 'store/slices/cart';

import { setRefreshPaymentOptions } from 'store/slices/thisuser';

import reSortPaymentOptions from 'actions/users/reSortPaymentOptions';

import setError from 'actions/status/setAlert';
import { roundtoN } from 'helpers';

import './../stripe.css';

const CheckoutForm = ({ platformUsed, cancelAction, donotshowcancelbutton, endingAction }) => {
    const location = useLocation();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [checkoutButtonText, setCheckoutButtonText] = useState('Add Payment Method');


    const { paymentOptionsAll } = useSelector(
        (state) => state.thisuser,
    );
    const { totalAfterCreditApplied } = useSelector((state) => state.cart);
    const intentType = window.localStorage.getItem('intentType');
    const [isLoading, setIsLoading] = useState(false);
    const options = {
        layout: 'tabs',
        // business: {
        //     name: 'BUSINESS GOES HERE',
        // },
        terms: {
            card: 'never',
        },
        // fields: {
        //     billingDetails: {
        //         address: {
        //             country: 'never',
        //             postalCode: 'never',
        //         }
        //     }
        // }
    };
    useEffect(() => {
        if (intentType === 'payment') {
            const dollarAmount = roundtoN(totalAfterCreditApplied, 2);
            const textToShow = `Pay $${dollarAmount} Now`;
            setCheckoutButtonText(textToShow);
        } else {
            setCheckoutButtonText('Add Payment Method');
        }
    }, [intentType, totalAfterCreditApplied]);
    if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        const loading = <Grid>Loading...</Grid>;
        return loading;
    }
    const confirmCCInfo = async (event) => {
        event.preventDefault();
        console.log('confirming stripe info on tiny stripe element');

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        dispatch(setIsProcessing(true));
        setIsLoading(true);
        const hostname = window.location.host;
        let http = 'https://';
        if (hostname.includes('localhost')) http = 'http://';
        const urltosend = `${http}${hostname}${location.pathname}`;

        // here - create the dibs transactions starting point
        await stripe
            .confirmSetup({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: urltosend,
                },
                redirect: 'if_required',
            })
            .then(async (cs) => {

                const { setupIntent } = cs;
                const { status } = setupIntent;
                if (status) {
                    if (status === 'succeeded') {
                        dispatch(setShowCCCapture(false));
                        dispatch(setShowAllExistingPaymentOptions(true));
                        const { payment_method } = setupIntent;
                        dispatch(setRefreshPaymentOptions(true));
                        dispatch(setCardToCharge(payment_method));
                        if (paymentOptionsAll.length > 1) {
                            reSortPaymentOptions(paymentOptionsAll, payment_method);
                        }
                        endingAction();
                        dispatch(setIsProcessing(false));
                    }
                }
            })
            .catch((err) => {
                console.log('error is', err);
                if (
                    err.type === 'card_error' ||
                    err.type === 'validation_error'
                ) {
                    setMessage(err.message);
                    setError({
                        msg: err.message,
                        seconds: 5,
                        error: true,
                    });
                }
            });
        setIsLoading(false);
    };
    const onCancel = async () => {
        console.log(
            'canceled this action --> Add functionality to cancel stripe payment intent',
        );
        dispatch(setIsProcessing(false));
        dispatch(setShowCCCapture(false));
        cancelAction();

    };
    const onLoadErrorFn = async (event) => {
        console.log('error loading the stripe element', event);
        console.log('maybe do something here');
    }

    return (
        <form className="tiny">
            <PaymentElement type="payment" options={options} onLoadError={onLoadErrorFn} />
            <CheckoutButton
                buttonText={checkoutButtonText}
                clickAction={confirmCCInfo}
                isBuying={isLoading}
                pl={0}
                showConfirmButton={true}
            />
            {!donotshowcancelbutton && (
                <Grid container justifyContent="flex-end">
                    <Button sx={{ color: '#7e92a3' }} onClick={() => onCancel()}>
                        Cancel
                    </Button>
                </Grid>
            )}
            {message && <div id="setup-intent-message">{message}</div>}
        </form>
    );
};

export default CheckoutForm;
