const Typography = {
    typography: {
        h6: {
            fontSize: '0.65rem',
            fontWeight: 400,
            color: '#999',
        },
        eventtime: {
            fontSize: '0.95rem',
            fontWeight: 500,
        },
        subeventtime: {
            fontSize: '0.70rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventlocation: {
            fontSize: '0.95rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventname: {
            fontSize: '0.95rem',
            fontWeight: 400,
        },
        eventinstructor: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventprice: {
            fontSize: '1.25rem',
            fontWeight: 400,
            color: '#6C757D',
        },
        headers: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        packageNames: {
            fontSize: '0.8rem',
            fontWeight: 500,
            lineHeight: '1.2rem',
        },
        numClasses: {
            fontSize: '0.8rem',
            fontWeight: 400,
            // color: '#6C757D',
        },
        expiration: {
            fontSize: '0.6rem',
            fontWeight: 400,
            color: '#ADB5BD',
            lineHeight: '0.8rem',
            fontStyle: 'italic',
        },
        packageSubheaders: {
            fontSize: '0.8rem',
            fontWeight: 300,
            color: '#ADB5BD',
        },
        packageClasses: {
            fontSize: '0.9rem',
            fontWeight: 400,
        },
    },
};
export default Typography;
