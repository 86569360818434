import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// material-ui
import {
    Grid,
    Typography,
    Link,
    Fade,
    // useMediaQuery,
} from '@mui/material';

import { gridSpacing } from 'store/constant';

import { dispatch } from 'store';
import { setPageSelected, setShowingSchedule } from 'store/slices/menu';

import {
    registerWithEmailAndPassword,
    sendPasswordReset,
} from 'firebaseConfig';

import { useSelector } from 'store';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessageNew';
import setFirebaseAuthenticationPwd from 'actions/setFirebaseAuthenticationPwd';
import getUserExists from 'actions/users/userAlreadyExists';

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginAuthenticationForgotPassword = ({ setCurrentStep, emailAddyFp }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dibsId } = useParams();
    const [msgToShow, setMsgToShow] = React.useState(
        'Sending email to reset your password...',
    );
    // const { hasSetPwdFirebase, email, userid } = useSelector(
    //     (state) => state.thisuser,
    // );

    React.useEffect(() => {
        const msg = `A password reset link has been sent to ${emailAddyFp}. It will be sent from Dibs, our software provider and it may be in your spam folder. Please check your email and click the link to reset your password.`;
        // check to see if user already has a password on firebase in our database

        const createNewUserFirebaseSendPasswordReset = async (userid) => {
            // create user in firebase
            await registerWithEmailAndPassword(
                emailAddyFp,
                'temporarypasswordsecret',
            ).then(async () => {
                // update database with user info
                setFirebaseAuthenticationPwd(userid);
                // send email reset link
                await sendPasswordReset(emailAddyFp, dibsId).then(() => {
                    // setDoneSendingLink(true);
                    setMsgToShow(msg);
                    return true;
                });
            });
        };
        const sendPasswordResetToUser = async () => {
            await sendPasswordReset(emailAddyFp, dibsId).then(() => {
                // setDoneSendingLink(true);
                setMsgToShow(msg);
                return true;
            });
        };
        const sendPasswordResetLink = async () => {
            await getUserExists(emailAddyFp).then((res) => {
                const { hasSetFirebasedPwd, userid } = res;
                if (hasSetFirebasedPwd) {
                    sendPasswordResetToUser();
                } else {
                    createNewUserFirebaseSendPasswordReset(userid);
                }
            });
        };

        sendPasswordResetLink();
    }, [dibsId, emailAddyFp]);

    const goToSchedule = () => {
        dispatch(setPageSelected('schedule'));
        dispatch(setShowingSchedule(true));
        navigate(`/schedule/${dibsId}${location.search}`);
    };
    const goToLoginPage = () => {
        setCurrentStep('email');
    };
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <Fade in appear timeout={500}>
                    <LoginCard
                        title="Reset Your Password"
                        dibsstudioid={dibsId}>
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ ml: 1 }}>
                                <Typography variant="regularText">
                                    {msgToShow}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ ml: 1 }}>
                                <Grid container justifyContent="space-between">
                                    <Grid item xs={8} md={4} sx={{ mt: 1 }}>
                                        <Link
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => goToLoginPage()}>
                                            <Typography variant="guidance">
                                                Go back to the login page
                                            </Typography>
                                        </Link>
                                    </Grid>
                                    <Grid item xs={8} md={4} sx={{ mt: 1 }}>
                                        <Link
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => goToSchedule()}>
                                            <Typography variant="guidance">
                                                Go to the schedule page
                                            </Typography>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};

export default LoginAuthenticationForgotPassword;
