import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import currency from 'currency.js';

// material-ui
import { Grid, Button, Typography } from '@mui/material';

import { useSelector, dispatch } from 'store';

import confirmSpace from 'actions/confirmSpaceInClass';
import confirmClassExists from 'actions/confirmClassExists';
import completePurchase from 'actions/stripe/createStripeChargePm';
import checkoutWithPassesAndOrCredit from 'actions/checkout/checkoutWithPassesAndOrCredit';
import sendConfirmationEmail from 'actions/emails/send-confirmation';

import { setPageSelected } from 'store/slices/menu';
import { clearCart } from 'store/slices/cart';
import { setNeedsRefresh } from 'store/slices/menu';

import createSubscriptionOnStripe from 'actions/stripe/createSubscription';
import checkoutFreePackage from 'actions/checkout/checkoutFreePackage';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// ==============================|| CHECKOUT CART - CHARGE CARD ||============================== //

const StripeChargeCard = ({ cardid, exp, lastfour }) => {
    const navigate = useNavigate();
    const { stripeid, userid, stripeidForThisStudio } = useSelector(
        (state) => state.thisuser,
    );
    // console.log(`stripeid is: ${stripeid}`);
    // console.log('stripeIdforthisstudio is: ', stripeidForThisStudio);
    const { config } = useSelector((state) => state.dibsstudio);
    const {
        total,
        totalAfterCreditApplied,
        itemsInCartHere,
        packageItemsInCart,
        cartPreparedForCheckout,
        creditApplied,
    } = useSelector((state) => state.cart);
    const { dibsStudioId } = config;
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortouse = `#${color}`;
    const [existsClear, setExistsClear] = useState(false);
    const [spaceClear, setSpaceClear] = useState(false);
    const [cardDidNotGoThrough, setCardDidNotGoThrough] = useState(false);
    const [reasonDidNotGoThrough, setReasonDidNotGoThrough] = useState('');
    const [passdidnotgothrough, setPassDidNotGoThrough] = useState(false);
    const [isCharging, setIsCharging] = useState(false);
    const [showCCMessage, setShowCCMessage] = useState(true);
    const [messageForButton, setMessageForButton] =
        useState('Complete Purchase');
    const totalvalue = currency(totalAfterCreditApplied).format();
    useEffect(() => {
        if (totalAfterCreditApplied <= 0) setShowCCMessage(false);
        if (totalvalue) {
            setMessageForButton(`Complete Purchase for ${totalvalue}`);
        }
        const confirmThereIsSpace = async () => {
            await confirmSpace(itemsInCartHere).then((response) => {
                const { numFullClasses } = response;
                if (numFullClasses === 0) {
                    setSpaceClear(true);
                } else {
                    console.log(`there is no space in the class`);
                }
            });
        };
        const makeSureClassExists = async () => {
            await confirmClassExists(itemsInCartHere).then((response) => {
                const { numCancelledClasses } = response;
                if (numCancelledClasses === 0) {
                    setExistsClear(true);
                } else {
                    console.log(
                        `there is at least 1 class that does not exist`,
                    );
                }
            });
        };
        if (itemsInCartHere.length > 0) {
            confirmThereIsSpace();
            makeSureClassExists();
        } else if (itemsInCartHere.length === 0) {
            setSpaceClear(true);
            setExistsClear(true);
        }
    }, [itemsInCartHere, total, totalAfterCreditApplied, totalvalue]);

    const goBackCart = () => {
        dispatch(setPageSelected('checkout'));
        navigate(`/checkout/${dibsStudioId}`);
    };
    const handleChargeCard = async () => {
        setIsCharging(true);
        if (cardDidNotGoThrough || passdidnotgothrough) {
            dispatch(setPageSelected('payment'));
            navigate(`/payment/${dibsStudioId}`);
            return;
        }
        const pmid = cardid;
        if (itemsInCartHere.length > 0) {
            await confirmSpace(itemsInCartHere).then((response) => {
                const { numFullClasses } = response;
                if (numFullClasses === 0) {
                    setSpaceClear(true);
                } else {
                    console.log(`there is no space in the class`);
                }
            });
        }
        if (existsClear && spaceClear) {
            if (
                totalAfterCreditApplied <= 0 &&
                packageItemsInCart.length > 0 &&
                itemsInCartHere.length === 0
            ) {
                // processing free package - must be packages
                await checkoutFreePackage(userid, packageItemsInCart).then(
                    (res) => {
                        setIsCharging(false);
                        if (res === 1) {
                            // success
                            sendConfirmationEmail(
                                dibsStudioId,
                                userid,
                                cartPreparedForCheckout,
                                creditApplied,
                                totalAfterCreditApplied,
                                packageItemsInCart,
                            );
                            dispatch(setPageSelected('purchase-complete'));
                            setTimeout(() => {
                                dispatch(setPageSelected('schedule'));
                            }, 5000);
                            dispatch(clearCart(dibsStudioId));
                            navigate(`/purchase-complete/${dibsStudioId}`);
                        } else {
                            // was not successful
                            console.log(
                                'processing free package was not successful',
                            );
                            setPassDidNotGoThrough(true);
                        }
                    },
                );
            } else if (
                totalAfterCreditApplied <= 0 &&
                cartPreparedForCheckout.length > 0 &&
                itemsInCartHere.length > 0
            ) {
                // process checkout with pass (not card) - must be classes
                // handle credit application

                await checkoutWithPassesAndOrCredit(
                    userid,
                    cartPreparedForCheckout,
                    creditApplied,
                    totalAfterCreditApplied,
                ).then((res) => {
                    setIsCharging(false);
                    if (res === 1) {
                        // success
                        sendConfirmationEmail(
                            dibsStudioId,
                            userid,
                            cartPreparedForCheckout,
                            creditApplied,
                            totalAfterCreditApplied,
                            packageItemsInCart,
                        ); // testing this 2
                        dispatch(setPageSelected('purchase-complete'));
                        setTimeout(() => {
                            dispatch(setPageSelected('schedule'));
                        }, 3000);
                        dispatch(clearCart(dibsStudioId));
                        navigate(`/purchase-complete/${dibsStudioId}`);
                    } else if (res === 2) {
                        console.log('need to show error and refresh cart');
                        // send an email to ops to let them know that this type of error occurred
                        // type of error is - not enough credit and/or not enough passes to complete the submitted transaction
                    } else {
                        // was not successful
                        console.log('processing pass was not successful');
                        setPassDidNotGoThrough(true);
                        // setCardDidNotGoThrough(false);
                    }
                });
            } else if (totalAfterCreditApplied > 0) {
                await completePurchase(
                    total,
                    userid,
                    'usd',
                    stripeid,
                    pmid,
                    dibsStudioId,
                    itemsInCartHere,
                    packageItemsInCart,
                    stripeidForThisStudio,
                    creditApplied,
                    cartPreparedForCheckout,
                ).then((response) => {
                    console.log('response from charging the card is', response);
                    setIsCharging(false);

                    let successStatus = 0;
                    if (response === 1) successStatus = 1;
                    let expirationDateToSend = null;
                    let createNewSubscription = false;
                    let passcreated = [];
                    if (successStatus === 0) {
                        try {
                            const {
                                status,
                                expirationdatetosend,
                                createSubscription,
                                newpasscreated,
                            } = response;
                            successStatus = status;
                            console.log('successStatus is:', successStatus);
                            expirationDateToSend = expirationdatetosend;
                            createNewSubscription = createSubscription;
                            passcreated = newpasscreated;
                        } catch (error) {
                            console.log(`error is: ${error}`);
                            // TODO temporary handle for new stripe card process
                        }
                    }

                    if (successStatus === 1) {
                        dispatch(setPageSelected('purchase-complete'));
                        setTimeout(() => {
                            dispatch(setPageSelected('schedule'));
                        }, 3000);
                        dispatch(clearCart(dibsStudioId));

                        if (packageItemsInCart.length > 0) {
                            dispatch(setNeedsRefresh(true));
                            packageItemsInCart.forEach((pack) => {
                                console.log(
                                    '\n\n\ncreating a new subscription on stripe',
                                    createNewSubscription,
                                );
                                if (createNewSubscription) {
                                    createSubscriptionOnStripe(
                                        userid,
                                        dibsStudioId,
                                        pack.packageid,
                                        pack.price,
                                        pack.itemtax,
                                        pmid,
                                        expirationDateToSend,
                                        passcreated,
                                    ).then((response) => {
                                        console.log(
                                            `created new subscription response is: ${JSON.stringify(
                                                response,
                                            )}`,
                                        );
                                    });
                                }
                            });
                        }
                        navigate(`/purchase-complete/${dibsStudioId}`);
                    } else {
                        const { errorcode } = response;
                        if (errorcode === 'card_declined') {
                            console.log(`Card was declined`);
                            setReasonDidNotGoThrough(
                                'The card was declined by your bank.',
                            );
                            setCardDidNotGoThrough(true);
                        } else {
                            console.log(`there was an error charging the card`);
                            setReasonDidNotGoThrough(
                                'There was an error processing your payment. Please try again and contact us if you continue to have problems at studios@ondibs.com.',
                            );
                            setCardDidNotGoThrough(true);
                        }
                    }
                });
            }
        } else {
            console.log(
                `there was an issue - one of the classes is not available to book`,
            );
        }
    };
    if (passdidnotgothrough) {
        return (
            <>
                <Grid item xs={12} sx={{ ml: 2, my: 3 }}>
                    <Typography variant="errorText">
                        We were unable to process this purchase. Please return
                        to the cart, refresh your screen and try again. If you
                        continue to have issues, please contact us at
                        studios@ondibs.com
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="checkout"
                        fullWidth
                        onClick={handleChargeCard}
                        sx={{
                            backgroundColor: colortouse,
                            '&:hover': {
                                backgroundColor: colortouse,
                            },
                        }}>
                        Go back to the cart
                    </Button>
                </Grid>
            </>
        );
    }
    if (cardDidNotGoThrough) {
        return (
            <>
                <Grid item xs={12} sx={{ ml: 2, my: 3 }}>
                    <Typography variant="errorText">
                        We were unable to charge your card ending in {lastfour}.
                        {reasonDidNotGoThrough}
                        {'  '} Click below to try a different card.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="checkout"
                        fullWidth
                        onClick={handleChargeCard}
                        sx={{
                            backgroundColor: colortouse,
                            '&:hover': {
                                backgroundColor: colortouse,
                            },
                        }}>
                        Go back to try a different card
                    </Button>
                </Grid>
            </>
        );
    }
    return (
        <>
            {showCCMessage && (
                <Grid item xs={12} sx={{ ml: 2, my: 3 }}>
                    We will charge your card ending in {lastfour}
                </Grid>
            )}
            {!showCCMessage && (
                <Grid item xs={12} sx={{ ml: 2, my: 3 }}>
                    {''}
                </Grid>
            )}
            <Grid item xs={12}>
                <Button
                    variant="checkout"
                    fullWidth
                    disabled={isCharging}
                    onClick={() => handleChargeCard()}
                    sx={{
                        backgroundColor: colortouse,
                        '&:hover': {
                            backgroundColor: colortouse,
                        },
                    }}>
                    {/* Complete Purchase for ${totalvalue} */}
                    {isCharging ? (
                        <Typography
                            sx={{ color: '#fff', textTransform: 'capitalize' }}>
                            Processing...
                        </Typography>
                    ) : (
                        messageForButton
                    )}
                </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 3 }}>
                <Button
                    // component={Link}
                    // to={backtoCartUrl}
                    variant="text"
                    onClick={() => goBackCart()}
                    sx={{
                        color: colortouse,
                    }}
                    startIcon={<KeyboardBackspaceIcon />}>
                    Back to cart
                </Button>
            </Grid>
        </>
    );
};

StripeChargeCard.propTypes = {
    cardtocharge: PropTypes.string,
};

export default StripeChargeCard;
