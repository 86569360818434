import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Grid, Typography } from '@mui/material';

import { getDate, getMonth, getYear, parseISO } from 'date-fns';
import {
    utcToZonedTime,
    format as tzFormat,
    getTimezoneOffset,
    formatInTimeZone,
    toDate,
} from 'date-fns-tz';
import { DayPicker } from 'react-day-picker';
import { FormattedMessage } from 'react-intl';

import { subtractDaysFromDate, addDaysToDate } from 'helpers/index';
import { dispatch, useSelector } from 'store';
import { setDateToSearch } from 'store/slices/appointments';
import useGetCurrentLanguage from 'hooks/useTranslateGetLanguage';
import useTranslate from 'hooks/useTranslate';

import 'react-day-picker/dist/style.css';
import './day-picker.css';

// Import the locale from date-fns
import { enUS, it } from 'date-fns/locale';

const convertTimeZone = (date, formatStyle, language = 'en') => {
    const datefromDate = getDate(date);
    const monthFromDate = getMonth(date) + 1;
    const yearFromDate = getYear(date);
    let monthtouse = monthFromDate;
    let datetouse = datefromDate;
    if (monthtouse < 10) {
        monthtouse = `0${monthFromDate}`;
    }
    if (datetouse < 10) {
        datetouse = `0${datefromDate}`;
    }
    const newdateString = `${yearFromDate}-${monthtouse}-${datetouse}T00:00:00+00:00`;

    const parsedDate = toDate(`${newdateString}`);
    let locale = undefined;
    if (language === 'it') {
        locale = it; // Use Italian locale if language is Italian
    }
    const formatConfig = {
        locale: locale, // Pass the locale to formatInTimeZone
    };
    const codeDate = formatInTimeZone(
        parsedDate,
        'UTC',
        'yyyy-MM-dd HH:mm:ssXXX',
        formatConfig,
    );
    const longerDate = formatInTimeZone(
        parsedDate,
        'UTC',
        'MMMM d, yyyy', // Changed format to a more readable form
        formatConfig,
    );
    if (formatStyle === 'long') {
        return longerDate;
    }
    return codeDate;
};

export default function CalendarSelection() {
    const datequestion = new Date();
    const getLanguage = useGetCurrentLanguage();
    const [selected, setSelected] = React.useState(null);
    const { timeZone } = useSelector((state) => state.dibsstudio.config);
    const translate = useTranslate();
    const searchingOnText = translate('searching-on', 'Searching on');

    const theme = useTheme();

    let footer = (
        <Grid item sx={{ ml: 0.5, mt: 4, mb: 2 }}>
            <Typography variant="apptMoreInfoText">
                <FormattedMessage id="pick-a-day" />
            </Typography>
        </Grid>
    );
    if (selected) {
        const selectedConverted = convertTimeZone(
            selected,
            'long',
            getLanguage,
        );
        footer = (
            <Grid item sx={{ mt: 2, ml: 1 }}>
                <Typography variant="apptMoreInfoText" sx={{ mt: 2 }}>
                    {searchingOnText} {selectedConverted}.
                </Typography>
            </Grid>
        );
    }
    React.useEffect(() => {
        if (selected) {
            const dateInBusinessTZ = convertTimeZone(
                selected,
                'short',
                getLanguage,
            );
            dispatch(setDateToSearch(dateInBusinessTZ));
        }
    }, [selected, timeZone, getLanguage]);

    const mainColor = theme.palette.primary.main;

    const css = `
        .my-selected:not([disabled]) { 
            font-weight: bold; 
            border: 2px solid ${mainColor};
            color: ${mainColor};
            box-shadow: none
        }
        .my-selected:hover:not([disabled]) { 
            border-color: ${mainColor};
            color: ${mainColor};
            box-shadow: none
        }
    `;

    const datetouse = subtractDaysFromDate(datequestion, 1);
    const maxDateToShow = addDaysToDate(datetouse, 60);
    const disabledDays = [
        new Date(2024, 5, 10),
        { from: new Date(2024, 4, 18), to: datetouse },
    ];

    // Select the locale based on the config
    const selectedLocale = getLanguage === 'it' ? it : enUS;

    return (
        <Grid
            item
            xs={12}
            id="item-day-picker"
            sx={{ overflow: 'auto', maxWidth: '100%' }}
            border={0}>
            <style>{css}</style>
            <DayPicker
                mode="single"
                selected={selected}
                onSelect={setSelected}
                modifiersClassNames={{
                    selected: 'my-selected',
                    today: 'my-today',
                    button: 'button-leftright',
                }}
                fromMonth={datetouse}
                toDate={maxDateToShow}
                classNames={{
                    day: 'rdp-nav_button-updated',
                }}
                disabled={disabledDays}
                footer={footer}
                weekStartsOn={1}
                locale={selectedLocale} // Set the locale here
            />
        </Grid>
    );
}
