import axios from 'axios';

import { dispatch } from 'store';
import { setHasErrorReducer, setErrorMessageReducer } from 'store/slices/error';

import { getApiUrl } from 'helpers';

export default async function cancelPaymentIntent(paymentIntentId, onDibs) {
    const baseURL = getApiUrl('/stripe/cancel-payment-intent');
    let datatosend = {};
    const cancelIntent = async () => {
        await axios
            .post(baseURL, { paymentIntentId, onDibs })
            .then((res) => {
                console.log('canceled the payment intent', res);
                window.localStorage.removeItem('clientSecret');
                window.localStorage.removeItem('intentType');
                window.localStorage.removeItem('intentStatus');
                // window.localStorage.removeItem('intentPlatform');
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from canceling payment intent from stripe: ${err}`);
                dispatch(setHasErrorReducer(true));
                setTimeout(() => {
                    dispatch(setHasErrorReducer(false));
                    dispatch(setErrorMessageReducer(''));
                }, 5000);
                return err;
            });
    };

    await cancelIntent();
    return datatosend;
}
