import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

import { dispatch } from 'store';
import {
    setHasSuccessReducer, setSuccessMessageReducer
} from 'store/slices/error';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
};


export default function ConfirmationBox({
    question,
    moreinfo,
    openStatus,
    setAnswerToQuestion,
    setOpenStatus,
    idtouse
}) {
    const [open, setOpen] = React.useState(openStatus);
    const handleClose = () => {
        setOpenStatus(false);
        setOpen(false);
    };
    const handleYesAnswer = () => {
        dispatch(setHasSuccessReducer(false));
        dispatch(setSuccessMessageReducer(''));
        setAnswerToQuestion(true);
        setOpen(false);
        setOpenStatus(false);
    };
    React.useEffect(() => {
        setOpen(openStatus);
    }, [openStatus]);

    return (
        <div>
            <Modal
                id={idtouse}
                open={open}
                onClose={handleClose}
                aria-labelledby="confirmation-box-modal-title"
                aria-describedby="confirmation-box-question">
                <Box sx={style}>
                    <Grid container justifyContent={'center'}>
                        <Typography
                            id="modal-modal-title"
                            variant="packagehead">
                            {question}
                        </Typography>
                        <Typography
                            id="confirmation-box-more-info"
                            variant="subtitle2"
                            sx={{ mt: 2 }}>
                            {moreinfo}
                        </Typography>
                        <Grid
                            container
                            border={0}
                            justifyContent="space-between"
                            sx={{ mt: 4, px: 4 }}>
                            <Grid item xs={3} border={0}>
                                <Button onClick={handleClose}>No</Button>
                            </Grid>
                            <Grid item xs={3} border={0}>
                                <Button
                                    variant="checkout"
                                    onClick={handleYesAnswer}>
                                    Yes
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </div>
    );
}
