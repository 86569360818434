import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AccountHeader from 'layouts/header-new';
import BookNowButton from 'assets/icons/bookNowButton';
import UpdateConfig from 'views/wrappers/updateConfigs';
import sendMessageToParent from 'helpers/sendMessageToParent';

import { useSelector } from 'store';

export default function LoadingWidgetAsDrawer({ children }) {
    const { config } = useSelector((state) => state.dibsstudio);

    const { widgetWidth, buttonControlsWidget, dibsStudioId } = config;
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: dibsStudioId === '153' ? true : false, // flex studios wants their widget to open on page load
    });
    const [directionUsed, setDirectionUsed] = React.useState('right');

    window.addEventListener('message', (event) => {
        try {
            const { widgetopenedfromparent, widgetclosedfromparent } =
                event.data;
            if (widgetopenedfromparent !== undefined) {
                if (widgetopenedfromparent && !state.right) {
                    setState({ ...state, right: true });
                }
            }
            if (widgetclosedfromparent !== undefined) {
                if (widgetclosedfromparent && state.right) {
                    setState({ ...state, right: false });
                }
            }
        } catch (error) {
            console.log('error from loadWidgetAsDrawer event listener', error);
        }
    });
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            setTimeout(() => {
                sendMessageToParent({ widgetopen: false });
            }, 1000);
        }
        setState({ ...state, [directionUsed]: false });
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        if (!open) {
            setTimeout(() => {
                sendMessageToParent({ widgetopen: false });
            }, 1000);
        }
        setDirectionUsed(anchor);
        setState({ ...state, [anchor]: open });
    };
    const openDrawerFromButton = () => (event) => {
        setState({ ...state, right: true });
    };
    const DrawerHeaderNew = () => {
        return (
            <Grid
                container
                justifyContent="space-between"
                id="display-as-modal-container-header"
                alignItems="center"
                border={0}
                sx={{ mt: buttonControlsWidget ? 0 : 0 }}>
                <Grid item xs={2} sx={{ ml: 0, pl: 0 }} border={0}>
                    <IconButton
                        onClick={toggleDrawer('right', false)}
                        edge="start"
                        border={1}
                        sx={{
                            justifyContent: 'flex-start',
                            ml: 0.25,
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10} border={0}>
                    <AccountHeader />
                </Grid>
            </Grid>
        );
    };
    const list = () => (
        <Box
            sx={{
                width: widgetWidth,
            }}
            id="display-as-modal-box"
            role="presentation">
            <UpdateConfig>
                <DrawerHeaderNew />
                {children}
            </UpdateConfig>
        </Box>
    );
    return (
        <div>
            <React.Fragment key={'right'}>
                {!state['right'] && !window.isIframe && (
                    <BookNowButton
                        buttonShouldSetOpen={true}
                        buttonaction={openDrawerFromButton()}
                    />
                )}
                <SwipeableDrawer
                    anchor={'right'}
                    open={state['right']}
                    // onClose={() => toggleDrawer('right', false)}
                    // onClose={(ev, reason) => toggleDrawer('right', false)}
                    onClose={handleClose}
                    onOpen={() => toggleDrawer('right', true)}>
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
