import React from 'react';
import {
    Grid,
    Typography,
    Stack,
    Divider,
    // ButtonBase,
    Chip,
    // Fab,
    Fade,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useSelector, dispatch } from 'store';
import { addToCart } from 'store/slices/cart';
import { setPackagesIncludedInCheckout } from 'store/slices/packages';

import BuyIcon from 'assets/icons/buyIcon';

import associatePassWithEvent from 'actions/checkout/associatePassWithEventNew';
import checkoutWithPasses from 'actions/checkout/checkoutWithPassesAndOrCredit';
import addToWaitlist from 'actions/checkout/addToWaitlist';
import getPackagesForCheckout from 'actions/packages/getPromoPackagesForCheckout';
import { checkifClassOccursBeforeNow } from 'helpers/correctForTimezone';

const renderSwitch = (sw) => {
    switch (true) {
        case sw < 600:
            return 2.5;
        case sw < 800:
            return 4.2;
        case sw < 1300:
            return 4;
        default:
            return 5.5;
    }
};
const renderTimeMargins = (sw) => {
    switch (true) {
        case sw < 600:
            return 0.5;
        case sw < 800:
            return 1;
        case sw < 1300:
            return 2;
        default:
            return 2;
    }
};
const renderSpaceForTime = (sw) => {
    switch (true) {
        case sw < 600:
            return 2.3;
        case sw < 800:
            return 2;
        case sw < 1300:
            return 1.2;
        default:
            return 1.2;
    }
};
const renderSpaceForTitle = (sw) => {
    switch (true) {
        case sw < 700:
            return 6.5;

        default:
            return 5.5;
    }
};
const renderMarginForCartText = (openType) => {
    if (openType === 'right') return 3.5;
    if (openType === 'full-page') return 5;
    return 3;
};

const EventItem = ({ event, isLast }) => {
    const navigate = useNavigate();
    const { showPricesWidget, color } = useSelector(
        (state) => state.dibsstudio.studioConfig,
    );
    const { availablePackagesNotBeingUsed, packagesBeingUsed } = useSelector(
        (state) => state.cart,
    );
    const [showPriceForThisClass, setShowPriceForThisClass] =
        React.useState(true);
    const [needToGetCheckoutPackages, setNeedToGetCheckoutPackages] =
        React.useState(false);
    const {
        dibsStudioId,
        buttonControlsWidget,
        widgetWidth,
        widgetOpenStyle,
        timeZone,
    } = useSelector((state) => state.dibsstudio.config);

    const classisbeforenow = checkifClassOccursBeforeNow(event, timeZone);

    const { itemsInCartHere } = useSelector((state) => state.cart);
    const { passes, numPublicPasses, numPrivatePasses, userid } = useSelector(
        (state) => state.thisuser,
    );
    const urlToLink = `/checkout/${dibsStudioId}`;
    const findEventInCart = itemsInCartHere.find((i) => i.eventid === event.id);
    const [marginTop, setMarginTop] = React.useState(5.5);
    const [timeMargin, setTimeMargin] = React.useState(2);
    const [spaceForTime, setSpaceForTime] = React.useState(1.2);
    const [isPrettyWide, setIsPrettyWide] = React.useState(true);
    const [spaceForClassname, setSpaceForClassname] = React.useState(5);
    const [cartTextMargin, setCartTextMargin] = React.useState(2.5);
    const [alreadyOnWaitlist, setAlreadyOnWaitlist] = React.useState(false);
    const [waitlistText, setWaitlistText] = React.useState('WAITLIST');
    // const [showWaitlistText, setShowWaitlistText] = React.useState(false);

    const [paddingToUse, setPaddingToUse] = React.useState(0);
    const [marginToUseClassNameLeft, setMarginToUseClassNameLeft] =
        React.useState(0);

    const [hasAtLeastOnePrivatePass, setHasAtLeastOnePrivatePass] =
        React.useState(false);
    const [hasAtleastOnePublicPass, setHasAtleastOnePublicPass] =
        React.useState(false);
    const [variantToUse, setVariantToUse] = React.useState('eventprice');
    const [classIsInPast, setClassIsInPast] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    let inCart = false;
    let quantity = 0;
    let quantityText = '';
    let dividerMargin = buttonControlsWidget ? 3.5 : 5.5;
    React.useEffect(() => {
        if (event.spots_booked >= event.seats) {
            event.isFull = true;
        }
        if (buttonControlsWidget) {
            setCartTextMargin(renderMarginForCartText(widgetOpenStyle));
        }

        if (window.innerWidth > 0 && window.innerWidth < 600) {
            setPaddingToUse(1);
            setMarginToUseClassNameLeft(1);
        } else if (buttonControlsWidget) {
            setPaddingToUse(3);
            setMarginToUseClassNameLeft(5);
        } else {
            setMarginToUseClassNameLeft(0);
        }
        setMarginTop(
            renderSwitch(
                buttonControlsWidget ? widgetWidth : window.innerWidth,
            ),
        );
        setTimeMargin(
            renderTimeMargins(
                buttonControlsWidget ? widgetWidth : window.innerWidth,
            ),
        );

        setSpaceForTime(
            renderSpaceForTime(
                buttonControlsWidget ? widgetWidth : window.innerWidth,
            ),
        );
        setSpaceForClassname(
            renderSpaceForTitle(
                buttonControlsWidget ? widgetWidth : window.innerWidth,
            ),
        );
        if (buttonControlsWidget ? widgetWidth : window.innerWidth < 800) {
            setIsPrettyWide(false);
        }
        if (event.private === true) {
            if (numPrivatePasses > 0) {
                setVariantToUse('strikethroughprice');
            }
        } else if (numPublicPasses > 0) {
            if (availablePackagesNotBeingUsed) {
                setVariantToUse('strikethroughprice');
            }
        }
    }, [
        availablePackagesNotBeingUsed,
        buttonControlsWidget,
        checked,
        event,
        event.private,
        numPrivatePasses,
        numPublicPasses,
        passes,
        widgetOpenStyle,
        widgetWidth,
    ]);

    React.useEffect(() => {
        const getCheckoutPackages = async () => {
            await getPackagesForCheckout(dibsStudioId).then((res) => {
                dispatch(setPackagesIncludedInCheckout(res));
                // setNeedToGetCheckoutPackages(false);
                // removing this bc we do not need it to check it every time
            });
        };
        if (needToGetCheckoutPackages) {
            getCheckoutPackages();
        }
    }, [needToGetCheckoutPackages, dibsStudioId]);
    if (findEventInCart) {
        inCart = true;
        quantity = findEventInCart.quantity;
        quantityText = `${quantity} in cart`;
        dividerMargin = 3;
    }

    let priceFormatted = '';
    if (showPricesWidget) {
        priceFormatted = `$${event.price}`;
    }
    const nameOfClass = event.eventName;
    const formattedName = nameOfClass.toUpperCase();
    const gotocart = () => {
        navigate(urlToLink);
    };
    const addItemToCart = async (event) => {
        if (classisbeforenow) {
            setClassIsInPast(true);
            setTimeout(() => {
                setClassIsInPast(false);
            }, 4000);
            return;
        }
        if (!needToGetCheckoutPackages) setNeedToGetCheckoutPackages(true);
        if (event.isFull) {
            await addToWaitlist(userid, event.id, 'widget', null)
                .then((res) => {
                    if (res.success) {
                        setWaitlistText('ON WAITLIST');
                        setAlreadyOnWaitlist(true);
                    } else {
                        console.log('did not get added');
                    }
                })
                .catch((err) => {
                    console.log('error adding to waitlist', err);
                });
            return;
        }
        const passtoinclude = await associatePassWithEvent(
            event,
            passes,
            packagesBeingUsed,
        );

        const passin = {
            event,
            howtopay: passtoinclude,
            dibsId: dibsStudioId,
        };
        dispatch(addToCart(passin));
    };
    let showWaitlistText = false;
    if (event.isFull) {
        showWaitlistText = true;
    }
    // var rect = thisElement.getBoundingClientRect();
    // console.log('rect is', rect);
    // const { y } = rect;
    // TO DO - check if I need to scrollTo on this - meaning, is it not visible in the screen?

    return (
        <Fade in timeout={450}>
            <Grid item xs={12} sx={{ mt: marginTop }} border={0}>
                <Grid
                    container
                    id={`event-info-container-${event.id}`}
                    alignItems="flex-start"
                    border={0}
                    justifyContent="flex-start"
                    sx={{
                        px: paddingToUse,
                    }}>
                    {/* time grid */}
                    <Grid
                        item
                        id="event-time-info"
                        xs={spaceForTime}
                        sx={{ ml: timeMargin }}
                        border={0}>
                        <Grid container>
                            <Stack>
                                <Grid item>
                                    <Typography variant="eventtime">
                                        {event.eventTime}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subeventtime">
                                        {event.minuteslong} mins
                                    </Typography>
                                </Grid>
                                {!isPrettyWide && (
                                    <Grid item sx={{ lineHeight: 0.9 }}>
                                        <Typography
                                            variant="subeventtime"
                                            sx={{ fontStyle: 'italic' }}>
                                            {event.locationName}
                                        </Typography>
                                    </Grid>
                                )}
                            </Stack>
                        </Grid>
                    </Grid>
                    {/* location grid */}
                    {isPrettyWide && (
                        <Grid item xs={1.75} sx={{ ml: 1 }} border={0}>
                            <Typography variant="eventlocation">
                                {event.locationName}
                            </Typography>
                        </Grid>
                    )}
                    {/* class instructor names grid */}
                    <Grid
                        item
                        id="class-name-info"
                        xs={spaceForClassname}
                        border={0}
                        sx={{ pl: 2.5, pr: 2 }}>
                        <Grid
                            container
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            sx={{
                                ml: marginToUseClassNameLeft,
                            }}>
                            <Stack>
                                <Grid item xs={12} sx={{ lineHeight: 1.3 }}>
                                    <Typography variant="eventname">
                                        {formattedName}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="eventinstructor">
                                        {event.instructorName}
                                    </Typography>
                                </Grid>
                            </Stack>
                        </Grid>
                    </Grid>
                    {/* price grid */}
                    {showPriceForThisClass && (
                        <Grid
                            item
                            id="price-info"
                            xs
                            sx={{ pr: window.innerWidth < 500 ? 1 : 2 }}
                            border={0}>
                            <Grid container justifyContent="center" border={0}>
                                <Typography variant={variantToUse}>
                                    {priceFormatted}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs sx={{ mr: 0 }} border={0}>
                        <Grid
                            container
                            justifyContent="flex-end"
                            border={0}
                            alignItems="center">
                            <Grid
                                item
                                border={0}
                                onClick={() => addItemToCart(event)}
                                id={`purchase-button-${event.id}`}>
                                <Stack id="buy-icon-stack">
                                    <BuyIcon
                                        color={color}
                                        id={event.id}
                                        incart={inCart}
                                        isFull={event.isFull}
                                        onWaitlist={alreadyOnWaitlist}
                                        tiny={window.innerWidth < 500}
                                        classisbeforenow={classisbeforenow}
                                    />
                                    {showWaitlistText && (
                                        <Grid
                                            item
                                            id="waitlist-text"
                                            border={0}>
                                            <Grid
                                                container
                                                border={0}
                                                justifyContent="flex-start">
                                                <Typography
                                                    variant="waitlist"
                                                    textAlign="right">
                                                    {waitlistText}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {inCart && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start">
                        <Grid item sx={{ mb: 0, mr: cartTextMargin }}>
                            <Chip
                                label={quantityText}
                                size="small"
                                sx={{ borderRadius: 2.5 }}
                                clickable
                                onClick={() => {
                                    gotocart();
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {classIsInPast && (
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start">
                        <Grid item sx={{ mb: 0, mr: cartTextMargin }}>
                            <Chip
                                label="This class is in the past and cannot be booked"
                                size="small"
                                sx={{ borderRadius: 2.5 }}
                                color="warning"
                            />
                        </Grid>
                    </Grid>
                )}
                {/* <Divider sx={{ mt: dividerMargin, mb: 0 }} /> */}
                {isLast ? null : <Divider sx={{ mt: dividerMargin, mb: 0 }} />}
            </Grid>
        </Fade>
    );
};

export default EventItem;
