import { Grid, Typography, Fade } from '@mui/material';

import { useSelector } from 'store';

import StaffItem from './staffItem';
import AllStaffItem from './allStaffItem';
import useTranslate from 'hooks/useTranslate';
import { capitalizeEntireString } from 'helpers';

const StaffList = ({ setIsChoosingProvider }) => {
    const translate = useTranslate();
    const test = false;
    const { appointmentStaff } = useSelector((state) => state.appointments);
    const chooseProviderTxt = translate(
        'choose-provider',
        'Choose A Provider For Your Service',
    );
    const chooseProviderTxtCapitalized =
        capitalizeEntireString(chooseProviderTxt);
    return (
        <Fade in appear timeout={600}>
            <Grid
                container
                id="staff-list-container"
                border={0}
                sx={{
                    mt: window.isTiny ? 2 : 4,
                    py: window.isTiny ? 1 : 2,
                    mb: 5,
                }}>
                <Grid item xs={12} border={0} id="choose-provider-service">
                    <Grid container justifyContent="center">
                        <Typography
                            variant={window.isTiny ? 'h5title' : 'h1title'}
                            textAlign="center"
                            sx={{
                                mb: 2,
                                mx: window.isTiny ? 4 : 0,
                                mt: window.isTiny ? 2 : 0,
                            }}>
                            {chooseProviderTxtCapitalized}
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        id="staff-list-items-container"
                        overflow="hidden"
                        justifyContent="center"
                        border={0}
                        sx={{
                            overflowY: 'scroll',
                            maxHeight: '480px',
                            pr: window.isTiny ? 0 : 0.5,
                        }}>
                        <Grid
                            item
                            xs={window.isTiny ? 12 : 11.5}
                            id="all-staff-item">
                            {test && (
                                <AllStaffItem
                                    allstaff={appointmentStaff}
                                    setIsChoosingProvider={
                                        setIsChoosingProvider
                                    }
                                />
                            )}
                            {appointmentStaff.map((staff) => (
                                <StaffItem
                                    staff={staff}
                                    key={staff.id}
                                    setIsChoosingProvider={
                                        setIsChoosingProvider
                                    }
                                />
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default StaffList;
