import { findIndex } from 'lodash';
import sortPasses from './sortPassesByPriority';

export default async function associatePassesWithEvents(events, passes) {

    const passespostfilter = await sortPasses(passes);
    let privatePassesUsed = 0;
    let groupPassesUsed = 0;
    let privatePassesAvailable = 0;
    let groupPassesAvailable = 0;
    let currentPassBeingUsed = 0;
    try {
        const { privateFinalPasses, groupFinalPasses } = passespostfilter;
        privatePassesAvailable = privateFinalPasses.length;
        groupFinalPasses.forEach((pass) => {
            const { usesCount, totalUses } = pass;
            const totalUsesToUse = totalUses ? totalUses : 999999;
            const totalAvailable = totalUsesToUse - usesCount;
            groupPassesAvailable += totalAvailable;
        });

        // groupPassesAvailable = groupFinalPasses.length;

        const assignPrivatePackages = (thisevent) => {
            const { studioPackage } = privateFinalPasses[privatePassesUsed];
            const { packageName } = studioPackage;
            const howpaying = {
                passid: privateFinalPasses[privatePassesUsed].id,
                method: 'with_passid',
                passname: packageName,
                quantity: 1,
                amountToCharge: {
                    total: 0,
                }
            }
            const newevent = {
                ...thisevent,
                howtopay: howpaying,
            };
            privatePassesUsed += 1
            return newevent;
        };
        // handle case where package does not apply
        const findCurrentPackage = async () => {
            let indextoreturn = 0;
            groupFinalPasses.forEach((pass, index) => {
                const { usesCount, totalUses } = pass;
                let availableuses = 0;
                if (totalUses === null) {
                    availableuses = 999;
                } else {
                    availableuses = totalUses - usesCount;
                }
                if (availableuses === 0) {
                    currentPassBeingUsed = index + 1;
                    indextoreturn = currentPassBeingUsed;
                }
            });
            return indextoreturn;
        };


        const handleCaseNoPassAvailable = async (thisevent) => {
            const howpayingnopass = {
                passid: 0,
                method: 'creditcard',
                passname: '',
                quantity: thisevent.quantity,
                amountToCharge: thisevent.price * thisevent.quantity,
            }
            const neweventtoreturn = {
                ...thisevent,
                howtopay: howpayingnopass,
            };
            return neweventtoreturn;
        };
        const assignGroupPackages = async (thisevent) => {
            groupPassesUsed += 1
            const findIndexToUse = await findCurrentPackage();
            const { studioPackage } = groupFinalPasses[findIndexToUse];
            const { packageName } = studioPackage;
            const isAvailability = groupPassesAvailable - groupPassesUsed >= 0 ? true : false;

            let howpaying;
            if (isAvailability) {
                howpaying = {
                    passid: groupFinalPasses[currentPassBeingUsed].id,
                    method: 'with_passid',
                    passname: packageName,
                    quantity: 1,
                    amountToCharge: 0,
                }
            } else {
                console.log('something saying not available');
                console.log('the event affected is', thisevent);
                const eventwithnopass = await handleCaseNoPassAvailable(thisevent);
                return eventwithnopass;
                // return await handleCaseNoPassAvailable(thisevent);
            }

            const newevent = {
                ...thisevent,
                howtopay: howpaying,
            };
            return newevent;
        };

        const newcartPrepared = await events.map(async (event) => {
            let newevent;
            const isPrivate = event.private;
            if (isPrivate) {
                if (privatePassesAvailable > privatePassesUsed) {
                    newevent = assignPrivatePackages(event);
                    // handle for case where there are two spots or more
                } else {
                    newevent = await handleCaseNoPassAvailable(event);
                }
            } else {
                if (groupPassesAvailable > groupPassesUsed) {
                    newevent = await assignGroupPackages(event);
                } else {
                    newevent = await handleCaseNoPassAvailable(event);
                }
            }
            return newevent;
        });
        const toreturn = await Promise.all(newcartPrepared);
        return toreturn;

    } catch (err) {
        console.log('err from association passes with events', err);
    }

}
