// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const initialState = {
    appointmentTypes: [],
    apptCategories: [],
    isLoading: false,
    hasError: false,
    errorMsg: '',
    selectedId: null,
    selectedIdsMulti: [],
    appointmentStaff: [],
    selectedStaff: null,
    datetosearch: null,
    apptToConfirm: null,
    openTime: '',
    closeTime: '',
    multiModeOn: false,
    apptIsConfirmed: false,
};

const appointments = createSlice({
    name: 'appointments',
    initialState,
    reducers: {
        // HAS ERROR
        setHasError(state, action) {
            // state.hasError = false;
            // state.hasError = action.payload;
            return {
                ...state,
                hasError: action.payload,
            };
        },
        setApptStaff(state, action) {
            return {
                ...state,
                appointmentStaff: action.payload,
            };
        },
        setApptCategories(state, action) {
            return {
                ...state,
                apptCategories: action.payload,
            };
        },
        setErrorMsg(state, action) {
            return {
                ...state,
                errorMsg: action.payload,
            };
        },
        setSelectedStaff(state, action) {
            return {
                ...state,
                selectedStaff: action.payload,
            };
        },
        setApptIsConfirmed(state, action) {
            return {
                ...state,
                apptIsConfirmed: action.payload,
            };
        },
        clearSelectedStaff(state, action) {
            return {
                ...state,
                selectedStaff: null,
            };
        },
        setApptToConfirm(state, action) {
            return {
                ...state,
                apptToConfirm: action.payload,
            };
        },
        setDateToSearch(state, action) {
            return {
                ...state,
                datetosearch: action.payload,
            };
        },
        setSelectedId(state, action) {
            return {
                ...state,
                selectedId: action.payload,
            };
        },
        setSelectedIdsMulti(state, action) {
            const { selectedIdsMulti } = state;
            if (!selectedIdsMulti.includes(action.payload)) {
                selectedIdsMulti.push(action.payload);
            }
        },
        clearSelectedIdsMulti(state, action) {
            return {
                ...state,
                selectedIdsMulti: [],
            };
        },
        removeSelectedIdsMulti(state, action) {
            const { selectedIdsMulti } = state;
            selectedIdsMulti.filter((id) => id !== action.payload);
            const newIds = selectedIdsMulti.filter(
                (id) => id !== action.payload,
            );
            return {
                ...state,
                selectedIdsMulti: newIds,
            };
        },
        setMultiModeOn(state, action) {
            return {
                ...state,
                multiModeOn: action.payload,
            };
        },
        // SET APPT TYPES
        setAppointmentTypes(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                appointmentTypes: action.payload,
            };
        },
        setAppointmentOpenCloseTimes(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                openTime: action.payload.openTime,
                closeTime: action.payload.closeTime,
            };
        },
        // SET LOADING
        setLoading(state, action) {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
    },
});

// Reducer
export default appointments.reducer;
export const {
    setHasError,
    setAppointmentTypes,
    setErrorMsg,
    setLoading,
    setSelectedId,
    setSelectedStaff,
    clearSelectedStaff,
    setApptCategories,
    setApptStaff,
    setDateToSearch,
    setApptToConfirm,
    clearSelectedIdsMulti,
    setAppointmentOpenCloseTimes,
    setSelectedIdsMulti,
    setMultiModeOn,
    removeSelectedIdsMulti,
    setApptIsConfirmed,
} = appointments.actions;

// ----------------------------------------------------------------------
