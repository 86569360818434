import { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import { dispatch, useSelector } from 'store';
import {
    setPageSelected,
    setShowingSchedule,
    setShowingAccount,
    setShowingAppointments,
} from 'store/slices/menu';

const ScheduleIcon = ({ on, color }) => {
    let { dibsId } = useParams();
    const colortoshow = `#${color}`;
    const navigate = useNavigate();
    const [oncolor, setOnColor] = useState(colortoshow);
    const { isFiltered, typeOfFilter, dateFilteredTo } = useSelector(
        (state) => state.menu,
    );
    const [urlLink, setUrlLink] = useState('');
    useEffect(() => {
        if (!on) {
            setOnColor('#CED4DA');
        }
        if (on) {
            setOnColor(colortoshow);
        }
    }, [colortoshow, on]);
    useEffect(() => {
        if (isFiltered) {
            if (typeOfFilter === 'date') {
                setUrlLink(
                    `/schedule/${dibsId}?filtered=true&datetofilter=${dateFilteredTo}`,
                );
            }
        } else {
            setUrlLink(`/schedule/${dibsId}`);
        }
    }, [isFiltered, typeOfFilter, dateFilteredTo, dibsId]);
    const goSchedule = () => {
        dispatch(setPageSelected('schedule'));
        dispatch(setShowingSchedule(true));
        dispatch(setShowingAccount(false));
        dispatch(setShowingAppointments(false));
        navigate(urlLink);
    };

    return (
        <Grid item onClick={() => goSchedule()}>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                    icon="fa-circle"
                    color={oncolor}
                    transform="grow-11"
                />
                <FontAwesomeIcon
                    icon="fa-light fa-calendar-lines"
                    color="#fff"
                    transform="grow-2"
                />
            </span>{' '}
        </Grid>
    );
};
export default ScheduleIcon;
