import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Typography,
    TextField,
    Button,
    Collapse,
} from '@mui/material';
import BuyIcon from 'assets/icons/buyIconNoCircle';

import verifyCode from 'actions/promo-codes/verifyCode';

import { useSelector } from 'store';

const ErrorMessageComponent = ({ promoCodeHasError, errorMsgPc }) => {
    return (
        <Collapse in={promoCodeHasError} timeout={300}>
            <Grid item sx={{ mb: 3.5, mt: 3, mr: 0.5 }} id="error-promo-code">
                <Grid container justifyContent="flex-end">
                    <Grid item>
                        <Typography color="error" variant="packagehead">
                            {errorMsgPc}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Collapse>
    );
};

const PromoCodes = ({ color }) => {
    const [showPromoField, setShowPromoField] = useState(false);
    const [promoCodeHasError, setPromoCodeHasError] = useState(false);
    const [errorMsgPc, setErrorMsgPc] = useState('');
    const { cartPreparedForCheckout } = useSelector((state) => state.cart);
    const { userid } = useSelector((state) => state.thisuser);
    const handleClickPromo = () => {
        setShowPromoField(!showPromoField);
    };
    const handlePromoCodeSubmit = async () => {
        // TESTING DATA AT THE MOMENT
        console.log('this userid is', userid);
        const res = await verifyCode(
            'HOLIDAY50',
            userid, // add a value here for testing
            'not a pack',
            218, // dibs id
            cartPreparedForCheckout,
        );
        const { displayError, errorMsg, showLogin } = res;
        if (displayError) {
            setErrorMsgPc(errorMsg);
            setPromoCodeHasError(true);
            setTimeout(() => {
                setPromoCodeHasError(false);
                setErrorMsgPc('');
            }, 15000);
            if (showLogin) {
                console.log('show the user an option to login');
            }
        } else {
            console.log('promo code is valid - now calculate the discount');
        }
    };
    useEffect(() => {
        return () => {
            setShowPromoField(false);
        };
    }, []);

    const PromoCodeField = () => {
        return (
            <div>
                <Box
                    sx={{
                        mt: 2,
                        mr: 1.35,
                    }}
                    id="promo-code-box-show"
                    border={0}>
                    <Grid container justifyContent="flex-end" sx={{ mr: 0.5 }}>
                        <Grid
                            item
                            id="promo-code-item-determines-size"
                            border={0}
                            xs={11}
                            md={7}
                            lg={5}
                            xl={4}>
                            <Grid item sx={{ mb: 0.5, ml: 0.5 }}>
                                <Typography variant="subtitle1">
                                    Enter your promo code or gift card number
                                </Typography>
                            </Grid>
                            <Grid item sx={{ mb: 1.5 }} id="enter-promo-code">
                                <TextField fullWidth />
                            </Grid>
                            <ErrorMessageComponent
                                errorMsgPc={errorMsgPc}
                                promoCodeHasError={promoCodeHasError}
                            />
                            <Grid
                                item
                                id="submit-promo-code-btn"
                                border={0}
                                sx={{ mr: 0.35 }}>
                                <Grid container justifyContent="flex-end">
                                    <Button
                                        variant="checkoutinverse"
                                        sx={{ mr: 1.25, cursor: 'pointer' }}
                                        onClick={() => {
                                            setShowPromoField(false);
                                        }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        variant="checkout"
                                        onClick={() => {
                                            handlePromoCodeSubmit();
                                        }}>
                                        Apply
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    };
    const ButtonForShowPromoCode = () => {
        return (
            <div>
                <Box
                    sx={{ mt: 2, mr: 1.35, cursor: 'pointer' }}
                    id="promo-code-box-click-to-add"
                    onClick={handleClickPromo}>
                    <Grid container justifyContent="flex-end" sx={{ mr: 0.5 }}>
                        <Grid item id="promo-code-plus">
                            <Typography variant="subtitle1">
                                <BuyIcon
                                    color={color}
                                    id="promo-codes-buy-button"
                                />
                            </Typography>
                        </Grid>
                        <Grid item id="promo-code-item">
                            <Typography
                                variant="subtitle1"
                                color={color}
                                sx={{ fontWeight: 500 }}>
                                Add Promo Code Or Gift Card
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        );
    };
    return (
        <>
            <Collapse in={showPromoField} timeout={300}>
                <PromoCodeField />;
            </Collapse>
            <Collapse in={!showPromoField} timeout={300}>
                <ButtonForShowPromoCode />
            </Collapse>
        </>
    );
};

export default PromoCodes;
