import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function sendConfirmationEmail(
    dibsStudioId,
    userid,
    creditApplied,
    totalAfterCreditApplied,
    packageItemsInCart,
    emailType,
) {
    console.log('\n\n\n\n\nLOG 92 - total after credit applied', totalAfterCreditApplied);
    console.log('package items in cart are', packageItemsInCart);
    console.log('\n\n\n\n\n');
    let templatename = 'package-purchase-with-autopay';
    console.log('packageItemsInCart in send confirmation package is', packageItemsInCart);
    const packageIsArray = Array.isArray(packageItemsInCart);

    if (packageIsArray) {
        if (packageItemsInCart[0].autopay === 'NONE') {
            templatename = 'package-purchase-no-autopay';
        }
    } else {
        if (packageItemsInCart.autopay === 'NONE') {
            templatename = 'package-purchase-no-autopay';
        }
    }

    if (emailType === 'autopayYes') {
        templatename = 'package-purchase-with-autopay';
    }
    const datatosend = {
        dibsId: dibsStudioId,
        userid,
        templateName: templatename,
        source: 'widget',
        creditApplied,
        totalAfterCreditApplied,
        packageItemsInCart,
    };
    const url = getApiUrl('/send-email-confirmations-package');
    await axios
        .post(url, { data: datatosend })
        .then((res) => {
            const { data } = res;
            console.log('\nEmail confirmation sent', data);
        })
        .catch((error) => {
            console.log(
                'there was an error - sending confirmaton email',
                error,
            );
        });
}
