import React from 'react';
import { Grid, Typography, Fade, Button } from '@mui/material';
// import Typography from '@mui/joy/Typography';
import { alpha } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import {
    setSelectedId,
    setSelectedStaff,
    // setSelectedIdsMulti,
    // setMultiModeOn,
    removeSelectedIdsMulti,
} from 'store/slices/appointments';
// import DotIcon from 'assets/icons/dotIcon';
import StaffList from 'components/appointment-types/staff/staffList';
import CalendarBox from './datetimecalendar/calendarIndex';
import configs from 'helpers/specialConfigs';
import ApptMultiSub from './apptMultiSubTitle';
import useTranslate from 'hooks/useTranslate';

const ApptItem = ({ apptList, isLast }) => {
    const translate = useTranslate();
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const colortoshow = `#${color}`;
    const [isChoosingProvider, setIsChoosingProvider] = React.useState(false);
    const { selectedStaff } = useSelector((state) => state.appointments);
    // const [multiModeOn, setMultiModeOn] = React.useState(false);

    const [isShowMore, setIsShowMore] = React.useState(false);

    const [checked, setChecked] = React.useState(true);
    const [timeToChooseDate, setTimeToChooseDate] = React.useState(false);
    const selectedServicesTxt = translate(
        'selected-services',
        'Selected Services',
    );
    const selectStaffTxt = translate(
        'choose-staff-member',
        'Next: Choose A Staff Member',
    );
    const providerTxt = translate('provider', 'Provider');
    const editTxt = translate('edit', 'Edit');

    React.useEffect(() => {
        if (selectedStaff !== null) {
            setTimeToChooseDate(true);
        } else {
            setTimeToChooseDate(false);
        }
    }, [selectedStaff]);

    const unselectThisProvider = () => {
        dispatch(setSelectedId(null));
        setIsChoosingProvider(false);
        dispatch(setSelectedStaff(null));
        setTimeToChooseDate(false);
    };
    const goBackTextAction = () => {
        setTimeToChooseDate(false);
        setIsChoosingProvider(true);
    };
    const giveOptionChooseStaffMember = () => {
        setIsChoosingProvider(true);
        setTimeToChooseDate(false);
    };
    const transparent = alpha(colortoshow, 0.02);
    const specialBgColor = configs(dibsStudioId, 'bgColorAppts');
    let bgColorToUse = '#fff';
    if (specialBgColor !== undefined) {
        bgColorToUse = specialBgColor;
    }
    let borderColorToUse = '#d9d9d9';
    const specialBorderColor = configs(dibsStudioId, 'borderColorAppts');
    let chooseMoreServicesText =
        '+ select one of the options below to add more services';
    if (dibsStudioId === '226') {
        chooseMoreServicesText =
            '+ RECEIVE 30% OFF ON ANY ADDITIONAL SERVICE AVAILABLE BELOW';
    }
    if (specialBorderColor !== undefined) {
        borderColorToUse = specialBorderColor;
    }
    let transparencyToUse = transparent;
    const specialTransparency = configs(dibsStudioId, 'transparencyAppts');
    if (specialTransparency !== undefined) {
        transparencyToUse = specialTransparency;
    }
    if (apptList === undefined) return null;
    if (apptList.length === 0) return null;
    return (
        <Fade in appear timeout={450}>
            <Grid
                item
                xs={12}
                sx={{ mt: 2.5 }}
                border={0}
                id="grid-item-appointment">
                <Grid
                    container
                    alignItems="flex-start"
                    border={0}
                    // sx={{ mx: 3 }}
                    justifyContent="space-evenly">
                    {/* time grid */}
                    <Grid
                        item
                        sx={{ mx: window.innerWidth < 650 ? 0 : 5 }}
                        backgroundColor={
                            checked ? transparencyToUse : bgColorToUse
                        }
                        border={0}
                        xs={12}
                        md={10.5}
                        lg={10}>
                        <Grid
                            container
                            border={0}
                            id="appointment-item-container">
                            <Grid
                                item
                                id="item-holding-appt-option"
                                border={4}
                                borderRadius={1.2}
                                borderColor={
                                    checked ? colortoshow : borderColorToUse
                                }
                                xs={12}
                                sx={{
                                    p: 2.25,
                                    '&:hover': {
                                        borderColor: colortoshow,
                                    },
                                }}>
                                <Grid
                                    container
                                    border={0}
                                    flex
                                    justifyContent="center"
                                    id="appointment-item-container-down-one-multi"
                                    sx={{
                                        pl: window.isTiny ? 0 : 0.75,
                                        pt: window.isTiny ? 0 : 0.5,
                                    }}>
                                    <Grid
                                        item
                                        xs={12}
                                        id="title-appt-multi"
                                        border={0}>
                                        <Grid container justifyContent="center">
                                            <Typography
                                                variant="apptTitle"
                                                sx={{
                                                    fontWeight: 700,
                                                    my: 2,
                                                }}>
                                                {selectedServicesTxt}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {apptList.map((appt, i) => {
                                        return (
                                            <ApptMultiSub
                                                appt={appt}
                                                i={`appt-multi-sub-${i}`}
                                                key={`appt-multi-sub-${i}`}
                                            />
                                        );
                                    })}
                                    <Grid
                                        item
                                        border={0}
                                        xs={12}
                                        md={11}
                                        id="multi-confirmation-staff-selection">
                                        <Fade in timeout={950}>
                                            <Grid item xs={12} border={0}>
                                                {!isChoosingProvider &&
                                                    selectedStaff === null && (
                                                        <Grid
                                                            container
                                                            justifyContent="center">
                                                            <Grid
                                                                item
                                                                xs={11}
                                                                border={0}
                                                                sx={{
                                                                    // pr: 3,
                                                                    mt: 3,
                                                                    pt: 4,
                                                                    pb: 1,
                                                                }}>
                                                                <Grid
                                                                    container
                                                                    justifyContent="flex-end">
                                                                    <Button
                                                                        variant="checkoutUpdated"
                                                                        type="submit"
                                                                        onClick={
                                                                            giveOptionChooseStaffMember
                                                                        }>
                                                                        {
                                                                            selectStaffTxt
                                                                        }
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Grid
                                                                    border={0}
                                                                    container
                                                                    justifyContent="flex-end"
                                                                    id="select-provider-text-multi-option"
                                                                    sx={{
                                                                        mt: 4,
                                                                        pr: 0.5,
                                                                    }}>
                                                                    <Typography variant="eventwithcolor">
                                                                        {' '}
                                                                        {
                                                                            chooseMoreServicesText
                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                {!isChoosingProvider &&
                                                    selectedStaff !== null && (
                                                        <Fade
                                                            in={checked}
                                                            timeout={600}>
                                                            <Grid
                                                                item
                                                                id="provider-select-item-multi"
                                                                xs={12}
                                                                sx={{
                                                                    mt: 6,
                                                                    ml: 1,
                                                                }}>
                                                                <Typography variant="providerUpdated">
                                                                    {
                                                                        providerTxt
                                                                    }
                                                                    :{' '}
                                                                    {
                                                                        selectedStaff.firstname
                                                                    }{' '}
                                                                    {
                                                                        selectedStaff.lastname
                                                                    }
                                                                </Typography>
                                                                <Typography
                                                                    variant="eventwithcolor"
                                                                    onClick={() =>
                                                                        unselectThisProvider()
                                                                    }>
                                                                    {'   '}
                                                                    {editTxt}
                                                                </Typography>
                                                            </Grid>
                                                        </Fade>
                                                    )}
                                                {isChoosingProvider && (
                                                    <Fade
                                                        in={checked}
                                                        timeout={600}>
                                                        <Grid item xs={12}>
                                                            <StaffList
                                                                setIsChoosingProvider={
                                                                    setIsChoosingProvider
                                                                }
                                                            />
                                                        </Grid>
                                                    </Fade>
                                                )}
                                                {timeToChooseDate && (
                                                    <Fade
                                                        in={checked}
                                                        timeout={600}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{ mt: 1 }}
                                                            border={0}>
                                                            <CalendarBox
                                                                gobackaction={
                                                                    goBackTextAction
                                                                }
                                                                setTimeToChooseDate={
                                                                    setTimeToChooseDate
                                                                }
                                                            />
                                                        </Grid>
                                                    </Fade>
                                                )}
                                            </Grid>
                                        </Fade>
                                    </Grid>
                                    <Grid
                                        item
                                        id="appointment-title-container-multi"
                                        xs={10}
                                        sx={{ mb: 1 }}
                                        border={0}>
                                        <Grid container>
                                            <Grid
                                                item
                                                id="typography-text-check"
                                                xs={12}
                                                sx={{ pt: 0.6 }}>
                                                {apptList.appointment_type}
                                            </Grid>
                                            {isShowMore && (
                                                <Fade in timeout={600}>
                                                    <Grid
                                                        item
                                                        xs={
                                                            window.isTiny
                                                                ? 11
                                                                : 9
                                                        }
                                                        sx={{
                                                            pt: 2,
                                                            mr: 5,
                                                            mb: 2,
                                                        }}></Grid>
                                                </Fade>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    );
};

export default ApptItem;
