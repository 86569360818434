import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';

import createStripePaymentIntent from 'actions/stripe/createStripePaymentIntentConnected';
import setupIntent from 'actions/stripe/createStripeSetupIntent';
import { useSelector, dispatch } from 'store';

import { auth, logout } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

import { setIsLoggedIn, setLastLocation } from 'store/slices/thisuser';
import {
    setShowChooseSingleOrPackage,
    setShowSummaryPaymentScreen,
    setShowCheckoutButton,
    setShowAllExistingPaymentOptions,
    setIsUsingCreditToCoverClass,
} from 'store/slices/cart';
import { roundtoN } from 'helpers';
import { formatDollarAmountTwoDigits } from 'helpers';

const PayPerClassCard = ({
    isWideButNotTooWide,
    setShowConfirmButton,
    setShowCCForm,
    setShowPaymentOptions,
    setHasChosen,
}) => {
    const theme = useTheme();
    const location = useLocation();
    const [user, loading] = useAuthState(auth);
    const [hasAuthUser, setHasAuthUser] = useState(false);
    const { dibsId } = useParams();
    const navigate = useNavigate();
    const {
        ccinfo,
        stripeid,
        stripeidForThisStudio,
        numCreditCardsOnFile,
        userid,
        credit,
        email,
        firstname,
        lastname,
    } = useSelector((state) => state.thisuser);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const {
        creditApplied,
        totalAfterCreditApplied,
        cartPreparedForCheckout,
        taxrateforclasses,
        showSummaryPaymentScreen,
        showCheckoutButton,
    } = useSelector((state) => state.cart);
    const [ccText, setCCText] = useState(
        'Click below to checkout without purchasing a package.',
    );
    const [payWithCardText, setPayWithCardText] = useState('Pay With Card');
    useEffect(() => {
        if (numCreditCardsOnFile === 0) {
            setCCText('Click below to checkout without purchasing a package.');
        } else {
            setCCText('Click below to checkout using a credit or debit card.');
            if (creditApplied > 0) {
                setCCText(
                    `Click below to checkout using your credit. You have ${formatDollarAmountTwoDigits(
                        credit,
                    )} in credit.`,
                );
            }
        }
    }, [
        ccinfo,
        stripeid,
        stripeidForThisStudio,
        numCreditCardsOnFile,
        creditApplied,
        credit,
    ]);
    useEffect(() => {
        if (creditApplied > 0 && totalAfterCreditApplied === 0) {
            setPayWithCardText(`Apply Credit`);
        } else {
            setPayWithCardText(`Pay With Card`);
        }
    }, [creditApplied, totalAfterCreditApplied]);
    useEffect(() => {
        if (user) {
            setHasAuthUser(true);
            dispatch(setIsLoggedIn(true));
        }

        // console.log('user confirmed - load page');
        if (!user) {
            dispatch(setLastLocation(`/checkout/${dibsId}${location.search}`));
            window.localStorage.setItem(
                'lastpagevisited',
                `/checkout/${dibsId}${location.search}`,
            );
            window.localStorage.setItem(
                'lastlocation',
                `/checkout/${dibsId}${location.search}`,
            );
            if (!loading) {
                dispatch(setIsLoggedIn(false));
                setHasAuthUser(false);
            }
        }
    }, [user, dibsId, loading, location]);
    const clickButton = async () => {
        console.log('\n\n\n\nclicked pay with card (or credit)');
        setShowPaymentOptions(false);
        setShowConfirmButton(true);
        setHasChosen(true);
        dispatch(setShowChooseSingleOrPackage(false));

        if (payWithCardText === 'Pay With Card') {
            if (!hasAuthUser) {
                console.log(
                    'we do not register a user right now - will redirect to login - check to see user',
                );
                console.log('user is', user);
                setTimeout(() => {
                    navigate(`/login/${dibsId}${location.search}`);
                }, 10000);
            }
            dispatch(setShowSummaryPaymentScreen(true));
            console.log('stripeid is', stripeid);
            if (!stripeid) {
                console.log('no stripe id - handle');
                return;
            }
            const options = {
                dibsStudioId,
                customerid: stripeid,
                userid,
                cart: cartPreparedForCheckout,
                taxrate: taxrateforclasses,
            };
            if (numCreditCardsOnFile === 0) {
                // create a stripe payment intent on connected account
                await setupIntent(
                    stripeid,
                    userid,
                    email,
                    `${firstname} ${lastname}`,
                    dibsId,
                    true,
                )
                    .then((res) => {
                        const { clientSecret } = res;
                        sessionStorage.setItem('clientSecret', clientSecret);
                        console.log(
                            'setting client secret - line 97 - pay per class card',
                            res.clientSecret,
                        );
                        window.localStorage.setItem(
                            'clientSecret',
                            clientSecret,
                        );
                        window.localStorage.setItem(
                            'clientSecretDate',
                            new Date().toISOString(),
                        );
                        window.localStorage.setItem('intentPlatform', 'dibs'); // can be dibs or not dibs - so like studio or connected
                        window.localStorage.setItem('intentType', 'setup');
                        window.localStorage.setItem(
                            'intentSetFrom',
                            'payperclasscard',
                        );
                    })
                    .catch((err) => {
                        console.log(
                            'there was an error creating the payment intent',
                            err,
                        );
                    });
                // setShowCCForm(true); TODO - not sure if this is needed anymore
            }
        } else {
            console.log('paying with credit');
            console.log('setting checkout buttton to true');
            dispatch(setShowCheckoutButton(true));
            dispatch(setShowAllExistingPaymentOptions(false));
            dispatch(setIsUsingCreditToCoverClass(true));
        }
        // setShowConfirmButton(true);
    };

    const thisbutton = (
        <React.Fragment>
            <Button
                size="small"
                onClick={clickButton}
                id="button-to-select-pay-per-class"
                sx={{
                    width: '142px',
                    mt: 0.75,
                    mb: 0.75,
                    mx: 1,
                    lineHeight: 1,
                    color: '#ffffff',
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.light,
                    },
                }}>
                {payWithCardText}
            </Button>
        </React.Fragment>
    );

    return (
        <Grid container>
            <Grid item id="holding-pay-per-class-card-option" xs={10.5}>
                <Box id="box-holding-card">
                    <Card variant="outlined">
                        <CardContent>
                            <Grid
                                container
                                justifyContent="center"
                                id="pay-per-class-container">
                                <Typography
                                    variant="packageNamesTiny"
                                    align="center"
                                    color={theme.palette.primary.main}
                                    sx={{ mb: 1 }}>
                                    PAY PER CLASS
                                </Typography>
                            </Grid>
                            <Grid container justifyContent="center">
                                <Grid item xs={12} sx={{ mt: 1, mb: 0 }}>
                                    <Typography
                                        variant="eventlocation"
                                        display="block"
                                        textAlign="center">
                                        {ccText}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mb: 0.5,
                                pt: 0.75,
                                mx: 1,
                            }}>
                            {thisbutton}
                        </CardActions>
                    </Card>
                </Box>
            </Grid>
        </Grid>
    );
};
export default PayPerClassCard;
