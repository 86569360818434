import axios from 'axios';
// API ID 1: 1e5a1a0a-1b0a-4b0e-8b0a-1a0b0a0b0a0b

export default async function dropEvent(
    eventtodrop,
    userid,
    dibsStudioId,
    earlyDrop,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/drop-event`;
    let datatosend = {};
    const drop = async () => {
        await axios
            .post(basicurl, {
                eventtodrop,
                userid,
                dibsStudioId,
                earlyDrop,
                dropLocationFrom: 'widget',
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error dropping event: ${err}`);
                return err;
            });
    };
    await drop();
    return datatosend;
}
