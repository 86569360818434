import React from 'react';
import { gridSpacing } from 'store/constant';

import { dispatch } from 'store';
import { setPhone, setBirthday } from 'store/slices/thisuser';

// material-ui
import {
    Grid,
    Button,
    InputLabel,
    Typography,
    TextField,
    Link,
    Fade,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import LoginCard from 'ui-component/cards/AccountSubCardNew';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

const validationSchema = yup.object().shape({
    phone: yup.string().phone('US', 'Please enter a valid phone number'),
    birthday: yup.date('MM/DD'),
});

// ============================|| NEW - LOGIN PAGE ||============================ //

const LoginAuthenticationCard = ({ setCurrentStep }) => {
    const skiptext = 'Skip>>';
    const goNextScreen = () => {
        console.log('goNextScreen');
        setCurrentStep('setpassword');
    };
    const formik = useFormik({
        initialValues: {
            phone: '',
            birthday: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                dispatch(setBirthday(values.birthday));
                dispatch(setPhone(values.phone));
                goNextScreen();
            } catch (error) {
                console.log(`error was caught: ${JSON.stringify(error)}`);
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });

    return (
        <Grid container justifyContent="center">
            <Grid item xs={11} md={5} sx={{ mb: 5 }}>
                <Fade in={true} timeout={500}>
                    <LoginCard title="Register">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ ml: 1, mr: 1 }}>
                                <Typography variant="regularText">
                                    Enter your phone number and birthday
                                    (optional)
                                </Typography>
                                <form
                                    className="form-profile"
                                    onSubmit={formik.handleSubmit}>
                                    <Grid
                                        container
                                        justifyContent="space-around">
                                        <Grid
                                            item
                                            xs={10}
                                            md={5}
                                            sx={{ mt: 2, ml: 0.5 }}>
                                            <InputLabel>Phone</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="phone"
                                                value={
                                                    formik.values.phone || ''
                                                }
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="XXX-XXX-XXXX"
                                                error={
                                                    formik.touched.phone &&
                                                    Boolean(formik.errors.phone)
                                                }
                                                helperText={
                                                    formik.touched.phone &&
                                                    formik.errors.phone
                                                }
                                                sx={{ mt: 1 }}>
                                                Phone
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            md={5}
                                            sx={{ mt: 2, ml: 0.5 }}>
                                            <InputLabel>Birthday</InputLabel>
                                            <TextField
                                                fullWidth
                                                name="birthday"
                                                value={formik.values.birthday}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                placeholder="MM/DD"
                                                error={
                                                    formik.touched.birthday &&
                                                    Boolean(
                                                        formik.errors.birthday,
                                                    )
                                                }
                                                helperText={
                                                    formik.touched.birthday &&
                                                    formik.errors.birthday
                                                }
                                                sx={{ mt: 1 }}>
                                                Birthday
                                            </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={11}
                                            sx={{ mt: 4, mb: 2.5 }}>
                                            <Grid
                                                container
                                                justifyContent="flex-start">
                                                <Grid item xs={11} md={7}>
                                                    <Button
                                                        variant="checkout"
                                                        type="submit"
                                                        // fullWidth
                                                        disabled={
                                                            formik.isSubmitting
                                                        }>
                                                        Next
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={1} md={4}>
                                                    <Grid
                                                        container
                                                        justifyContent="flex-end"
                                                        alignItems="center">
                                                        <Grid
                                                            item
                                                            xs={3}
                                                            sx={{ mt: 1 }}>
                                                            <Link
                                                                onClick={() =>
                                                                    goNextScreen()
                                                                }>
                                                                <Typography>
                                                                    {skiptext}
                                                                </Typography>
                                                            </Link>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </LoginCard>
                </Fade>
            </Grid>
        </Grid>
    );
};
export default LoginAuthenticationCard;
