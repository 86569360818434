export default function putEventsBackTogether(k) {
    const items = [...k];
    const itemsToReturn = [];
    items.forEach((item, j) => {
        const index = itemsToReturn.findIndex(
            (i) =>
                i.eventid === item.eventid &&
                i.howtopay.passid === item.howtopay.passid,
        );
        if (index === -1) {
            itemsToReturn.push(item);
        } else {
            const { howtopay, quantity } = itemsToReturn[index];
            const newhowtopay = {
                ...howtopay,
                quantity: howtopay.quantity + item.howtopay.quantity,
            };
            const thisitem = {
                ...item,
                howtopay: newhowtopay,
                quantity: item.quantity + quantity,
            };
            itemsToReturn[index] = thisitem;
        }
    });
    return itemsToReturn;
}
