import axios from 'axios';
import { getApiUrl, calculateDiscountedTax } from 'helpers';
import isUserNew from 'actions/isUserNew';

const applyPromo = async (pack, promo) => {
    let newsubtotal = 0;
    let newtax = 0;
    let newtotal = 0;
    let discount = 0;
    if (promo.type === 'CASH_OFF') {
        newsubtotal = pack.price - promo.amount;
        discount = promo.amount;
        if (newsubtotal < 0) {
            newsubtotal = 0;
            discount = pack.price;
        }
    }
    // pack.price = newsubtotal;
    pack.discountedprice = newsubtotal;
    pack.discountfrompromo = discount;
    newtax = await calculateDiscountedTax(pack);
    newtotal = pack.price + pack.tax;
    newtotal = newsubtotal + newtax;
    pack.tax = newtax;
    pack.totalprice = newtotal;
    return pack;
};

export default async function applyPromoCode(
    // promo,
    userid,
    pack,
    dibsId,
    promoCodeInfo,
    email,
) {
    let isNew = false;
    let returndata = {
        eligible: false,
        reason: '',
        pricechanged: false,
        newpackage: null,
    };
    // new clients only
    if (promoCodeInfo.first_time_studio_dibs) {
        await isUserNew(email, dibsId)
            .then((res) => {
                if (res) isNew = res.isNew;
            })
            .then(() => {
                if (!isNew) {
                    returndata.reason =
                        'Sorry, this promo code is only valid for new clients.';
                    return returndata;
                }
            });
    }
    // all clients
    const { user_usage_limit, code_usage_limit } = promoCodeInfo;
    const checkusageLimit = async () => {
        if (user_usage_limit > 0 || code_usage_limit > 0) {
            const url = getApiUrl('/check-promocode-usage-limits');
            await axios
                .post(url, {
                    promoinfo: promoCodeInfo,
                    userid,
                })
                .then((res) => {
                    const { data } = res;
                    if (data.atUserLimit)
                        returndata.reason =
                            "You've already used this promo code the maximum number of times.";
                    else if (data.atCodeLimit)
                        returndata.reason =
                            'This promo code has already been used the maximum number of times.';
                    else {
                        returndata.eligible = true;
                    }
                });
        }
        return;
    };
    await checkusageLimit().then(async () => {
        await applyPromo(pack, promoCodeInfo).then((newpack) => {
            returndata.eligible = true;
            returndata.pricechanged = true;
            returndata.newpackage = newpack;
        });
    });

    return returndata;
}
