import { useState, useEffect } from 'react';
import { sum } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector, dispatch } from 'store';
import { updateStripeIds, setStripeIdThisStudio } from 'store/slices/thisuser';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Fab, Badge, IconButton } from '@mui/material';

import getStripeIdForDibs from 'actions/users/getStripeIdForDibs';
import getStripeIdForStudio from 'actions/users/getStripeIdForStudio';

// assets
import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
// import { setColor } from 'store/slices/dibsstudio';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: -1.3,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: '0 2px',
        backgroundColor: theme.palette.success.light,
        fontSize: '0.55rem',
        height: '0.7rem',
    },
}));

// ==============================|| CART ITEMS - FLOATING BUTTON ||============================== //

const FabCartModalSide = ({ higher }) => {
    const theme = useTheme();
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const { color, colorisdark } = studioConfig;
    const { dibsStudioId } = config;
    const { userid, isLoggedIn, stripeid, stripeidForThisStudio } = useSelector(
        (state) => state.thisuser,
    );
    const [colortoshow, setColortoshow] = useState('#999999');
    const [cartPosition, setCartPosition] = useState('5.5%');
    const colorofcart = colorisdark ? 'grey.300' : 'grey.800';
    const [quantity, setQuantity] = useState(0);
    const { itemsInCartHere } = useSelector((state) => state.cart);
    // stripe id dibs
    useEffect(() => {
        const getStripeDibs = async () => {
            await getStripeIdForDibs(userid, dibsStudioId).then((res) => {
                if (res !== '') dispatch(updateStripeIds(res));
            });
        };
        if (window.isTiny) {
            setCartPosition('5.5%');
        } else if (window.innerHeight < 500) {
            setCartPosition('18%');
        } else if (higher) {
            setCartPosition('9%');
        } else {
            setCartPosition('12%');
        }

        if (stripeid === null || stripeid === '0' || stripeid === '') {
            if (isLoggedIn) getStripeDibs();
        }
    }, [dibsStudioId, higher, isLoggedIn, stripeid, userid]);
    // stripe id studio
    useEffect(() => {
        const getStripeStudio = async () => {
            await getStripeIdForStudio(userid, dibsStudioId).then((res) => {
                console.log('res from getStripeIdForStudio fabcart modal', res);
                if (res !== '') dispatch(setStripeIdThisStudio(res));
            });
        };
        if (
            stripeidForThisStudio === null ||
            stripeidForThisStudio === '0' ||
            stripeidForThisStudio === ''
        ) {
            if (isLoggedIn) getStripeStudio();
        }
    }, [dibsStudioId, isLoggedIn, userid, stripeidForThisStudio]);
    useEffect(() => {
        if (color !== undefined) {
            setColortoshow(`#${color}`);
        }
        const totalQuantity = sum(
            itemsInCartHere.map((item) =>
                item.dibsStudioId === dibsStudioId ? item.quantity : 0,
            ),
        );
        const quantitytoreturn = totalQuantity;
        setQuantity(quantitytoreturn);
    }, [color, dibsStudioId, itemsInCartHere]);
    // const theme = useTheme();

    // const totalQuantity = sum(itemsInCart.map((item) => item.quantity));

    const urlToLink = `/checkout/${dibsStudioId}`;
    return (
        <Fab
            component={Link}
            to={urlToLink}
            size="small"
            sx={{
                // top: higher ? '9%' : '12%',
                top: cartPosition,
                position: 'fixed',
                right: 0,
                zIndex: 'modal',
                // boxShadow: theme.customShadows.warning,
                bgcolor: colortoshow,
                color: colortoshow,
                // borderRadius: '8px',
                // borderTopRightRadius: 0, // can set preference here
                // borderBottomRightRadius: 0,
                // boderTop: 1,
                // borderRight: 0,
                // borderLeft: 1,
                // borderBottom: 1,
                // borderColor: '#999',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomRightRadius: '8px',
                '&:hover': {
                    bgcolor: colortoshow,
                    color: colortoshow,
                },
                // boxShadow: '0px 0px 0px 0px',
            }}>
            <IconButton
                disableRipple
                aria-label="cart"
                sx={{ '&:hover': { bgcolor: 'transparent' } }}
                size="large">
                <StyledBadge badgeContent={quantity} color="success">
                    <ShoppingCartTwoToneIcon sx={{ color: colorofcart }} />
                </StyledBadge>
            </IconButton>
        </Fab>
    );
};

export default FabCartModalSide;
