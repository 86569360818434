import axios from 'axios';

export default async function validatePassword(email, pwd, hash) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/validate-password`;
    let datatosend = {};
    const checkpassword = async () => {
        await axios
            .post(basicurl, {
                email,
                pwd,
                hash,
            })
            .then((res) => {
                console.log('data returned from validating password', res);
                const { data } = res;

                datatosend = data;
            })
            .catch((err) => {
                console.log(`error checking whether password matches: ${err}`);
                return err;
            });
    };
    await checkpassword();
    return datatosend;
}
