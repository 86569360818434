import axios from 'axios';

export default async function recordShopifyStatus(shopifyResponse, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const { shopifyCustomerId } = shopifyResponse;

    const basicurl = `${baseURL}/shopify/record-shopify-status`;

    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(basicurl, {
                userid,
                shopifyCustId: shopifyCustomerId,
            })
            .then((res) => {
                const { data } = res;
                console.log('data to return from shopify - record shopify status - api call is', data);
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error recording shopify status for this customer: ${err}`);
                return err;
            });
    };
    await createUser();
    return datatosend;
}
