import React from 'react';
import { useState, useEffect } from 'react';
import {
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    IconButton,
    TableRow,
    TableBody,
    Button,
    ButtonGroup,
} from '@mui/material';
import { useTheme, alpha } from '@mui/material/styles';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PropTypes from 'prop-types';

import { useSelector, dispatch } from 'store';
import {
    updateQuantityInCart,
    deleteEventItemCompletelyFromCart,
} from 'store/slices/cart';
import currency from 'currency.js';

import CheckoutItem from './checkoutItem';
import PackageNameDisplay from './PackageNameDisplay';

const updateItemQuantity = (id, quantity) => {
    const tosend = {
        eventid: id,
        updatedQuantity: quantity,
    };
    dispatch(updateQuantityInCart(tosend));
};

const removeItem = (id) => {
    dispatch(deleteEventItemCompletelyFromCart(id));
};

const Increment = ({ itemId, quantity }) => {
    const [value, setValue] = useState(quantity);

    const incrementHandler = () => {
        setValue(value - 1);
        updateItemQuantity(itemId, value - 1);
    };

    const decrementHandler = () => {
        setValue(value + 1);
        updateItemQuantity(itemId, value + 1);
    };

    return (
        <ButtonGroup
            size={window.isTiny ? 'small' : 'large'}
            variant="text"
            color="inherit"
            sx={{
                border: '1px solid',
                borderColor: 'grey.400',
            }}>
            <Button
                key="three"
                disabled={value <= 1}
                onClick={incrementHandler}
                sx={{
                    pr: 0.75,
                    pl: 0.75,
                    minWidth: '0px !important',
                    '&:enabled': {
                        color: '#495057',
                    },
                }}>
                <RemoveIcon fontSize="inherit" />
            </Button>
            <Button
                key="two"
                sx={{
                    pl: 0.5,
                    pr: 0.5,
                    color: '#495057',
                    fontWeight: 400,
                    fontSize: window.isTiny ? '0.6rem' : '0.9rem',
                    minWidth: '30px !important',
                }}>
                {value}
            </Button>
            <Button
                key="one"
                onClick={decrementHandler}
                disabled={value >= 4}
                sx={{
                    pl: 0.75,
                    pr: 0.75,
                    minWidth: '0px !important',
                    color: '#495057',
                }}>
                <AddIcon fontSize="inherit" />
            </Button>
        </ButtonGroup>
    );
};

Increment.propTypes = {
    itemId: PropTypes.number,
    quantity: PropTypes.number,
    updateQuantity: PropTypes.func,
};

const getWidthForTable = () => {
    return parseInt(window.innerWidth * 0.8);
};

const CartSummarTable = ({ rows }) => {
    const theme = useTheme();
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const { passes } = useSelector((state) => state.thisuser);
    const { availablePackagesNotBeingUsed, packagesBeingUsed } = useSelector(
        (state) => state.cart,
    );
    const colortouse = `#${color}`;
    const bglighter = alpha(colortouse, 0.3);
    const [moreAvailability, setMoreAvailability] = useState(false);
    useEffect(() => {
        if (availablePackagesNotBeingUsed) {
            setMoreAvailability(true);
        } else {
            setMoreAvailability(false);
        }
    }, [availablePackagesNotBeingUsed]);

    return (
        <TableContainer id="checkoutlist-container-table">
            <Table
                // sx={{ minWidth: widthOfTable }}
                aria-label="simple table">
                <TableHead
                    sx={{
                        borderTop: '1px solid',
                        color:
                            theme.palette.mode === 'dark'
                                ? theme.palette.dark.light + 15
                                : 'grey.200',
                    }}
                />
                <TableBody>
                    {/* {packageRows.map((pack, index) => {
                                return (
                                    <PackageSummary key={index} pack={pack} />
                                );
                            })} */}
                    {rows.map((row, index) => {
                        const { howtopay } = row;
                        const paymentMethod = howtopay.method;
                        const { passid } = howtopay;
                        const passname = howtopay.passname;
                        const rowpricetoshow =
                            row.price > 0
                                ? currency(row.price * row.quantity).format()
                                : 'FREE';

                        return (
                            <React.Fragment key={`${index}-${row.eventid}`}>
                                <TableRow
                                    key={index}
                                    id={`checkoutlist-row-${index}`}
                                    sx={{
                                        borderTop: 1,
                                        borderTopColor: '#eaeaea',
                                    }}>
                                    <TableCell
                                        component="th"
                                        key={`${index}-${Math.random()}`}
                                        scope="row"
                                        sx={{
                                            p: window.isTiny ? 0.5 : 1,
                                            borderBottom: 0,
                                        }}>
                                        <CheckoutItem
                                            row={row}
                                            bglighter={bglighter}
                                        />
                                    </TableCell>
                                    {!window.isTiny && (
                                        <>
                                            <TableCell
                                                key={Math.random()}
                                                align="center"
                                                sx={{
                                                    borderBottom: 0,
                                                }}>
                                                <Increment
                                                    quantity={row.quantity}
                                                    itemId={row.eventid}
                                                />
                                            </TableCell>
                                            <TableCell
                                                key={Math.random()}
                                                align="center"
                                                id={`checkoutlist-row-${index}-price`}
                                                sx={{
                                                    borderBottom: 0,
                                                }}>
                                                {paymentMethod ===
                                                    'creditcard' &&
                                                    row.price >= 0 &&
                                                    !moreAvailability &&
                                                    row.quantity && (
                                                        <Typography variant="subtitle1">
                                                            {rowpricetoshow}
                                                        </Typography>
                                                    )}

                                                {(paymentMethod !==
                                                    'creditcard' ||
                                                    moreAvailability) && (
                                                    <PackageNameDisplay
                                                        passname={passname}
                                                        passid={passid}
                                                        alternatePasses={passes}
                                                        normalPrice={row.price}
                                                        eventRow={row}
                                                        packagesBeingUsed={
                                                            packagesBeingUsed
                                                        }
                                                    />
                                                )}
                                            </TableCell>
                                        </>
                                    )}
                                    <TableCell
                                        key={Math.random()}
                                        align="right"
                                        sx={{
                                            p: 0,
                                            pr: window.isTiny ? 0 : 1,
                                            borderBottom: 0,
                                        }}>
                                        <IconButton
                                            onClick={() =>
                                                removeItem(row.eventid)
                                            }
                                            size="small">
                                            <DeleteTwoToneIcon
                                                sx={{
                                                    color: 'grey.500',
                                                }}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                                {window.isTiny && (
                                    <>
                                        <TableRow
                                            key={`${index}-${Math.random()}`}>
                                            <TableCell
                                                key={Math.random()}
                                                align="left"
                                                sx={{
                                                    borderBottom: 0,
                                                    p: 0,
                                                    py: 0.5,
                                                    pl: 0.75,
                                                }}>
                                                <Increment
                                                    quantity={row.quantity}
                                                    itemId={row.eventid}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={`${index}-tiny-paymentmethod`}
                                            sx={{
                                                borderBottom: 1,
                                                borderColor: '#eaeaea',
                                            }}>
                                            <TableCell
                                                key={Math.random()}
                                                align="left"
                                                sx={{
                                                    borderBottom: 0,
                                                    pl: 0.5,
                                                }}>
                                                {paymentMethod ===
                                                    'creditcard' &&
                                                    row.price &&
                                                    row.quantity && (
                                                        <Typography variant="subtitle1">
                                                            Cost:{' '}
                                                            {currency(
                                                                row.price *
                                                                    row.quantity,
                                                            ).format()}
                                                        </Typography>
                                                    )}
                                                {paymentMethod !==
                                                    'creditcard' && (
                                                    <Typography variant="subtitle1">
                                                        Apply pass - {passname}
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
export default CartSummarTable;
