import * as React from 'react';
import { Grid } from '@mui/material';

import { useSelector, dispatch } from 'store';
import { setWdth } from 'store/slices/dibsstudio';

// component imports
import DateHeaders from 'components/headers/date-headers/index';
import ScheduleList from 'components/schedule-list/index';

import CartLoader from 'loaders/cartLoader';

const ScheduleLayout = ({ dateToFilterTo, filterDate }) => {
    const { itemsInCartHere } = useSelector((state) => state.cart);
    const { config } = useSelector((state) => state.dibsstudio);
    const {
        dibsStudioId,
        buttonControlsWidget,
        widgetOpenStyle,
        widgetWidth,
        wdth,
    } = config;
    const [isSmallWidget, setIsSmallWidget] = React.useState(false);
    const [isTinyScreen, setIsTinyScreen] = React.useState(
        window.innerWidth < 500,
    );

    const [showCart, setShowCart] = React.useState(false);
    // background color for dates
    let bgColorToUse = isSmallWidget ? '#f5f5f5' : 'transparent';

    React.useEffect(() => {
        let windowWidth = window.innerWidth;
        if (windowWidth < 100) {
            windowWidth = wdth;
        }
        // const cdWidth = parseInt(windowWidth * 0.8);
        // console.log('widget width before container being set is:', widgetWidth);
        // console.log('width of container holding schedule being set: ', cdWidth);
        // console.log('widgetwidth page schedule is', widgetWidth);
        // dispatch(setWdth(cdWidth));
        if (buttonControlsWidget && widgetOpenStyle === 'right') {
            // dispatch(setWdth(parseInt(windowWidth * 0.5)));
        }
        if (windowWidth < 500) setIsTinyScreen(true);
        else setIsTinyScreen(false);
        if (itemsInCartHere.length > 0) {
            setShowCart(true);
        }
        if (buttonControlsWidget && widgetOpenStyle === 'right')
            setIsSmallWidget(true);
    }, [
        buttonControlsWidget,
        dibsStudioId,
        itemsInCartHere.length,
        wdth,
        widgetOpenStyle,
        widgetWidth,
    ]);
    return (
        <Grid
            container
            spacing={0}
            border={0}
            id="container-holding-schedule"
            direction="row"
            // backgroundColor="#ff6900"
            justifyContent="center"
            // width={widgetWidth}
            alignItems="flex-start">
            <Grid
                item
                xs={buttonControlsWidget && !isTinyScreen ? 12 : 11.5}
                md={buttonControlsWidget && !isTinyScreen ? 12 : 11}
                lg={buttonControlsWidget && !isTinyScreen ? 12 : 9.5}
                sx={{ mt: buttonControlsWidget ? 2 : 1 }}>
                <Grid
                    container
                    id="schedule-highest-level-container-sub-holding"
                    sx={{ mt: buttonControlsWidget ? 0 : 1 }}
                    justifyContent="center"
                    alignItems="flex-start">
                    {/* <Header /> */}
                    {showCart && <CartLoader />}
                    <Grid
                        item
                        id="grid-item-holds-date-headers"
                        xs={!window.isTiny || buttonControlsWidget ? 12 : 11.25}
                        border={0}
                        sx={{
                            mt: isSmallWidget ? 0 : 4,
                        }}>
                        <Grid
                            container
                            id="container-holds-dates-and-chevrons-outer"
                            direction="row"
                            backgroundColor={bgColorToUse}
                            border={isSmallWidget ? 1 : 0}
                            borderColor="#eee"
                            justifyContent="flex-start"
                            alignItems="center">
                            <Grid item xs={12}>
                                <DateHeaders
                                    dateToFilterTo={dateToFilterTo}
                                    filterDate={filterDate}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 1 }}>
                        <Grid
                            container
                            id="container-holds-schedule-list-outer"
                            direction="row"
                            justifyContent="center"
                            alignItems="center">
                            <Grid item xs={window.isTiny ? 11.25 : 12}>
                                <ScheduleList />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ScheduleLayout;
