import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box } from '@mui/material';

import ConfirmationTable from 'components/checkout/confirmation';
import RemainingPackageStatement from 'components/checkout/confirmation/remainingPackages';
import BackToSchedule from 'components/shared/BackToScheduleButton';
import UpcomingClasses from 'components/shared/UpcomingClasses';

import getPasses from 'actions/passes/getPasses';
import { useSelector, dispatch } from 'store';
import { setAvailablePasses } from 'store/slices/thisuser';

import PaymentStatus from 'components/stripe/new/paymentStatus';
import ShowingProgress from 'components/stripe/new/processingCCStatus';

const CheckoutPageView = () => {
    let { dibsId } = useParams();
    const {
        cartJustCheckedOut,
        creditJustApplied,
        totalAfterCreditJUSTApplied,
        cartCompletedIsReadyToShow,
        isProcessing,
    } = useSelector((state) => state.cart);
    const { userid } = useSelector((state) => state.thisuser);
    const [cartToUse, setCartToUse] = useState(cartJustCheckedOut);

    const [isCharging, setIsCharging] = useState(false);
    useEffect(() => {
        if (cartJustCheckedOut && cartJustCheckedOut.length > 0)
            setCartToUse(cartJustCheckedOut);
    }, [cartJustCheckedOut]);
    useEffect(() => {
        const getPassesForUser = async () => {
            await getPasses(userid, dibsId).then((res) => {
                const { passes } = res;
                dispatch(setAvailablePasses(passes));
            });
        };
        getPassesForUser();
    }, [dibsId, cartJustCheckedOut, userid]);

    return (
        <Grid
            container
            spacing={3}
            id="checkout-layout-container"
            direction="row"
            justifyContent="center"
            alignItems="flex-start">
            <Grid item xs={11.5} md={11} lg={9} xl={8}>
                <Grid container sx={{ mt: 1 }}>
                    <PaymentStatus />
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Grid
                            container
                            direction="row"
                            id="checkoutlist-container-highest-one"
                            justifyContent="center"
                            alignItems="center">
                            <Grid
                                item
                                xs={window.isTiny ? 11.5 : 12}
                                sx={{ mr: window.isTiny ? 0.5 : 0 }}>
                                <Box
                                    sx={{
                                        border: 1,
                                        borderRadius: 2,
                                        borderColor: '#d3d3d3',
                                        px: 2,
                                        pt: 2,
                                        minHeight: 100,
                                        mt: 0,
                                    }}>
                                    <Grid
                                        container
                                        id="checkoutlist-nothighest-container">
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                mx: window.isTiny ? 0 : 3,
                                                mt: window.isTiny ? 1 : 5,
                                            }}>
                                            {isProcessing && (
                                                <ShowingProgress />
                                            )}
                                            {!cartCompletedIsReadyToShow && (
                                                <>
                                                    <Grid
                                                        container
                                                        justifyContent="center">
                                                        <Typography
                                                            variant="successText"
                                                            textAlign="center"
                                                            sx={{
                                                                ml: 2,
                                                                fontSize:
                                                                    '1rem',
                                                            }}>
                                                            You're Confirmed!
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        spacing={2}
                                                        id="checkout-list-container"
                                                        justifyContent="center">
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={11}
                                                            xl={10}
                                                            border={0}
                                                            sx={{
                                                                mt: 4,
                                                                mb: 4,
                                                            }}>
                                                            <ConfirmationTable
                                                                rows={cartToUse}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sx={{
                                                                mb: 4,
                                                                ml: 2,
                                                            }}>
                                                            <RemainingPackageStatement />
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        justifyContent="space-between"
                                        sx={{ px: 3 }}>
                                        <Grid item>
                                            <BackToSchedule />
                                        </Grid>
                                        <Grid item>
                                            <UpcomingClasses text="Go To Your Classes" />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CheckoutPageView;
