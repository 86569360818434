import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import { useSelector } from 'store';

const renderSwitch = (sw) => {
    switch (true) {
        case sw < 600:
            return 1;
        case sw < 800:
            return 2;
        case sw < 1300:
            return 3.5;
        default:
            return 5;
    }
};

const BuyIcon = ({ color, isFull, id, tiny, onWaitlist, classisbeforenow }) => {
    const { buttonControlsWidget, widgetWidth } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const [iconToUse, setIconToUse] = useState('fa-regular fa-plus');

    const widthScreen = window.innerWidth;
    const [rightMargin, setRightMargin] = useState(
        buttonControlsWidget ? 1 : 5,
    );
    const [leftMargin, setLeftMargin] = useState(0);
    const [colortoshow, setColortoshow] = useState(`#${color}`);
    const [strokeColor, setStrokeColor] = useState('#414141');
    useEffect(() => {
        setRightMargin(
            renderSwitch(buttonControlsWidget ? widgetWidth : widthScreen),
        );

        if (isFull && !onWaitlist) {
            // setRightMargin(2);
            setLeftMargin(1.7);
            setColortoshow('#ddd');
            setStrokeColor('#414141');
        }
        if (classisbeforenow) {
            setLeftMargin(1.7);
            setColortoshow('#ddd');
            setStrokeColor('#B1AAAC');
        }

        if (onWaitlist) {
            setIconToUse('fa-regular fa-check');
            // setRightMargin(2);
            setLeftMargin(3);
            setColortoshow('#ddd');
            setStrokeColor('#414141');
        }
    }, [
        buttonControlsWidget,
        widgetWidth,
        isFull,
        widthScreen,
        onWaitlist,
        classisbeforenow,
    ]);

    // <FontAwesomeIcon icon="fa-regular fa-check" />;

    if (tiny) {
        return (
            <Grid
                item
                sx={{
                    ml: leftMargin,
                    mr: rightMargin,
                    mt: 0.25,
                    height: '20px',
                }}
                id={`div-around-button-buy${id}`}>
                <span className="fa-layers fa-fw">
                    <FontAwesomeIcon
                        icon="fa-circle fa-2x"
                        transform="grow-10 down-3"
                        color={colortoshow}
                    />
                    <FontAwesomeIcon
                        icon={iconToUse}
                        color={strokeColor}
                        transform="grow-3 down-3"
                    />
                </span>
            </Grid>
        );
    }

    return (
        <Grid
            item
            sx={{ ml: leftMargin, mr: rightMargin, mt: 0.25, height: '33px' }}
            id={`div-around-button-100-${id}`}>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon
                    icon="fa-circle fa-2x"
                    transform="grow-23 down-3"
                    color={colortoshow}
                />
                <FontAwesomeIcon
                    icon={iconToUse}
                    color={strokeColor}
                    transform="grow-10 down-3"
                />
            </span>
        </Grid>
    );
};
export default BuyIcon;
