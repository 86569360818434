import { dispatch } from 'store';
import { setPaymentOptions, setRefreshPaymentOptions } from 'store/slices/thisuser';

export default async function reSortPaymentOptions(allOptions, pmid) {
    const newOptions = [];
    newOptions.push(pmid);
    allOptions.forEach((option) => {
        console.log('option being resorted is', option);
        console.log('i think i need to look at whether the option is a string or an object');
        if (option !== pmid) {
            newOptions.push(option);
        }
    });
    console.log('\n\n\n\nLOOK HERE - on resort - setting payment options to', newOptions)
    // dispatch(setPaymentOptions(newOptions));
    dispatch(setPaymentOptions(allOptions)); // testing this - not sure how this is supposed to work

    setTimeout(() => {
        dispatch(setRefreshPaymentOptions(false));
    }, 20000);
    return;
}
