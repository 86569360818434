import { useEffect, useState } from 'react';
import { Grid, Box, Collapse, Typography, Divider } from '@mui/material';
import StripeInfo from 'components/stripe/new/loadStripe';
import CollapseComponent from 'views/wrappers/collapse';

const CollectCardInfo = ({ intentType, cancelAction }) => {

    const setup = intentType === 'setup';
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11.5}>
                <Divider sx={{ mt: 6 }} />
                <Grid item id="enter-cc-info" xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} border={0} sx={{ mt: 5, mb: 2 }}>
                            <Typography textAlign="center" variant="h5">
                                Add New Payment Method
                            </Typography>
                        </Grid>
                        <Grid item xs={11} md={10} lg={9}>
                            <Box id="box-holding-enter-cc-info">
                                <StripeInfo includeButton setup={setup} cancelAction={cancelAction} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 4 }} />
            </Grid>
        </Grid>
    );
};
export default CollectCardInfo;
