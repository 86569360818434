import axios from 'axios';

export default async function createNewShopifyUser(thisdata, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/shopify/create-customer`;

    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(basicurl, {
                thisdata,
                userid
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error creating new shopify customer: ${err}`);
                return err;
            });
    };
    await createUser();
    return datatosend;
}
