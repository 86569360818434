import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, ButtonBase } from '@mui/material';

import { setPageSelected } from 'store/slices/menu';

import { useDispatch } from 'store';

const BackChevronIcon = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const goBack = () => {
        dispatch(setPageSelected('schedule'));
        navigate(-1);
    };
    return (
        <ButtonBase onClick={() => goBack()}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Grid item className="fa-icon-chevron-styles">
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                            icon="fa-solid fa-chevron-left"
                            color="#000"
                            transform="grow-7 down-1"
                        />
                    </span>
                </Grid>
            </Grid>
        </ButtonBase>
    );
};
export default BackChevronIcon;
