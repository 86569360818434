import { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Typography,
    Button,
    Fade,
    LinearProgress,
    Checkbox
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { capitalizeString } from 'helpers';
import { useSelector, dispatch } from 'store';
import XIcon from 'assets/icons/XIcon';
import NotifyRemovePayment from 'components/notifications/removePaymentMethod';

import { setRefreshPaymentOptions } from 'store/slices/thisuser';
import { setShowAllExistingPaymentOptions, setShowSummaryPaymentScreen } from 'store/slices/cart';
import reSortPaymentOptions from 'actions/users/reSortPaymentOptions';

const IndividualCardDisplaySelected = ({ cc, useThisCard }) => {
    const theme = useTheme();
    const [last4ToUse, setLast4ToUse] = useState('');
    const [cardBrandHere, setCardBrandHere] = useState('');
    const [expy, setExpy] = useState('');
    const [isRemoving, setIsRemoving] = useState(false);
    const [isRemovingFromStripe, setIsRemovingFromStripe] = useState(false);
    const [showRemoveButton, setShowRemoveButton] = useState(true);
    const { studioConfig } = useSelector((state) => state.dibsstudio);
    const { cardtocharge } = useSelector((state) => state.cart);
    const [thiscardIsSelected, setThisCardIsSelected] = useState(false);
    const { color } = studioConfig;
    const coloruse = `#${color}`;
    const [bcolor, setBColor] = useState(coloruse);

    useEffect(() => {
        if (cardtocharge) {
            if (cardtocharge === cc.id) {
                setThisCardIsSelected(true);
            } else {
                setThisCardIsSelected(false);
            }
        }
    }, [cardtocharge, cc]);

    useEffect(() => {

        if (cc) {
            const { card } = cc;
            const { brand, exp_month, exp_year, last4 } = card;
            setExpy(`${exp_month}/${exp_year}`);
            setLast4ToUse(last4);
            setCardBrandHere(brand);
        }
        setBColor(coloruse);
    }, [cc, coloruse]);
    const cardnum = `**** **** **** ${last4ToUse}`;
    const useThisCardClicked = () => {
        console.log('using this card - here - why would this be clicked')
        useThisCard();
        dispatch(setShowAllExistingPaymentOptions(false));
        dispatch(setShowSummaryPaymentScreen(true));
    };
    const classnameToUse = `credit-card ${cardBrandHere} selectable`;


    return (
        <Fade in timeout={500} appear>
            <Box maxWidth={210} border={0}>
                <Grid item xs={12} sx={{ mr: 2, ml: 2, mb: 2, mt: 0 }} className={classnameToUse}>
                    <Grid container id="profile-individual-card-display-selected" >
                        {/* <Grid item xs={12} sx={{ mb: 2, ml: 0.5 }}>
                        {cardBrandHere}
                    </Grid> */}

                        <Grid item xs={12} sx={{ ml: 1 }}>
                            <Typography variant="h4">Card Number</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1, ml: 1 }}>
                            <Typography variant="creditcard">{cardnum}</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: 1 }}>
                            <Typography variant="h4">Expiration</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: 1 }}>
                            <Typography variant="creditcard">{expy}</Typography>
                        </Grid>

                    </Grid>

                </Grid>

                <Grid item xs sx={{ mt: 3 }} border={0}>
                    <Grid container justifyContent="center">
                        <Button
                            variant="greenButton"
                            onClick={useThisCardClicked}
                            sx={{ mb: 4 }}>
                            Use This Card
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Fade>
    );
};
export default IndividualCardDisplaySelected;
