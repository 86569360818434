import PropTypes from 'prop-types';
import React from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import {
    auth,
    sendEmailAuthLink,
    verifyUserClickedEmailLink,
} from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { lowercaseString } from 'helpers';
import setLastLogin from 'actions/setLastLogin';
import { gridSpacing } from 'store/constant';
import LinearProgress from 'assets/LinearProgress';

import { dispatch } from 'store';
import { setIsInLoginLinkFlow, setIsLoggedIn } from 'store/slices/thisuser';

// material-ui
import {
    Grid,
    Button,
    // Divider,
    // Grid,
    InputLabel,
    Typography,
    TextField,
    Link,
    // useMediaQuery,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector } from 'store';

// project imports
import SubCard from 'ui-component/cards/AccountSubCard';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

// import Google from 'assets/images/icons/social-google.svg';
// import { addStudioData } from 'store/slices/dibsstudio';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Your email address looks incorrect. Please try again')
        .required('Required'),
});

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginAuthenticationCard = () => {
    const { lastLocation, hasSetPwdFirebase, isInLoginLinkFlow, email } =
        useSelector((state) => state.thisuser);
    const { dibsId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [user, loading] = useAuthState(auth);
    const [showError, setShowError] = React.useState(false);
    const [isVerifying, setIsVerifying] = React.useState(false);
    // if logged in, take them to the page they were on
    React.useEffect(() => {
        if (user) {
            console.log('logging in line 64 - firebase');
            dispatch(setIsLoggedIn(true));
        }
        const verifyEmailLink = async () => {
            console.log(`running verify email link`);
            dispatch(setIsInLoginLinkFlow(false));
            setIsVerifying(true);
            await verifyUserClickedEmailLink(email).then((res) => {
                if (res === 1) {
                    // then redirect the user here
                    // update user profile
                    if (!hasSetPwdFirebase) {
                        navigate(`/account/${dibsId}${location.search}`, {
                            replace: true,
                        });
                    } else if (lastLocation.length > 2 && hasSetPwdFirebase) {
                        console.log(
                            'sending user to their lastLocation --> ',
                            lastLocation,
                        );
                        navigate(lastLocation, { replace: true });
                    } else {
                        console.log(`last location is not available`);
                        // if no other conditions, send user to the schedule page
                        console.log('would send to schedule page');
                        navigate(`/schedule/${dibsId}`, { replace: true });
                    }
                } else if (res === 0) {
                    console.log('there was an error verifying the email link');
                    setShowError(true);
                }
                setIsVerifying(false);
            });
        };
        // user just clicked on email link
        if (!user && !loading && !isVerifying) {
            verifyEmailLink();
        }
    }, [
        dibsId,
        email,
        hasSetPwdFirebase,
        isVerifying,
        lastLocation,
        loading,
        navigate,
        user,
    ]);

    if (showError) {
        const emailLink = `/login-email-link/${dibsId}`;
        return (
            <Grid container justifyContent="center">
                <Grid item xs={10} md={6} sx={{ mb: 5 }}>
                    <SubCard title="Sign In">
                        <Grid
                            container
                            spacing={gridSpacing}
                            justifyContent="flex-start">
                            <ErrorMessage />
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography variant="regularText">
                                    We could not verify your email address using
                                    the link. Please click on the link below to
                                    try again.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mb: 3 }}>
                                <Link href={emailLink}>Try again</Link>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={6} sx={{ mb: 5 }}>
                {/* <SubCard title="Sign In">
                    <Grid
                        container
                        spacing={gridSpacing}
                        justifyContent="flex-start"> */}
                <ErrorMessage />
                <Grid container>
                    <Grid item xs={10} md={6} sx={{ mb: 5 }}>
                        <SubCard title="Sign In">
                            <Grid
                                container
                                spacing={gridSpacing}
                                justifyContent="flex-start">
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Typography variant="regularText">
                                        One moment while we verify your login
                                        information...
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
                                    <LinearProgress />
                                </Grid>
                            </Grid>
                        </SubCard>
                    </Grid>
                </Grid>
                {/* </Grid>
                </SubCard> */}
            </Grid>
        </Grid>
    );
};

export default LoginAuthenticationCard;
