import { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';

import { setWdth } from 'store/slices/dibsstudio';
import { dispatch } from 'store';
import Validate from './ValidateLink';

const AuthListNoPwd = () => {
    const [cdwidth, setCdWidth] = useState(1000);
    useEffect(() => {
        setCdWidth(setCdWidth);
        const windowWidth = window.innerWidth;
        const cdWidth = parseInt(windowWidth * 0.8);
        setCdWidth(cdWidth);
        // dispatch(setWdth(cdWidth));
    }, []);
    return (
        <Box
            id="outerValidate"
            justifyContent="center"
            sx={{
                // border: 0,
                // borderRadius: 2,
                // borderColor: '#d3d3d3',
                px: 0,
                pt: 0,
                minHeight: 100,
                mt: 0,
            }}>
            <Grid
                id="container-box-validate"
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                <Grid
                    item
                    id="validate-item-grid-width-set"
                    xs={10}
                    lg={10}
                    sx={{ mt: 5, width: cdwidth, px: 2 }}>
                    <Validate />
                </Grid>
            </Grid>
        </Box>
    );
};
export default AuthListNoPwd;
