import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import updateFillRates from 'actions/schedule/updateFillRates';
export default async function checkoutWithPassesAndOrCredit(
    userid,
    cartItems,
    creditApplied,
    totalAfterCreditApplied,
) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    let basicurl = `${baseURL}/checkout-with-pass-and-or-credit`;
    if (totalAfterCreditApplied === 0 && creditApplied > 0) {
        basicurl = `${baseURL}/checkout-credit-only`;
    }
    let datatosend = {};
    const checkoutUUID = uuidv4();
    const checkout = async () => {
        await axios
            .post(basicurl, {
                userid,
                cartItems,
                creditApplied,
                totalAfterCreditApplied,
                checkoutUUID,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
                updateFillRates(cartItems);
            })
            .catch((err) => {
                console.log(`error checking out with passes: ${err}`);
                return err;
            });
    };
    await checkout();
    return datatosend;
}
