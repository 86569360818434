import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useSelector, dispatch } from 'store';
import { auth } from 'firebaseConfig';
import {
    setCurrentStep,
    setAlreadyHasAccount,
    setIsLoggedIn,
} from 'store/slices/thisuser';
// import InputLabel from 'ui-component/extended/Form/InputLabel';
import StepsToCheckout from './stepsToCheckout';
import StepsToCheckoutPassword from './stepsToCheckoutPassword';
import StepsToCheckoutCreateNew from './stepsToCheckoutCreateNew';
import EnterCCInfo from 'components/payment/enterCCInfo';
import EmailSent from 'components/user/EmailSent';

const UserPaymentForm = () => {
    const [user] = useAuthState(auth);
    const { currentStep, alreadyHasAccount } = useSelector(
        (state) => state.thisuser,
    );
    // const [loggedin, setLoggedin] = useState(false);
    useEffect(() => {
        if (user) {
            dispatch(setIsLoggedIn(true));
            dispatch(setCurrentStep('payment'));
            dispatch(setAlreadyHasAccount(true));
        }
    }, [user]);

    const getPageToUse = () => {
        if (currentStep === 'check-email-for-link') {
            return <EmailSent />;
        } else if (currentStep === 'email') {
            return <StepsToCheckout />;
        } else if (!alreadyHasAccount) {
            return <StepsToCheckoutCreateNew />;
        } else if (currentStep === 'payment') {
            return <EnterCCInfo />;
        } else {
            return <StepsToCheckoutPassword />;
        }
    };
    return (
        <Grid container>
            <Grid item xs={10}>
                {getPageToUse()}
            </Grid>
        </Grid>
    );
};
export default UserPaymentForm;
