import axios from 'axios';
export default async function createStripeSetupIntent(
    customerid,
    userid,
    email,
    fullname,
    dibsStudioId,
    onDibs
) {
    const onDibsPlatform = onDibs ? 'dibs' : 'studio';
    window.localStorage.setItem('stripeReady', false);
    window.localStorage.setItem('intentPlatform', onDibsPlatform);
    window.localStorage.setItem('intentType', 'setup');
    window.localStorage.setItem('intentSetFromTwo', 'action-setup-intent');
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/create-setup-intent`;
    let datatosend = {};
    const reqbody = {
        customerid: customerid,
        userid: userid,
        email: email,
        name: fullname,
        dibsStudioId,
        onDibs
    };
    const promises = [];
    const intent = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from getting setupIntent Action: ${err}`);
                return err;
            });
    };
    promises.push(intent());
    // await intent();
    await Promise.all(promises);
    return datatosend;
}
