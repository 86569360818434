import { Grid, Typography, TextField, Button, Fade } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useSelector, dispatch } from 'store';
import { gridSpacing } from 'store/constant';
import {
    setFirstname,
    setLastname,
    setUserEmail,
    setBirthday,
    setPhone,
} from 'store/slices/thisuser';

import SubCard from 'ui-component/cards/AccountSubCard';
import { lowercaseString, capitalizeString } from 'helpers';
import updateProfileInfo from 'actions/updateProfileInfo';

const validationSchema = yup.object().shape({
    firstname: yup
        .string()
        .min(2, 'Your name is too short. Please try again.')
        .max(90, 'Your name is too long. Please try again.')
        .required('Required'),
    lastname: yup
        .string()
        .min(2, 'Your lastname is too short. Please try again.')
        .max(90, 'Your name is too long. Please try again.')
        .required('Required'),
    phone: yup.string().phone('US', 'Please enter a valid phone number'),
    birthday: yup.date('MM/DD'),
});

// ==============================|| ACCOUNT INFO ||============================== //

const AccountInfo = () => {
    const {
        userid,
        firstname,
        lastname,
        email,
        phone,
        birthday,
        hasSetPwdFirebase,
    } = useSelector((state) => state.thisuser);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const [hasError, setHasError] = useState(false);
    const [gridWidth, setGridWidth] = useState(5.5);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [firstnameHere, setFirstnameHere] = useState(firstname);
    const [lastnameHere, setLastnameHere] = useState(lastname);
    const [phonehere, setPhoneHere] = useState(phone);
    const [birthdayhere, setBirthdayHere] = useState(birthday);
    const [marginToUse, setMarginToUse] = useState(0);
    let phonetouse = phone;
    if (phone === null) {
        phonetouse = '';
    }
    useEffect(() => {
        if (!hasSetPwdFirebase) {
            setGridWidth(10);
        }
        if (window.isTiny) setMarginToUse(10);
    }, [hasSetPwdFirebase]);
    useEffect(() => {
        setFirstnameHere(firstname);
        setLastnameHere(lastname);
        setPhoneHere(phonetouse);
        setBirthdayHere(birthday);
    }, [firstname, lastname, phonetouse, birthday]);
    const formik = useFormik({
        initialValues: {
            firstname: firstnameHere,
            phone: phonehere,
            email: email,
            lastname: lastnameHere,
            birthday: birthdayhere,
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                const emailtosend = lowercaseString(values.email);
                const firstnameToSend = capitalizeString(values.firstname);
                const lastnameToSend = capitalizeString(values.lastname);
                const profile = {
                    userid,
                    email: emailtosend,
                    firstname: firstnameToSend,
                    lastname: lastnameToSend,
                    birthday: values.birthday,
                    phone: values.phone,
                };
                await updateProfileInfo(profile)
                    .then((res) => {
                        if (!res.success) {
                            setErrorMessage(res.message);
                            setHasError(true);
                            setTimeout(() => {
                                setHasError(false);
                                setErrorMessage('');
                            }, 20000);
                        }
                        if (res.success) {
                            setSuccessMessage(res.message);
                            setHasSuccess(true);
                            setTimeout(() => {
                                setHasSuccess(false);
                                setSuccessMessage('');
                            }, 10000);
                            dispatch(setFirstname(firstnameToSend));
                            dispatch(setLastname(lastnameToSend));
                            dispatch(setUserEmail(emailtosend));
                            if (values.birthday !== null) {
                                dispatch(setBirthday(values.birthday));
                            }
                            if (values.phone !== null) {
                                dispatch(setPhone(values.phone));
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(
                            `caught error is: ${JSON.stringify(
                                error,
                                null,
                                2,
                            )}`,
                        );
                    });
                // navigate('/dashboard', { replace: true });
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });
    const accountProfileTitle = <FormattedMessage id="account-profile" />;
    return (
        <Fade in timeout={700}>
            <Grid
                item
                xs={window.isTiny ? 12 : 10}
                md={window.overlay ? 10 : gridWidth}
                lg={window.isTiny ? 11 : gridWidth}
                sx={{ mb: 2, mr: window.isTiny ? 0 : 3 }}>
                <SubCard
                    id="Account-Profile"
                    title={accountProfileTitle}
                    dibsstudioid={dibsStudioId}>
                    <Grid container spacing={gridSpacing}>
                        {hasError && (
                            <Grid item xs={12}>
                                <Typography variant="errorText">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}
                        {hasSuccess && (
                            <Grid item xs={12}>
                                <Typography variant="successText">
                                    {successMessage}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} id="form-profile-accountinfo">
                            <form
                                className="form-profile"
                                onSubmit={formik.handleSubmit}>
                                <Grid container>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            mb: window.isTiny ? 1 : 2,
                                            mt: 2,
                                            mr: 2,
                                        }}>
                                        <TextField
                                            name="firstname"
                                            label="First Name"
                                            value={
                                                formik.values.firstname || ''
                                            }
                                            fullWidth
                                            onChange={formik.handleChange}
                                            placeholder={firstname}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.firstname &&
                                                Boolean(formik.errors.firstname)
                                            }
                                            helperText={
                                                formik.touched.firstname &&
                                                formik.errors.firstname
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={5}
                                        sx={{
                                            mt: 2,
                                            mr: window.isTiny ? 2 : 0,
                                            mb: window.isTiny ? 1 : 0,
                                        }}>
                                        <TextField
                                            name="lastname"
                                            label="Last Name"
                                            value={formik.values.lastname || ''}
                                            fullWidth
                                            onChange={formik.handleChange}
                                            placeholder={lastname}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.lastname &&
                                                Boolean(formik.errors.lastname)
                                            }
                                            helperText={
                                                formik.touched.lastname &&
                                                formik.errors.lastname
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={10.3}
                                        sx={{
                                            mb: window.isTiny ? 1 : 2,
                                            mt: 2,
                                            mr: window.isTiny ? 2 : 0,
                                        }}>
                                        <TextField
                                            name="email"
                                            label="Email"
                                            value={formik.values.email || ''}
                                            fullWidth
                                            onChange={formik.handleChange}
                                            placeholder={email}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.email &&
                                                Boolean(formik.errors.email)
                                            }
                                            helperText={
                                                formik.touched.email &&
                                                formik.errors.email
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        sx={{
                                            mb: 1,
                                            mt: 2,
                                            mr: 2,
                                        }}>
                                        <TextField
                                            name="phone"
                                            label="Phone"
                                            value={formik.values.phone || ''}
                                            fullWidth
                                            onChange={formik.handleChange}
                                            placeholder="XXX-XXX-XXXX"
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.phone &&
                                                Boolean(formik.errors.phone)
                                            }
                                            helperText={
                                                formik.touched.phone &&
                                                formik.errors.phone
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={4}
                                        sx={{
                                            mb: 1,
                                            mt: window.isTiny ? 2 : 2,
                                            mr: 2,
                                        }}>
                                        <TextField
                                            name="birthday"
                                            label="Birthday"
                                            value={formik.values.birthday || ''}
                                            fullWidth
                                            onChange={formik.handleChange}
                                            placeholder="MM/DD"
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.birthday &&
                                                Boolean(formik.errors.birthday)
                                            }
                                            helperText={
                                                formik.touched.birthday &&
                                                formik.errors.birthday
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={9}
                                        sx={{ mt: 2, mb: 2 }}>
                                        <Button
                                            variant="checkout"
                                            fullWidth
                                            type="submit"
                                            disabled={formik.isSubmitting}
                                            // onClick={handleChargeCard}>
                                        >
                                            Update Account Details
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Fade>
    );
};
export default AccountInfo;
