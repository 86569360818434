import * as React from 'react';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Typography, Grid, Zoom, Fade } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

import { dispatch } from 'store';
import {
    setManualOverridePackageApplication
} from 'store/slices/cart';
import { truncate } from 'lodash';


export default function PackageNameDisplay({ passname, alternatePasses, passid, normalPrice, eventRow, packagesBeingUsed }) {
    // console.log('passname being passed to package name display is', passname);

    const [packageValue, setPackageValue] = React.useState(0);
    const [packageOptions, setPackageOptions] = React.useState([]);
    const [showingCashValue, setShowingCashValue] = React.useState(false);
    const [cashValueOption, setCashValueOption] = React.useState(alternatePasses.length || 1);
    const [needsRefresh, setNeedsRefresh] = React.useState(false);
    const [showSelect, setShowSelect] = React.useState(false);

    React.useEffect(() => {
        setShowSelect(false);
        const { howtopay } = eventRow;
        // const { passid } = howtopay;
        const getIndex = async () => {
            const thisindex = packageOptions.findIndex((pass) => {
                if (pass.id === passid) {
                    return true;
                } else {
                    return false;
                }
            });
            if (thisindex >= 0) {
                setPackageValue(thisindex);
            }
        }
        if (passid > 0) {
            getIndex();
            setShowingCashValue(false);
        } else {
            setShowingCashValue(true);
            setPackageValue(cashValueOption);
        }
        setShowSelect(true);
    }, [packageOptions, eventRow, cashValueOption, passid, needsRefresh]);
    React.useEffect(() => {
        setShowSelect(false);
        const getList = async () => {
            const filteredlist = await alternatePasses.filter((pass) => {

                if (eventRow.private) {
                    if (pass.private_pass) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return (pass.private_pass === false)
                }
            });
            const filteredforusage = await filteredlist.filter((pass) => {
                const { id, totalUses, usesCount } = pass;
                const usedInThisCart = packagesBeingUsed.length;
                const passinuse = packagesBeingUsed.includes(id);
                const isthisone = (id === passid);
                const totalAvailable = totalUses - usesCount;
                const availableForThisCart = totalAvailable - usedInThisCart;
                if (availableForThisCart > 0) {
                    return true;
                } else if (passinuse && !isthisone) {
                    return false;
                } else {
                    return true;
                }
            });
            const list = await filteredforusage.map((pass, i) => {

                const { studioPackage, expiresAt } = pass;
                const { packageName } = studioPackage;
                const thisvalue = {
                    value: i,
                    label: packageName,
                    passid: pass.id,
                    ...pass,
                };
                return thisvalue;
            });
            return list;
        };
        getList().then((res) => {
            setCashValueOption(res.length);
            const nopackage = {
                'value': res.length,
                'label': showingCashValue ? `$${normalPrice}` : `Remove Package`,
                'passid': 0,
            };
            const newlist = [nopackage, ...res];
            const listsorted = newlist.sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                } else if (a.value > b.value) {
                    return 1;
                }
                return 0;
            });

            setPackageOptions(listsorted);
            setShowSelect(true);
        });
    }, [alternatePasses, eventRow.private, normalPrice, packagesBeingUsed, passid, showingCashValue, needsRefresh]);

    if (packageOptions.length === 0) {
        return (
            <Grid>
                <Typography variant="subtitle1">
                    {passname}
                </Typography>
            </Grid>
        );
    }
    const handlePackageChange = (event) => {
        setShowSelect(false);
        const newvaluepackage = packageOptions[event.target.value];
        const payload = {
            newPassInfo: newvaluepackage,
            eventtochange: eventRow,
        }
        dispatch(setManualOverridePackageApplication(payload));
        setPackageValue(event.target.value);
        setNeedsRefresh(!needsRefresh);
        setShowSelect(true);
    };
    if (packageValue > packageOptions.length - 1) {
        return (
            <Typography variant="subtitle1">Loading...</Typography>
        );
    }
    return (
        <AnimatePresence>
            {showSelect && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1 }}
                    exit={{ opacity: 0 }}
                >

                    <Select
                        id={`select-package-name-choice-${passid}`}
                        value={packageValue}
                        native={false}
                        input={<Input disableUnderline />}
                        // label="Age"
                        sx={{ mb: 0 }}
                        onChange={handlePackageChange}
                        renderValue={(selected) => {
                            if (packageOptions[selected].label === 'Remove Package') return null;
                            return (
                                <Typography variant="subtitle1">
                                    {packageOptions[selected].label}
                                </Typography>
                            );
                        }}
                    >
                        {packageOptions.map((pass) => {
                            return (
                                <MenuItem key={pass.value} value={pass.value}>
                                    <Typography variant="subtitle1">
                                        {pass.label}
                                    </Typography>

                                </MenuItem>
                            );
                        })}
                    </Select>
                </motion.div>
            )}

        </AnimatePresence>

    );
}