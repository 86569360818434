import updateDibsTransactions from 'actions/checkout/updateDibsTransactions';
import updateEventCounts from 'actions/checkout/updateEventCounts';

export default async function finishTransactionAfterCharge(
    userid,
    uuid,
    stripeChargeId,
) {
    await updateDibsTransactions(uuid, stripeChargeId, userid).then(async (res) => {
        console.log('updated dibs transactions', res);
        await updateEventCounts(uuid).then((res) => {
            console.log('updated event counts', res);
        });
    }).catch((err) => {
        console.log('error updating dibs transactions and event counts', err);
        return {
            msg: 'error updating dibs transactions and event counts',
            error: err,
        }
    });

    return { msg: 'success' };
}
