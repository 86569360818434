import axios from 'axios';
import { getApiUrl } from 'helpers';
export default async function getStripeUserInfo(dibsId, userid) {
    const url = getApiUrl('/stripe/get-stripe-user-info');
    let datatosend;
    const reqbody = {
        dibsId,
        userid,
    };
    await axios
        .post(url, reqbody)
        .then((res) => {
            const { data } = res;
            datatosend = data;

        })
        .catch((err) => {
            console.log(
                `error from getting stripe payments Action: ${err}`,
            );
            return err;
        });

    return datatosend;
}
