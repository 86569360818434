import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, ButtonBase } from '@mui/material';

import { setMovedScheduleOut } from 'store/slices/menu';

import { useDispatch, useSelector } from 'store';

const RightChevronIcon = () => {
    const dispatch = useDispatch();
    const { daystomove } = useSelector((state) => state.menu);
    const moveScheduleToRight = () => {
        dispatch(setMovedScheduleOut(daystomove));
    };
    const isSmall = window.innerWidth < 500;
    return (
        <ButtonBase onClick={() => moveScheduleToRight()}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Grid item className="fa-icon-chevron-styles">
                    <span className="fa-layers fa-fw">
                        <FontAwesomeIcon
                            icon="fa-solid fa-chevron-right"
                            color="#000"
                            transform={
                                isSmall ? 'grow-2 down-2' : 'grow-7 down-2'
                            }
                        />
                    </span>
                </Grid>
            </Grid>
        </ButtonBase>
    );
};
export default RightChevronIcon;
