import axios from 'axios';

export default async function getStripeIdForDibs(userid, dibsId) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/get-stripeid-or-create`;
    let datatosend = {};
    const checkExists = async () => {
        await axios
            .post(basicurl, {
                userid,
                dibsId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error getting stripe id or creating stripe id: ${err}`,
                );
                return err;
            });
    };
    await checkExists();
    return datatosend;
}
