import React from 'react';
import { Grid, Typography, Fade, Checkbox, Button, Stack } from '@mui/material';
// import Typography from '@mui/joy/Typography';
import { useTheme, alpha } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import {
    setSelectedId,
    setSelectedStaff,
    setSelectedIdsMulti,
    setMultiModeOn,
    removeSelectedIdsMulti,
} from 'store/slices/appointments';
import DotIcon from 'assets/icons/dotIcon';
import StaffList from 'components/appointment-types/staff/staffList';
import CalendarBox from './datetimecalendar/calendarIndex';
import configs from 'helpers/specialConfigs';

const ApptSubItem = ({ appt, i }) => {
    const theme = useTheme();
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const colortoshow = `#${color}`;
    const { passes, numPublicPasses, numPrivatePasses } = useSelector(
        (state) => state.thisuser,
    );
    const [numAppts, setNumAppts] = React.useState(0);
    const [isChoosingProvider, setIsChoosingProvider] = React.useState(false);
    const { selectedId, selectedStaff, selectedIdsMulti, multiModeOn } =
        useSelector((state) => state.appointments);
    // const [multiModeOn, setMultiModeOn] = React.useState(false);

    const [isShowMore, setIsShowMore] = React.useState(false);

    const [checked, setChecked] = React.useState(false);
    const [timeToChooseDate, setTimeToChooseDate] = React.useState(false);

    React.useEffect(() => {
        if (selectedId === appt.id) {
            setChecked(true);
        } else if (selectedIdsMulti.includes(appt.id)) {
            setChecked(true);
        } else if (!selectedIdsMulti.includes(appt.id)) {
            setChecked(false);
        }
        if (selectedStaff !== null) {
            setTimeToChooseDate(true);
        } else {
            setTimeToChooseDate(false);
        }
    }, [appt.id, selectedId, selectedIdsMulti, selectedStaff]);

    const nameOfAppt = appt.appointment_type;
    let formattedName = nameOfAppt.toUpperCase();
    let secondPartOfName = '';
    if (dibsStudioId === '226') {
        const searchFor = '- ';
        const indexQuote = formattedName.search(searchFor);
        if (indexQuote > 0) {
            // splitting english + italian
            secondPartOfName = formattedName.slice(indexQuote);
            formattedName = formattedName.slice(0, indexQuote);
        }
    }

    const showmore = () => {
        setIsShowMore(!isShowMore);
    };
    const selectThisType = () => {
        if (!checked) {
            dispatch(setSelectedId(appt.id));
            dispatch(setSelectedIdsMulti(appt.id));
        }
        // if (checked) {
        //     dispatch(setSelectedId(null));
        //     dispatch(setSelectedStaff(null));
        //     dispatch(removeSelectedIdsMulti(appt.id));
        // }
    };
    const unselectThisProvider = () => {
        dispatch(setSelectedId(null));
        setIsChoosingProvider(false);
        dispatch(setSelectedStaff(null));
        setTimeToChooseDate(false);
    };
    const checkoruncheck = () => {
        // dispatch(setSelectedId(appt.id));
        if (checked) {
            dispatch(setSelectedId(null));
            setIsChoosingProvider(false);
            dispatch(setSelectedStaff(null));
            setTimeToChooseDate(false);
            dispatch(removeSelectedIdsMulti(appt.id));
        }
        setTimeToChooseDate(false);
    };
    const goBackTextAction = () => {
        setTimeToChooseDate(false);
        setIsChoosingProvider(true);
    };
    const addMultiMode = () => {
        console.log('multi mode entered');
        // setMultiModeOn(true);
        dispatch(setMultiModeOn(true));
        dispatch(setSelectedIdsMulti(appt.id));
    };
    const giveOptionChooseStaffMember = () => {
        setIsChoosingProvider(true);
        setTimeToChooseDate(false);
    };
    const transparent = alpha(colortoshow, 0.02);
    const specialBgColor = configs(dibsStudioId, 'bgColorAppts');
    let bgColorToUse = '#fff';
    if (specialBgColor !== undefined) {
        bgColorToUse = specialBgColor;
    }
    let borderColorToUse = '#d9d9d9';
    const specialBorderColor = configs(dibsStudioId, 'borderColorAppts');
    if (specialBorderColor !== undefined) {
        borderColorToUse = specialBorderColor;
    }
    let transparencyToUse = transparent;
    const specialTransparency = configs(dibsStudioId, 'transparencyAppts');
    if (specialTransparency !== undefined) {
        transparencyToUse = specialTransparency;
    }

    return (
        <Grid
            item
            border={0}
            // backgroundColor="#ffffff"
            sx={{
                py: 0,
                pl: 1,
                my: 0,
            }}
            key={`mapped-appt-title-${i}`}
            xs={12}
            md={10.5}>
            <Grid
                container
                id="appointment-title-container-sub"
                border={0}
                alignContent="center"
                justifyContent="space-between"
                sx={{ mt: 2 }}>
                <Grid
                    item
                    id="appt-title-grid"
                    xs={9}
                    border={0}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    key={`appt-title-${i}`}>
                    <Stack>
                        <Typography variant="apptTitle">
                            <span
                                style={{
                                    fontWeight:
                                        dibsStudioId === '226' ? 700 : 400,
                                }}>
                                {formattedName}{' '}
                            </span>
                            {secondPartOfName}
                        </Typography>
                        <Typography
                            variant="apptSubUpdated"
                            sx={{
                                mt: 1,
                                ml: 0.25,
                            }}>
                            ${appt.default_price} <DotIcon />{' '}
                            {appt.length_minutes} mins
                        </Typography>
                    </Stack>
                </Grid>
                <Grid
                    item
                    id="typography-text-check"
                    xs={2}
                    key={`appt-text-check-${i}`}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                        <Grid
                            container
                            border={0}
                            justifyContent="space-around"
                            alignItems="flex-start">
                            <Grid
                                item
                                id="checkbox-item"
                                border={0}
                                sx={{
                                    mt: 1,
                                }}>
                                <Checkbox
                                    inputProps={{
                                        border: 5,
                                    }}
                                    checked={checked}
                                    onChange={(e) => {
                                        checkoruncheck(appt);
                                    }}
                                    sx={{
                                        color: '#999',
                                        '&.Mui-checked': {
                                            color: colortoshow,
                                        },
                                        transform: 'scale(1.2)',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ApptSubItem;
