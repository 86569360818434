import { GOOGLE_AUTH_CONFIG, GOOGLE_CALENDAR_COLOR_CONFIG } from 'config';
import { setAddedToCalendar } from 'store/slices/menu';
import { dispatch } from 'store';

const { client_id, apikey, discoveryDoc, scopes } = GOOGLE_AUTH_CONFIG;

let tokenClient;
let gapiInited = false;
let gisInited = false;
let htmllinktoreturn = '';
let eventtoconfirm;

// console.log('client_id', client_id);
// console.log('apikey', apikey);
// console.log('discoveryDoc', discoveryDoc);
// console.log('scopes', scopes);

export const updateEventToConfirm = (eventpassedin) => {
    eventtoconfirm = eventpassedin;
};

const tellUserPopupBlocked = () => {
    document.getElementById('button-add-to-calendar').innerText =
        'Added to calendar';
    const styleEl = document.createElement('style');
    document.head.appendChild(styleEl);
    const styleSheet = styleEl.sheet;
    var stylefortext = `#div-holding-text-to-say-popup-blocked {
                    font-size: 0.75rem;
                    line-height: 1.2;
                    font-weight: 400;
                    padding-left: 10px;
                    padding-bottom: 20px;
                    }`;

    styleSheet.insertRule(stylefortext);
    const divHoldingButton = document.querySelector(
        '#div-holding-add-to-calendar-button',
    );
    const newdiv = document.createElement('div');
    newdiv.setAttribute('id', 'div-holding-text-to-say-popup-blocked');
    newdiv.innerHTML = `<p>It looks like your browser is blocking the popup that shows you the calendar event. </p><p>If you'd like, you can check your calendar manually to confirm that the appointment was added.</p>`;
    divHoldingButton.appendChild(newdiv);
};

const getColorMatch = (studioid) => {
    const colorid = GOOGLE_CALENDAR_COLOR_CONFIG[studioid];
    if (!colorid) {
        return 1;
    }
    return colorid;
};

const loadScripts = async () => {
    const addEventToButtonAndHide = () => {
        document.getElementById('button-add-to-calendar').style.visibility =
            'hidden';
        const calendarBtn = document.querySelector('#button-add-to-calendar');
        calendarBtn.addEventListener('click', handleAuthClick);
    };

    const addScript = ({ src, id, onLoad }) => {
        const existing = document.getElementById(id);
        if (existing) {
            return existing;
        } else {
            const script = document.createElement('script');
            script.src = src;
            script.id = id;
            script.async = true;
            script.onload = () => {
                if (onLoad) {
                    onLoad();
                }
            };
            document.body.appendChild(script);
            return script;
        }
    };
    /**
     * After google api is loaded. Initialize the client
     */
    function loadGoogleApiClient() {
        addEventToButtonAndHide();
        window.gapi.load('client', initializeGapiClient);
    }
    /**
     * Callback after the API client is loaded. Loads the
     * discovery doc to initialize the API.
     */
    async function initializeGapiClient() {
        await window.gapi.client.init({
            apiKey: apikey,
            discoveryDocs: [discoveryDoc],
        });
        gapiInited = true;
        maybeEnableButtons();
    }

    /**
     * Callback after Google Identity Services are loaded.
     */
    function gisLoaded() {
        tokenClient = window.google.accounts.oauth2.initTokenClient({
            client_id: client_id,
            scope: scopes,
            callback: '', // defined later
        });
        gisInited = true;

        maybeEnableButtons();
    }
    /**
     * Enables add to calendar interaction after all libraries are loaded.
     */
    function maybeEnableButtons() {
        if (gapiInited && gisInited) {
            document.getElementById('button-add-to-calendar').style.visibility =
                'visible';
        }
    }
    /**
     *  Sign in the user upon button click.
     */
    function handleAuthClick() {
        tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) {
                throw resp;
            }
            document.getElementById('button-add-to-calendar').innerText =
                'Adding to calendar';
            await addEventToCalendar();
        };

        if (window.gapi.client.getToken() === null) {
            // Prompt the user to select a Google Account and ask for consent to share their data
            // when establishing a new session.
            tokenClient.requestAccessToken({ prompt: 'consent' });
        } else {
            // Skip display of account chooser and consent dialog for an existing session.
            tokenClient.requestAccessToken({ prompt: '' });
        }
    }
    /**
     * Print the summary and start datetime/date of the next ten events in
     * the authorized user's calendar. If no events are found an
     * appropriate message is printed.
     */
    async function addEventToCalendar() {
        // let response;
        const colortouse = await getColorMatch(eventtoconfirm.dibsId);

        try {
            var event = {
                summary: eventtoconfirm.title,
                location: eventtoconfirm.location,
                description: eventtoconfirm.description,

                start: {
                    dateTime: eventtoconfirm.start,
                    timeZone: eventtoconfirm.timezone,
                },
                colorId: colortouse,
                sendUpdates: 'all', // all or externalOnly
                end: {
                    dateTime: eventtoconfirm.end,
                    timeZone: eventtoconfirm.timezone,
                },
                // recurrence: ['RRULE:FREQ=DAILY;COUNT=2'],
                // attendees: [{ email: eventtoconfirm.useremail }],

                reminders: {
                    useDefault: false,
                    overrides: [
                        { method: 'email', minutes: 24 * 60 },
                        { method: 'popup', minutes: 30 },
                    ],
                },
            };

            await window.gapi.client.calendar.events
                .insert({
                    calendarId: 'primary',
                    resource: event,
                })
                .then((res) => {
                    document.getElementById(
                        'button-add-to-calendar',
                    ).innerText = 'Added to calendar';
                    const calendarBtn = document.querySelector(
                        '#button-add-to-calendar',
                    );
                    calendarBtn.removeEventListener('click', handleAuthClick);
                    dispatch(setAddedToCalendar(true));

                    const { result } = res;
                    const { htmlLink } = result;
                    htmllinktoreturn = htmlLink;
                    try {
                        const newwindow = window.open(
                            htmllinktoreturn,
                            'calendarevent',
                        );
                        try {
                            const { window } = newwindow;
                            const { closed } = window;
                            if (closed) {
                                const added =
                                    htmllinktoreturn !== '' ? true : false;
                                tellUserPopupBlocked(added);
                            }
                        } catch (err) {
                            console.log('error opening new window v2', err);
                            const added =
                                htmllinktoreturn !== '' ? true : false;
                            tellUserPopupBlocked(added);
                            return;
                        }
                    } catch (err) {
                        console.log('error opening new page to calendar', err);
                        return;
                    }
                });
        } catch (err) {
            console.log('error adding to calendar', err);
            return;
        }
    }

    /**
     * Load Google api.js is loaded.
     */
    addScript({
        src: `https://apis.google.com/js/api.js`,
        id: 'google-api-script',
        onLoad: () => {
            // console.log('Google api script loaded!');
            loadGoogleApiClient();
        },
    });
    /**
     * Load Google Identity Services.
     */
    addScript({
        src: `https://accounts.google.com/gsi/client`,
        id: 'google-indentity-services-script',
        onLoad: () => {
            // console.log('Google indentity services script loaded!');
            gisLoaded();
        },
    });
};

export default loadScripts;
