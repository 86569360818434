import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { roundtoN } from 'helpers';

// material-ui
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    Link,
    Grid,
    Fade
} from '@mui/material';

// third-party
import currency from 'currency.js';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { dispatch, useSelector } from 'store';

import { setTotal, setSubTotal, setTotalTax, setCreditApplied, setTotalAfterCreditApplied, setShowSummaryPaymentScreen, setShowCheckoutButton, setShowAllExistingPaymentOptions, setIsUsingCreditToCoverPackage } from 'store/slices/cart';

// ==============================|| CHECKOUT CART - ORDER SUMMARY ||============================== //

const OrderSummary = ({ itemsincart, totalPostCredit, creditApplied }) => {
    const { needstopay, cartPreparedForCheckout, potentialRemainingCredit, total, showCheckoutButton } = useSelector(
        (state) => state.cart,
    );
    const { paymentOptionsAll } = useSelector((state) => state.thisuser);
    const handleNoCreditClick = () => {
        dispatch(setIsUsingCreditToCoverPackage(false));
        dispatch(setCreditApplied(0));
        dispatch(setTotalAfterCreditApplied(total));
        // check to see if the total is > 0 - do we need to capture credit card
        if (total > 0) {
            console.log('need to capture credit card');
            if (showCheckoutButton) {
                console.log('we are in the check out process - let us see if we have credit card on file');
                dispatch(setShowCheckoutButton(false));
                if (paymentOptionsAll.length > 0) {
                    console.log('cc on file - should show summary screen');
                    dispatch(setShowSummaryPaymentScreen(true));
                } else {
                    console.log('no cc on file - should show all payment options - prompt user to add card');
                    dispatch(setShowAllExistingPaymentOptions(true))
                }
            }
        } else {
            dispatch(setShowSummaryPaymentScreen(false));
        }
        // if we are in the checkout process - do we have a credit card on file
        // credit card on file, then, show the summary screen
        // no credit card on file, then show all existing payment options - prompt user to add credit card
    }
    const [showOrderSummary, setShowOrderSummary] = useState(true);
    const cart = useMemo(() => {
        if (itemsincart) {
            const subtotalClasses = itemsincart.reduce(
                (acc, item) => acc + item.price * item.quantity,
                0,
            );
            const getvaluetoadd = (price, quantity, howtopay) => {
                let p = price * quantity;
                if (howtopay !== undefined && howtopay.passid > 0) {
                    p = 0;
                }
                return p;
            };
            const testSubtotalClasses = cartPreparedForCheckout.reduce(
                (acc, item) =>
                    acc +
                    getvaluetoadd(item.price, item.quantity, item.howtopay),
                0,
            );
            const classItemTaxToAdd = itemsincart.reduce(
                (acc, item) => acc + item.itemtax * item.quantity,
                0,
            );

            const testClassItemTaxToAdd = cartPreparedForCheckout.reduce(
                (acc, item) =>
                    acc +
                    getvaluetoadd(item.itemtax, item.quantity, item.howtopay),
                0,
            );
            const subtotal = subtotalClasses;
            const testSubtotal = testSubtotalClasses;
            const discount = 0; // take care of discounts later
            // const tax = (subtotal - discount) * 0.045;
            const tax = roundtoN(classItemTaxToAdd, 2);
            const testTax = roundtoN(testClassItemTaxToAdd, 2);
            const total = subtotal + tax;
            const testTotal = testSubtotal + testTax;

            const numitems = itemsincart.reduce(
                (acc, item) => acc + item.quantity,
                0,
            );
            return {
                subtotal: testSubtotal,
                tax: testTax,
                total: testTotal,
                numitems,
                totalPostCredit: totalPostCredit,
                creditApplied: creditApplied,
                potentialRemainingCredit,
                discount,
            };
        } else {
            return null;
        }
    }, [cartPreparedForCheckout, creditApplied, itemsincart, totalPostCredit, potentialRemainingCredit]);

    useEffect(() => {
        if (!needstopay) {
            // dispatch(setTotal(0));
            // setShowOrderSummary(false);
        } else if (cart.total > 0) {
            dispatch(setSubTotal(cart.subtotal));
            dispatch(setTotal(cart.total));
            dispatch(setTotalTax(cart.tax));
        }
    }, [cart, needstopay]);
    useEffect(() => {
        if (total === 0) {
            dispatch(setShowSummaryPaymentScreen(false));
        }
    }, [total]);


    return (
        <>
            {showOrderSummary && (
                <SubCard>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 'auto' }}
                            size="small"
                            aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle1">
                                            Order Summary
                                        </Typography>
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2">
                                            Sub Total
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2">
                                            {currency(cart.subtotal).format()}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {cart.discount > 0 && (
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="subtitle2">
                                                Coupon Discount
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            {cart.discount && (
                                                <Typography variant="subtitle2">
                                                    -
                                                    {currency(
                                                        cart.discount,
                                                    ).format()}
                                                </Typography>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="subtitle2">
                                            Tax
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2">
                                            {currency(cart.tax).format()}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }}>
                                        <Typography variant="subtitle1">
                                            Total
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{ borderBottom: 'none' }}>
                                        <Typography variant="subtitle1">
                                            {currency(cart.total).format()}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                {cart.creditApplied > 0 && (
                                    <>
                                        {/* <Fade in={cart.creditApplied > 0}> */}
                                        <TableRow>
                                            <TableCell
                                                sx={{ borderBottom: 'none' }}>
                                                &nbsp;
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="subtitle2">
                                                    Credit Applied
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Typography variant="subtitle2">
                                                    (
                                                    {currency(
                                                        cart.creditApplied,
                                                    ).format()}
                                                    )
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell
                                                sx={{ borderBottom: 'none' }}>
                                                <Typography variant="subtitle1">
                                                    Total Cost
                                                </Typography>
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                sx={{ borderBottom: 'none' }}>
                                                <Typography variant="subtitle1">
                                                    {currency(
                                                        cart.totalPostCredit,
                                                    ).format()}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        {cart.creditApplied > 0 && (
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: 'none' }}>{' '}</TableCell>
                                                <TableCell align="right"
                                                    sx={{ borderBottom: 'none' }}>
                                                    <Typography>
                                                        <Typography variant="caption" sx={{ fontStyle: 'italic' }}>(After this transaction, you will have {currency(cart.potentialRemainingCredit).format()} in credit remaining)</Typography>
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {/* </Fade> */}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </SubCard>

            )}
            {cart.creditApplied > 0 && (
                <Fade in={cart.creditApplied > 0}>
                    <Grid container border={0} justifyContent="flex-end">
                        <Typography sx={{ mt: 2, mb: 2, mr: 2 }}>
                            <Link
                                onClick={handleNoCreditClick}
                                style={{ cursor: 'pointer' }}
                            >
                                <Typography variant="caption" sx={{ color: '#ff6900' }}>Do not apply my existing credit to this purchase</Typography>
                            </Link>

                        </Typography>
                    </Grid>
                </Fade>
            )}
        </>
    );
};

OrderSummary.propTypes = {
    checkout: PropTypes.object,
};

export default OrderSummary;
