import axios from 'axios';

export default async function confirmAvailablePass(passid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/confirm-pass-availability`;
    let datatosend = {};
    const checkPasses = async () => {
        await axios
            .post(basicurl, {
                passid
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error getting confirmAvailablePass for this user ${passid}: ${err}`);
                return err;
            });
    };
    await checkPasses();
    return datatosend;
}
