import { useState } from 'react';
import { Grid, Fade } from '@mui/material';

import CalendarDisplay from './calendardisplay';
import AvailableAppts from './availableAppointments';
import ConfirmationPanel from './confirmationPanel';
import 'react-day-picker/dist/style.css';
import './day-picker.css';

const CalendarIndex = ({ gobackaction, setTimeToChooseDate }) => {
    const [isConfirming, setIsConfirming] = useState(false);
    const [resetSearch, setResetSearch] = useState(false);
    // const colortoshow = `#${color}`;
    // useEffect(() => {}, [isConfirming]);

    if (isConfirming)
        return (
            <Fade in={isConfirming} timeout={600}>
                <Grid
                    container
                    id="container-confirmation-panel"
                    justifyContent="center"
                    sx={{ mt: 7 }}>
                    <ConfirmationPanel
                        setIsConfirming={setIsConfirming}
                        setResetSearch={setResetSearch}
                        setTimeToChooseDate={setTimeToChooseDate}
                    />
                </Grid>
            </Fade>
        );
    return (
        <Grid
            container
            border={0}
            id="calendar-display-container"
            justifyContent="space-between"
            sx={{ mb: 5, pr: 0, pl: window.isTiny ? 0 : 1, mt: 2 }}>
            <Grid
                item
                xs={12}
                md={5}
                border={0}
                sx={{ mt: 6 }}
                id="calendar-display-choose-date">
                <CalendarDisplay />
            </Grid>
            <Grid item xs={12} md={6} border={0} sx={{ m: 0 }}>
                <Grid
                    container
                    border={0}
                    justifyContent="flex-end"
                    id="container-w-available-appts">
                    <AvailableAppts
                        setIsConfirming={setIsConfirming}
                        resetSearch={resetSearch}
                        setResetSearch={setResetSearch}
                        gobackaction={gobackaction}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CalendarIndex;
