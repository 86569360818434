import { useState, useEffect } from 'react';
import { Grid, Box } from '@mui/material';
import ListOfEvents from './eventsList';
import { useSelector } from 'store';

const ScheduleList = () => {
    const { buttonControlsWidget, widgetOpenStyle } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const [hasClassesOnThisDay, setHasClassesOnThisDay] = useState(false);
    const [borderBottomColor, setBorderBottomColor] = useState('#d3d3d3');
    const isSmallWidget = buttonControlsWidget && widgetOpenStyle === 'right';
    const borderstyleModal = {
        borderBottom: `1px solid ${borderBottomColor}`,
        borderTop: '0px solid #999',
        borderLeft: 0,
        borderRight: 0,
    };
    useEffect(() => {
        if (hasClassesOnThisDay) setBorderBottomColor('#fff');
        else setBorderBottomColor('#d3d3d3');
    }, [hasClassesOnThisDay]);
    const borderstyleInline = {
        border: '1px solid #d3d3d3',
    };
    return (
        <Box
            id="box-that-holds-schedule"
            sx={{
                // borderColor: '#d3d3d3',
                borderColor: '#fff',
                border: isSmallWidget ? borderstyleModal : borderstyleInline,
                // borderTop: buttonControlsWidget ? 1 : 0,
                borderRadius: isSmallWidget ? 0 : 2,
                px: buttonControlsWidget ? 0 : 2,
                pt: buttonControlsWidget ? 0 : 2,
                // minHeight: 50,
                mt: 0,
            }}>
            <Grid container border={0}>
                <Grid item xs={12}>
                    <ListOfEvents
                        setHasClassesOnThisDay={setHasClassesOnThisDay}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
export default ScheduleList;
