import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DotIcon = ({ color }) => {
    const colortoshow = '#716d6d';
    // if (color) {
    //     colortoshow = `${color}`;
    // }

    // <FontAwesomeIcon icon="fa-regular fa-check" />;
    return (
        <FontAwesomeIcon
            icon="fa-circle fa-solid"
            transform="shrink-20"
            color={colortoshow}
        />
    );
};
export default DotIcon;
