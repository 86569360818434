import { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Typography,
    Button,
    Fade,
    LinearProgress,
    Checkbox
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { capitalizeString } from 'helpers';
import { useSelector, dispatch } from 'store';
import XIcon from 'assets/icons/XIcon';
import NotifyRemovePayment from 'components/notifications/removePaymentMethod';
import removeCardFromStripe from 'actions/stripe/removeCardFromStripe';

import { setRefreshPaymentOptions } from 'store/slices/thisuser';
import { setCardToCharge, setCardBrand, setExpy, setLastFourCard } from 'store/slices/cart';
import setSuccess from 'actions/status/setAlert';

const IndividualCardDisplayProfile = ({ cc, setSelected, exemptFromSelect }) => {
    const theme = useTheme();
    const [last4ToUse, setLast4ToUse] = useState('');
    const [cardBrandHere, setCardBrandHere] = useState('');
    const [expy, setExpyHere] = useState('');
    const [isRemoving, setIsRemoving] = useState(false);
    const [isRemovingFromStripe, setIsRemovingFromStripe] = useState(false);
    const [showRemoveButton, setShowRemoveButton] = useState(true);
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const { userid } = useSelector((state) => state.thisuser);
    const { dibsStudioId } = config;
    const { cardtocharge } = useSelector((state) => state.cart);
    const [thiscardIsSelected, setThisCardIsSelected] = useState(false);
    const [userMadeAction, setUserMadeAction] = useState(false);
    const { color } = studioConfig;
    const coloruse = `#${color}`;
    const [bcolor, setBColor] = useState(coloruse);

    useEffect(() => {
        if (cardtocharge) {
            if (cardtocharge === cc.id) {
                setThisCardIsSelected(true);
            } else {
                setThisCardIsSelected(false);
            }
        }
    }, [cardtocharge, cc]);

    useEffect(() => {
        let selectACard = true;
        if (exemptFromSelect) selectACard = false;
        if (thiscardIsSelected) {
            setTimeout(() => {
                if (!userMadeAction && selectACard) {
                    setSelected(cc);
                }
            }, 4000);
        }
    }, [thiscardIsSelected, cc, setSelected, userMadeAction, exemptFromSelect]);

    useEffect(() => {

        if (cc) {
            const { card } = cc;
            const { brand, exp_month, exp_year, last4 } = card;
            setExpyHere(`${exp_month}/${exp_year}`);
            setLast4ToUse(last4);
            setCardBrandHere(brand);
        }
        setBColor(coloruse);
    }, [cc, coloruse]);
    const cardnum = `**** **** **** ${last4ToUse}`;
    const showMessage = () => {
        setIsRemoving(true);
        setShowRemoveButton(false);
    };
    const confirmRemoving = async (value) => {
        if (value === 'yes') {
            setIsRemovingFromStripe(true);
            await removeCardFromStripe(cc, dibsStudioId, userid).then((res) => {
                console.log('response from removing card', res);
                if (res === 1) {
                    dispatch(setRefreshPaymentOptions(true));
                    setSuccess({
                        msg: 'Card removed!',
                        alertType: 'success',
                        success: true
                    })
                    setIsRemovingFromStripe(false);
                    setIsRemoving(false);
                    // setTimeout(() => {
                    //     setIsRemovingFromStripe(false);
                    //     setIsRemoving(false);
                    // }, 1000);
                    setShowRemoveButton(true);

                } else {
                    setIsRemovingFromStripe(false);
                    setIsRemoving(false);
                    setShowRemoveButton(true);
                    setSuccess({
                        msg: 'There was an error removing this card. Try refreshing your screen and then try again.',
                        error: true
                    })
                }

            }).catch((err) => {
                console.log('error removing card', err);
                setIsRemovingFromStripe(false);
                setIsRemoving(false);
                setShowRemoveButton(true);
            });
        } else if (value === 'no') {
            setIsRemoving(false);
            setShowRemoveButton(true);
        }
    };
    const classnameToUse = `credit-card-tiny ${cardBrandHere} selectable isdefault`;
    const onChangeCheck = () => {
        setUserMadeAction(true);
        setThisCardIsSelected(!thiscardIsSelected);
        const { id } = cc;
        dispatch(setCardToCharge(id));
        dispatch(setExpy(expy));
        dispatch(setLastFourCard(last4ToUse));
        dispatch(setCardBrand(cardBrandHere));
        setSuccess({
            msg: 'Your selected card has been updated',
            success: true,
            seconds: 5
        })
        if (exemptFromSelect) return;
        setSelected(cc);
    };

    return (
        <Box maxWidth={210}>
            <Grid item xs={12} sx={{ mr: 1, ml: 1, mb: 2, mt: 0 }} className={classnameToUse} onClick={onChangeCheck}>
                <Grid container id="profile-individual-card-display" >
                    <Grid item xs={12} sx={{ ml: 1, mt: 0 }}>
                        <Grid container justifyContent="flex-end">
                            <Checkbox checked={thiscardIsSelected} onChange={onChangeCheck} size="small" sx={{ pb: 0, pt: 0.7, px: 0 }} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1, pt: 0, mt: 0 }} id="holding-text-card-number">
                        <Typography variant="h4tiny">Card Number</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, ml: 1 }}>
                        <Typography variant="creditcardtiny">{cardnum}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="h4tiny">Expiration</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="creditcardtiny">{expy}</Typography>
                    </Grid>
                </Grid>

            </Grid>


            {isRemoving && !isRemovingFromStripe && (
                <Fade
                    in={isRemoving}
                    timeout={{ appear: 500, enter: 400, exit: 500 }}>
                    <Grid item xs={12} sx={{ mx: 2, my: 0.5, pt: 1 }}>
                        <NotifyRemovePayment
                            message="Remove this payment method?"
                            action={confirmRemoving}
                        />
                    </Grid>
                </Fade>
            )}
            {isRemovingFromStripe && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
        </Box>
    );
};
export default IndividualCardDisplayProfile;
