// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    hasError: false,
    errorMessage: '',
    hasSuccess: false,
    successMessage: '',
    secondstoshow: 5,
    hasInfo: false,
    infoMessage: '',
    hasWarning: false,
    warningMessage: '',
    alertType: '',
    messageToDisplay: '',
};

const error = createSlice({
    name: 'error',
    initialState,
    reducers: {
        // HAS ERROR
        setHasErrorReducer(state, action) {
            state.hasError = action.payload;
            state.alertType = 'error';
        },
        setMessageToDisplay(state, action) {
            state.messageToDisplay = action.payload;
        },
        setSecondsToShow(state, action) {
            state.secondstoshow = action.payload;
        },
        // ADD ERROR MESSAGE
        setErrorMessageReducer(state, action) {
            state.errorMessage = action.payload;
            state.messageToDisplay = action.payload;

        },
        // HAS SUCCESS
        setHasSuccessReducer(state, action) {
            state.hasSuccess = action.payload;
            state.alertType = 'success';
        },
        // ADD SUCCESS MESSAGE
        setSuccessMessageReducer(state, action) {
            state.successMessage = action.payload;
            state.messageToDisplay = action.payload;
        },
        setHasWarningReducer(state, action) {
            state.hasWarning = action.payload;
            state.alertType = 'warning';
        },
        setWarningMessageReducer(state, action) {
            state.warningMessage = action.payload;
            state.messageToDisplay = action.payload;
        },
        setHasInfoReducer(state, action) {
            state.hasInfo = action.payload;
            state.alertType = 'info';
        },
        setInfoMessageReducer(state, action) {
            state.infoMessage = action.payload;
            state.messageToDisplay = action.payload;
        },
        clearErrorMessageReducer(state) {
            state.hasError = false;
            state.errorMessage = '';
            state.alertType = '';
        },
        clearSuccessMessageReducer(state) {
            state.hasSuccess = false;
            state.successMessage = '';
            state.alertType = '';
        },
        clearAllStatusMessageReducer(state) {
            state.hasSuccess = false;
            state.successMessage = '';
            state.hasError = false;
            state.errorMessage = '';
            state.alertType = '';
            state.hasInfo = false;
            state.infoMessage = '';
            state.hasWarning = false;
            state.warningMessage = '';
            state.messageToDisplay = '';
        },
    },
});

// Reducer
export default error.reducer;
export const {
    setHasErrorReducer,
    setErrorMessageReducer,
    setHasWarningReducer,
    setWarningMessageReducer,
    setMessageToDisplay,
    setHasInfoReducer,
    setInfoMessageReducer,
    clearErrorMessageReducer,
    clearSuccessMessageReducer,
    clearAllStatusMessageReducer,
    setHasSuccessReducer,
    setSuccessMessageReducer,
    setSecondsToShow,
} = error.actions;

// ----------------------------------------------------------------------
