import axios from 'axios';
export default async function cancelRenewal(dibsId, userid, passid, packageid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/stripe/cancel-renewal`;
    let datatosend = {};
    const tosend = {
        dibsId,
        userid,
        passid,
        packageid,
    };
    const cancel = async () => {
        await axios
            .post(basicurl, tosend)
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error checking whether class exists: ${err}`);
                return err;
            });
    };
    await cancel();
    return datatosend;
}
