import axios from 'axios';

export default async function updatePhoneNumber(userid, phonenumber, countryAbbr) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/user/update-phonenumber`;

    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(basicurl, {
                userid,
                phoneNumber: phonenumber,
                countryAbbrev: countryAbbr,
            })
            .then((res) => {
                const { data } = res;
                console.log('\n\ndata to return from updating user phonenumber', data);
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error updating user phone number - IN update phone #: ${err} userid is ${userid} phonenumber is ${phonenumber}`);
                return err;
            });
    };
    await createUser();
    return datatosend;
}
