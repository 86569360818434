import { useState, useEffect } from 'react';
import {
    Grid,
    Box,
    Typography,
    Button,
    Fade,
    LinearProgress,
    Checkbox,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { capitalizeString } from 'helpers';
import { useSelector, dispatch } from 'store';
import XIcon from 'assets/icons/XIcon';
import NotifyRemovePayment from 'components/notifications/removePaymentMethod';
import removeCardFromStripe from 'actions/stripe/removeCardFromStripe';

import { setRefreshPaymentOptions } from 'store/slices/thisuser';
import {
    setCardToCharge,
    setCardBrand,
    setExpy,
    setLastFourCard,
    setPlatformHoldingCardToCharge,
} from 'store/slices/cart';
import setSuccess from 'actions/status/setAlert';
import getAllStripePayments from 'actions/stripe/getAllStripePayments';

const IndividualCardDisplayProfile = ({
    cc,
    setSelected,
    exemptFromSelect,
}) => {
    const theme = useTheme();
    const [last4ToUse, setLast4ToUse] = useState('');
    const [cardBrandHere, setCardBrandHere] = useState('');
    const [expy, setExpyHere] = useState('');
    const [platformHolding, setPlatformHolding] = useState('');
    const [isRemoving, setIsRemoving] = useState(false);
    const [isRemovingFromStripe, setIsRemovingFromStripe] = useState(false);
    const [showRemoveButton, setShowRemoveButton] = useState(true);
    const [doNotShowCard, setDoNotShowCard] = useState(false);
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const { userid } = useSelector((state) => state.thisuser);
    const { dibsStudioId } = config;
    const { cardtocharge } = useSelector((state) => state.cart);
    const [thiscardIsSelected, setThisCardIsSelected] = useState(false);
    const [userMadeAction, setUserMadeAction] = useState(false);
    const { color } = studioConfig;
    const coloruse = `#${color}`;
    const [bcolor, setBColor] = useState(coloruse);

    useEffect(() => {
        if (cardtocharge) {
            if (cardtocharge === cc.id) {
                setThisCardIsSelected(true);
            } else {
                setThisCardIsSelected(false);
            }
        }
    }, [cardtocharge, cc]);

    useEffect(() => {
        let selectACard = true;
        if (exemptFromSelect) selectACard = false;
        if (thiscardIsSelected) {
            setTimeout(() => {
                if (!userMadeAction && selectACard) {
                    setSelected(cc);
                }
            }, 1000);
        }
    }, [thiscardIsSelected, cc, setSelected, userMadeAction, exemptFromSelect]);

    useEffect(() => {
        if (cc) {
            try {
                const { card, platform } = cc;
                const { brand, exp_month, exp_year, last4 } = card;
                setExpyHere(`${exp_month}/${exp_year}`);
                setLast4ToUse(last4);
                setCardBrandHere(brand);
                setDoNotShowCard(false);
                setPlatformHolding(platform);
            } catch (err) {
                console.log('error getting card info', err);
                setDoNotShowCard(true);
            }
        }
        setBColor(coloruse);
    }, [cc, coloruse]);
    const cardnum = `**** **** **** ${last4ToUse}`;
    const showMessage = () => {
        setIsRemoving(true);
        setShowRemoveButton(false);
    };
    const confirmRemoving = async (value) => {
        if (value === 'yes') {
            setIsRemovingFromStripe(true);
            console.log('cc card that is being removed is', cc);
            await removeCardFromStripe(cc, dibsStudioId, userid)
                .then(async (res) => {
                    console.log(
                        '\n\n\nRESPONSE from removing card from stripe',
                        res,
                    );
                    if (res === 1) {
                        console.log('card was removed from stripe');
                        dispatch(setRefreshPaymentOptions(true)); // check this - why didn't it update
                        setSuccess({
                            msg: 'Card removed!',
                            alertType: 'success',
                            success: true,
                        });
                        setIsRemovingFromStripe(false);
                        setIsRemoving(false);
                        // setTimeout(() => {
                        //     setIsRemovingFromStripe(false);
                        //     setIsRemoving(false);
                        // }, 1000);
                        setShowRemoveButton(true);
                    } else {
                        setIsRemovingFromStripe(false);
                        setIsRemoving(false);
                        setShowRemoveButton(true);
                        setSuccess({
                            msg: 'There was an error removing this card. Try refreshing your screen and then try again.',
                            error: true,
                        });
                    }
                })
                .catch((err) => {
                    console.log('error removing card', err);
                    setIsRemovingFromStripe(false);
                    setIsRemoving(false);
                    setShowRemoveButton(true);
                });
        } else if (value === 'no') {
            setIsRemoving(false);
            setShowRemoveButton(true);
        }
    };
    const classnameToUse = `credit-card ${cardBrandHere} selectable isdefault`;
    const onChangeCheck = () => {
        setUserMadeAction(true);
        const { id } = cc;
        if (cardtocharge === id) {
            setSuccess({
                msg: 'To select this card for payment, click the green button.',
                warning: true,
                seconds: 5,
            });
            return;
        }
        setThisCardIsSelected(!thiscardIsSelected);
        dispatch(setCardToCharge(id));
        dispatch(setExpy(expy));
        dispatch(setLastFourCard(last4ToUse));
        dispatch(setCardBrand(cardBrandHere));
        dispatch(setPlatformHoldingCardToCharge(platformHolding));

        if (exemptFromSelect) return;
        setSelected(cc);
    };
    if (doNotShowCard) return null;

    return (
        <Box maxWidth={210}>
            <Grid
                item
                xs={12}
                sx={{ mr: 2, ml: 2, mb: 2, mt: 0 }}
                className={classnameToUse}
                onClick={onChangeCheck}>
                <Grid container id="profile-individual-card-display">
                    {/* <Grid item xs={12} sx={{ mb: 2, ml: 0.5 }}>
                        {cardBrandHere}
                    </Grid> */}
                    <Grid item xs={12} sx={{ ml: 1, mt: 0 }}>
                        <Grid container justifyContent="flex-end">
                            <Checkbox
                                checked={thiscardIsSelected}
                                onChange={onChangeCheck}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="h4">Card Number</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 1, ml: 1 }}>
                        <Typography variant="creditcard">{cardnum}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="h4">Expiration</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ ml: 1 }}>
                        <Typography variant="creditcard">{expy}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {showRemoveButton && (
                <Grid item xs={12} sx={{ mt: 0 }} border={0}>
                    <Grid container justifyContent="center">
                        <Button onClick={showMessage}>Remove this card</Button>
                    </Grid>
                </Grid>
            )}

            {isRemoving && !isRemovingFromStripe && (
                <Fade
                    in={isRemoving}
                    timeout={{ appear: 500, enter: 400, exit: 500 }}>
                    <Grid item xs={12} sx={{ mx: 2, my: 0.5, pt: 1 }}>
                        <NotifyRemovePayment
                            message="Remove this payment method?"
                            action={confirmRemoving}
                        />
                    </Grid>
                </Fade>
            )}
            {isRemovingFromStripe && (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            )}
        </Box>
    );
};
export default IndividualCardDisplayProfile;
