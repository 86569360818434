import axios from 'axios';

export default async function updateProfileInfo(profile) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/update-profile`;
    let datatosend = {};
    const hasAccount = async () => {
        await axios
            .post(basicurl, {
                profile,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from updating Profile: ${err}`);
                return err;
            });
    };
    await hasAccount();
    return datatosend;
}
