import { Grid, Fade } from '@mui/material';

import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';

import { useSelector, dispatch } from 'store';
import { setIsProcessing } from 'store/slices/cart';

import SubCard from 'ui-component/cards/AccountSubCardTinyCentered';
import CollectCC from 'components/stripe/CollectCardInfoTiny';
import setupIntent from 'actions/stripe/createStripeSetupIntent';
import cancelSetupIntent from 'actions/stripe/intents/cancelSetupIntent';
import Progress from 'assets/CircularIndeterminate';

// ==============================|| DISPLAY CC CARDS ||============================== //
import './credit-card-tiny.css';



const DisplayAddingCCNoPayments = ({ endingAction, intentId }) => {

    const subCardTitle = 'Adding A Payment Method';
    const [setupIntentIsReady, setSetupIntentIsReady] = useState(false);
    const [didRequestSetupIntent, setDidRequestSetupIntent] = useState(false);
    const [showTinyCardCapture, setShowTinyCardCapture] = useState(true);
    const [setupIntentCreatedId, setSetupIntentCreatedId] = useState(null);
    const [onDibsPlatform, setOnDibsPlatform] = useState(true);
    const [isReady, setIsReady] = useState(false);
    const { userid, firstname, lastname, email, stripeid } =
        useSelector((state) => state.thisuser);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);

    useEffect(() => {
        if (intentId !== null) {
            console.log('intentID is', intentId);
            console.log('line 39 - setting ready to true')
            setIsReady(true);
        } else {
            setIsReady(false);
        }
    }, [intentId]);
    useEffect(() => {
        return () => {
            setIsReady(false);
        };
    }, []);

    const cancelAction = async () => {
        endingAction();
        setShowTinyCardCapture(false);
        setSetupIntentIsReady(false);
        setDidRequestSetupIntent(false);
        setShowTinyCardCapture(false);
        await cancelSetupIntent(setupIntentCreatedId, onDibsPlatform, dibsStudioId)
            .then((res) => {
                console.log('intent was canceled - response was', res);
                setSetupIntentIsReady(false);
                setDidRequestSetupIntent(false);
            })
            .catch((err) => {
                console.log('error canceling the intent', err);
            });

        console.log('canceled the setup intent - on cancel - display tiny cards', setupIntentCreatedId);
    }



    const toggleShowCardCapture = async () => {
        setShowTinyCardCapture(!showTinyCardCapture);
        dispatch(setIsProcessing(false));
        setOnDibsPlatform(true);
        if (!showTinyCardCapture) {
            window.localStorage.setItem('stripeReady', false);
            window.localStorage.setItem('intentPlatform', 'dibs');
            window.localStorage.setItem('intentType', 'setup');
            window.localStorage.setItem('intentSetFrom', 'adding-cc-no-payments');
            if (!didRequestSetupIntent) {
                setDidRequestSetupIntent(true);
                await setupIntent(
                    stripeid,
                    userid,
                    email,
                    `${firstname} ${lastname}`,
                    dibsStudioId,
                    onDibsPlatform,
                )
                    .then((res) => {
                        console.log('setting client secret - line 90', res.clientSecret)
                        window.localStorage.setItem('clientSecret', res.clientSecret);
                        window.localStorage.setItem('clientSecretDate', new Date().toISOString());
                        setSetupIntentCreatedId(res.setupIntentId);
                        // dispatch(setShowCCCapture(true));
                        setSetupIntentIsReady(true);
                        // NEXT STEP
                        // remove it if it exists
                        // cancel it if there was an intent
                    })
                    .catch((err) => {
                        console.log('error is - after creating stripe intent on tiny cards', err);
                        cancelAction();
                    });

            } else {
                console.log('\n\n\nsetup intent was already requested, so we will not request it again');
            }

        } else {
            // user has clicked go back - 
            // need to cancel the intent
            console.log('\n\n\n\ncc capture was true, now setting the credit card capture to false');
            console.log('\n\n\n\nI THINK I NEED TO CANCEL THE INTENT HERE');

        }
    };

    console.log('isREADY is', isReady)
    return (
        <Fade in timeout={600}>
            <Grid container justifyContent="center">
                <Grid
                    item
                    id="display-tiny-card-container-no-payments"
                    border={0}
                    xs={window.isTiny ? 12 : 11}
                    sx={{ mb: 2 }}>
                    <SubCard title={subCardTitle} newVariantTitle="h5SmallTitle" includeSecondary={false}>
                        <Grid container spacing={gridSpacing}>
                            {isReady && (
                                <Grid item xs={12}>
                                    <Grid container justifyContent="center">
                                        <Grid item xs={12}>
                                            <CollectCC intentType="setup" cancelAction={cancelAction} endingAction={endingAction} donotshowcancelbutton />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )}
                            {!isReady && (
                                <Grid item xs={12} id="loading-cc-capture-no-payment">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-evenly">
                                        {/* {showCardCapture && addPaymentMethod} */}
                                        <Progress />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </SubCard>
                </Grid>
            </Grid>
        </Fade>
    );
};
export default DisplayAddingCCNoPayments;
