import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';

import PromoCodeField from 'components/promo-codes/promoField';
import AbbrevCreditCard from 'components/credit-cards/AbbrevCreditCard';
import DifferentCardOption from 'components/credit-cards/DifferentCardOption';

import { useSelector } from 'store';

const BottomSectionForPackages = ({
    pack,
    userid,
    email,
    credit,
    showCreditCardAction,
}) => {
    const [showSection, setShowSection] = useState(false);
    const [showUseDifferentCard, setShowUseDifferentCard] = useState(false);
    const { paymentOptionsAll } = useSelector((state) => state.thisuser);

    // const { total } = useSelector((state) => state.cart);
    useEffect(() => {
        // if (total === 0) setShowSection(false);
        if (pack.price > 0) setShowSection(true);
    }, [pack, pack.price]);
    useEffect(() => {
        if (paymentOptionsAll.length > 0) setShowUseDifferentCard(true);
        else setShowUseDifferentCard(false);
    }, [paymentOptionsAll]);
    if (!showSection) return null;
    return (
        <>
            <Grid container border={0} sx={{ mt: 3 }}>
                <PromoCodeField
                    pack={pack}
                    userid={userid}
                    email={email}
                    credit={credit}
                />
            </Grid>
            <Grid container border={0} sx={{ mt: 1.5, mb: 1 }}>
                <AbbrevCreditCard pack={pack} showccaction={showCreditCardAction} />
            </Grid>
            {showUseDifferentCard && (
                <Grid container border={0} sx={{ mt: 0, mb: 1 }}>
                    <DifferentCardOption
                        pack={pack}
                        showccaction={showCreditCardAction}
                    />
                </Grid>
            )}

        </>
    );
};

export default BottomSectionForPackages;
