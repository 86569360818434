import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    PaymentElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import { setRefreshPaymentOptions } from 'store/slices/thisuser';
import Progress from 'assets/CircularIndeterminate';

const CheckoutForm = ({ color, location }) => {
    const stripe = useStripe();
    const { userid } = useSelector((state) => state.thisuser);
    const elements = useElements();
    let { dibsId } = useParams();
    const theme = useTheme();
    let urlReturn = `http://localhost:3000/payment/${dibsId}`;
    if (location) urlReturn = `http://localhost:3000${location.pathname}`;
    console.log(`urlReturn is: ${urlReturn}`);
    let prefix = 'https://';
    let domain = window.location.hostname;
    if (process.env.NODE_ENV === 'production') {
        urlReturn = `${prefix}${domain}/payment/${dibsId}`;
        if (location) urlReturn = `${prefix}${domain}${location.pathname}`;
    }
    // const colortoshow = `#ff6900`;
    // const newtheme = createTheme({
    //     ...theme,
    //     palette: {
    //         primary: {
    //             main: color,
    //         },
    //     },
    // });
    const [errorMessage, setErrorMessage] = useState(null);
    const [isAddingCC, setIsAddingCC] = useState(false);
    const paymentElementOptions = {
        layout: 'tabs',
        business: {
            name: 'BUSINESS GOES HERE'
        },
        terms: {
            card: 'never'
        }
        // theme: 'stripe',
        // variables: {
        //     colorPrimary: '#ff6900',
        // },
        // appearance: {
        //     theme: 'minimal',
        // },
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('clicked submit payment method');
        setIsAddingCC(true);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        console.log('urlReturn is: ', urlReturn);
        console.log('check -- need to confirm that this confirmPayment is correct')
        await stripe
            .confirmPayment({
                elements,
                confirmParams: {
                    return_url: urlReturn,
                },
            })
            .then((result) => {
                console.log('result from adding intent is: ', result);
                setIsAddingCC(false);
                dispatch(setRefreshPaymentOptions(true));
                setTimeout(() => {
                    dispatch(setRefreshPaymentOptions(false));
                }, 4000);
            });
    };
    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
            />

            {isAddingCC && (
                <button background="#ff6900" disabled={!stripe}>
                    <Progress color="white" size={24} />
                </button>
            )}
            {!isAddingCC && (
                <button background="#ff6900" disabled={!stripe}>
                    {stripe ? 'Confirm Credit Card' : 'Loading...'}
                </button>
            )}
            {errorMessage && <div>{errorMessage}</div>}
        </form>
    );
};

export default CheckoutForm;
