import { roundtoN } from 'helpers';
// need to update how much credit is actually available for this user

export default async function ApplyCredit(cart, credit) {
    let creditapplied = 0;
    let remainingtotal = 0;
    let remainingcreditbalance = credit;
    const totalcharge = cart.reduce((acc, item) => {
        const { passid } = item.howtopay;
        if (passid > 0) {
            return acc;
        } else {
            const { price, itemtax, quantity } = item;
            const fullcharge = acc + (price + itemtax) * quantity
            return fullcharge;
        }
    }, 0);
    if (credit >= totalcharge) {
        creditapplied = totalcharge;
        remainingtotal = 0;
    } else {
        creditapplied = credit;
        remainingtotal = totalcharge - credit;
    }
    remainingcreditbalance = remainingcreditbalance - creditapplied;
    if (remainingcreditbalance < 0) {
        remainingcreditbalance = 0;
    }
    const remaining = roundtoN(remainingcreditbalance, 2);
    return {
        creditapplied: roundtoN(creditapplied, 2),
        remainingtotal: roundtoN(remainingtotal, 2),
        remainingcreditbalance: remaining,
    };
}
