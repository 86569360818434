import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Fade } from '@mui/material';
import SubCard from 'ui-component/cards/AccountSubCard';

import { useSelector, dispatch } from 'store';
import { setPageSelected, setShowingSchedule } from 'store/slices/menu';

import getCredit from 'actions/getCredit';

import Credit from './credit';
import Pass from './pass';

const DisplayPasses = ({ passes, hasPasses }) => {
    const nopassmessage = `You don't have any passes or credits.`;
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const navigate = useNavigate();
    const { userid, credit } = useSelector((state) => state.thisuser);
    const [creditAmount, setCreditAmount] = useState(0);
    const [showCredit, setShowCredit] = useState(false);

    useEffect(() => {
        const checkcredit = () => {
            if (credit > 0) {
                setShowCredit(true);
                setCreditAmount(credit);
            }
        };
        if (dibsStudioId && userid) checkcredit();
    }, [credit, dibsStudioId, userid]);
    if (!hasPasses && !showCredit)
        return (
            <Grid item xs={11} sx={{ ml: 1, mt: 2 }}>
                {nopassmessage}
            </Grid>
        );

    const goPurchasePasses = () => {
        dispatch(setPageSelected('purchase-passes'));
        dispatch(setShowingSchedule(false));
        navigate(`/packages/${dibsStudioId}`);
    };
    const secondaryButton = (
        <Grid item xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end">
                <Button
                    variant="text"
                    onClick={goPurchasePasses}
                    sx={{
                        justifyContent: 'flex-end',
                        pl: 0.5,
                    }}>
                    Buy Passes Or Memberships
                </Button>
            </Grid>
        </Grid>
    );
    const smallButton = (
        <Button variant="text" onClick={goPurchasePasses}>
            Add New
        </Button>
    );
    return (
        <Fade in timeout={600}>
            <Grid
                item
                xs={window.isTiny ? 12 : 11.5}
                sx={{ mb: 2, mr: window.isTiny ? 0 : 2.5 }}>
                <SubCard
                    title="Passes & Credits"
                    secondary={window.isTiny ? smallButton : secondaryButton}>
                    <Grid container>
                        {creditAmount > 0 && (
                            <>
                                <Grid item xs={12} sx={{ ml: 1 }}>
                                    <Credit credit={creditAmount} />
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12} sx={{ mt: 3, ml: 1, mb: 5 }}>
                            <Typography variant="subMenuTitle">
                                Available Passes
                            </Typography>
                            {!hasPasses && (
                                <Grid item sx={{ mt: 1 }}>
                                    <Typography variant="body2">
                                        You don't have any available passes.
                                    </Typography>
                                </Grid>
                            )}
                            {hasPasses && (
                                <Grid container spacing={2}>
                                    {passes.map((pass) => (
                                        <Pass
                                            pass={pass}
                                            key={pass.id}
                                            dibsId={dibsStudioId}
                                            userid={userid}
                                        />
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Fade>
    );
};
export default DisplayPasses;
