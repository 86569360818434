import { useNavigate, useParams } from 'react-router-dom';

// ==============================|| GOOGLE AUTH REDIRECT ||============================== //

const GoogleAuthComplete = () => {
    const { dibsId } = useParams();
    const navigate = useNavigate();

    // gets called after auth is complete
    // now should send to the page that is next up in the flow
    console.log('google auth is complete');
    // only using this for calendar right now. If we add more, then make this more sophisticated.
    navigate(`/calendar/${dibsId}`, { replace: false });
};

export default GoogleAuthComplete;
