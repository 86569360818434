import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function retrievePaymentIntent(
    intentid, stripeAccountId
) {
    const url = getApiUrl('/stripe/retrieve-payment-intent');
    let datatosend = {};
    const reqbody = {
        paymentIntent: intentid,
        stripeAccountId
    };
    const getIntent = async () => {

        await axios
            .post(url, reqbody)
            .then((res) => {
                // returns stripeinfo
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from retrieving stripe intent: ${err}`);
                return err;
            });
    };

    await getIntent();
    return datatosend;
}
