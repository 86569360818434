import { useState, useEffect } from 'react';

import {
    Grid,
    Typography,
    Button,
    TextField,
    Autocomplete,
    Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { nanoid } from 'nanoid';

import * as yup from 'yup';
import 'yup-phone-lite';

import {
    capitalizeEntireString,
    formatPhoneNumberUSAlreadyValidated,
    determineWhetherIsValid,
} from 'helpers';
import createAppt from 'actions/appointments/createAppt';
import DotIcon from 'assets/icons/dotIcon';
import {
    getDateAsString,
    gettimeasstring,
    getShortTimezoneText,
} from 'helpers/getTimezoneText';
import { useSelector, dispatch } from 'store';
import { registerWithEmailAndPassword, auth } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    setApptToConfirm,
    setSelectedId,
    clearSelectedIdsMulti,
    clearSelectedStaff,
    setDateToSearch,
    setApptIsConfirmed,
} from 'store/slices/appointments';
import { setAddedToCalendar } from 'store/slices/menu';
import {
    setPhone,
    setCountryForMobile,
    setFirstname,
    setLastname,
    setUserEmail,
    setUserPhone,
} from 'store/slices/thisuser';
import XIcon from 'assets/icons/XIcon';
import AddToCalendarButton from 'components/checkout/addtocalendarnew';
import configs from 'helpers/specialConfigs';
import sendApptConfirmationEmail from 'actions/emails/send-confirmation-appt';
import getShopifyStatus from 'actions/shopify/getShopifyStatus';
import updateUserPhoneNumber from 'actions/users/updatePhoneNumber';
import updatePhoneNumberIfNull from 'actions/users/updatePhoneNumberIfNull';
import getFirebaseStatus from 'actions/users/getFirebaseStatus';
import setAlert from 'actions/status/setAlert';
import triggerShopifyEmail from 'actions/shopify/triggerShopifyNotification';
import countries from 'helpers/countries';
import useTranslate from 'hooks/useTranslate';
import useGetCurrentLanguage from 'hooks/useTranslateGetLanguage';

const validationSchemaEn = yup.object().shape({
    email: yup
        .string()
        .email(
            'Your email address looks incorrect. Please check your email address and try again.',
        )
        .required('Required'),
    phone: yup
        .string()
        .phone('US', 'Please enter a valid phone number')
        .required('Required'),
    firstname: yup
        .string()
        .min(2, 'Your name is too short. Please try again.')
        .max(90, 'Your name is too long. Please try again.')
        .required('Required'),
    lastname: yup
        .string()
        .min(2, 'Your lastname is too short. Please try again.')
        .max(90, 'Your name is too long. Please try again.')
        .required('Required'),
});
const validationSchemaIt = yup.object().shape({
    email: yup
        .string()
        .email(
            'Il tuo indirizzo email sembra errato. Controlla il tuo indirizzo email e riprova.',
        )
        .required('Obbligatorio'),
    phone: yup
        .string()
        .phone('IT', 'Inserisci un numero di telefono valido')
        .required('Obbligatorio'),
    firstname: yup
        .string()
        .min(2, 'Il tuo nome è troppo corto. Riprova.')
        .max(90, 'Il tuo nome è troppo lungo. Riprova.')
        .required('Obbligatorio'),
    lastname: yup
        .string()
        .min(2, 'Il tuo cognome è troppo corto. Riprova.')
        .max(90, 'Il tuo nome è troppo lungo. Riprova.')
        .required('Obbligatorio'),
});
const getValidationSchema = (locale) => {
    switch (locale) {
        case 'it':
            return validationSchemaIt;
        case 'en':
        default:
            return validationSchemaEn;
    }
};

const ConfirmationPanel = ({
    setIsConfirming,
    setResetSearch,
    setTimeToChooseDate,
}) => {
    const translate = useTranslate();
    const thislanguage = useGetCurrentLanguage();
    const validationSchema = getValidationSchema(thislanguage);
    const [shortTzText, setShortTzText] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [apptConfirmed, setApptConfirmed] = useState(false);
    const [calendarUrlExists, setCalendarUrlExists] = useState(false);
    const [calendarUrlToUse, setCalendarUrlToUse] = useState(null);
    const [autoCompleteDefaultValue, setAutoCompleteDefaultValue] = useState(
        countries[0],
    );
    const countryTxt = translate('country', 'Country');
    const phoneTxt = translate('phone', 'Phone Number');
    const submittingTxt = translate('submitting', 'Submitting');
    const confirmTxt = translate('confirm', 'Confirm');
    const totalFeeTxt = translate(
        'total-fee',
        'The total fee for this appointment is $',
    );
    const apptConfirmedTxt = translate(
        'appt-is-confirmed',
        'Your Appointment Is Confirmed',
    );
    const bySchedulingTxt = translate(
        'by-scheduling',
        'By scheduling an appointment, you consent to receiving SMS confirmations. You may opt out at any time by going to your account and editing your communication preferences.',
    );
    const youWillReceiveTxt = translate(
        'email-text-confirmation',
        'You will receive an email and/or text confirmation shortly',
    );

    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortoshow = `#${color}`;
    const theme = useTheme();
    // const colortoshow = `#000000`;
    const { config } = useSelector((state) => state.dibsstudio);
    const {
        userid,
        firstname,
        lastname,
        email,
        phone,
        countryForMobile,
        smsTransactions,
    } = useSelector((state) => state.thisuser);

    // const [initialCountry, setInitialCountry] = useState(countryForMobile);
    const [countryAbbr, setCountryAbbr] = useState(countryForMobile);
    const [countrycodeToUse, setCountryCodeToUse] = useState('1');
    const [user, loading] = useAuthState(auth);
    const [firstnameFromRedux, setFirstnameFromRedux] = useState(
        capitalizeEntireString(firstname),
    );
    const [lastnameFromRedux, setLastnameFromRedux] = useState(
        capitalizeEntireString(lastname),
    );
    const [emailFromRedux, setEmailFromRedux] = useState(
        capitalizeEntireString(email),
    );
    const [alreadyAddedShopify, setAlreadyAddedShopify] = useState(false);

    const thisphonestring = formatPhoneNumberUSAlreadyValidated(
        phone,
        countryAbbr,
    );
    const [alreadySetupFirebase, setAlreadySetUpFirebase] = useState(false);
    const [countryValue, setCountryValue] = useState(countries[0]);
    const [isInitialRun, setIsInitialRun] = useState(true);

    const [phoneFromRedux, setPhoneFromRedux] = useState(thisphonestring);

    useEffect(() => {
        const getShopStatus = async () => {
            await getShopifyStatus(userid).then((res) => {
                const { alreadyAddedToShopify } = res;
                if (alreadyAddedToShopify) {
                    setAlreadyAddedShopify(true);
                } else {
                    setAlreadyAddedShopify(false);
                }
            });
        };
        if (userid > 0) {
            getShopStatus();
        }
    }, [userid]);

    const {
        dibsStudioId,
        timeZone,
        studioName,
        customTwilioNumUS,
        sendConfirmationEmailDibs,
        sendSMSConfirmation,
    } = config;
    let calendarUrl;
    const {
        customerServicePhone,
        address,
        address2,
        city,
        state,
        zip,
        customerServiceEmail,
    } = useSelector((state) => state.dibsstudio.customerService);
    const { apptToConfirm, apptIsConfirmed } = useSelector(
        (state) => state.appointments,
    );
    const [apptTitle, setApptTitle] = useState(
        capitalizeEntireString(apptToConfirm.apptType),
    );

    let transparencyToUse = alpha(colortoshow, 0.05);
    const specialTransparency = configs(dibsStudioId, 'transparencyAppts');
    if (specialTransparency !== undefined) {
        transparencyToUse = specialTransparency;
    }
    useEffect(() => {
        setShortTzText(getShortTimezoneText(timeZone));
    }, [timeZone]);

    const determinePhoneValue = (val) => {
        const thisStr = val.replace(countrycodeToUse, '');
        const nodash = thisStr.replace('-', '');
        const nospace = nodash.replace(' ', '');
        const noplus = nospace.replace('+', '');
        const valueWCode = `+${countrycodeToUse} ${noplus}`;
        return valueWCode;
    };
    const atsign = thislanguage === 'it' ? ', ore ' : '@ ';
    const withTxt = thislanguage === 'it' ? 'con ' : 'w/ ';
    const timetext =
        atsign +
        gettimeasstring(apptToConfirm.start_time, thislanguage) +
        ' ' +
        shortTzText;

    const forgetThisAppt = () => {
        dispatch(setApptToConfirm(null));
        dispatch(setSelectedId(null));
        dispatch(clearSelectedStaff());
        setIsConfirming(false);
        setTimeToChooseDate(false);
        if (apptIsConfirmed) {
            setResetSearch(true);
            setTimeout(() => {
                setResetSearch(false);
            }, 2000);
            dispatch(setApptIsConfirmed(false));
        }
        dispatch(clearSelectedIdsMulti());
        dispatch(setDateToSearch(null));
        localStorage.removeItem('apptBeingConfirmed');
    };

    const addApptToDb = async (contactValues) => {
        let useridToPass = userid;
        if (contactValues.email !== emailFromRedux) {
            useridToPass = 0;
        }

        updatePhoneNumberIfNull(
            userid,
            contactValues.email,
            contactValues.phone,
            countryAbbr,
        );
        if (
            contactValues.phone !== phoneFromRedux &&
            contactValues.email === emailFromRedux &&
            phoneFromRedux.length <= 2
        ) {
            dispatch(setPhone(contactValues.phone));
            dispatch(setCountryForMobile(countryAbbr));

            await updateUserPhoneNumber(
                userid,
                contactValues.phone,
                countryAbbr,
            ).then((res) => {
                console.log(
                    'AFTER UPDATING USER PHONE NUMBER - updated phone number is',
                    res,
                );
            });
        }
        // check to see if there is a phone number in the database for this email addy
        const appttocreate = {
            dibsId: dibsStudioId,
            userid: useridToPass,
            studioName,
            apptToConfirm,
            sendSMSConfirmation,
            customTwilioNumUS,
            customerServicePhone,
            timeZone,
            ...contactValues,
            alreadyAddedShopify,
            customerServiceInfo: {
                customerServiceEmail,
                custServAddress: address,
                custServAddress2: address2,
                custServCity: city,
                custServState: state,
                custServZip: zip,
                custServPhone: customerServicePhone,
            },
        };
        localStorage.setItem(
            'apptBeingConfirmed',
            JSON.stringify(apptToConfirm),
        );

        await createAppt(
            appttocreate,
            countryAbbr,
            smsTransactions,
            thislanguage,
        ).then(async (res) => {
            const { isComplete, eventscreated } = res;
            if (isComplete >= 1) {
                const datasending = {
                    appttocreate,
                    eventscreated,
                };
                const pwd = nanoid();

                // here - i should get user info to see if this user has already set firebase password
                const fbStatus = await getFirebaseStatus(contactValues.email);
                const { firebaseinfo } = fbStatus;
                let needstosetupfirebase = false;
                try {
                    if (firebaseinfo) {
                        const { firebase_auth_pwd } = firebaseinfo;
                        // also can use firebase_auth - if needed
                        if (firebase_auth_pwd) {
                            setAlreadySetUpFirebase(true);
                        } else {
                            setAlreadySetUpFirebase(false);
                            needstosetupfirebase = true;
                        }
                    }
                } catch (err) {
                    setAlert({
                        msg: 'There was an error creating your appointment. Please refresh your screen and try again. If you continue to have issues, please contact customer service.',
                        seconds: 8,
                        error: true,
                    });
                }

                if (!user && !loading) {
                    if (needstosetupfirebase) {
                        registerWithEmailAndPassword(contactValues.email, pwd);
                    }
                }
                const sendEmailFromDibs = true; // for some reason, sendConfirmationEmailDibs is not being defined, so let's do this as a placeholder
                if (sendEmailFromDibs) {
                    await sendApptConfirmationEmail(
                        datasending,
                        thislanguage,
                    ).then((res) => {
                        try {
                            const { calendarLink } = res;
                            calendarUrl = calendarLink;
                            setCalendarUrlToUse(calendarLink);
                            setCalendarUrlExists(true);
                            dispatch(setAddedToCalendar(false));
                        } catch (error) {
                            console.log(
                                'error is - send appt confirmaton email',
                                error,
                            );
                        }
                    });
                    setApptConfirmed(true);
                    dispatch(setApptIsConfirmed(true));
                } else {
                    console.log(
                        'Handle case where confirmation is not sent via Dibs',
                    );
                }
            } else if (isComplete === 0) {
                setApptConfirmed(false);
                dispatch(setApptIsConfirmed(false));
            }
        });
    };
    const formik = useFormik({
        initialValues: {
            firstname: firstnameFromRedux,
            lastname: lastnameFromRedux,
            email: emailFromRedux,
            phone: phoneFromRedux,
            countrycode: countrycodeToUse,
        },
        // test vs production
        validationSchema,
        onSubmit: async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm },
        ) => {
            try {
                setIsSubmitting(true);
                const result = determineWhetherIsValid(
                    values.phone,
                    countryAbbr,
                );
                if (!result.valid) {
                    setErrors({
                        phone: 'Please enter a valid phone number',
                    });
                    setIsSubmitting(false);
                    return;
                }
                await addApptToDb({
                    firstname: values.firstname,
                    lastname: values.lastname,
                    email: values.email,
                    phone: values.phone,
                }).then(() => {
                    resetForm();
                    setIsSubmitting(false);
                    dispatch(setFirstname(values.firstname));
                    dispatch(setLastname(values.lastname));
                    dispatch(setUserEmail(values.email));
                    dispatch(setUserPhone(values.phone));
                });
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        const updatephonevaluesformik = (phonevalue) => {
            formik.values.phone = phonevalue;
        };
        const formatPhoneString = (phoneString) => {
            const newphoneString = formatPhoneNumberUSAlreadyValidated(
                phoneString,
                countryAbbr,
            );
            const newPhoneStringWithCode = `+${countrycodeToUse} ${newphoneString}`;
            updatephonevaluesformik(newPhoneStringWithCode);
            return newPhoneStringWithCode;
        };
        if (user && !loading) {
            setFirstnameFromRedux(firstname);
            setLastnameFromRedux(lastname);
            setEmailFromRedux(email);
            if (phone && isInitialRun) {
                const phonetoset = formatPhoneString(phone);
                setPhoneFromRedux(phonetoset);
                setIsInitialRun(false);
            }
        }
    }, [
        user,
        firstname,
        lastname,
        email,
        phone,
        loading,
        countryAbbr,
        countrycodeToUse,
        formik.values,
        isInitialRun,
    ]);

    const changeValue = (e, v) => {
        console.log('changing valu to ---', v);
        if (v) {
            setCountryCodeToUse(v.phone);
            setCountryAbbr(v.code);
        }
    };
    useEffect(() => {
        countries.forEach((country) => {
            if (country.code === countryAbbr) {
                setCountryValue(country);
                setCountryCodeToUse(country.phone);
            }
        });
    }, [countryAbbr]);
    return (
        <Grid item xs={12} border={0} id="item-confirmation-panel-sub-one">
            <Grid
                container
                justifyContent="flex-start"
                border={window.isTiny ? 0 : 2}
                backgroundColor={transparencyToUse}
                borderColor="primary.main"
                borderRadius={1.5}
                id="confirmation-panel-sub-one-container"
                sx={{ p: 1.75 }}>
                <Grid
                    item
                    id="forget-this-appt-button"
                    xs={12}
                    sx={{ mb: 1.5 }}
                    onClick={() => forgetThisAppt()}>
                    <Grid container justifyContent="flex-end">
                        <XIcon size="small" />
                    </Grid>
                </Grid>
                {apptIsConfirmed && (
                    <Grid
                        item
                        xs={12}
                        sx={{ mb: 4, ml: 1 }}
                        id="appt-is-confirmed">
                        <Typography variant="h2">{apptConfirmedTxt}</Typography>
                    </Grid>
                )}
                {apptToConfirm.numOfAppts > 1 && (
                    <Grid
                        item
                        xs={12}
                        sx={{ mb: 1, ml: 1 }}
                        id="services-booking-title">
                        <Typography variant="eventtitle" sx={{ lineHeight: 2 }}>
                            Services booking:
                        </Typography>
                        <Grid sx={{ my: 2 }} id="appt-details-map">
                            {apptToConfirm.apptDetails.map((appt, i) => {
                                return (
                                    <Grid
                                        key={`confirmation-panel-title-${i}`}
                                        item
                                        id="appt-type-booking"
                                        sx={{ mt: 1, lineHeight: 1.6 }}>
                                        <DotIcon /> {appt.apptType}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                )}
                {apptToConfirm.numOfAppts <= 1 && (
                    <Grid
                        item
                        xs={12}
                        sx={{ mb: 1, ml: 1 }}
                        id="appt-title-event">
                        <Typography variant="eventtitle">
                            {apptTitle}
                        </Typography>
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    id="appt-date-time-string"
                    sx={{ mt: 0.5, ml: 1, mr: window.isTiny ? 5 : 0 }}>
                    <Typography variant="eventname">
                        {getDateAsString(
                            apptToConfirm.start_time,
                            thislanguage,
                        )}{' '}
                        {timetext}
                    </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 0.5, ml: 1 }}>
                    <Typography variant="eventname">
                        {withTxt}
                        {apptToConfirm.firstname} {apptToConfirm.lastname}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ mt: 2, ml: 1, mb: window.isTiny ? 2 : 4 }}>
                    <Typography
                        variant="eventname"
                        sx={{ fontStyle: 'italic' }}>
                        {totalFeeTxt} {apptToConfirm.priceAppt}.
                    </Typography>
                </Grid>
                {apptIsConfirmed && (
                    <Grid item xs={12} border={0} sx={{ mb: 2, ml: 1, mt: 1 }}>
                        <Typography
                            variant="eventtime"
                            sx={{ fontStyle: 'italic' }}>
                            {youWillReceiveTxt}.
                        </Typography>
                    </Grid>
                )}

                {!apptIsConfirmed && (
                    <form
                        className="form-profile"
                        onSubmit={formik.handleSubmit}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 6,
                                mb: 2.5,
                                ml: 1,
                            }}>
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={12} sx={{ mb: 3 }}>
                                    <Typography variant="eventtime">
                                        <Grid item id="enter-contact-info">
                                            <FormattedMessage id="entercontactinfo" />
                                        </Grid>
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    id="firstname-client"
                                    xs={window.isTiny ? 12 : 10.2}
                                    sx={{
                                        mr: window.isTiny ? 1 : 1,
                                    }}>
                                    <TextField
                                        id="firstname-client"
                                        name="firstname"
                                        value={formik.values.firstname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        label={
                                            <FormattedMessage id="firstname" />
                                        }
                                        fullWidth
                                        variant="outlined"
                                        error={
                                            formik.touched.firstname &&
                                            Boolean(formik.errors.firstname)
                                        }
                                        helperText={
                                            formik.touched.firstname &&
                                            formik.errors.firstname
                                        }
                                        sx={{ lineHeight: 0.9 }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={window.isTiny ? 12 : 10.2}
                                    sx={{ mt: window.isTiny ? 1.5 : 3, mr: 1 }}>
                                    <TextField
                                        id="lastname-basic"
                                        label={
                                            <FormattedMessage id="lastname" />
                                        }
                                        name="lastname"
                                        value={formik.values.lastname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        variant="outlined"
                                        error={
                                            formik.touched.lastname &&
                                            Boolean(formik.errors.lastname)
                                        }
                                        helperText={
                                            formik.touched.lastname &&
                                            formik.errors.lastname
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={window.isTiny ? 12 : 10.2}
                                    sx={{ mt: window.isTiny ? 1.5 : 3, mr: 1 }}>
                                    <TextField
                                        id="email-basic"
                                        label="Email"
                                        name="email"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        variant="outlined"
                                        error={
                                            formik.touched.email &&
                                            Boolean(formik.errors.email)
                                        }
                                        helperText={
                                            formik.touched.email &&
                                            formik.errors.email
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={window.isTiny ? 12 : 2.5}
                                    md={4}
                                    sx={{ mt: window.isTiny ? 2.5 : 3, mr: 1 }}>
                                    <Autocomplete
                                        id="country-code"
                                        options={countries}
                                        label="Country Code"
                                        name="countrycode"
                                        // value={formik.values.countrycode}
                                        // onChange={formik.handleChange}
                                        // onBlur={formik.handleBlur}
                                        fullWidth
                                        autoHighlight
                                        value={countryValue}
                                        onChange={(event, newValue) => {
                                            setCountryValue(newValue);
                                            changeValue(event, newValue);
                                        }}
                                        // defaultValue={countries[0]}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        // onChange={(e, v) => changeValue(e, v)}
                                        // sx={{
                                        //     backgroundColor: theme.palette.background.default, '&:hover': {
                                        //         backgroundColor:
                                        //             theme.palette
                                        //                 .background.default,
                                        //     },
                                        // }}
                                        variant="outlined"
                                        renderOption={(props, option) => {
                                            return (
                                                <Box
                                                    component="li"
                                                    {...props}
                                                    sx={{ mb: 0.5 }}
                                                    height="1.4375em">
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            fontSize: '0.75rem',
                                                            lineHeight: '1rem',
                                                        }}>
                                                        {option.label} +
                                                        {option.phone}
                                                    </Typography>
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => {
                                            // params.inputProps.className = '';
                                            return (
                                                <TextField
                                                    {...params}
                                                    id="country-code-input"
                                                    name="countrycode"
                                                    label={countryTxt}
                                                    fullWidth
                                                    variant="outlined"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: 'off', // disable autocomplete and autofill
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={window.isTiny ? 12 : 7.6}
                                    md={6.05}
                                    sx={{ mt: window.isTiny ? 1.5 : 3, mr: 1 }}>
                                    <TextField
                                        id="cell-phone-num"
                                        label={phoneTxt}
                                        name="phone"
                                        value={formik.values.phone}
                                        // onChange={formik.handleChange}
                                        // onChange={(e, v) => formik.setFieldValue("phone", changeValue(e, v))}
                                        onChange={(e) =>
                                            formik.setFieldValue(
                                                'phone',
                                                determinePhoneValue(
                                                    e.target.value,
                                                ),
                                            )
                                        }
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        variant="outlined"
                                        error={
                                            formik.touched.phone &&
                                            Boolean(formik.errors.phone)
                                        }
                                        helperText={
                                            formik.touched.phone &&
                                            formik.errors.phone
                                        }
                                    />
                                </Grid>
                                <Grid
                                    item
                                    id="item-confirmation-submit-button-appts"
                                    xs={window.isTiny ? 11.5 : 10.4}
                                    sx={{ mt: 4, mb: 2.5 }}>
                                    <Button
                                        variant="checkout"
                                        type="submit"
                                        fullWidth
                                        disabled={isSubmitting}
                                        // onClick={() => addApptToDb()} for testing - change this
                                        sx={{ backgroundColor: '#0820a3' }}>
                                        {isSubmitting
                                            ? `${submittingTxt}...`
                                            : confirmTxt}
                                    </Button>
                                </Grid>
                                <Grid
                                    item
                                    xs={11.5}
                                    md={10.4}
                                    id="agree-to-communication-prefs">
                                    <Typography variant="h6">
                                        {bySchedulingTxt}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
                {apptIsConfirmed && calendarUrlExists && (
                    <Grid item xs={12} border={0} sx={{ mb: 2, ml: 0, mt: 1 }}>
                        <AddToCalendarButton
                            appt={apptToConfirm}
                            tz={timeZone}
                            dibsId={dibsStudioId}
                            calendarurl={calendarUrlToUse}
                        />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
export default ConfirmationPanel;
