import { roundtoN } from 'helpers';
// need to update how much credit is actually available for this user

export default async function ApplyCreditPackage(packageprice, taxrate, credit) {
    let creditapplied = 0;
    let remainingtotal = 0;
    let remainingcreditbalance = credit;
    let creditCovers = false;
    const taxRate = taxrate / 100;
    const taxtocharge = taxRate * packageprice;
    const totalcharge = packageprice + taxtocharge;
    const amountToCharge = roundtoN(totalcharge, 2);
    if (credit >= amountToCharge) {
        creditapplied = amountToCharge;
        remainingtotal = 0;
        creditCovers = true;
    } else {
        creditapplied = credit;
        remainingtotal = amountToCharge - credit;
    }
    remainingcreditbalance = remainingcreditbalance - creditapplied;
    if (remainingcreditbalance < 0) {
        remainingcreditbalance = 0;
    }
    const remaining = roundtoN(remainingcreditbalance, 2);
    return {
        creditapplied: roundtoN(creditapplied, 2),
        remainingtotal: roundtoN(remainingtotal, 2),
        remainingcreditbalance: remaining,
        creditCovers
    };
}
