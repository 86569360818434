import { useEffect, useState, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { useSelector, dispatch } from 'store';
import PackagesListItem from './packagesListItem';
import getCredit from 'actions/getCredit';
import { setAvailableCredit } from 'store/slices/thisuser';

const PackagesList = () => {
    const { packages } = useSelector((state) => state.packages);
    const { purchasingPackageId, isPurchasingAPackage } = useSelector(
        (state) => state.cart,
    );
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const { dateSelected, maxDateForComparison } = useSelector(
        (state) => state.menu,
    );
    const { userQualifiesPromos, userid } = useSelector(
        (state) => state.thisuser,
    );

    const [listPackages, setListPackages] = useState([]);
    const [listMemberships, setListMemberships] = useState([]);
    const [listPromos, setListPromos] = useState([]);
    const listPackagesMemo = useMemo(() => listPackages, [listPackages]);
    const listMembershipsMemo = useMemo(
        () => listMemberships,
        [listMemberships],
    );
    const listPromosMemo = useMemo(() => listPromos, [listPromos]);
    const [listPrivatePackages, setListPrivatePackages] = useState([]);
    const listPrivatePackagesMemo = useMemo(
        () => listPrivatePackages,
        [listPrivatePackages],
    );
    const [onDemandPackages, setOnDemandPackages] = useState([]);
    const onDemandPackagesMemo = useMemo(
        () => onDemandPackages,
        [onDemandPackages],
    );
    const [showPromoSection, setShowPromoSection] = useState(true); // when purchasing in a different section, do not show this one
    const [showPrivateSection, setShowPrivateSection] = useState(true); // when purchasing in a different section, do not show this one
    const [showOnDemandSection, setShowOnDemandSection] = useState(true); // when purchasing in a different section, do not show this one
    const [showMembershipSection, setShowMembershipSection] = useState(true); // when purchasing in a different section, do not show this one

    useEffect(() => {
        const updateCredit = async () => {
            await getCredit(dibsStudioId, userid)
                .then((res) => {
                    dispatch(setAvailableCredit(res));
                })
                .catch((err) => {
                    console.log(`error getting available credit: ${err}`);
                });
        };
        if (isPurchasingAPackage) {
            updateCredit();
        }
        if (packages.length > 0) {
            const packagesListArray = [];
            const privatePackagesListArray = [];
            const onDemandPackagesListArray = [];
            const membershipsListArray = [];
            const promoListArray = [];
            const setPackageCardForEach = () => {
                packages.forEach((pack) => {
                    const isMembership = pack.autopay !== 'NONE';
                    if (isPurchasingAPackage) {
                        if (pack.id !== purchasingPackageId) {
                            return;
                        }
                    }
                    const valuestoshow = {
                        id: pack.id,
                        packageName: pack.name,
                        numClasses: pack.classAmount,
                        price: pack.price,
                        discountfrompromo: 0,
                        discountedprice: pack.price,
                        autopay: pack.autopay,
                        priceAutopay: pack.priceAutopay,
                        autopayIncrement: pack.autopayIncrement,
                        unlimited: pack.unlimited,
                        private: pack.private,
                        onDemandAccess: pack.on_demand_access,
                        description: pack.customDescription,
                        isMembership: isMembership,
                        validity: pack.passesValidFor,
                        validIncrement: pack.validForInterval,
                        commitmentPeriod: pack.commitment_period,
                        limitedToNewClients: pack.onlyFirstPurchase,
                        purchaseLimit: pack.packagePurchaseLimit,
                        taxAmount: pack.taxrate,
                        showPricePerClass: pack.show_price_per_class,
                        pricePerClassOverride: pack.price_per_class_override,
                        isPromo: pack.onlyFirstPurchase, // use this for now - to do - add promo field to packages
                    };
                    if (pack.onlyFirstPurchase) {
                        promoListArray.push(valuestoshow);
                    } else if (
                        !pack.private &&
                        !pack.on_demand_access &&
                        !isMembership
                    ) {
                        packagesListArray.push(valuestoshow);
                    } else if (pack.private) {
                        privatePackagesListArray.push(valuestoshow);
                    } else if (isMembership) {
                        membershipsListArray.push(valuestoshow);
                    } else if (pack.on_demand_access) {
                        onDemandPackagesListArray.push(valuestoshow);
                    }
                });

                setListPackages(packagesListArray);
                setListPrivatePackages(privatePackagesListArray);
                setOnDemandPackages(onDemandPackagesListArray);
                setListMemberships(membershipsListArray);
                setListPromos(promoListArray);
            };
            // if (!doneSetting) {
            //     setScheduleForEach();
            //     setDoneSetting(true);
            // }
            setPackageCardForEach();
        }
    }, [
        dateSelected,
        dibsStudioId,
        isPurchasingAPackage,
        maxDateForComparison,
        packages,
        purchasingPackageId,
        userid,
    ]);
    return (
        <Grid container id="packages-list-container">
            {listPackagesMemo.length === 0 &&
                listPromosMemo.length === 0 &&
                listMembershipsMemo === 0 &&
                listPrivatePackagesMemo === 0 && (
                    <Grid item xs={12}>
                        No packages are available at this time.
                    </Grid>
                )}
            {listPromosMemo.length > 0 &&
                userQualifiesPromos && ( // change back post testing
                    <Grid
                        item
                        xs={12}
                        sx={{ mb: 6 }}
                        border={0}
                        borderColor="green">
                        <Grid
                            container
                            id="list-promos-container"
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            border={0}
                            borderColor="pink"
                            // spacing={4}
                        >
                            <Grid item xs={12} sx={{ mb: 1.5, mt: 1 }}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    sx={{ mb: 0.5 }}>
                                    <Typography variant="packagehead">
                                        Promotional Offers
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{ mb: 1.5, mx: 1.5 }}
                                border={0}>
                                <Grid
                                    container
                                    // spacing={3}
                                    borderRadius={2}
                                    id="container-for-this-card"
                                    justifyContent="space-evenly"
                                    border={0}
                                    alignItems="stretch"
                                    sx={{
                                        backgroundColor: '#F5F5F5',
                                        pt: 3,
                                        px: 2,
                                    }}>
                                    {listPromosMemo.map((pack, i) => {
                                        let valuetoreturn = null;

                                        if (isPurchasingAPackage) {
                                            if (
                                                pack.id === purchasingPackageId
                                            ) {
                                                valuetoreturn = (
                                                    <PackagesListItem
                                                        key={pack.id}
                                                        pack={pack}
                                                        confirmingPurchase={
                                                            true
                                                        }
                                                    />
                                                );
                                            } else {
                                                return null;
                                            }
                                        } else {
                                            valuetoreturn = (
                                                <PackagesListItem
                                                    key={pack.id}
                                                    pack={pack}
                                                    confirmingPurchase={false}
                                                />
                                            );
                                        }
                                        return valuetoreturn;
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

            {listPackagesMemo.length > 0 && (
                <Grid item xs={12} sx={{ mb: 6 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        // spacing={4}
                    >
                        <Grid item xs={12} sx={{ mb: 1.5, mt: 1 }}>
                            <Grid container justifyContent="center">
                                <Typography variant="packagehead">
                                    Packages
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1.5, mx: 1.5 }}>
                            <Grid
                                container
                                // spacing={3}
                                borderRadius={2}
                                justifyContent="space-evenly"
                                sx={{
                                    backgroundColor: '#F5F5F5',
                                    pt: 3,
                                    px: 2,
                                }}>
                                {listPackagesMemo.map((pack, i) => {
                                    let valuetoreturn = null;
                                    if (isPurchasingAPackage) {
                                        if (pack.id === purchasingPackageId) {
                                            valuetoreturn = (
                                                <PackagesListItem
                                                    key={pack.id}
                                                    pack={pack}
                                                    confirmingPurchase={true}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        valuetoreturn = (
                                            <PackagesListItem
                                                key={pack.id}
                                                pack={pack}
                                                confirmingPurchase={false}
                                            />
                                        );
                                    }
                                    return valuetoreturn;
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {listMembershipsMemo.length > 0 && (
                <Grid item xs={12} sx={{ mb: 6 }}>
                    <Grid
                        container
                        id="memberships-container"
                        direction="row"
                        justifyContent="center"
                        alignItems="flex-start"
                        // spacing={4}
                    >
                        <Grid item xs={12} sx={{ mb: 1.5, mt: 1 }}>
                            <Grid
                                container
                                justifyContent="center"
                                sx={{ mb: 0.5 }}>
                                <Typography variant="packagehead">
                                    Memberships
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1.5, mx: 1.5 }}>
                            <Grid
                                container
                                // spacing={3}
                                borderRadius={2}
                                id="container-holding-memberships"
                                alignItems="stretch"
                                justifyContent="space-evenly"
                                sx={{
                                    backgroundColor: '#F5F5F5',
                                    pt: 3,
                                    px: 2,
                                }}>
                                {listMembershipsMemo.map((pack, i) => {
                                    let valuetoreturn = null;
                                    if (isPurchasingAPackage) {
                                        if (pack.id === purchasingPackageId) {
                                            valuetoreturn = (
                                                <PackagesListItem
                                                    key={pack.id}
                                                    pack={pack}
                                                    confirmingPurchase={true}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        valuetoreturn = (
                                            <PackagesListItem
                                                key={pack.id}
                                                pack={pack}
                                                confirmingPurchase={false}
                                            />
                                        );
                                    }
                                    return valuetoreturn;
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {listPrivatePackagesMemo.length > 0 && (
                <Grid item xs={12} sx={{ mb: 6 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        // spacing={4}
                    >
                        <Grid item xs={12} sx={{ mb: 1.5, mt: 1 }}>
                            <Grid container justifyContent="center">
                                <Typography variant="packagehead">
                                    Private Packages & Memberships
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1.5, mx: 1.5 }}>
                            <Grid
                                container
                                // spacing={3}
                                borderRadius={2}
                                justifyContent="space-evenly"
                                alignItems="stretch"
                                sx={{
                                    backgroundColor: '#F5F5F5',
                                    pt: 3,
                                    px: 2,
                                }}>
                                {listPrivatePackagesMemo.map((pack, i) => {
                                    let valuetoreturn = null;
                                    if (isPurchasingAPackage) {
                                        if (pack.id === purchasingPackageId) {
                                            valuetoreturn = (
                                                <PackagesListItem
                                                    key={pack.id}
                                                    pack={pack}
                                                    confirmingPurchase={true}
                                                />
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        valuetoreturn = (
                                            <PackagesListItem
                                                key={pack.id}
                                                pack={pack}
                                                confirmingPurchase={false}
                                            />
                                        );
                                    }
                                    return valuetoreturn;
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {onDemandPackagesMemo.length > 0 && (
                <Grid item xs={12} sx={{ mb: 6 }}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={4}>
                        <Grid item xs={12} sx={{ mb: 1.5 }}>
                            <Typography variant="packagehead">
                                On Demand Packages
                            </Typography>
                        </Grid>
                        {listPackagesMemo.map((pack, i) => {
                            let valuetoreturn = null;
                            if (isPurchasingAPackage) {
                                if (pack.id === purchasingPackageId) {
                                    valuetoreturn = (
                                        <PackagesListItem
                                            key={pack.id}
                                            pack={pack}
                                            confirmingPurchase={true}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            } else {
                                valuetoreturn = (
                                    <PackagesListItem
                                        key={pack.id}
                                        pack={pack}
                                        confirmingPurchase={false}
                                    />
                                );
                            }
                            return valuetoreturn;
                        })}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
export default PackagesList;
