import { useEffect, useState } from 'react';

// material-ui
import { Grid, Fade } from '@mui/material';

// project imports
import { dispatch, useSelector } from 'store';
import { setAvailablePasses, setAvailableCredit } from 'store/slices/thisuser';
import { setNeedsRefresh } from 'store/slices/menu';

// assets
import Passes from 'components/account/passes-credits/displayPasses';

import getAvailablePasses from 'actions/getAvailablePasses';
import getCredit from 'actions/getCredit';

// ==============================|| PROFILE - CREDITS AND PASSES ||============================== //
const initialPasses = [
    {
        id: 1,
        name: 'Pass loading...',
        price: 0,
        isMembership: false,
        expDate: '',
        cancancel: true,
        remaining: '-',
        isUnlimited: false,
        studioPackage: {},
    },
];

const PassesCredits = () => {
    const { userid, passes } = useSelector((state) => state.thisuser);
    const { config } = useSelector((state) => state.dibsstudio);
    const { needsRefresh } = useSelector((state) => state.menu);
    const { dibsStudioId, wdth } = config;
    const [doneLoading, setDoneLoading] = useState(false);
    const [availablePasses, setAvailablePassesThis] = useState(initialPasses);
    const [hasPasses, setHasPasses] = useState(false);
    useEffect(() => {
        const getPassesCredit = async () => {
            if (passes.length > 0) {
                setAvailablePassesThis(passes);
                setHasPasses(true);
            }
            await getAvailablePasses(dibsStudioId, userid)
                .then((res) => {
                    dispatch(setAvailablePasses(res));
                    setAvailablePassesThis(res);
                    if (res.length > 0) {
                        setHasPasses(true);
                    } else {
                        setHasPasses(false);
                    }
                    setDoneLoading(true);

                })
                .catch((err) => {
                    console.log(`Error getting available passes: ${err}`);
                    setAvailablePassesThis([]);
                    setDoneLoading(false);
                });
            await getCredit(dibsStudioId, userid)
                .then((res) => {
                    dispatch(setAvailableCredit(res));
                })
                .catch((err) => {
                    console.log(`error getting available credit: ${err}`);
                });
        };
        if (!doneLoading && passes) {
            getPassesCredit();
            dispatch(setNeedsRefresh(false));
        }
        if (needsRefresh) {
            setDoneLoading(false);
            dispatch(setNeedsRefresh(false));
        }
    }, [dibsStudioId, doneLoading, passes, userid, needsRefresh]);

    return (
        <Grid container id="passes-credits-container" justifyContent="center">
            {doneLoading && (
                <Fade in timeout={500}>
                    <Grid
                        item
                        xs={window.isTiny ? 12 : 12}
                        sx={{
                            ml: window.isTiny ? 0 : 2,
                            mt: 2,
                            width: wdth,
                            border: 0,
                        }}>
                        <Passes
                            passes={availablePasses}
                            hasPasses={hasPasses}
                        />
                    </Grid>
                </Fade>
            )}
        </Grid>
    );
};

export default PassesCredits;
