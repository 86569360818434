import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);

export const correctTime = (datetime, tz) => {
    // 2023-05-19T16:30:00.000Z

    const hour = dayjs.utc(datetime).hour();
    const min = dayjs.utc(datetime).minute();

    dayjs.tz.setDefault(tz);
    const newtime = dayjs.tz(datetime, tz).hour(hour).minute(min).format();
    return newtime;
};
const convertdateToStr = (dateNum) => {
    const dateAddOne = dateNum + 1;
    const datestr = dateAddOne.toString();
    if (datestr.length === 1) {
        return `0${datestr}`;
    }
    return datestr;
};

export const checkifClassOccursBeforeNow = (event, timezone) => {
    // this takes an event (from schedule page) - which is in UTC - converts to local time, and checks if the class starts before now (in the local time)
    // returns true or false
    const datetime = event.startDate;
    const hour = dayjs.utc(datetime).hour();
    const min = dayjs.utc(datetime).minute();
    const dateUtc = dayjs.utc(datetime).date();
    const monthUtc = dayjs.utc(datetime).month();
    const yearUtc = dayjs.utc(datetime).year();
    const hrstr = hour.toString();
    const minstr = min.toString();
    const datestr = dateUtc.toString();
    const monthstr = convertdateToStr(monthUtc);
    const yearstr = yearUtc.toString();
    const newdatestring = `${yearstr}-${monthstr}-${datestr}T${hrstr}:${minstr}:00`;

    const timeOfClass = dayjs
        .tz(newdatestring, timezone)
        .format('YYYY-MM-DDTHH:mm:ss');

    const timenow = dayjs().tz(timezone).format('YYYY-MM-DDTHH:mm:ss');

    let classstartsbeforenow = false;
    if (timeOfClass < timenow) classstartsbeforenow = true;

    return classstartsbeforenow;
};
