import { Grid, Button } from '@mui/material';
import { dispatch } from 'store';
import { setApptCategoryShowing } from 'store/slices/menu';
import { capitalizeWords } from 'helpers';

const ServiceCategories = ({ idtouse, categoryName, step }) => {
    const setCategory = () => {
        dispatch(setApptCategoryShowing(categoryName));
    };
    const alignLeft = step % 2 === 0 ? true : false;

    let howtoalign = 'flex-end';
    if (alignLeft) {
        howtoalign = 'flex-start';
    }
    if (window.isTiny) howtoalign = 'center';
    const categoryNameCapitalized = capitalizeWords(categoryName);
    return (
        <Grid item xs={10} md={5.5} border={0} id={idtouse}>
            <Grid container justifyContent={howtoalign}>
                <Button
                    key={idtouse}
                    variant="text"
                    onClick={() => setCategory()}>
                    {categoryNameCapitalized}
                </Button>
            </Grid>
        </Grid>
    );
};
export default ServiceCategories;
