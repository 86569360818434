import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { auth, logout } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useSelector } from 'store';
import { dispatch } from 'store';
import { setIsLoggedIn, setLastLocation } from 'store/slices/thisuser';

const Protected = ({ children, pagetogo }) => {
    const [user, loading] = useAuthState(auth);

    const { dibsId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    // const { hasSetPwdFirebas} = useSelector((state) => state.thisuser);
    const [hasAuthUser, setHasAuthUser] = useState(false);
    useEffect(() => {
        if (user) setHasAuthUser(true);
        // console.log('user confirmed - load page');
        if (!user && !loading) {
            console.log('no user - redirecting to login');
            dispatch(setIsLoggedIn(false));
            dispatch(
                setLastLocation(`/${pagetogo}/${dibsId}${location.search}`),
            );
            navigate(`/login/${dibsId}${location.search}`);
        }
    }, [user, navigate, dibsId, loading, pagetogo, location]);

    if (hasAuthUser) {
        return children;
    }
};
export default Protected;
Protected.propTypes = {
    children: PropTypes.node,
};
