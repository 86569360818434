import axios from 'axios';
import dayjs from 'dayjs';

export default async function isUserNew(email, dibsId) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/widget/get-user-new-status`;
    let datatosend = {};

    const checkIsNew = async () => {
        await axios
            .post(basicurl, {
                email,
                dibsId
            })
            .then((res) => {

                const { data } = res;
                const { isNew, lastVisit, alreadyPurchasedPromo } = data;
                const dateVisited = dayjs(lastVisit)
                const dateToday = dayjs()
                const datatoreturn = {
                    isNew,
                    lastVisit,
                    newAgain: isNew,
                    alreadyPurchasedPromo,
                }
                const monthsSinceVisit = dateToday.diff(dateVisited, 'month');
                if (monthsSinceVisit >= 12) {
                    datatoreturn.newAgain = true;
                }
                datatosend = datatoreturn;
            })
            .catch((err) => {
                console.log(
                    `error checking whether the user is new - Action: ${err}`,
                );
                return err;
            });
    };
    await checkIsNew();
    return datatosend;
}
