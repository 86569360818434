import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import { useSelector } from 'store';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={2} ref={ref} variant="filled" {...props} />;
});

export default function ErroAlertAsSnackbar() {
    const widthWindow = window.innerWidth;
    const [alertwidthtext, setalertWidthText] = React.useState('450px');
    React.useEffect(() => {
        if (widthWindow < 600) {
            setalertWidthText('100%');
        } else {
            setalertWidthText('450px');
        }
    }, [widthWindow]);
    const {
        secondstoshow,
        hasError,
        hasSuccess,
        hasInfo,
        hasWarning,
        alertType,
        messageToDisplay,
    } = useSelector((state) => state.error);

    const newseconds = secondstoshow * 1000;

    const [open, setOpen] = React.useState(hasError);
    const [displayMsg, setDisplayMsg] = React.useState(false);
    React.useEffect(() => {
        if (hasError || hasSuccess || hasInfo || hasWarning) {
            setOpen(true);
            setDisplayMsg(true);
        } else {
            setOpen(false);
            setDisplayMsg(false);
        }
    }, [hasError, hasInfo, hasSuccess, hasWarning]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    if (displayMsg && alertType.length > 3) {
        return (
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar
                    open={open}
                    autoHideDuration={newseconds}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <Alert
                        onClose={handleClose}
                        severity={alertType}
                        sx={{ width: alertwidthtext, mr: 2, mb: 1 }}>
                        {messageToDisplay}
                    </Alert>
                </Snackbar>
            </Stack>
        );
    } else {
        return null;
    }
}
