// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const initialState = {
    userid: 0,
    isLoggedIn: false,
    alreadyHasAccount: false,
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    numCreditCardsOnFile: 0,
    numDibsCreditCards: 0,
    numStudioCreditCards: 0,
    ccinfo: {
        last4: 'XXXX',
        exp_month: '',
        exp_year: '',
        brand: '',
        pm: '',
        fingerprint: '',
        pmstudio: '',
    },
    clickedChangePaymentMethod: false,
    paymentOptionsAll: [],
    stripeid: null,
    stripeidforstudios: '',
    stripeidForThisStudio: null,
    upcomingClasses: [],
    pastClasses: [],
    passes: [],
    addedToShopify: false,
    birthday: '',
    classesNeedRefresh: true,
    countryForMobile: 'US',
    credit: 0,
    currentStep: 'email',
    doneLoading: false,
    hasError: false,
    hasSetPwdFirebase: false,
    hasSignedInFirebase: false,
    isInLoginLinkFlow: false,
    isNewUser: true,
    isRegistering: false,
    lastLocation: '',
    numPrivatePasses: 0,
    numPublicPasses: 0,
    recentlyLoggedIn: false,
    refreshAvailablePasses: false,
    refreshPaymentOptions: 0,
    shopifyCustomerId: '',
    userQualifiesPromos: true,
    emailMarketing: true,
    smsMarketing: false,
    smsTransactions: true,
};

const thisuser = createSlice({
    name: 'thisuser',
    initialState,
    reducers: {
        // HAS ERROR
        setHasError(state, action) {
            // state.hasError = false;
            // state.hasError = action.payload;
            return {
                ...state,
                hasError: action.payload,
            };
        },
        setLastLocation(state, action) {
            return {
                ...state,
                lastLocation: action.payload,
            };
        },
        setRefreshAvailablePasses(state, action) {
            return {
                ...state,
                refreshAvailablePasses: action.payload,
            };
        },
        setCountryForMobile(state, action) {
            return {
                ...state,
                countryForMobile: action.payload,
            };
        },
        setPmIdStudio(state, action) {
            return {
                ...state,
                ccinfo: {
                    ...state.ccinfo,
                    pmstudio: action.payload,
                },
            };
        },
        setIsRegistering(state, action) {
            return {
                ...state,
                isRegistering: action.payload,
            };
        },
        setCardInfo(state, action) {
            const { pmid, cardinfo, pmidstudio } = action.payload;
            return {
                ...state,
                numCreditCardsOnFile: state.numCreditCardsOnFile + 1,
                ccinfo: {
                    ...state.ccinfo,
                    pm: pmid,
                    last4: cardinfo.last4,
                    exp_month: cardinfo.exp_month,
                    exp_year: cardinfo.exp_year,
                    brand: cardinfo.brand,
                    fingerprint: cardinfo.fingerprint,
                    pmstudio: pmidstudio,
                },
            };
        },
        setUserQualifiesPromos(state, action) {
            return {
                ...state,
                userQualifiesPromos: action.payload,
            };
        },
        setRecentlyLoggedIn(state, action) {
            return {
                ...state,
                recentlyLoggedIn: action.payload,
            };
        },
        updateCommunicationPreferences(state, action) {
            const { email_marketing, sms_marketing, sms_transactions } =
                action.payload;
            if (email_marketing !== undefined) {
                return {
                    ...state,
                    emailMarketing: email_marketing,
                };
            } else if (sms_marketing !== undefined) {
                return {
                    ...state,
                    smsMarketing: sms_marketing,
                };
            } else if (sms_transactions !== undefined) {
                return {
                    ...state,
                    smsTransactions: sms_transactions,
                };
            }
        },
        updateStripeIds: (state, action) => {
            return {
                ...state,
                stripeid: action.payload,
                // stripeid: action.payload.dibsStripeId,
                // stripeidForThisStudio: action.payload.studioStripeId,
            };
        },
        setDefaultCCInfo: (state, action) => {
            return {
                ...state,
                ccinfo: action.payload,
            };
        },
        setPaymentOptions: (state, action) => {
            const newPaymentOptionsAll = action.payload;
            return {
                ...state,
                paymentOptionsAll: newPaymentOptionsAll,
            };
        },
        setAddedToShopify: (state, action) => {
            return {
                ...state,
                addedToShopify: action.payload,
            };
        },
        setShopifyCustId: (state, action) => {
            return {
                ...state,
                shopifyCustomerId: action.payload,
            };
        },
        setClickedChangePaymentMethod: (state, action) => {
            return {
                ...state,
                clickedChangePaymentMethod: action.payload,
            };
        },
        setStripeIdForStudios: (state, action) => {
            const valuetoadd = action.payload;
            console.log('stripeid for studios getting added', valuetoadd);
            const oldarray = state.stripeidforstudios;
            const objtoadd =
                oldarray.indexOf(valuetoadd) === -1 ? valuetoadd : null;

            const newarray = [...state.stripeidforstudios, objtoadd];
            return {
                ...state,
                stripeidforstudios: newarray,
            };
        },
        setStripeIdThisStudio: (state, action) => {
            return {
                ...state,
                stripeidForThisStudio: action.payload,
            };
        },
        setErrorMsg(state, action) {
            return {
                ...state,
                errorMsg: action.payload,
            };
        },
        setIsNewUser(state, action) {
            return {
                ...state,
                isNewUser: action.payload,
            };
        },
        setDoneLoading(state, action) {
            return {
                ...state,
                doneLoading: action.payload,
            };
        },
        setIsInLoginLinkFlow(state, action) {
            return {
                ...state,
                isInLoginLinkFlow: action.payload,
            };
        },
        setStripeId(state, action) {
            return {
                ...state,
                stripeid: action.payload,
            };
        },
        setUserId(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                userid: action.payload,
            };
        },
        setIsLoggedIn(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                isLoggedIn: action.payload,
            };
        },
        setCurrentStep(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                currentStep: action.payload,
            };
        },
        setHasSignedInFirebase(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                hasSignedInFirebase: action.payload,
            };
        },
        setHasSetPwdFirebase(state, action) {
            return {
                ...state,
                hasSetPwdFirebase: action.payload,
            };
        },
        setAlreadyHasAccount(state, action) {
            return {
                ...state,
                alreadyHasAccount: action.payload,
            };
        },
        setUserEmail(state, action) {
            return {
                ...state,
                email: action.payload,
            };
        },
        setEncryptedPassword(state, action) {
            return {
                ...state,
                encryptedpassword: action.payload,
            };
        },
        setPhone(state, action) {
            return {
                ...state,
                phone: action.payload,
            };
        },
        setNumCreditCardsOnFile(state, action) {
            const { totalNumCC, dibsNumCC, studioNumCC } = action.payload;
            return {
                ...state,
                numCreditCardsOnFile: totalNumCC,
                numDibsCreditCards: dibsNumCC,
                numStudioCreditCards: studioNumCC,
            };
        },
        setUserPhone(state, action) {
            return {
                ...state,
                phone: action.payload,
            };
        },
        setUpcomingClasses(state, action) {
            return {
                ...state,
                upcomingClasses: action.payload,
            };
        },
        setClassesNeedRefresh(state, action) {
            return {
                ...state,
                classesNeedRefresh: action.payload,
            };
        },
        setPastClasses(state, action) {
            return {
                ...state,
                pastClasses: action.payload,
            };
        },
        setFirstname(state, action) {
            return {
                ...state,
                firstname: action.payload,
            };
        },
        setLastname(state, action) {
            return {
                ...state,
                lastname: action.payload,
            };
        },
        setRefreshPaymentOptions(state, action) {
            return {
                ...state,
                refreshPaymentOptions: action.payload,
            };
        },
        setAvailablePasses(state, action) {
            const passes = action.payload;
            let numprivatepass = 0;
            let numpublicpass = 0;
            if (passes) {
                passes.map((pass) => {
                    if (pass.private_pass) numprivatepass += 1;
                    else numpublicpass += 1;
                    return pass;
                });
            }

            return {
                ...state,
                numPublicPasses: numpublicpass,
                numPrivatePasses: numprivatepass,
                passes: action.payload,
            };
        },
        setBirthday(state, action) {
            return {
                ...state,
                birthday: action.payload,
            };
        },
        setAvailableCredit(state, action) {
            return {
                ...state,
                credit: action.payload,
            };
        },
        clearOldUserExceptEmail(state, action) {
            return {
                ...state,
                userid: 0,
                isLoggedIn: false,
                alreadyHasAccount: false,
                firstname: '',
                lastname: '',
                numCreditCardsOnFile: 0,
                phone: '',
                upcomingClasses: [],
                credit: 0,
                hasError: false,
                currentStep: 'email',
                // hasSignedInFirebase: false,
                hasSetPwdFirebase: false,
                stripeid: null,
                stripeidForThisStudio: null,
                stripeidforstudios: [],
                isInLoginLinkFlow: false,
                birthday: '',
                doneLoading: false,
                lastLocation: '',
                recentlyLoggedIn: false,
                isNewUser: true,
                userQualifiesPromos: true,
                encryptedpassword: '',
                passes: [],
                numPublicPasses: 0,
                numPrivatePasses: 0,
                ccinfo: {
                    last4: 'XXXX',
                    exp_month: '',
                    exp_year: '',
                    brand: '',
                    pm: '',
                    fingerprint: '',
                    pmstudio: '',
                },
            };
        },

        clearThisUser(state) {
            return {
                ...state,
                userid: 0,
                isLoggedIn: false,
                alreadyHasAccount: false,
                firstname: '',
                lastname: '',
                email: '',
                passes: [],
                numPublicPasses: 0,
                numPrivatePasses: 0,
                numCreditCardsOnFile: 0,
                phone: '',
                upcomingClasses: [],
                credit: 0,
                hasError: false,
                currentStep: 'email',
                // hasSignedInFirebase: false,
                hasSetPwdFirebase: false,
                stripeid: null,
                stripeidForThisStudio: null,
                stripeidforstudios: [],
                isInLoginLinkFlow: false,
                birthday: '',
                doneLoading: false,
                lastLocation: '',
                recentlyLoggedIn: false,
                isNewUser: true,
                userQualifiesPromos: true,
                encryptedpassword: '',
                ccinfo: {
                    ...state.ccinfo,
                    last4: 'XXXX',
                    exp_month: '',
                    exp_year: '',
                    brand: '',
                    pm: '',
                    fingerprint: '',
                    pmstudio: '',
                },
            };
        },
    },
});

// Reducer
export default thisuser.reducer;
export const {
    setHasError,
    setErrorMsg,
    setIsLoggedIn,
    setUserEmail,
    setEncryptedPassword,
    setNumCreditCardsOnFile,
    setUserPhone,
    setCountryForMobile,
    setRefreshAvailablePasses,
    clearOldUserExceptEmail,
    setUpcomingClasses,
    setPastClasses,
    setAvailablePasses,
    setStripeIdForStudios,
    setAvailableCredit,
    setPaymentOptions,
    setAlreadyHasAccount,
    updateCommunicationPreferences,
    setIsNewUser,
    setClassesNeedRefresh,
    setFirstname,
    setStripeId,
    setStripeIdThisStudio,
    setLastname,
    setRecentlyLoggedIn,
    setHasSetPwdFirebase,
    setBirthday,
    setPmIdStudio,
    setCurrentStep,
    setRefreshPaymentOptions,
    clearThisUser,
    setDefaultCCInfo,
    setClickedChangePaymentMethod,
    setHasSignedInFirebase,
    setIsRegistering,
    setDoneLoading,
    updateStripeIds,
    setUserId,
    setIsInLoginLinkFlow,
    setLastLocation,
    setUserQualifiesPromos,
    setCardInfo,
    setPhone,
    setShopifyCustId,
    setAddedToShopify,
} = thisuser.actions;

// ----------------------------------------------------------------------
