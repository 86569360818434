// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
    config: {
        dibsStudioId: 0,
        studioName: '',
        timeZone: '',
        currency: 'USD',
        wdth: 1000,
        widgetWidth: 600,
        entryPage: 'schedule',
        showAppointments: false,
        showSchedule: true,
        clientCcOnFile: false,
        languageToDisplay: 'en',
        locale: 'en',
        buttonControlsWidget: false,
        widgetOpenStyle: 'full-page',
        cancelTime: 12,
        customTwilioNumUS: null,
        sendConfirmationEmailDibs: true,
        sendSMSConfirmation: true,
        configDoneLoading: false,
    },
    studioConfig: {
        terms: '',
        color: 'eeeeee',
        intervalEnd: 14,
        colorisdark: false,
        autopayNotice: '',
        use_spot_booking: '',
        showWidgetButton: false,
        show_credit_load: false,
        showChatbot: false,
        first_class_fixed_price: '',
        display_giftcards: '',
        spot_label: '',
        vod_access_period: '',
        instructorAlt: null,
        showPricesWidget: true,
        imageUrls: {
            color_logo: '',
            hero_url: '',
        },
        hasMemberships: true,
        salesTax: 0,
        retailTax: 0,
        billing: {
            stripe_account_id: '',
            stripeid: '',
            stripe_cardid: '',
            subscription_fee: '',
            total_monthly_charge: '',
            date_of_charge: '',
        },
    },
    customerService: {
        customerServiceEmail: '',
        customerServicePhone: '',
        customEmailToSendFrom: '',
        locationName: '',
        currentLocationId: null,
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        allLocationIds: [], // future - multiple locations should be established
    },
    settings: {
        dynamicPricing: false,
        flashCredits: false,
        minPrice: 20,
        maxPrice: 30,
        showChatbot: false,
    },
    integrations: {
        classpass: false,
        gymPass: false,
        cp_amount: 0,
        oldstudioid: 0,
    },
};

const dibsstudio = createSlice({
    name: 'dibsstudio',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            return {
                ...state,
                error: action.payload,
            };
        },
        // ADD STUDIO DATA
        addStudioData(state, action) {
            return {
                ...state,
                config: action.payload,
            };
        },
        setDynamicPricingStatus(state, action) {
            return {
                ...state,
                settings: {
                    ...state.settings,
                    dynamicPricing: action.payload,
                },
            };
        },
        setCurrency(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    currency: action.payload,
                },
            };
        },
        setTwilioUSNum(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    customTwilioNumUS: action.payload,
                },
            };
        },
        setLanguageToDisplay(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    languageToDisplay: action.payload.locale,
                    locale: action.payload.locale,
                },
            };
        },
        setSendConfimationEmailDibs(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    sendConfirmationEmailDibs: action.payload,
                },
            };
        },
        setConfigDoneLoading(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    configDoneLoading: action.payload,
                },
            };
        },
        setShowCreditLoad(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    show_credit_load: action.payload,
                },
            };
        },
        updateCustomerServiceInfo(state, action) {
            return {
                ...state,
                customerService: {
                    ...state.customerService,
                    locationName: action.payload.locationName,
                    currentLocationId: action.payload.currentLocationId,
                    address: action.payload.address,
                    address2: action.payload.address2,
                    city: action.payload.city,
                    state: action.payload.state,
                    zip: action.payload.zip,
                    customerServiceEmail: action.payload.custServEmail,
                    customerServicePhone: action.payload.custServPhone,
                    allLocationIds: action.payload.allLocationIds,
                },
            };
        },
        setWdth(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    wdth: action.payload,
                },
            };
        },
        setButtonControlsWidget(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    buttonControlsWidget: action.payload,
                },
            };
        },
        setWidgetWidth(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    widgetWidth: action.payload,
                },
            };
        },
        setWidgetOpenStyle(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    widgetOpenStyle: action.payload,
                },
            };
        },
        setClientCcOnFile(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    clientCcOnFile: action.payload,
                },
            };
        },
        setInstructorAlt(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    instructorAlt: action.payload,
                },
            };
        },
        setEntryPage(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    entryPage: action.payload,
                },
            };
        },
        setShowAppts(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    showAppointments: action.payload,
                },
            };
        },
        setShowScheduleConfig(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    showSchedule: action.payload,
                },
            };
        },
        setIntervalEnd(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    intervalEnd: action.payload,
                },
            };
        },
        setColorIsDark(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    colorisdark: action.payload,
                },
            };
        },
        setSalesTaxRate(state, action) {
            const taxrate = action.payload;
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    salesTax: taxrate,
                },
            };
        },
        setShowPrices(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    showPricesWidget: action.payload,
                },
            };
        },
        setWidgetButton(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    showWidgetButton: action.payload,
                },
            };
        },
        setDibsStudioId(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    dibsStudioId: action.payload,
                },
            };
        },
        setStudioName(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    studioName: action.payload,
                },
            };
        },
        setColor(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    color: action.payload,
                },
            };
        },
        setTimeZone(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    timeZone: action.payload,
                },
            };
        },
        setStudioTerms(state, action) {
            return {
                ...state,
                studioConfig: {
                    ...state.studioConfig,
                    terms: action.payload,
                },
            };
        },
        setStudioCancelTime(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    cancelTime: action.payload,
                },
            };
        },
        setSendSMSConfirmation(state, action) {
            return {
                ...state,
                config: {
                    ...state.config,
                    sendSMSConfirmation: action.payload,
                },
            };
        },
    },
});

// Reducer
export default dibsstudio.reducer;
export const {
    addStudioData,
    hasError,
    setFirstClassFixedPrice,
    setSendConfimationEmailDibs,
    setCurrency,
    setClasspass,
    setWidgetOpenStyle,
    setGympass,
    setShowChatbot,
    setConfigDoneLoading,
    setEntryPage,
    setInstructorAlt,
    setClientCcOnFile,
    setSendSMSConfirmation,
    setStudioTerms,
    setWidgetButton,
    setDynamicPricing,
    setFlashCreditsStore,
    setDynamicPricingStatus,
    setOpenClose,
    setWidgetWidth,
    setLanguageToDisplay,
    setGlobalPrices,
    setGeneralLocationData,
    setCustomEmailToSendFrom,
    setStudioConfigData,
    setStudioImageUrls,
    setStudioColorRedux,
    setNumDaysToShowCalendar,
    setRafAwardRedux,
    setColorIsDark,
    setStudioCancelTime,
    setTwilioUSNum,
    setShowScheduleConfig,
    setTaxesRedux,
    setShowAppts,
    setStudioProfileAccountInfo,
    setPaymentInfo,
    setStudioTimeZone,
    resetDibsStudioData,
    setDibsStudioId,
    setStudioName,
    setShowPrices,
    setColor,
    setTimeZone,
    setWdth,
    setSalesTaxRate,
    setIntervalEnd,
    setShowCreditLoad,
    updateCustomerServiceInfo,
    setButtonControlsWidget,
} = dibsstudio.actions;

// ----------------------------------------------------------------------
