import { useState, useEffect } from 'react';
import {
    Grid,
    TextField,
    Box,
    Typography,
    Stack,
    FormControl,
    Checkbox,
    Link,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { useSelector } from 'store';

const AddCardOption = ({ pack, showCreditCardAction }) => {
    const theme = useTheme();
    const { studioConfig } = useSelector((state) => state.dibsstudio);
    const { ccinfo } = useSelector((state) => state.thisuser);
    const { color } = studioConfig;
    const { cardlastfour } = useSelector((state) => state.cart);
    const colortouse = `#${color}`;
    const [checked, setChecked] = useState(true);


    const showcc = () => {
        showCreditCardAction(true);
    };

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            border={0}
            sx={{ mb: 1.4, mt: 0.4 }}>
            <Grid item>
                <Link onClick={showcc} underline="none" color="#4b4949" style={{ cursor: 'pointer' }}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: '400',
                            borderBottom: '1px dashed',
                            borderColor: theme.palette.primary.main,
                            p: 0,
                            px: 0.5,
                        }}>
                        Add a payment option
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    );
};
export default AddCardOption;
