import { Button, Grid, Stack, Fade, Collapse } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';

import { useSelector } from 'store';

import CollapseComponent from 'views/wrappers/collapse';

const CheckoutButton = ({
    buttonText,
    clickAction,
    thisid,
    isBuying,
    pl,
    showConfirmButton,
    isDisabled,
}) => {
    const theme = useTheme();
    const { total, showCCCapture, isProcessing, showCheckoutButton } =
        useSelector((state) => state.cart);
    const { clickedChangePaymentMethod } = useSelector(
        (state) => state.thisuser,
    );
    const disableThisButton = isDisabled || clickedChangePaymentMethod;

    const checkoutButton = (
        <>
            <Grid container justifyContent="flex-end">
                <Grid item xs={12} sx={{ pl: pl, mt: 3, mb: 2 }}>
                    <Stack spacing={2}>
                        <LoadingButton
                            id={thisid || 'checkout-button'}
                            variant="contained"
                            fullWidth
                            disableElevation
                            disabled={disableThisButton}
                            loading={isProcessing}
                            sx={{
                                backgroundColor: theme.palette.success.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.success.main,
                                },
                                '&:disabled': {
                                    backgroundColor: theme.palette.success.main,
                                },
                                py: 0.75,
                            }}
                            onClick={clickAction}>
                            {buttonText}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </>
    );
    return checkoutButton;
    // if (total <= 0) return null;
    // if (clickedChangePaymentMethod) return null;
    // return (
    //     <CollapseComponent showThis={showCheckoutButton} children={checkoutButton} />
    // );
};
export default CheckoutButton;
