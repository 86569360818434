// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import dibsstudioReducer from './slices/dibsstudio';
import scheduleReducer from './slices/schedule';
import menuReducer from './slices/menu';
import packagesReducer from './slices/packages';
import cartReducer from './slices/cart';
import snackbarReducer from './slices/snackbar';
import thisuserReducer from './slices/thisuser';
import errorReducer from './slices/error';
import apptReducer from './slices/appointments';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    dibsstudio: persistReducer(
        { key: 'dibsstudio', storage, keyPrefix: 'dibsstudio-' },
        dibsstudioReducer,
    ),
    cart: persistReducer({ key: 'dibscart', storage }, cartReducer),
    thisuser: persistReducer({ key: 'thisuser', storage }, thisuserReducer),
    schedule: scheduleReducer,
    appointments: apptReducer,
    menu: persistReducer({ key: 'dibsmenu', storage }, menuReducer),
    // packages: packagesReducer,
    packages: persistReducer({ key: 'dibs', storage }, packagesReducer),
    snackbar: snackbarReducer,
    error: errorReducer,
});

export default reducer;
