import React from 'react';
import {
    Grid,
    Typography,
    Stack,
    Divider,
    // ButtonBase,
    Chip,
    Drawer,
    // Fab,
} from '@mui/material';

import { useSelector, dispatch } from 'store';
import { setClassesNeedRefresh } from 'store/slices/thisuser';

import { breakupDate, displayDate, formatDollarAmountTwoDigits } from 'helpers';
import { isEarlyCancel } from 'helpers/rules';
import ConfirmationBox from 'ui-component/ConfirmationBox';
import dropEvent from 'actions/dropEvent';

import XIcon from 'assets/icons/XIcon';
// import CheckIcon from 'assets/icons/CheckIcon';
import SquareIcon from 'assets/icons/SquareIcon';

import setSuccess from 'actions/status/setAlert';
import setAlert from 'actions/status/setAlert';
import {
    setHasSuccessReducer,
    setSuccessMessageReducer,
} from 'store/slices/error';

const ClassItem = ({ thisclass, isLast, previous }) => {
    const { showPricesWidget, color } = useSelector(
        (state) => state.dibsstudio.studioConfig,
    );
    const { cancelTime, dibsStudioId } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const { userid } = useSelector((state) => state.thisuser);

    const [formattedtime, setFormattedtime] = React.useState('');
    const [formatteddate, setFormattedDate] = React.useState('');
    const [paymentString, setPaymentString] = React.useState('');
    const [question, setQuestion] = React.useState('');
    const [openStatus, setOpenStatus] = React.useState(false);
    const [dropText, setDropText] = React.useState('DROP');
    const [unpaidApptText, setUnpaidApptText] = React.useState(
        'Unpaid appointment or class',
    );
    const [secondColumnAlign, setSecondColumnAlign] = React.useState('left');
    const [questionText, setQuestionText] = React.useState(
        'Are you sure you want to drop this class?',
    );
    const [earlyDrop, setEarlyDrop] = React.useState(false);
    const [confirmDrop, setConfirmDrop] = React.useState(false);
    const [moreinfotext, setMoreinfotext] = React.useState('');
    const [paymentSubString, setPaymentSubString] = React.useState('');
    const [attendedStatus, setAttendedStatus] = React.useState('CheckedIn');
    const [colorOfIcon, setColorOfIcon] = React.useState(color);
    const [typeOfIcon, setTypeOfIcon] = React.useState('good');
    const [unpaidStatus, setUnpaidStatus] = React.useState(true);
    const [colorOfText, setColorOfText] = React.useState('#b0aeae');
    React.useEffect(() => {
        if (previous) {
            if (thisclass.dropped) {
                if (thisclass.early_cancel) {
                    setAttendedStatus('Early Cancel');
                    setColorOfIcon(color);
                    setTypeOfIcon('good');
                    setColorOfText('#b0aeae');
                } else {
                    setAttendedStatus('Late Cancel');
                    setColorOfIcon('b0aeae');
                    setColorOfText('b0aeae');
                    setTypeOfIcon('okay');
                }
            } else if (thisclass.checkedin) {
                setAttendedStatus('Checked In');
                setColorOfText('#616161');
                setColorOfIcon(color);
                setTypeOfIcon('good');
            } else {
                setAttendedStatus('No Show');
                setColorOfIcon('b0aeae');
                setTypeOfIcon('bad');
                setColorOfText('#b0aeae');
            }
        }
    }, [thisclass, previous, color]);

    const newthisclass = React.useMemo(() => {
        return {
            ...thisclass,
            id: thisclass.id,
            event: thisclass,
            dtinfo: thisclass,
        };
    }, [thisclass]);
    const { id, event, dtinfo } = newthisclass;
    const { name, location, instructor, price_dibs, start_date } = event;
    const { locationName } = location;
    let inCart = false;

    let priceFormatted = '';
    if (showPricesWidget) {
        priceFormatted = `$${price_dibs}`;
    }
    const nameOfClass = name;
    const formattedName = nameOfClass.toUpperCase();
    let instructorName = `${instructor.firstname} ${instructor.lastname}`;
    const dropclass = () => {
        dispatch(setHasSuccessReducer(false));
        dispatch(setSuccessMessageReducer(''));
        // console.log('am dropping this class', thisclass);
        // console.log('canceltime is: ', cancelTime);
        const earlydrop = isEarlyCancel(event.start_date, cancelTime);
        setQuestion(questionText);
        if (earlydrop) {
            setEarlyDrop(true);
            if (
                newthisclass.dtinfo.with_passid !== null &&
                newthisclass.dtinfo.with_passid > 0
            ) {
                setMoreinfotext('Your pass will be returned to your account.');
            } else if (newthisclass.dtinfo.studio_credits_spent > 0) {
                setMoreinfotext(
                    'Your credit will be returned to your account.',
                );
            } else if (newthisclass.dtinfo.amount > 0) {
                const amountToShow = formatDollarAmountTwoDigits(
                    newthisclass.dtinfo.amount,
                );
                setMoreinfotext(
                    `Your account will be credited in the amount of ${amountToShow}.`,
                );
            }
        } else {
            setMoreinfotext(
                'Please note! This is a late drop, meaning you will not get any credit back to your account for dropping this class.',
            );
        }

        setOpenStatus(true);
        // dropEvent(thisclass);
    };

    const { serviceName } = newthisclass;

    const { studio_credits_spent, amount, with_passid, unpaid } = dtinfo;
    React.useEffect(() => {
        setUnpaidStatus(unpaid);
    }, [unpaid]);
    React.useEffect(() => {
        if (dibsStudioId === '226') {
            setDropText('CANCEL');
            setUnpaidApptText('Unpaid appointment');
            setQuestionText(
                'Are you sure you want to cancel this appointment?',
            );
        }
    }, [dibsStudioId]);

    React.useEffect(() => {
        const getTime = async () => {
            await breakupDate(start_date).then((res) => {
                setFormattedtime(res.formattedtime);
            });
            await displayDate(start_date).then((res) => {
                setFormattedDate(res);
            });
        };
        getTime();

        if (amount > 0) {
            setPaymentString(`${formatDollarAmountTwoDigits(amount)}`);
            if (studio_credits_spent > 0) {
                setPaymentSubString(
                    `Applied ${formatDollarAmountTwoDigits(
                        studio_credits_spent,
                    )} in credit`,
                );
                // setPaymentString(
                //     `Applied ${formatDollarAmountTwoDigits(
                //         studio_credits_spent,
                //     )} in credit`,
                // );
            }
        } else if (unpaidStatus) {
            setPaymentString(unpaidApptText);
        } else {
            if (
                !serviceName ||
                serviceName === undefined ||
                serviceName === null ||
                serviceName === 'undefined'
            ) {
                setPaymentString('');
            } else {
                if (amount === 0) {
                    setPaymentString(`Free Event`);
                } else {
                    setPaymentString(`Applied ${serviceName}`);
                }
            }
        }
    }, [
        amount,
        dtinfo.id,
        formatteddate,
        id,
        newthisclass,
        previous,
        serviceName,
        start_date,
        studio_credits_spent,
        unpaid,
        unpaidApptText,
        unpaidStatus,
    ]);
    React.useEffect(() => {
        const dropThis = async () => {
            console.log('dropping this event', newthisclass);
            await dropEvent(newthisclass, userid, dibsStudioId, earlyDrop).then(
                (res) => {
                    console.log('response after dropping the event is', res);
                    if (res === 1) {
                        dispatch(setClassesNeedRefresh(true));
                        setTimeout(() => {
                            dispatch(setClassesNeedRefresh(false));
                        }, 1000);
                        setSuccess({
                            msg: 'Successfully dropped!',
                            seconds: 5,
                            success: true,
                        });
                    } else {
                        console.log('dropping was not successful');
                        dispatch(
                            setAlert({
                                msg: 'There was a problem dropping this class. Please refresh your screen and try again.',
                                seconds: 5,
                                error: true,
                            }),
                        );
                    }
                },
            );
        };
        if (confirmDrop) {
            dropThis();
            setConfirmDrop(false);
        }
    }, [confirmDrop, dibsStudioId, earlyDrop, newthisclass, userid]);

    return (
        <Grid
            item
            xs={12}
            id={`class-${thisclass.eventid}-listing-${thisclass.dibsTransactionId}`}>
            <ConfirmationBox
                openStatus={openStatus}
                setOpenStatus={setOpenStatus}
                moreinfo={moreinfotext}
                question={question}
                setAnswerToQuestion={setConfirmDrop}
                idtouse={`drop-confirmation-${thisclass.eventid}-listing-${thisclass.dibsTransactionId}`}
            />
            <Grid item xs={12} sx={{ ml: window.isTiny ? 0 : 1, mb: 3, mt: 0 }}>
                <Typography variant="coloredHeader">{formatteddate}</Typography>
            </Grid>
            <Grid
                container
                alignItems="flex-start"
                justifyContent="flex-start"
                id="cont-upcoming-classes-indi">
                {/* time grid */}
                <Grid
                    item
                    xs={window.isTiny ? 3 : 2.25}
                    sx={{ mx: window.isTiny ? 0 : 2 }}>
                    <Grid container>
                        <Stack>
                            <Grid item>
                                <Typography variant="boldHeader">
                                    {formattedtime}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="lightHeader">
                                    {locationName}
                                </Typography>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
                <Grid
                    item
                    id="item-holding-class-title"
                    xs={window.isTiny ? 6.5 : 5}
                    sx={{
                        pl: 0.5,
                        mb: 3,
                        border: 0,
                        ml: window.isTiny ? 0 : 0,
                    }}>
                    <Grid
                        container
                        alignItems="flex-start"
                        justifyContent={
                            window.isTiny ? 'center' : 'flex-start'
                        }>
                        <Stack>
                            <Grid item xs={12}>
                                <Typography variant="regHeader">
                                    {formattedName}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="lightHeader">
                                    w/ {instructorName}
                                </Typography>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
                {/* how they paid container */}
                {!window.isTiny && (
                    <Grid item xs>
                        <Grid
                            container
                            border={0}
                            display="flex"
                            justifyContent="center"
                            alignContent="center">
                            {/* <Stack> */}
                            <Grid item border={0}>
                                <Stack>
                                    <Typography
                                        variant="regHeader"
                                        sx={{ textAlign: 'center', mb: 0.5 }}>
                                        {paymentString}
                                    </Typography>
                                    {studio_credits_spent > 0 && (
                                        <Typography
                                            variant="lightHeadertiny"
                                            textAlign="center"
                                            sx={{ fontStyle: 'italic' }}>
                                            {paymentSubString}
                                        </Typography>
                                    )}
                                </Stack>
                            </Grid>
                            {/* </Stack> */}
                        </Grid>
                    </Grid>
                )}
                {!previous && (
                    <Grid
                        id="item-holding-drop-button-no-prev"
                        item
                        xs={window.isTiny ? 2.3 : 1.5}
                        sx={{ mr: window.isTiny ? 0 : 1, border: 0 }}>
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="flex-start">
                            <Grid item border={0} id="drop-class-button-item">
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    onClick={dropclass}
                                    spacing={0.5}>
                                    <XIcon
                                        color={color}
                                        id={event.id}
                                        incart={inCart}
                                        size={window.isTiny ? 'small' : null}
                                    />
                                    <Typography variant="packageUsed">
                                        {dropText}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {previous && (
                    <Grid
                        id="item-holding-drop-button-prev"
                        item
                        xs={window.isTiny ? 2.3 : 1.5}
                        sx={{ mr: window.isTiny ? 0 : 1, border: 0 }}>
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="flex-start">
                            <Grid item border={0} sx={{ width: '100px' }}>
                                <Stack
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    // width="100px"
                                    spacing={0.5}>
                                    <SquareIcon
                                        color={colorOfIcon}
                                        id={event.id}
                                        incart={inCart}
                                        size={window.isTiny ? 'small' : null}
                                        typeOfIcon={typeOfIcon}
                                    />
                                    <Typography
                                        variant="packageUsed"
                                        sx={{ color: colorOfText }}>
                                        {attendedStatus}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {!isLast && <Divider sx={{ mt: 2, mb: 0 }} />}
        </Grid>
    );
};

export default ClassItem;
