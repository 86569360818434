import * as React from 'react';
import { Grid, Fade, Box, AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { setHasErrorReducer } from 'store/slices/error';

import { useSelector } from 'store';
import { dispatch } from 'store';

const ErrorMessage = () => {
    const theme = useTheme();
    const { hasError, errorMessage } = useSelector((state) => state.error);

    if (!hasError) return null;
    return (
        <Fade in>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="fixed">
                    <Grid
                        item
                        xs={12}
                        sx={{ backgroundColor: theme.palette.error.light }}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sx={{ my: 2 }}>
                                <Stack direction="row">
                                    <Grid item xs={12}>
                                        <Typography align="center">
                                            {errorMessage}
                                        </Typography>
                                    </Grid>
                                    <Grid item alignItems="right">
                                        <IconButton
                                            color="primary"
                                            aria-label="close"
                                            size="small"
                                            style={{
                                                position: 'absolute',
                                                top: '3px',
                                                right: '0',
                                            }}
                                            onClick={() =>
                                                dispatch(
                                                    setHasErrorReducer(false),
                                                )
                                            }>
                                            <CloseIcon
                                                fontSize="small"
                                                align="right"
                                            />
                                        </IconButton>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </AppBar>
            </Box>
        </Fade>
    );
};

export default ErrorMessage;
