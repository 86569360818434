import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import { Grid } from '@mui/material';

import { useSelector } from 'store';
import { setPageSelected } from 'store/slices/menu';

// layout
import LayoutBox from 'ui-component/LayoutBox';
import RegistrationCard from 'ui-component/cards/AccountSubCardNew'

// components

const SignupFromFriend = () => {
    console.log('is this being used');
    let { dibsId } = useParams();
    console.log('dibsId is', dibsId);
    let params = new URLSearchParams(document.location.search);
    let useridReferrer = params.get("referredby");
    let friendemail = params.get("friendemail");
    console.log('useridReferrer', useridReferrer);
    console.log('friendemail', friendemail);
    const setDibsIdLocalStorage = (dibsId) => {
        window.localStorage.setItem('dibsId', dibsId);
    };
    React.useEffect(() => {
        console.log('useEffect on login page');
        setDibsIdLocalStorage(dibsId);
    }, [dibsId]);
    return (
        <LayoutBox mheight="25vh" margTop={5} idtoreference="new-registration-from-friend">
            <Grid
                container
                border={0}
                id="login-container"
                direction="row"
                flex={1}
                justifyContent="center"
                alignItems="flex-start"
                sx={{ height: 'auto' }}>
                <Grid item xs={12} md={8} lg={5} sx={{ mt: 5 }}>
                    <RegistrationCard title="Sign Up" dibsstudioid={dibsId}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="flex-start">
                            <Grid item xs={12}>Registered By</Grid>
                            <Grid item xs={12}>Email</Grid>
                            <Grid item xs={12}>Firstname</Grid>
                            <Grid item xs={12}>Lastname</Grid>
                            <Grid item xs={12}>Phone</Grid>
                        </Grid>
                    </RegistrationCard>
                </Grid>
            </Grid>
        </LayoutBox>
    );
};

export default SignupFromFriend;
