import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import {
    Typography,
    Stack,

} from '@mui/material';


import CircleIcon from 'assets/icons/circleIcon';
import { useSelector } from 'store';

const RemainingPackages = () => {
    const { passes } = useSelector((state) => state.thisuser);
    const [passesRemaining, setPassesRemaining] = useState(passes);
    useEffect(() => {
        if (passes) setPassesRemaining(passes);
    }, [passes]);
    if (passesRemaining.length === 0) return null;

    return (
        <Stack>
            {passesRemaining.map((pass, index) => {
                const { totalUses, usesCount, studioPackage, expiresAt } = pass;
                const remaining = totalUses - usesCount;
                const { packageName } = studioPackage;
                const expiresAtFormatted = dayjs(expiresAt).format('M/D/YYYY');

                const text = `You have ${remaining} credit${remaining > 1 ? 's' : ''} remaining in your ${packageName} that expires on ${expiresAtFormatted}.`
                return (

                    <Stack direction="row" key={`remaining-credits-${index}-${Math.random()}`}>
                        <CircleIcon />
                        <Typography variant="h5stafftitle" sx={{ mb: 2, ml: 0.5 }}>
                            {text}
                        </Typography>
                    </Stack>

                )
            })}
        </Stack>
    )
};

export default RemainingPackages;