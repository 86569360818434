import splitEventsForProcessing from 'helpers/splitEventsToProcess';
import putEventsBackTogether from 'helpers/putEventsBackTogether';
const moment = require('moment');
// need to handle how to apply credit as well

export default async function setPassesWithEventsCartView(
    itemsInCart,
    passes,
    credit,
) {

    const passesAsArray = [...passes];
    let groupPasses = [];
    const privatePasses = [];
    const promises = [];
    const cartItemsToSort = [...itemsInCart];
    const itemsToReturn = [];
    const splitEvents = splitEventsForProcessing(cartItemsToSort);
    const sortedPasses = passesAsArray
        .sort((a, b) => {
            if (!a.autopay && !b.autopay) return 0;
            if (a.autopay && !b.autopay) return -1;
            if (!a.autopay && b.autopay) return 1;
            return moment(a.expiresAt) - moment(b.expiresAt);
        })
        .sort((a, b) => {
            return moment(a.expiresAt) - moment(b.expiresAt);
        });
    const itemsToProcess = splitEvents.sort((a, b) => {
        return moment(a.start_date) - moment(b.start_date);
    });
    const splitprivatepublic = () => {
        sortedPasses.forEach((pass) => {
            if (pass.private_pass) {
                privatePasses.push(pass);
            } else {
                groupPasses.push(pass);
            }
        });
        return;
    };
    promises.push(splitprivatepublic());
    await Promise.all(promises);

    // assign the pass
    const assignThePass = async (item) => {
        // run through private
        if (item.private && item.canApplyPass) {
            if (privatePasses.length > 0) {
                privatePasses.map((pass) => {
                    if (pass.totalUses > pass.usesCount) {
                        console.log(
                            'this has at least 1 private pass available',
                        );
                    }
                    return pass;
                });
            } else {
                return item;
            }
        } else {
            let paymethod = {};
            let itemnew = item;
            groupPasses = groupPasses.map((pass) => {
                let totalUses =
                    pass.totalUses === null ? 99999 : pass.totalUses;
                let usesCount = pass.usesCount;
                let passesavailable = totalUses - usesCount;
                let quantitycovered = 0;
                let quantityneedtocover = item.quantity;
                for (let i = 0; i < item.quantity; i++) {
                    if (passesavailable > 0 && item.canApplyPass) {
                        const { studioPackage } = pass;
                        const { packageName } = studioPackage;

                        paymethod = {
                            passid: pass.id,
                            method: 'with_passid',
                            passname: packageName,
                            quantity: 1,
                            amountToCharge: {
                                subtotal: 0,
                                tax: 0,
                                total: 0,
                                discount: 0,
                            },
                        };
                        pass = {
                            ...pass,
                            usesCount: usesCount + 1,
                        };
                        passesavailable = passesavailable - 1;

                        itemnew = {
                            ...item,
                            quantity: 1,
                            howtopay: paymethod,
                        };
                    } else {
                        const itemtax = item.itemtax;
                        const totalprice = itemtax + item.price;
                        const extrapaymentmethod = {
                            passid: 0,
                            method: 'creditcard',
                            passname: '',
                            quantity: quantityneedtocover - quantitycovered,
                            amountToCharge: {
                                subtotal:
                                    item.price *
                                    (quantityneedtocover - quantitycovered),
                                tax:
                                    itemtax *
                                    (quantityneedtocover - quantitycovered),
                                total:
                                    totalprice *
                                    (quantityneedtocover - quantitycovered),
                                discount: 0,
                            },
                        };
                        itemnew = {
                            ...item,
                            quantity: 1,
                            howtopay: extrapaymentmethod,
                        };
                    }
                }
                return pass;
            });
            return itemnew;
        }
    };

    // process events
    const assignPassToEventIfAvailable = async (item) => {
        let payMethod = {
            passid: 0,
            method: 'creditcard',
            passname: '',
            quantity: item.quantity,
            amountToCharge: {
                subtotal: item.price,
                tax: item.itemtax,
                total: item.price + item.itemtax,
                discount: 0,
            },
        };
        const newitem = {
            ...item,
            howtopay: payMethod,
        };
        const itemtoreturn = await assignThePass(newitem);
        return itemtoreturn;
    };
    const processItemsInCart = async () => {
        return itemsToProcess.map(async (item) => {
            const itemWithPaymethod = await assignPassToEventIfAvailable(item);
            itemsToReturn.push(itemWithPaymethod);
            return itemsToReturn;
        });
    };
    promises.push(await processItemsInCart());
    await Promise.all(promises);
    const itemsToReturnSummary = putEventsBackTogether(itemsToReturn);
    return itemsToReturnSummary;
}
