import { Grid, Box, Typography, Divider } from '@mui/material';
import { formatDollarAmountTwoDigits } from 'helpers';

const Credit = ({ credit }) => {
    return (
        <Grid item xs={12} sx={{ mt: 2 }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                }}>
                <Typography variant="creditDisplay">
                    You have {formatDollarAmountTwoDigits(credit)} in credit.
                </Typography>
                <Typography variant="lightHeader">
                    Your credit will be automatically applied to your next
                    purchase.
                </Typography>
            </Box>
            <Divider sx={{ mt: 7, mb: 2 }} />
        </Grid>
    );
};
export default Credit;
