import axios from 'axios';
// Matching API service = Appt-1A-GetUpcomingAppts
export default async function getUpcomingAppts(dibsStudioId, userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/get-upcoming-appts`;
    let datatosend = {};
    const reqbody = {
        dibsStudioId,
        userid,
    };
    console.log('reqbody from getting upcoming classes is', reqbody)
    const intent = async () => {
        await axios
            .post(basicurl, reqbody)
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error from getting upcoming appt: ${err}`);
                return err;
            });
    };
    await intent();
    return datatosend;
}
