import { dispatch } from 'store';
import {
    setColor,
    setTimeZone,
    setWidgetButton,
    setInstructorAlt,
    setShowAppts,
    setStudioName,
    setSalesTaxRate,
    setEntryPage,
    setColorIsDark,
    setStudioTerms,
    setIntervalEnd,
    setShowCreditLoad,
    setShowScheduleConfig,
    setClientCcOnFile,
    setButtonControlsWidget,
    setWidgetOpenStyle,
    setStudioCancelTime,
    updateCustomerServiceInfo,
    setDynamicPricingStatus,
    setSendSMSConfirmation,
    setTwilioUSNum,
    setSendConfimationEmailDibs,
    setConfigDoneLoading,
    setCurrency,
} from 'store/slices/dibsstudio';
import { setAppointmentOpenCloseTimes } from 'store/slices/appointments';

export default async function updateConfigs(data) {
    dispatch(setConfigDoneLoading(false));
    dispatch(setColor(data.color));
    dispatch(setButtonControlsWidget(data.buttonControlsWidget));
    dispatch(setWidgetOpenStyle(data.widgetOpenStyle));
    dispatch(setShowAppts(data.showAppts));
    dispatch(setShowScheduleConfig(data.showSchedule));
    dispatch(setTimeZone(data.timezone));
    dispatch(setStudioCancelTime(data.cancelTime));
    dispatch(setColorIsDark(data.colorisdark));
    dispatch(setWidgetButton(data.displayWidgetButton));
    dispatch(setInstructorAlt(data.instructorAltName));
    dispatch(setClientCcOnFile(data.keepClientCCOnFile));
    dispatch(setStudioName(data.studioName));
    dispatch(setSalesTaxRate(data.taxRate));
    dispatch(setEntryPage(data.widgetEntryPoint));
    dispatch(setStudioTerms(data.terms));
    dispatch(setIntervalEnd(data.intervalEnd));
    dispatch(setShowCreditLoad(data.showCreditLoad));
    dispatch(setCurrency(data.currency));
    dispatch(setSendSMSConfirmation(data.sendSMSConfirmation));
    dispatch(setTwilioUSNum(data.twilioCustomNumUS));
    dispatch(setSendConfimationEmailDibs(data.sendEmailConfirmationViaDibs));
    dispatch(
        updateCustomerServiceInfo({
            locationName: data.locationName,
            address: data.locationAddress,
            address2: data.locationAddress2,
            city: data.locationCity,
            state: data.locationState,
            zip: data.locationZipcode,
            custServEmail: data.locationCustomerServiceEmail,
            custServPhone: data.locationPhone,
            currentLocationId: data.locationIdShowing,
            allLocationIds: data.locationIdsAll,
        }),
    );
    dispatch(setDynamicPricingStatus(data.locationDynamicPricing));
    dispatch(
        setAppointmentOpenCloseTimes({
            openTime: data.locationApptsOpenTime,
            closeTime: data.locationApptsCloseTime,
        }),
    );
    dispatch(setConfigDoneLoading(true));
}
