// PaymentStatus.jsx

import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import QuickTest from './paymentStatus';


import retrieveSetupIntent from 'actions/stripe/retrieveSetupIntent';
import clonePaymentMethod from 'actions/stripe/clonePaymentMethod';
import chargeCard from 'actions/stripe/chargeCard';

import { useSelector } from 'store';




const PaymentStatus = () => {
    const [message, setMessage] = useState(null);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const { userid } = useSelector((state) => state.thisuser);
    const { cartPreparedForCheckout, totalAfterCreditApplied, creditApplied } = useSelector((state) => state.cart);

    const [progress, setProgress] = React.useState(0);
    const [buffer, setBuffer] = React.useState(10);
    const [madeTheCharge, setMadeTheCharge] = React.useState(false);
    const [stripeIdHere, setStripeIdHere] = useState('');
    const [pm, setPm] = useState('');
    const [alreadyRan, setAlreadyRan] = useState(false);


    const progressRef = React.useRef(() => { });
    React.useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                setBuffer(progress + diff + diff2);
            }
        };
    });

    React.useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ width: '100%', px: 4, mt: 8, mb: 6 }}>
            <Grid sx={{ my: 3 }}><Typography textAlign="center">Hang tight! Almost done...</Typography></Grid>
            <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
            {/* <QuickTest /> */}
        </Box>
    );
};

export default PaymentStatus;