import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function updateFillRates(cartItems) {
    const url = getApiUrl('/schedule/update-fill-rates');
    let valuetoreturn = {};
    await axios
        .post(url, cartItems)
        .then((res) => {
            valuetoreturn = res.data;
        })
        .catch((error) => {
            valuetoreturn = error;
        });
    return valuetoreturn;
}
