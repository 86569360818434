import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Grid,
    Typography,
    Stack,
    useMediaQuery,
    Button,
    ButtonGroup,
    // Box,
} from '@mui/material';
// assets
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useTheme } from '@mui/material/styles';
import { useSelector, dispatch } from 'store';
import { setPageSelected } from 'store/slices/menu';
// import CartDiscount from './CartDiscount';
import OrderSummary from './../checkout/OrderSummary';
import UserInfo from 'components/user/userInfo';
// import currency from 'currency.js';

// ==============================|| PAYMENT - INCREMENT QUANTITY ||============================== //

const Increment = ({ itemId, quantity, updateQuantity }) => {
    const [value, setValue] = useState(quantity);

    const incrementHandler = () => {
        setValue(value - 1);
        updateQuantity(itemId, value - 1);
    };

    const decrementHandler = () => {
        setValue(value + 1);
        updateQuantity(itemId, value + 1);
    };

    return (
        <ButtonGroup
            size="large"
            variant="text"
            color="inherit"
            sx={{
                border: '1px solid',
                borderColor: 'grey.400',
            }}>
            <Button
                key="three"
                disabled={value <= 1}
                onClick={incrementHandler}
                sx={{
                    pr: 0.75,
                    pl: 0.75,
                    minWidth: '0px !important',
                    '&:enabled': {
                        color: '#495057',
                    },
                }}>
                <RemoveIcon fontSize="inherit" />
            </Button>
            <Button
                key="two"
                sx={{
                    pl: 0.5,
                    pr: 0.5,
                    color: '#495057',
                    fontWeight: 400,
                    fontSize: '0.9rem',
                }}>
                {value}
            </Button>
            <Button
                key="one"
                onClick={decrementHandler}
                disabled={value >= 4}
                sx={{
                    pl: 0.75,
                    pr: 0.75,
                    minWidth: '0px !important',
                    color: '#495057',
                }}>
                <AddIcon fontSize="inherit" />
            </Button>
        </ButtonGroup>
    );
};

Increment.propTypes = {
    itemId: PropTypes.number,
    quantity: PropTypes.number,
    updateQuantity: PropTypes.func,
};

const CheckoutList = ({
    dibsId,
    // checkout,
    // onNext,
    // removeProduct,
    // updateQuantity,
}) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const {
        itemsInCartHere,
        packageItemsInCart,
        totalAfterCreditApplied,
        creditApplied,
    } = useSelector((state) => state.cart);
    const { color } = useSelector((state) => state.dibsstudio.studioConfig);
    const colortouse = `#${color}`;
    const [rows, setRows] = useState(itemsInCartHere);
    const [packageRows, setPackageRows] = useState(packageItemsInCart);
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    useEffect(() => {
        setRows(itemsInCartHere);
        setPackageRows(packageItemsInCart);
    }, [itemsInCartHere, packageItemsInCart]);

    const goConfirmation = () => {
        dispatch(setPageSelected('confirm-purchase'));
        navigate(`/confirm/${dibsId}${location.search}`);
    };
    const goBackCart = () => {
        dispatch(setPageSelected('checkout'));
        navigate(`/checkout/${dibsId}${location.search}`);
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle1">Cart Items</Typography>
                    <Typography variant="caption" sx={{ fontSize: '0.875rem' }}>
                        ({rows.length})
                    </Typography>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <OrderSummary
                    itemsincart={itemsInCartHere}
                    packageItemsInCart={packageItemsInCart}
                    totalPostCredit={totalAfterCreditApplied}
                    creditApplied={creditApplied}
                />
            </Grid>
            <Grid item xs={12}>
                <UserInfo />
            </Grid>
            <Grid item xs={12} sx={{ mb: 5 }}>
                <Grid
                    direction={matchDownMD ? 'column-reverse' : 'row'}
                    container
                    spacing={3}
                    alignItems={matchDownMD ? '' : 'center'}>
                    <Grid item xs={12} md={12} lg={12} sx={{ mt: 2, mb: 3 }}>
                        <Button
                            // component={Link}
                            // to={backtoCartUrl}
                            variant="text"
                            onClick={() => goBackCart()}
                            sx={{
                                color: colortouse,
                            }}
                            startIcon={<KeyboardBackspaceIcon />}>
                            Back to cart
                        </Button>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} sx={{ ml: 3, mt: 4, mb: 6 }}>
                            <Stack spacing={2}>
                                {/* <CartDiscount /> */}
                                <Button
                                    variant="contained"
                                    fullWidth
                                    disableElevation
                                    sx={{
                                        backgroundColor: colortouse,
                                        '&:hover': {
                                            backgroundColor: colortouse,
                                        },
                                    }}
                                    onClick={() => goConfirmation()}>
                                    Review Your Purchase
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

CheckoutList.propTypes = {
    checkout: PropTypes.object,
    updateQuantity: PropTypes.func,
    removeProduct: PropTypes.func,
    onNext: PropTypes.func,
};
export default CheckoutList;
