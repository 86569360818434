import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import CheckoutLayout from 'layouts/checkout';

import { dispatch, useSelector } from 'store';
// import { verifyUserClickedEmailLink } from 'firebaseConfig';
// import { useWhatChanged } from '@simbathesailor/use-what-changed';
import { setPageSelected } from 'store/slices/menu';
import { setLastLocation, setAvailableCredit } from 'store/slices/thisuser';
import {
    setItemsInCartWithPasses,
    setTotal,
    setSubTotal,
    setTotalTax,
    setNeedsToPay,
    setTotalAfterCreditApplied,
    setCreditApplied,
    setNewCartPreparedForCheckout,
    updatePackagesBeingUsed,
    determineExtraAvailability,
    determineExtraAvailabilityNew,
    updatePotentialRemainingCredit
} from 'store/slices/cart';
import setPassesWithEventsCartView from 'actions/checkout/setPassesWithEventsCartView';
import getValuesForTotals from 'actions/checkout/getValuesForTotals';
import getCreditToApply from 'actions/checkout/getCreditToApply';
import getCreditFromDB from 'actions/getCredit';
import associatePassesWithEvents from 'actions/checkout/associatePassesWithEventsNew';

const CheckoutPageView = () => {
    let { dibsId } = useParams();

    const { passes, credit, userid } = useSelector((state) => state.thisuser);
    const { itemsInCartHere, packageItemsInCart, cartPreparedForCheckout, manualOverridePackageApplication } =
        useSelector((state) => state.cart);
    const [calledPasses, setCalledPasses] = useState(true);
    const location = useLocation();

    useEffect(() => {
        dispatch(setPageSelected('checkout'));
        dispatch(setLastLocation(location.pathname));
        setCalledPasses(false);
        const setPasses = async () => {
            await setPassesWithEventsCartView(
                itemsInCartHere,
                packageItemsInCart,
                passes,
                credit,
            ).then(async (res) => {
                const tosend = {
                    res,
                };
                dispatch(setItemsInCartWithPasses(tosend));
                await getCreditToApply(res, credit).then((cc) => {
                    console.log('on charging page - setting credit applied to', cc.creditapplied)
                    dispatch(setTotalAfterCreditApplied(cc.remainingtotal));
                    dispatch(setCreditApplied(cc.creditapplied));
                });
                await getValuesForTotals(res, packageItemsInCart).then(
                    async (res) => {
                        dispatch(setTotal(res.total));
                        dispatch(setSubTotal(res.subtotal));
                        dispatch(setTotalTax(res.tax));
                        if (!res.showSummary) {
                            dispatch(setNeedsToPay(false));
                        } else {
                            dispatch(setNeedsToPay(true));
                        }
                    },
                );
            });
        };

        // setPasses();
    }, [
        credit,
        itemsInCartHere,
        location.pathname,
        packageItemsInCart,
        passes,
    ]);
    useEffect(() => {
        const associatePassesWithCart = async () => {
            await associatePassesWithEvents(itemsInCartHere, passes).then((newcart) => {

                if (newcart.length > 0) {
                    dispatch(setNewCartPreparedForCheckout(newcart));
                    dispatch(updatePackagesBeingUsed(newcart));
                    const extraAvailability = {
                        passes,
                        newcart
                    }
                    dispatch(determineExtraAvailabilityNew(extraAvailability));
                }
            });
        };
        if (!manualOverridePackageApplication) {
            associatePassesWithCart();
        }
    }, [itemsInCartHere, manualOverridePackageApplication, passes])
    useEffect(() => {
        dispatch(updatePackagesBeingUsed(cartPreparedForCheckout));
        const extraAvailability = {
            passes,
            newcart: cartPreparedForCheckout
        }
        dispatch(determineExtraAvailabilityNew(extraAvailability));
    }, [cartPreparedForCheckout, passes])
    useEffect(() => {
        const updateCreditApplied = async () => {
            await getCreditFromDB(dibsId, userid).then((res) => {
                dispatch(setAvailableCredit(res));
            }).then(async () => {
                await getCreditToApply(cartPreparedForCheckout, credit).then((res) => {
                    const { remainingtotal, creditapplied, remainingcreditbalance } = res;
                    dispatch(setTotalAfterCreditApplied(remainingtotal));
                    dispatch(setCreditApplied(creditapplied));
                    dispatch(updatePotentialRemainingCredit(remainingcreditbalance));

                })
            });

        };
        updateCreditApplied();
    }, [cartPreparedForCheckout, credit, userid, dibsId])
    return <CheckoutLayout dibsId={dibsId} />;
};

export default CheckoutPageView;
