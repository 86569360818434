import { Grid, Typography, Button, Fade } from '@mui/material';

import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';

import { useSelector, dispatch } from 'store';
import { setIsProcessing, setShowCCCapture } from 'store/slices/cart';

import SubCard from 'ui-component/cards/AccountSubCard';
import IndividualCard from 'components/payment/profileIndividualCardDisplay';
import CollectCC from 'components/stripe/CollectCardInfo';
import setupIntent from 'actions/stripe/createStripeSetupIntent';

// ==============================|| DISPLAY CC CARDS ||============================== //
import './credit-card.css';

const DisplayCards = ({ payments }) => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [paymentsToDisplay, setPaymentsToDisplay] = useState([]);
    const [showPayments, setShowPayments] = useState(false);
    const [showCardCapture, setShowCardCapture] = useState(false);
    const [setupIntentIsReady, setSetupIntentIsReady] = useState(false);
    const { userid, firstname, lastname, email, stripeid } =
        useSelector((state) => state.thisuser);
    const { showCCCapture } = useSelector((state) => state.cart);
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const toggleShowCardCapture = async () => {
        window.localStorage.setItem('stripeReady', false);
        setShowCardCapture(!showCardCapture);
        dispatch(setShowCCCapture(!showCardCapture))
        dispatch(setIsProcessing(false));
        const onDibs = true;
        setupIntent(
            stripeid,
            userid,
            email,
            `${firstname} ${lastname}`,
            dibsStudioId,
            onDibs,
        )
            .then((res) => {
                window.localStorage.setItem('clientSecret', res.clientSecret);
                window.localStorage.setItem('clientSecretDate', new Date().toISOString());
                window.localStorage.setItem('intentStatus', 'requires_payment_method');
                dispatch(setShowCCCapture(true));
                setSetupIntentIsReady(true);
            })
            .catch((err) => {
                console.log('error is - after creating stripe intent', err);
            });
    };
    useEffect(() => {
        if (!showCCCapture) {
            setShowCardCapture(false);
        }
    }, [showCCCapture]);

    useEffect(() => {
        setPaymentsToDisplay(payments);
        if (payments.length > 0) {
            // setPaymentsToDisplay(payments);
            setShowPayments(true);
        } else {
            setShowPayments(false);
        }
    }, [payments]);
    const secondaryButton = (
        <Grid item xs={12}>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                id="display-cards-container-button"
                alignItems="flex-end">
                <Button
                    variant="text"
                    onClick={toggleShowCardCapture}
                    sx={{
                        justifyContent: 'flex-end',
                        pl: 0.5,
                    }}>
                    {showCardCapture ? 'Go Back' : 'Add New Card'}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <Fade in timeout={600}>
            <Grid
                item
                xs={window.isTiny ? 12 : 11}
                md={window.isTiny ? 11.5 : 11}
                sx={{ mb: 2, mr: window.isTiny ? 0 : 3 }}>
                <SubCard title="Payment Methods" secondary={secondaryButton}>
                    <Grid container spacing={gridSpacing}>
                        {/* {hasError && (
                            <Grid item xs={12}>
                                <Typography variant="errorText">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}
                        {hasSuccess && (
                            <Grid item xs={12}>
                                <Typography variant="successText">
                                    {successMessage}
                                </Typography>
                            </Grid>
                        )} */}
                        {showCardCapture && setupIntentIsReady && (
                            <Grid item xs={12}>
                                <Grid container justifyContent="center">
                                    <Grid item xs={12} md={10} lg={8}>
                                        <CollectCC intentType="setup" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        {paymentsToDisplay.length === 0 && (
                            <Grid item xs={12} sx={{ mt: 2 }}>
                                <Typography>You do not have any payment methods on file.</Typography>
                            </Grid>
                        )}

                        <Grid item xs={12} id="payments-display-cards">
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start">
                                {/* {showCardCapture && addPaymentMethod} */}
                                {showPayments &&
                                    !showCardCapture &&
                                    paymentsToDisplay.map((payment, i) => (
                                        <Grid
                                            key={i}
                                            item
                                            xs={4.5}
                                            md={4}
                                            lg={3}
                                            xl={2.5}
                                            sx={{ mb: 2, mr: 5, mt: 2, pl: 2 }}>
                                            <IndividualCard
                                                key={i}
                                                cc={payment}
                                                exemptFromSelect
                                            />
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Fade>
    );
};
export default DisplayCards;
