import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import * as React from 'react';

import { useSelector } from 'store';
import { dispatch } from 'store';
import UpdateCartThis from './updateCart';

import updateConfigs from 'actions/updateConfigs';
import { setDibsStudioId, setLanguageToDisplay } from 'store/slices/dibsstudio';
import { refreshCartItemsWhenChangingStudios } from 'store/slices/cart';
import BookNowButton from 'assets/icons/bookNowButton';
import useConfig from 'hooks/useConfig';

const UpdateConfigs = ({ children }) => {
    const { dibsId } = useParams();
    const [searchParams] = useSearchParams();
    const lang = searchParams.get('lang') || 'en';
    const { locale, onChangeLocale } = useConfig();
    const { config } = useSelector((state) => state.dibsstudio);
    const { widgetIsOpen } = useSelector((state) => state.menu);
    const { dibsStudioId, buttonControlsWidget, widgetOpenStyle } = config;
    const [showBookNow, setShowBookNow] = React.useState(false);

    useEffect(() => {
        if (!buttonControlsWidget) {
            setShowBookNow(false);
        } else if (buttonControlsWidget && !widgetIsOpen) {
            setShowBookNow(true);
        } else {
            setShowBookNow(false);
        }
    }, [buttonControlsWidget, widgetIsOpen]);

    useEffect(() => {
        dispatch(refreshCartItemsWhenChangingStudios({ dibsId: dibsId }));
    }, [dibsId]);
    useEffect(() => {
        if (locale !== lang) {
            onChangeLocale(lang);
        }
        dispatch(setLanguageToDisplay(lang));
    }, [lang, locale, onChangeLocale]);
    useEffect(() => {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const url = `${baseURL}/widget/get-basic-config`;
        // const url = 'http://192.168.0.36:3001/api/v2/widget/get-basic-config';
        const updateBasicConfigs = async () => {
            await axios
                .post(url, { dibsStudioId: dibsId })
                .then((response) => {
                    dispatch(setDibsStudioId(dibsId));
                    if (response.data) {
                        updateConfigs(response.data);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        updateBasicConfigs();
        if (dibsId !== dibsStudioId) {
            dispatch(setDibsStudioId(dibsId));
        }
        dispatch(refreshCartItemsWhenChangingStudios({ dibsId: dibsId }));
    }, [dibsId, dibsStudioId]);
    // if (showBookNow) return <BookNowButton />;
    // return <DisplayAsModal children={children} />;
    return <UpdateCartThis children={children} />;
};
export default UpdateConfigs;
UpdateConfigs.propTypes = {
    children: PropTypes.node,
};
