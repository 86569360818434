import axios from 'axios';

export default async function setFirebaseAuthentication(userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/set-firebase-auth`;
    let datatosend = {};
    const setFirebase = async () => {
        await axios
            .post(basicurl, {
                userid,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error setting firebase authentication in database: ${err}`,
                );
                return err;
            });
    };
    await setFirebase();
    return datatosend;
}
