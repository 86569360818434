
export default async function matchPaymentMethods(paymentOptions, cardtocharge) {

    let paymentInfoToReturn;
    paymentOptions.forEach((payment) => {
        const { id, card } = payment;
        if (id === cardtocharge) {
            const { exp_month, exp_year, last4, brand } = card
            paymentInfoToReturn = {
                pmid: id,
                exp_month,
                exp_year,
                last4,
                brand
            }
        }
    })
    // if there is no payment info to return, then just return the first one
    if (!paymentInfoToReturn && paymentOptions.length > 0) {
        const { card, id } = paymentOptions[0];
        const { exp_month, exp_year, last4, brand } = card
        paymentInfoToReturn = {
            pmid: id,
            exp_month,
            exp_year,
            last4,
            brand
        }
    }

    return paymentInfoToReturn;
}
