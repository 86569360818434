import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function verifyCode(
    promo,
    userid,
    pack,
    dibsStudioId,
    cart,
) {
    const url = getApiUrl('/verify-promo-code-exists');
    // const urlreferrer = getApiUrl('/create-friend-referral');
    let valuetosend = {};
    await axios
        .post(url, { promo, userid, pack, dibsStudioId, cart })
        .then(async (res) => {
            valuetosend = res.data;
        })
        .catch((error) => {
            valuetosend = error;
        });
    return valuetosend;
}
