import { useEffect, useState } from 'react';
import { Grid, Box, Collapse, Typography, Divider } from '@mui/material';
import StripeInfo from 'components/stripe/new/loadStripeTiny';
import CollapseComponent from 'views/wrappers/collapse';

const CollectCardInfo = ({ intentType, cancelAction, donotshowcancelbutton, endingAction }) => {

    const setup = intentType === 'setup';
    return (
        <Grid container justifyContent="center">
            <Grid item xs={11.5} id="item-holding-enter-cc-info">
                <Grid item id="enter-cc-info-tiny" xs={12}>
                    <Grid container justifyContent="center">
                        <Grid item xs={11}>
                            <Box id="box-holding-enter-cc-info-tiny">
                                <StripeInfo includeButton setup={setup} cancelAction={cancelAction} endingAction={endingAction} donotshowcancelbutton={donotshowcancelbutton} />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default CollectCardInfo;
