import { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Button } from '@mui/material';

import { dispatch, useSelector } from 'store';
import { setCurrentStep } from 'store/slices/thisuser';
import { setCardToCharge } from 'store/slices/cart';

import DefaultCard from './defaultCard';
import NextPaymentMethod from './nextPaymentMethod';
import AddNewCard from 'components/stripe';
import { clearAllStatusMessageReducer } from 'store/slices/error';

const EnterCCInfo = () => {
    console.log('enter cc info is running');
    const { email } = useSelector((state) => state.thisuser);
    const { config, studioConfig } = useSelector((state) => state.dibsstudio);
    const { total, totalAfterCreditApplied } = useSelector(
        (state) => state.cart,
    );
    const { color } = studioConfig;
    const colortoshow = `#${color}`;
    // const { total } = itemsInCart;
    const { dibsStudioId } = config;
    const [last4send, setLast4send] = useState('');
    const [cardBrand, setCardBrand] = useState('');
    const [expiText, setExpiText] = useState('');
    const [hasCards, setHasCards] = useState(false);
    const [cardid, setCardid] = useState(null);
    const [morethanone, setMorethanone] = useState(false);
    const [remainingCards, setRemainingCards] = useState([]);
    const [showCardCapture, setShowCardCapture] = useState(false);
    const [cardMessage, setCardMessage] = useState('Choose A Payment Method');
    const [runOnce, setRunOnce] = useState(true);
    const [showCCInfo, setShowCCInfo] = useState(true);
    const toggleShowCardCapture = () => {
        setShowCardCapture(!showCardCapture);
        dispatch(clearAllStatusMessageReducer());
    };
    useEffect(() => {
        if (email === '') {
            dispatch(setCurrentStep('email'));
        }
        if (hasCards) {
            setCardMessage('Choose A Payment Method');
        } else {
            setCardMessage('Add A New Payment Method');
        }
        if (totalAfterCreditApplied <= 0) {
            setShowCCInfo(false);
        }
        const getPaymentInfo = async () => {
            const baseURL = process.env.REACT_APP_BASE_URL;
            const basicurl = `${baseURL}/get-client-payment-methods`;
            await axios
                .post(basicurl, { dibsStudioId: dibsStudioId, email: email })
                .then((response) => {
                    const { stripeCustomer, exists } = response.data;
                    if (exists) {
                        const { data, has_more } = stripeCustomer;
                        if (data.length === 0) {
                            setHasCards(false);
                        } else {
                            const defaultCard = data[0];
                            const { card, id } = defaultCard;
                            const { brand, last4, exp_month, exp_year } = card;
                            const expText = `${exp_month}/${exp_year}`;
                            setLast4send(last4);
                            setCardBrand(brand);
                            setExpiText(expText);
                            setMorethanone(has_more);
                            dispatch(setCardToCharge(id));
                            setCardid(id);
                            setRemainingCards(data.slice(1));
                            setHasCards(true);
                        }
                    }
                })
                .catch((error) => {
                    console.log(
                        `error displaying payment methods is: ${error}`,
                    );
                });
        };
        if (runOnce) {
            getPaymentInfo();
            setRunOnce(false);
        }
    }, [
        dibsStudioId,
        email,
        hasCards,
        runOnce,
        total,
        totalAfterCreditApplied,
    ]);
    if (!showCCInfo) {
        return (
            <>
                <Grid container>
                    <Grid item xs={12} sx={{ mb: 4 }}>
                        Payment Info
                    </Grid>
                    <Grid item xs={12}>
                        No payment method required.
                    </Grid>
                </Grid>
            </>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12} sx={{ mb: 4 }}>
                {cardMessage}
            </Grid>
            <Grid item xs={12}>
                {!showCardCapture && (
                    <Grid container>
                        {hasCards && (
                            <Grid item key={cardid} sx={{ mr: 3, mb: 3 }}>
                                <DefaultCard
                                    id={cardid}
                                    defaultLast4={last4send}
                                    cardBrand={cardBrand}
                                    more={morethanone}
                                    exp={expiText}
                                />
                            </Grid>
                        )}
                        {remainingCards.map((cc) => {
                            const { id, card } = cc;
                            const { last4, exp_month, exp_year, brand } = card;
                            const expText = `${exp_month}/${exp_year}`;
                            return (
                                <Grid item key={id} sx={{ mr: 3, mb: 3 }}>
                                    <NextPaymentMethod
                                        id={id}
                                        nextlast4={last4}
                                        exp={expText}
                                        cardBrand={brand}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Grid>
            <Grid item xs={8}>
                {!showCardCapture && (
                    <Grid item xs={6} sx={{ mt: 2 }}>
                        <Button
                            variant="text"
                            onClick={toggleShowCardCapture}
                            sx={{ justifyContent: 'flex-start', pl: 0.5 }}>
                            Add New Card
                        </Button>
                    </Grid>
                )}
                {showCardCapture && (
                    <>
                        <Grid item xs={9} sx={{ mt: 1, mb: 2 }}>
                            Enter your credit card information
                        </Grid>
                        <Grid item xs={8} sx={{ mt: 1, mb: 2 }}>
                            <AddNewCard color={colortoshow} />
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
export default EnterCCInfo;
