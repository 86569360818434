import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function sendConfirmationEmail(dibsId, userid, cartPrepared, creditApplied, totalAfterCreditApplied) {
    const url = getApiUrl('/send-class-email-confirmation');
    let valuetoreturn;
    await axios
        .post(url, {
            dibsId,
            userid,
            cartPrepared,
            creditApplied,
            totalAfterCreditApplied
        })
        .then((res) => {
            console.log('\n\n\n\n\nresponse after sending confirmation email appt for class is', res);
            const { data } = res;
            valuetoreturn = data;
        })
        .catch((error) => {
            console.log(
                'there was an error - sending class confirmaton email',
                error,
            );
        });
    return valuetoreturn;
}
